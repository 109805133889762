import api from '../../../api';

class PostUsersManualAsset {

  post(id, data) {
    return api.post('/v1/users/'+id+'/manual/asset', data);
  }
}

export default new PostUsersManualAsset();



export default {

    it: {
        connecting_to: 'Connessione a',
        connecting_heading: 'Connettere un Exchange',
        list_insert_name: "Inserisci il nome dell'exchange che vuoi connettere (eg. Binance)",
        list_follow_instruction: 'Segui le istruzioni che compariranno.',
        login_to: 'Effettua il login su',
        list_create_api: "Crea un'API Key chiamata 360Track con permessi di sola lettura.",
        list_insert_info: 'Inserisci le informazioni generate nei campi corrispondenti.',
        missing_required_field: 'Se non riesci a trovare il campo richiesto, per favore',
        missing_contact_us: 'contattaci.',
    },

    es: {
        connecting_to: 'Conectando a',
        connecting_heading: 'Conexi�n de un Exchange',
        list_insert_name: 'Inserta el nombre del exchange que quieres conectar (eg. Binance)',
        list_follow_instruction: 'Siga las instrucciones que se monstrar�n.',
        login_to: 'Inicia sesi�n en',
        list_create_api: 'Crea una nueva API Key llamada 360Track con acceso de solo lectura.',
        list_insert_info: 'Inserta la informaci�n que se ha generado en los campos coincidentes.',
        missing_required_field: 'Si no puedes encontrar el campo requerido, puedes',
        missing_contact_us: 'cont�ctarnos.',
    },

    en: {
        connecting_to: 'Connecting to',
        connecting_heading: 'Connect your Exchange',
        list_insert_name: 'Insert the name of the exchange you want to connect (eg. Binance)',
        list_follow_instruction: 'Follow the instructions that will be displayed.',
        login_to: 'Login to',
        list_create_api: 'Create a new API Key called 360Track with read-only access.',
        list_insert_info: 'Insert the information that have been generated in the matching fields.',
        missing_required_field: 'If you cannot find the required field, please',
        missing_contact_us: 'contact us.',
    },

    fr: {
        connecting_to: 'Connexion �',
        connecting_heading: 'Connecter un Exchange',
        list_insert_name: "Ins�rez le nom de l'exchange que vous souhaitez connecter (eg. Binance)",
        list_follow_instruction: "Suivez les instructions qui s'afficheront.",
        login_to: 'Connectez-vous �',
        list_create_api: 'Cr�ez une nouvelle API Key appel�e 360Track avec un acc�s en lecture seule.',
        list_insert_info: 'Ins�rez les informations g�n�r�es dans les champs correspondants.',
        missing_required_field: 'Si vous ne trouvez pas le champ requis, veuillez',
        missing_contact_us: 'nous contacter.',
    }

};
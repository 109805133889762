



export default {

    it: {

        your_notes: 'Riepilogo',
        write_here_notes: 'Questa è la vista default con il riepilogo di dati importanti, aggiornati in tempo reale. Modificala come vuoi, interagisci, sposta, elimina e aggiungi blocchi! 🥳 E ricorda che puoi aggiungere e modificare tutti i testi che vuoi per note e commenti. Basta cliccare sui testi o crearne di nuovi!🖱️',

        // dashboard
        your_dashboard: 'La tua Dashboard',
        your_dashboard_of: 'La tua Dashboard di',
        dashboard_introduction_first: 'Clicca su Asset per vedere i tuoi asset aggregati, su Connessioni per visionare i tuoi portfolio e le tue API. 😄',
        dashboard_introduction_second: 'P.S. Lo sai che puoi modificare questo messaggio cliccandoci sopra? 🤓',
        discover_360Track: 'Scopri in anteprima il nuovo 360Track',
        choose_how_manage: ' - Scegli tu come gestire i tuoi investimenti in base alle tue esigenze',
        connect_and_monitor: ' - Connetti e monitora più di 100.000 asset in maniera centralizzata',
        add_delete_customize: ' - Aggiungi, cancella e personalizza il testo a tuo piacimento',
        reorder_sections: ' - Riordina le sezioni come meglio preferisci',
        manage_the_tables: ' - Gestisci le tabelle e le viste in base alle tue esigenze',
        in_case_of_bugs: 'In caso di bug non esitare a contattarci',
        we_are_available: 'Siamo a tua disposizione per risolvere ogni tuo problema 😊',
    },

    es: {

        your_notes: 'Visión General',
        write_here_notes: 'Esta es la visión general con los datos importantes, actualizada en tiempo real. ¡Edítala como quieras, interactúa, mueve, borra y añade bloques! 🥳 Y recuerda que puedes añadir y editar tantos textos como quieras para notas y comentarios. Sólo tienes que hacer clic en los textos o crear otros nuevos! 🖱️',

        // dashboard
        your_dashboard: 'Tu Dashboard',
        your_dashboard_of: 'Tu Dashboard de',
        dashboard_introduction_first: 'Haga clic en Activos para ver sus activos agregados, en Conexiones para ver sus portfolios y API. 😄',
        dashboard_introduction_second: '¿Sabías que puedes editar este mensaje haciendo clic en él? 🤓',
        discover_360Track: 'Obtenga una vista previa del nuevo 360Track',
        choose_how_manage: ' - Tú eliges cómo gestionar tus inversiones según tus necesidades',
        connect_and_monitor: ' - Conecte y supervise más de 100 000 activos de forma centralizada',
        add_delete_customize: ' - Agregue, elimine y personalice el texto a su gusto',
        reorder_sections: ' - Reorganiza las secciones como quieras',
        manage_the_tables: ' - Gestiona tablas y vistas según tus necesidades',
        in_case_of_bugs: 'En caso de error, no dude en contactarnos',
        we_are_available: 'Estamos a tu disposición para solucionar todos tus problemas 😊',
    },

    en: {

        your_notes: 'Default Overview',
        write_here_notes: 'This is the default view with the summary of important data, updated in real time. Edit it as you wish. Interact, move, delete and add blocks! 🥳 And remember that you can add and edit as many texts as you like for notes and comments. Simply click on the texts or create new ones! 🖱️',

        // dashboard
        your_dashboard: 'Your Dashboard',
        your_dashboard_of: 'Your Dashboard of',
        dashboard_introduction_first: 'Click on Asset to see your aggregated assets, on Connections to display your portfolios and APIs. 😄',
        dashboard_introduction_second: 'Do you know you can edit this message by clicking on it? 🤓',
        discover_360Track: 'Get a preview of the new 360Track',
        choose_how_manage: ' - You choose how to manage your investments according to your needs',
        connect_and_monitor: ' - Connetti e monitora più di 100.000 asset in maniera centralizzata',
        add_delete_customize: ' - Connect and monitor more than 100,000 assets centrally',
        reorder_sections: ' - Rearrange the sections however you like',
        manage_the_tables: ' - Manage tables and views according to your needs',
        in_case_of_bugs: 'In case of a bug, please do not hesitate to contact us',
        we_are_available: 'We are at your service to solve all your problems 😊',
    },

    fr: {

        your_notes: 'Récapitulatif',
        write_here_notes: "Ici vous trouvez la page récapitulative avec les données les plus importantes, qui sont mises à jour en temps réel. Modifiez la page comme vous le souhaitez, interagissez, déplacez, supprimez et ajoutez des blocs ! 🥳 Et n'oubliez pas que vous pouvez ajouter et modifier autant de textes que vous le souhaitez pour les notes et les commentaires. Il suffit de cliquer sur les textes ou d'en créer de nouveaux ! 🖱️",

        // dashboard
        your_dashboard: 'Votre Dashboard',
        your_dashboard_of: 'Votre Dashboard de',
        dashboard_introduction_first: 'Cliquez sur Ressources pour voir vos ressources agrégées, Connexions pour voir vos portefeuilles et vos API. 😄',
        dashboard_introduction_second: 'Saviez-vous que vous pouvez modifier ce message en cliquant dessus? 🤓',
        discover_360Track: 'Découvrez un aperçu du nouveau 360Track',
        choose_how_manage: ' - Choisissez comment gérer vos investissements en fonction de vos besoins',
        connect_and_monitor: ' - Connectez et surveillez plus de 100 000 actifs de manière centralisée',
        add_delete_customize: ' - Ajoutez, supprimez et personnalisez le texte à votre guise',
        reorder_sections: ' - Réorganisez les sections comme vous le souhaitez',
        manage_the_tables: ' - Gérez les tables et les vues selon vos besoins',
        in_case_of_bugs: 'En cas de bug merci de nous contacter',
        we_are_available: 'Nous sommes à votre disposition pour résoudre tous vos problèmes 😊',
    },
};
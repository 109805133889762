<template>

    <div @click="openAddBlockModal"
         :style="cssProps">
        <span class="material-icons white body-large">
            add
        </span>
        <p class="label-large white">
            {{ $t('dashboard.asset_details.add_block') }}
        </p>
    </div>

</template>

<script>
    export default {
        name: 'Add-Block-Component',
        props: ['show'],
        emits: ['addBlockAction'],
        methods: {
            openAddBlockModal() {
                this.$emit('addBlockAction');
            }
        },
        computed: {
            cssProps() {
                return {
                    '--display-mode': (this.show) ? 'flex' : 'none'
                };
            }
        }
    }
</script>

<style scoped>

    div {
        cursor: pointer;
        display: var(--display-mode);
        align-items: center;
        width: fit-content;
        height: fit-content;
        gap: 4px;
        padding: 8px 16px;
        background-color: var(--placeholder);
        border-radius: var(--middle-rounded);
    }

</style>
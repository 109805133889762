<template>
    <div id="bottombar-menu">
        <bottombar-navigation-item-component :to="'/dashboard'"
                                             :icon="'dashboard'"
                                             :name="$t('navigation.dashboard')" />
        <bottombar-navigation-item-component :to="'/rewards'"
                                             :icon="'how_to_vote'"
                                             :name="$t('navigation.vote')" />
        <special-bottombar-navigation-item-component :to="'/connect'"
                                                     :icon="'add_circle'"
                                                     :name="$t('navigation.connect')" />
        <bottombar-navigation-item-component :to="'/support'"
                                             :icon="'help_outline'"
                                             :name="$t('navigation.support')" />
        <div style="width:60px;"/>
    </div>
</template>

<script>

    import BottombarNavigationItemComponent from '@/components/navigation/bottombar/NavigationItem';
    import SpecialBottombarNavigationItemComponent from '@/components/navigation/bottombar/NavigationItemSpecial';

    export default {
        name: 'Bottombar-Component',
        components: {
            BottombarNavigationItemComponent,
            SpecialBottombarNavigationItemComponent
        }
    }
</script>

<style scoped>
    div#bottombar-menu {
        height: 64px;
        background-color: var(--primary);
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 1;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
</style>
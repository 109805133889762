<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">



                    <div id="connection-delete-modal">
                        <h1 class="headline-small black">
                            {{ $t('actions.delete_connection_warning') }}
                            "{{ connectionName }}"
                        </h1>
                        <button-eliminate-animation-component />
                        <p class="body-large black">
                            {{ $t('actions.to_eliminate') }}
                            "{{ connectionName }}"
                            {{ $t('actions.type_name') }}
                            "{{ connectionName }}"
                            {{ $t('actions.then_click_delete') }}
                        </p>
                        <p class="body-large black">
                            {{ $t('actions.you_will_lose_data') }}
                            "{{ connectionName }}".
                        </p>
                        <div class="input-fields">
                            <input-field-component v-model="inputValue.doubleCheck"
                                                   type="text"
                                                   :label="$t('actions.type_here')"
                                                   :isValid="isValid.doubleCheck" />
                        </div>
                        <div class="horizontal-frame">
                            <button class="primary full-rounded"
                                    @click="deleteConnection">
                                <p class="label-large">
                                    {{ $t('actions.delete_button') }}
                                </p>
                            </button>
                            <button class="tertiary full-rounded"
                                    @click="closeModal">
                                <p class="label-large">
                                    {{ $t('actions.abort_button') }}
                                </p>
                            </button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </Transition>
</template>



<script>

    import InputFieldComponent from '@/components/fields/InputField';
    import ButtonEliminateAnimationComponent from '@/components/lottieImages/ClickHere';

    import DeleteUsersCryptoExchanges from "@/services/users/crypto/exchanges/delete.service";
    import DeleteUsersCryptoWallets from "@/services/users/crypto/wallet/address/delete.service";
    import DeleteUsersManualAsset from "@/services/users/manual/asset/delete.service";
    import DeleteUsersOpenbankingAccount from "@/services/users/openbanking/account/delete.service";

    import EventBus from "@/common/EventBus";

    export default {
        name: 'Delete-Connection-Modal',
        props: ['show', 'pageObject', 'connectionName'],
        emits: ['close'],
        components: {
            InputFieldComponent,
            ButtonEliminateAnimationComponent
        },
        data() {
            return {
                inputValue: {
                    doubleCheck: ''
                },
                isValid: {
                    doubleCheck: true
                }
            };
        },
        computed: {
            connections() {
                return this.$store.getters['connections/getConnections']
            },
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },

            deleteConnection() {

                if (this.pageObject == null ||
                    this.pageObject.properties == null ||
                    this.pageObject.properties.connection == null ||
                    this.pageObject.properties.connection.service == null ||
                    this.pageObject.properties.connection.id == null) {

                    return;
                }

                if (this.inputValue.doubleCheck !== this.connectionName) {
                    this.isValid.doubleCheck = false;
                    return;
                } else {
                    this.isValid.doubleCheck = true;
                }

                const service = this.pageObject.properties.connection.service;
                const id = this.pageObject.properties.connection.id;
                const source = this.connections.find(x => x.id === id).source;



                if (service === 'manual') {
                    this._deleteConnection(DeleteUsersManualAsset, source);
                } else if (service === 'exchange') {
                    this._deleteConnection(DeleteUsersCryptoExchanges, source);
                } else if (service === 'wallet') {
                    this._deleteConnection(DeleteUsersCryptoWallets, source);
                } else if (service === 'openbanking') {

                    const user_id = this.$store.getters['auth/getUserId'];
                    const sources = this.connections
                        .find(x => x.source === source).balance
                        .map(x => x.accountId);

                    // delete all the multicurrency accounts
                    for (let i = 0; i < sources.length - 1; i++) {
                        DeleteUsersOpenbankingAccount.delete(user_id, sources[i]).then(
                            () => {
                                // do nothing
                            },
                            (error) => {
                                if (error.response && error.response.status === 403) {
                                    EventBus.dispatch("logout");
                                }
                            }
                        );
                    }

                    // delete the last account
                    this._deleteConnection(DeleteUsersOpenbankingAccount, sources[sources.length - 1]);
                }

            },
            _deleteConnection(ServiceApi, source) {

                const user_id = this.$store.getters['auth/getUserId'];
                const page_id = this.pageObject.properties.connection.id;

                ServiceApi.delete(user_id, source).then(
                    () => {
                        this.$store.dispatch('pages/deletePage', {
                            pageId: page_id
                        }).then(() => {
                            // to update the connections displayed in the table
                            this.$store.dispatch('connections/refresh');
                        }).then(() => {
                            // the page does not exist anymore
                            this.$router.go(-1);
                        });
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    });
            },
        }
    }
</script>

<style scoped>

    div#connection-delete-modal {
        width: 100%;
        max-width: 808px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    div#connection-delete-modal > div.input-fields {
        width: 100%;
        max-width: 408px;
        display: flex;
    }

        div#connection-delete-modal > div.horizontal-frame {
            display: flex;
            justify-content: center;
            gap: 16px;
        }
</style>
<template>

    <div id="profile-view">
        <h1 class="headline-small black">
            {{ $t('profile.profile') }}
        </h1>

        <h2 class="title-medium black bold">
            {{ $t('profile.personal_information') }}
        </h2>

        <profile-information-component />

        <h2 class="title-medium black bold">
            {{ $t('profile.system_preference') }}
        </h2>

        <system-preferences-component />

        <h2 class="title-medium black bold">
            {{ $t('profile.manage_api_connections') }}
        </h2>

        <profile-connections-component />

        <h2 class="title-medium black bold">
            {{ $t('profile.manage_manual_portfolios') }}
        </h2>

        <profile-portfolios-component />

        <h2 class="title-medium black bold"
            id="views">
            {{ $t('profile.your_views') }}
        </h2>

        <profile-views-component />

        <h2 class="title-medium black bold">
            {{ $t('profile.your_owners') }}
        </h2>

        <profile-owners-component />

        <h2 class="title-medium black bold">
            {{ $t('profile.account_and_privacy') }}
        </h2>

        <terms-and-conditions-component v-if="$breakpoints.isXS()" />

        <div class="buttons-frame">
            <delete-profile-button-component />
        </div>



        <page-overlay-component v-if="pageQuery"
                                :pageId="pageQuery" />
    </div>
</template>

<script>

    import ProfileInformationComponent from '@/components/profile/ProfileInformation';
    import SystemPreferencesComponent from '@/components/profile/SystemPreferences';
    import ProfileConnectionsComponent from '@/components/profile/ProfileConnections';
    import ProfilePortfoliosComponent from '@/components/profile/ProfilePortfolios';
    import ProfileViewsComponent from '@/components/profile/ProfileViews';
    import ProfileOwnersComponent from '@/components/profile/ProfileOwners';
    import TermsAndConditionsComponent from '@/components/profile/TermsAndConditions';
    import DeleteProfileButtonComponent from '@/components/profile/DeleteProfileButton';
    

    import PageOverlayComponent from '@/components/dashboard/PageOverlay';

    export default {
        name: 'Profile-View',
        components: {
            ProfileInformationComponent,
            SystemPreferencesComponent,
            ProfileConnectionsComponent,
            ProfilePortfoliosComponent,
            ProfileViewsComponent,
            ProfileOwnersComponent,
            TermsAndConditionsComponent,
            DeleteProfileButtonComponent,
            PageOverlayComponent
        },
        computed: {
            pageQuery() {
                return this.$route.query.p;
            },
        }
    }
</script>

<style scoped>
    div#profile-view {
        margin: 32px auto 32px auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        width: 100%;
        max-width: 808px;
        position: relative;
    }
    @media (max-width: 599px) {
        div#profile-view {
            padding: 0px 8px;
            width: calc(100% - 16px);
        }
    }

    @media (max-width: 599px) {
        div#profile-view > h2 {
            width: fit-content;
            padding-bottom: 2px;
            border-bottom: 2px solid var(--secondary);
        }
    }
    @media (min-width: 600px) {
        div#profile-view > h2 {
            padding-left: 16px;
            border-left: 2px solid var(--secondary);
        }
    }

        div#profile-view > div.buttons-frame {
            display: flex;
        }
</style>
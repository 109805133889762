

import asset_details from './dashboard/assetDetails.js';
import connection_details from './dashboard/connectionDetails.js';
import owner_details from './dashboard/ownerDetails.js';
import initialization from './dashboard/initialization.js';

export default {

    it: {
        empty_vault: 'Sembra che il tuo Vault sia vuoto',
        connect_assets: 'Inizia la tua avventura connettendo i tuoi asset',
        connect_first_asset: 'Connetti il tuo primo asset',

        h1: 'Titolo 1',
        h2: 'Titolo 2',
        h3: 'Titolo 3',
        paragraph: 'Paragrafo',
        table: 'Tabella',
        assets_in_portfolio: 'Asset in portfolio',

        transform_in_h1: 'Trasforma in titolo 1',
        transform_in_h2: 'Trasforma in titolo 2',
        transform_in_h3: 'Trasforma in titolo 3',
        transform_in_paragraph: 'Trasforma in paragrafo',
        add_table: 'Aggiungi tabella',
        add_row: 'Aggiungi testo',
        delete_row: 'Cancella blocco',
        del: 'DEL',

        drag: 'Trascina',
        to_move: 'per muovere',
        do: 'Fai',
        click: 'clic',
        to_open_menu: 'per aprire il menu',
        to_add_row: 'per aggiungere una riga',

        asset_details: asset_details.it,
        connection_details: connection_details.it,
        owner_details: owner_details.it,
        initialization: initialization.it,
    },

    es: {
        empty_vault: 'Parece que tu vault est� vac�o',
        connect_assets: 'Empieza tu viaje conectando s�tus activos',
        connect_first_asset: 'Conecta tu primer activo',

        h1: 'T�tulo 1',
        h2: 'T�tulo 2',
        h3: 'T�tulo 3',
        paragraph: 'P�rrafo',
        table: 'Tabla',
        assets_in_portfolio: 'Activos en Portfolio',

        transform_in_h1: 'Transformar en t�tulo 1',
        transform_in_h2: 'Transformar en t�tulo 2',
        transform_in_h3: 'Transformar en t�tulo 3',
        transform_in_paragraph: 'Transformar en p�rrafo',
        add_table: 'A�adir tabla',
        add_row: 'A�adir texto',
        delete_row: 'Borrar bloque',
        del: 'DEL',

        drag: 'Arrastre',
        to_move: 'para mover',
        do: 'Haga',
        click: 'clic',
        to_open_menu: 'para abrir el men�',
        to_add_row: 'para agregar una filaa',

        asset_details: asset_details.es,
        connection_details: connection_details.es,
        owner_details: owner_details.es,
        initialization: initialization.es,
    },

    en: {
        empty_vault: 'It looks like your vault is empty',
        connect_assets: 'Start your journey by connecting your assets',
        connect_first_asset: 'Connect your first asset',

        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
        paragraph: 'Paragraph',
        table: 'Table',
        assets_in_portfolio: 'Assets in portfolio',

        transform_in_h1: 'Trasform in Heading 1',
        transform_in_h2: 'Trasform in Heading 2',
        transform_in_h3: 'Trasform in Heading 3',
        transform_in_paragraph: 'Trasform in paragraph',
        add_table: 'Add Table',
        add_row: 'Add Text',
        delete_row: 'Delete Block',
        del: 'DEL',

        drag: 'Drag',
        to_move: 'to move',
        do: '',
        click: 'Click',
        to_open_menu: 'to open the menu',
        to_add_row: 'to add a row',

        asset_details: asset_details.en,
        connection_details: connection_details.en,
        owner_details: owner_details.en,
        initialization: initialization.en,
    },

    fr: {
        empty_vault: 'Il semble que votre vault soit vide',
        connect_assets: 'Commencez votre voyage en connectant vos actifs',
        connect_first_asset: 'Connectez votre premier actif',

        h1: 'Titre 1',
        h2: 'Titre 2',
        h3: 'Titre 3',
        paragraph: 'Paragraphe',
        table: 'Tableau',
        assets_in_portfolio: 'Actifs du portefeuille',

        transform_in_h1: 'Transformez en titre 1',
        transform_in_h2: 'Transformez en titre 2',
        transform_in_h3: 'Transformez en titre 3',
        transform_in_paragraph: 'Transformez en paragraphe',
        add_table: 'Ajoutez un tableau',
        add_row: 'Ajoutez du texte',
        delete_row: 'Supprimez le bloc',
        del: 'DEL',

        drag: 'Glisser',
        to_move: 'pour d�placer',
        do: '',
        click: 'Cliquez',
        to_open_menu: 'pour ouvrir le menu',
        to_add_row: 'pour ajouter une ligne',

        asset_details: asset_details.fr,
        connection_details: connection_details.fr,
        owner_details: owner_details.fr,
        initialization: initialization.fr,
    }

};
<template>
    <div class="profile-information">

        <div class="fields-frame">

            <!-- language -->

            <div>
                <p class="label-large black">
                    {{ $t('profile.language') }}:
                </p>
                <div class="field-frame">
                    <toggle-field-component v-model="profileLanguage"
                                            :list="[{
                                                name: 'Italiano',
                                                value: 'it'
                                             },
                                             {
                                                name: 'Français',
                                                value: 'fr'
                                             },
                                             {
                                                name: 'English',
                                                value: 'en'
                                             },
                                             {
                                                name: 'Español',
                                                value: 'es'
                                             }]"
                                            @onItemClick="setLanguage" />
                </div>
            </div>


            <!-- currency -->

            <div>
                <p class="label-large black">
                    {{ $t('profile.preferred_currency') }}:
                </p>
                <div class="field-frame">
                    <toggle-field-component v-model="profileCurrency"
                                            :list="[{
                                                name: '€ EUR',
                                                value: 'EUR'
                                             },
                                             {
                                                name: '$ USD',
                                                value: 'USD'
                                             },
                                             {
                                                name: '£ GBP',
                                                value: 'GBP'
                                             },
                                             {
                                                name: '₣ CHF',
                                                value: 'CHF'
                                             }]"
                                            @onItemClick="setCurrency" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    import ToggleFieldComponent from '@/components/fields/ToggleField';

    import UsersProfileLanguage from "@/services/users/profile/language/post.service";
    import PostUsersProfileCurrency from '@/services/users/profile/currency/post.service';

    import EventBus from "@/common/EventBus";

    export default {
        name: 'System-Preference-Component',
        components: {
            ToggleFieldComponent
        },
        data() {
            return {
                isEditingLanguage: false,
                isEditingCurrency: false
            };
        },
        computed: {
            profileLanguage() {
                const language = this.$store.getters['auth/getUserLanguage'];
                switch (language) {
                    case 'it': return 'Italiano';
                    case 'fr': return 'Français';
                    case 'en': return 'English';
                    case 'es': return 'Español';
                }
                return '';
            },
            profileCurrency() {
                const currency = this.$store.getters['auth/getUserCurrency'];
                switch (currency) {
                    case 'EUR': return '€ - EUR';
                    case 'USD': return '$ - USD';
                    case 'GBP': return '£ - GBP';
                    case 'CHF': return '₣ - CHF';
                }
                return '$ USD';
            }
        },
        methods: {
            editLanguage() {
                this.isEditingLanguage = !this.isEditingLanguage;
            },
            setLanguage(language) {

                this.$i18n.locale = language;
                this.$store.dispatch('auth/setUserLanguage', language);
                const user_id = this.$store.getters['auth/getUserId'];
                UsersProfileLanguage.post(user_id, language).then(
                    () => {
                        this.isEditingLanguage = false;
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );

            },
            editCurrency() {
                this.isEditingCurrency = !this.isEditingCurrency;
            },
            setCurrency(currency) {

                this.$store.dispatch('auth/setUserCurrency', currency);
                const userId = this.$store.getters['auth/getUserId'];
                PostUsersProfileCurrency.post(userId, currency).then(
                    () => {
                        this.isEditingCurrency = false;
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                )
            },
        }

    }
</script>

<style scoped>

    div.profile-information {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

        div.profile-information > div.fields-frame {
            display: flex;
            gap: 8px;
            width: 100%;
        }

    @media (max-width: 599px) {
        div.profile-information > div.fields-frame {
            flex-direction: column;
        }
    }

    div.profile-information > div.fields-frame > div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

        div.profile-information > div.fields-frame > div > div.field-frame {
            display: flex;
            width: 100%;
        }

        div.profile-information > div.fields-frame > div > p {
            margin-left: 8px;
        }
</style>
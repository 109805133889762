import api from '../../../api';

class DeleteUsersCryptoExchanges {

  delete(id, item_id) {
    return api.delete('/v1/users/'+id+'/crypto/exchange/' + item_id);
  }
}

export default new DeleteUsersCryptoExchanges();

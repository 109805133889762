<template>

    <tr>
        <td class="minimum-width">
            <div class="icon-cell">
                <div>
                    <span class="material-icons black title-medium">
                        phone
                    </span>
                    <p class="body-medium black">
                        {{ $t('tables.mobile') }}
                    </p>
                </div>
                <span class="material-icons-outlined black body-small"
                      @click="$refs.ownerMobileOwnerProperty.focus()">
                    edit
                </span>
            </div>
        </td>
        <td class="input-cell">
            <p class="body-medium black"
               v-text="value"
               contenteditable="true"
               @blur="onEdit"
               ref="ownerMobileOwnerProperty"/>
            <p v-if="!value"
               class="placeholder-text body-medium placeholder"
               @click="$refs.ownerMobileOwnerProperty.focus()">
                {{ $t('tables.mobile_number') }}
            </p>
        </td>
    </tr>  

</template>

<script>
    import EventBus from "@/common/EventBus";
    import PutUsersProfileOwner from '@/services/users/profile/owner/put.service';

    export default {
        name: 'Mobile-Component',
        props: ['owner','mobile'],
        data() {
            return {
                value: undefined
            };
        },
        mounted() {
            this.value = this.initializeMobile
        },
        computed: {
            initializeMobile() {
                return this.mobile
            },
        },
        watch: {
            initializeMobile: function(newValue, oldValue) {
                if(typeof oldValue === 'undefined' && typeof newValue !== 'undefined') {
                    this.value = newValue;
                }
            }
        },
        methods: {
            onEdit(focusEvent) {

                if (typeof focusEvent.target.innerText !== undefined) {
                    this.value = focusEvent.target.innerText.trim();

                    if(this.value != null){

                        const userId = this.$store.getters['auth/getUserId'];
                        const ownerId = this.owner.id

                        PutUsersProfileOwner.put(userId, {
                            ownerId: ownerId,
                            mobile: this.value
                        }).then(() => {
                            this.$store.dispatch('views/updateOwnerFields', {
                                _id: ownerId,
                                mobile: this.value
                            });
                        }, (error) => {
                            if (error.response && error.response.status === 403) {
                                EventBus.dispatch("logout");
                            }
                        });
                    }
                }
            }
        },
    }
</script>

<style scoped>
    table > tbody > tr > td.minimum-width {
        width: 172px;
    }

    table > tbody > tr > td > div.icon-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        margin-bottom: 4px;
    }

        table > tbody > tr > td > div.icon-cell > div {
            display: flex;
            align-items: center;
            gap: 4px;
            width: fit-content;
        }

    table > tbody > tr > td.input-cell {
        vertical-align: middle;
        position: relative;
    }

        table > tbody > tr > td.input-cell > p[contenteditable]:hover {
            background-color: var(--secondary);
            border-radius: var(--middle-rounded);
            cursor: pointer;
            padding-left: 8px;
        }

        table > tbody > tr > td.input-cell > p[contenteditable]:focus {
            background-color: white;
            border-radius: var(--middle-rounded);
            cursor: pointer;
            padding-left: 8px;
        }

    /* placeholder text management */
    table > tbody > tr > td.input-cell > p[contenteditable] ~ p.placeholder-text {
        position: absolute;
        top: 4px;
        z-index: 0;
        cursor: pointer;
    }

        table > tbody > tr > td.input-cell > p.placeholder-text:hover {
            background-color: var(--secondary);
            border-radius: var(--middle-rounded);
            padding-left: 8px;
            width: 100%;
        }

    table > tbody > tr > td.input-cell > p[contenteditable]:focus ~ p.placeholder-text {
        display: none;
    }

    table > tbody > tr > td.input-cell > p[contenteditable]:hover ~ p.placeholder-text {
        padding-left: 8px;
    }

    @media (max-width: 904px) {
        table > tbody > tr > td.input-cell > p[contenteditable] {
            padding-left: 8px;
        }
            table > tbody > tr > td.input-cell > p[contenteditable] ~ p.placeholder-text {
                padding-left: 8px;
            }
    }

    @media (min-width: 905px) {
        table > tbody > tr > td > div.icon-cell > span {
            display: none;
        }
    }


</style>
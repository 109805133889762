<template>


    <table class="branded-table">
        <thead>
            <tr>
                <th class="text-align-left">
                    <p class="title-medium black bold">
                        {{ $t('tables.name') }}
                    </p>
                </th>
                <th>
                    <p class="title-medium black bold">
                        {{ $t('tables.value') }}
                    </p>
                </th>
                <th>
                    <timeframe-switcher-component />
                </th>
            </tr>
        </thead>
        <tbody v-if="connections.length > 0">

            <template v-for="(connection, index) in connections"
                      v-bind:key="index">

                <tr :class="{
                        'white-background': index%2,
                        'neutral-background': !(index%2)
                    }"
                    @click="goToDetails(connection)">

                    <td class="text-align-left">
                        <p class="body-medium black">
                            <connection-name-component :name="connection.name"
                                                       :service="connection.service"
                                                       :institutionId="connection.institutionId"
                                                       :connection="connection" />
                        </p>
                    </td>
                    <td>
                        <p class="body-medium black">
                            <connection-value-component :value="connection.value" />
                        </p>
                    </td>
                    <td class="text-align-left">
                        <connection-percentage-change-component :percentageChange="connection.percentageChange" />
                    </td>
                </tr>


                <tr :class="{
                        'white-background': index%2,
                        'neutral-background': !(index%2)
                    }"
                    @click="goToDetails(connection)">

                    <td class="text-align-left"
                        @click="goToDetails(asset)"
                        colspan="3">
                        
                        <connection-owners-component :ownerIds="connection.owners" />

                    </td>
                </tr>


            </template>

        </tbody>
        <tbody v-else>

            <no-item-in-table-component :activeItem="'connection'"
                                        :activeFilter="filter" />

        </tbody>
    </table>


</template>

<script>

    import TimeframeSwitcherComponent from '@/components/dashboard/table/heading/TimeframeSwitcher';

    import ConnectionValueComponent from '@/components/dashboard/table/row/connection/ConnectionValue';
    import ConnectionNameComponent from '@/components/dashboard/table/row/connection/ConnectionName';
    import ConnectionPercentageChangeComponent from '@/components/dashboard/table/row/connection/ConnectionPercentageChange';
    import ConnectionOwnersComponent from '@/components/dashboard/table/row/connection/ConnectionOwners';

    import NoItemInTableComponent from '@/components/dashboard/table/NoItemInTable';

    const { getDatesISO } = require('@/assets/js/markets/dates/getDatesISO.js');
    const { getDateBeforeISO } = require('@/assets/js/markets/dates/getDateBeforeISO.js');

    export default {
        name: 'Connection-View-Component',
        props: ['table', 'page', 'path', 'filter'],
        components: {
            TimeframeSwitcherComponent,
            ConnectionValueComponent,
            ConnectionNameComponent,
            ConnectionOwnersComponent,
            NoItemInTableComponent,
            ConnectionPercentageChangeComponent
        },
        methods: {
            goToDetails(connection) {

                if (connection.id in this.pages) {

                    this.routeToPage(connection);

                } else {

                    // create the page - the go to it
                    const params = {
                        name: connection.name,
                        id: connection.id,
                        icon: undefined,
                        service: connection.service,
                    };
                    this.$store.dispatch('pages/createConnectionPage', params).then(() => {
                        this.routeToPage(connection);
                    });
                }
            },
            routeToPage(connection) {

                if (this.$breakpoints.isLGOrLarger()) {

                    // if the screen is large enough, open it as a side panel
                    this.$router.push('/dashboard?p=' + connection.id);

                } else {

                    // else, just go to the page
                    this.$router.push('/dashboard/' + connection.id);
                }
            }
        },
        computed: {

            allConnections() {
                return this.$store.getters['connections/getConnections'];
            },
            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
            filterServices() {
                let filter = [];

                switch (this.filter) {
                    case 'banks': filter = ['openbanking']; break;
                    case 'exchanges': filter = ['exchange']; break;
                    case 'wallets': filter = ['wallet']; break;
                    case 'manual': filter = ['manual']; break;
                }
                return filter;
            },
            timeframe() {
                return this.$store.getters['auth/getUserTimeframe'];
            },

            connections() {
                const dates = getDatesISO();
                let connections = [];

                // filter all the connections of this table from all the connections
                this.allConnections.forEach(connection => {
                    if (this.table.properties.allConnections || this.table.connections.includes(connection.id)) {
                        connections.push(connection);
                    }
                });


                // filter assets by asset type
                if (this.filter != null && this.filter !== 'all') {

                    connections = connections.filter(x => this.filterServices.includes(x.service));
                }

                // compute the value of each connection
                for (const connection of connections) {
                    connection.value = 0;
                    connection.percentageChange = 0;

                    connection.balance.forEach(asset => {

                        let ticker = asset.ticker;
                        if (asset.type === 'forex') { // handle currency pair
                            ticker += '/USD' 
                        }
                        ticker = asset.staking_ticker || ticker; // handle ticker in staking


                        if (ticker && this.markets[ticker]) {


                            // do not ask the percentage of change to forex
                            if (asset.type !== 'forex') {


                                // get the percentageChange based on the timeframe
                                let assetPercentageChange = undefined;
                                let index = undefined;
                                let currentDate = undefined;
                                let breakFlag = 0;
                                const currentAsset = this.markets[ticker];
                                const currentPrice = this.markets[ticker].price;

                                if (currentAsset == null ||
                                    currentAsset.percentageChange == null ||
                                    currentAsset.price == null ||
                                    currentAsset.historical == null) {
                                    return; // break
                                }

                                switch (this.timeframe) {
                                    case '1D':
                                        assetPercentageChange = currentAsset.percentageChange;
                                        break;
                                    case '1W':
                                        currentDate = dates.week;
                                        do {

                                            breakFlag += 1;
                                            index = currentAsset.historical.map(x => x.timestamp).indexOf(currentDate);
                                            currentDate = getDateBeforeISO(currentDate);

                                        } while (index === -1 && breakFlag < 10);

                                        if (index >= 0) {
                                            assetPercentageChange = (currentPrice / currentAsset.historical[index].close - 1) * 100;
                                        }
                                        break;
                                    case '3M':
                                        currentDate = dates.quarter;
                                        do {

                                            breakFlag += 1;
                                            index = currentAsset.historical.map(x => x.timestamp).indexOf(currentDate);
                                            currentDate = getDateBeforeISO(currentDate);

                                        } while (index === -1 && breakFlag < 10);

                                        if (index >= 0) {
                                            assetPercentageChange = (currentPrice / currentAsset.historical[index].close - 1) * 100;
                                        }
                                        break;
                                    case 'YTD':
                                        currentDate = dates.year;
                                        do {

                                            breakFlag += 1;
                                            index = currentAsset.historical.map(x => x.timestamp).indexOf(currentDate);
                                            currentDate = getDateBeforeISO(currentDate);

                                        } while (index === -1 && breakFlag < 10);
                                        
                                        if (index >= 0) {
                                            assetPercentageChange = (currentPrice / currentAsset.historical[index].close - 1) * 100;
                                        }
                                        break;
                                }

                                // update the connection percentage change
                                if (assetPercentageChange) {
                                    connection.percentageChange =
                                        (connection.percentageChange * connection.value +
                                            assetPercentageChange * asset.amount * this.markets[ticker].price) /
                                        (connection.value + asset.amount * this.markets[ticker].price);
                                }
                            }
                            

                            // update total value
                            connection.value += asset.amount * this.markets[ticker].price;
                        }
                    })
                }


                // amend the value of institutionId for non openbanking connections
                for (const connection of connections) {
                    if (connection.institutionId == null) {
                        connection.institutionId = connection.source;
                    }
                }

                return connections;
            },

        }
    }
</script>

<style scoped>

    table.branded-table tbody tr {
        cursor: pointer;
    }

        table.branded-table tbody tr.white-background {
            background-color: var(--white);
        }

        table.branded-table tbody tr.neutral-background {
            background-color: var(--neutral);
        }
</style>
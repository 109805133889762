<template>

    <div class="current-price" :style="color">

        <p class="body-medium">
            <value-component :amount="1"
                             :currentPriceDollars="price" />
        </p>

        <span v-if="percentageChange && percentageChange > 0"
              class="material-icons">
            trending_up
        </span>
        <span v-else-if="percentageChange && percentageChange < 0"
              class="material-icons">
            trending_down
        </span>
        <span v-else
              class="material-icons">
            trending_flat
        </span>

    </div>
</template>

<script>

    import ValueComponent from '@/components/dashboard/table/row/asset/Value';

    export default {
        name: 'Current-Price-Component',
        props: ['price', 'percentageChange'],
        components: {
            ValueComponent
        },
        computed: {
            color() {

                let color = 'var(--tertiary)';

                if (this.percentageChange && this.percentageChange > 0) {
                    color = 'var(--green)';
                }
                if (this.percentageChange && this.percentageChange < 0) {
                    color = 'var(--red)';
                }

                return {
                    '--trend-color': color
                };
            }
        }

    }
</script>

<style scoped>
    div.current-price {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--trend-color);
    }
</style>
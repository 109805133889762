import api from '../../../api';

class MarketsETFsPrices {

  get(tickers) {
    return api.get('/v1/markets/etfs/' + tickers.join(',')  );
  }
}

export default new MarketsETFsPrices();

<template>

    <div class="asset-properties">
        <table>
            <tbody>


                <tr>
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                category
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.type') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium black">
                            <asset-type-component :type="asset.type" />
                        </p>
                    </td>
                </tr>

                
                <tr v-if="asset.type !== 'forex'">
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                sell
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.current_price') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <current-price-component :price="currentPrice"
                                                 :percentageChange="percentageChange" />
                    </td>
                </tr>


                <tr>
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                tag
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.amount') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium black">
                            <amount-component :amount="totalAmount"
                                              :precisionDigits="8"/>
                        </p>
                    </td>
                </tr>



                <tr v-if="asset.type !== 'forex'">
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                show_chart
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.value') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium black">
                            <value-component :amount="totalAmount"
                                             :currentPriceDollars="currentPrice" />
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import AmountComponent from '@/components/dashboard/table/row/asset/Amount';
    import ValueComponent from '@/components/dashboard/table/row/asset/Value';
    import AssetTypeComponent from '@/components/dashboard/table/row/asset/Type';
    import CurrentPriceComponent from '@/components/dashboard/table/row/asset/CurrentPrice';

    export default {
        name: 'Asset-Properties-Component',
        props: ['asset'],
        components: {
            AssetTypeComponent,
            AmountComponent,
            ValueComponent,
            CurrentPriceComponent
        },
        computed: {
            markets() {
                return this.$store.getters['markets/getMarkets'];
            },

            currentPrice() {
                return (this.markets[this.asset.ticker]) ? this.markets[this.asset.ticker].price : undefined;
            },

            percentageChange() {
                return (this.markets[this.asset.ticker]) ? this.markets[this.asset.ticker].percentageChange : undefined;
            },

            connections() {
                return this.$store.getters['connections/getConnections'];
            },

            totalAmount() { 
                let amount = 0;

                this.connections.forEach(connection => {
                    
                    connection.balance.forEach(asset => {

                        const assetTicker = asset.staking_ticker || asset.ticker;

                        if(assetTicker === this.asset.ticker) {
                            amount += asset.amount;
                        }
                    });
                });

                return amount;
            }
        }
    }
</script>

<style scoped>
    div.asset-properties {
        width: calc(100% - 104px);
        padding: 8px 52px;
        margin: 8px 0px;
        border-bottom: 1px solid var(--placeholder);
    }

    @media (max-width: 904px) {
        div.asset-properties {
            width: calc(100% - 48px);
            padding: 8px 24px;
        }
    }

    table {
        width: 100%;
    }

        table > tbody > tr > td {
            vertical-align: middle;
        }

        table > tbody > tr > td.minimum-width {
            width: 172px;
        }

            table > tbody > tr > td > div.icon-cell {
                display: flex;
                align-items: center;
                gap: 4px;
                width: fit-content;
                margin-top: 4px;
                margin-bottom: 4px;
            }
</style>
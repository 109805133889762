<template>


    <div id="topbar-menu">
        <div id="logo"
             @click.prevent="openInNewTab">
            <img :src="require('@/assets/logo.png')" />
        </div>
        <div class="menu">

            <div class="language-toggler-button"
                 @click="showDropdown = !showDropdown">
                <p class="label-large">
                    {{ $t('current') }}
                </p>
                <span class="material-icons">
                    arrow_drop_down
                </span>

                <dropdown-menu-component :show="showDropdown"
                                         :list="[{
                                                name: 'English',
                                                value: 'en'
                                            },{
                                                name: 'Italiano',
                                                value: 'it'
                                            },{
                                                name: 'Español',
                                                value: 'es'
                                            },{
                                                name: 'Français',
                                                value: 'fr'
                                            }]"
                                         @onItemClick="changeLocaleLanguage"
                                         class="dropdown" />
            </div>

            <div v-if="showSignUp" class="button white" @click="$router.push({path:'/register'})">
                <div class="label-large">
                    {{ $t('forms.auth.sign_up') }}
                </div>
            </div>

            <div v-else class="button white" @click="$router.push({path:'/login'})">
                <div class="label-large">
                    {{ $t('forms.auth.sign_in') }}
                </div>
            </div>
        </div>
    </div>


</template>

<script>

    import DropdownMenuComponent from '@/components/fields/DropdownMenu';

    export default {
        name: "TopBar-Component",
        components: {
            DropdownMenuComponent
        },
        data() {
            return {
                showDropdown: false
            };
        },
        computed: {
            showSignUp() {
                // The only page in which we show the sign up button is the login
                return ((this.$route.name === 'Login') ? true : false);
            }
        },
        methods: {
            openInNewTab() {
                window.open('https://360track.me', '_blank', 'noreferrer');
            },

            changeLocaleLanguage(language) {
                this.showDropdown = false;
                this.$i18n.locale = language;
            }
        },
    }
</script>

<style scoped>

    div#topbar-menu {
        height: 80px;
        background-color: transparent;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        div#topbar-menu > div#logo {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-left: 24px;
            cursor: pointer;
        }

            div#topbar-menu > div#logo > img {
                height: 32px;
            }

        div#topbar-menu > div.menu {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-right: 16px;
        }
        @media (min-width: 905px) {
            div#topbar-menu > div.menu {
                margin-right: 64px;
            }
        }

    div.button {
        align-items: center;
        border: 1px solid;
        border-color: var(--white);
        border-radius: var(--full-rounded);
        display: flex;
        padding: 16px 24px;
        width: fit-content;
    }

        div.button:hover {
            cursor: pointer;
            color: var(--secondary);
            border-color: var(--secondary);
        }

    div.language-toggler-button {
        align-items: center;
        border-radius: var(--full-rounded);
        display: flex;
        padding: 16px 24px;
        width: fit-content;
        color: white;
        position: relative;
    }

        div.language-toggler-button:hover {
            cursor: pointer;
            color: var(--secondary);
        }


    div.dropdown {
        margin-left: -96px;
        top: 48px;
    }
</style>

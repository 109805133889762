<template>
    <div class="type-label"
         :style="labelColor">
        {{ assetType }}
    </div>
</template>

<script>
    export default {
        name: 'Asset-Type-Component',
        props: ['type'],
        computed: {
            assetType() {

                switch (this.type) {
                    case 'stocks': return 'Stock';
                    case 'etfs': return 'ETF';
                    case 'cryptocurrency': return 'Cryptocurrency';
                    case 'forex': return 'Liquidity';
                    default: return '-';
                }
            },
            labelColor() {
                
                let backgroundColor = 'transparent';
                let textColor = 'black';

                switch (this.type) {
                    case 'stocks':
                        backgroundColor = '#093145';
                        textColor = '#FEFEFE';
                        break;
                    case 'etfs':
                        backgroundColor = '#3C6478';
                        textColor = '#FEFEFE';
                        break;
                    case 'cryptocurrency':
                        backgroundColor = '#C2571A';
                        textColor = '#FEFEFE';
                        break;
                    case 'forex':
                        backgroundColor = '#9A2617';
                        textColor = '#FEFEFE';
                        break;
                }
                
                return {
                    '--background-color': backgroundColor,
                    '--text-color': textColor
                };
            }
        }
    }
</script>

<style scoped>
    div.type-label {
        padding: 2px 8px;
        border-radius: var(--middle-rounded);
        background-color: var(--background-color);
        color: var(--text-color);
        width: fit-content;
    }
</style>
<template>
    <div id="connection-success-page">
        <h1 class="headline-large black">
            {{ $t('connect.result.asset_successfully_added') }}
        </h1>
        <connect-success-animation-component />
        <div class="horizontal-frame">
            <button class="secondary full-rounded"
                    @click="goToDashboard">
                <p class="label-large">
                    {{ $t('connect.result.go_to_dashboard') }}
                </p>
            </button>
            <button class="tertiary full-rounded"
                    @click="goToConnect">
                <p class="label-large">
                    {{ $t('connect.result.add_more') }}
                </p>
            </button>
        </div>
    </div>
</template>

<script>

    import ConnectSuccessAnimationComponent from '@/components/connect/result/SuccessAnimation';

    export default {
        name: 'Connect-Cash-Manual-Success',
        components: {
            ConnectSuccessAnimationComponent
        },
        methods: {
            goToDashboard() {
                this.$router.push('/dashboard');
            },
            goToConnect() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
    div#connection-success-page {
        width: 100%;
        max-width: 808px;
        margin: 32px auto auto auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    div#connection-success-page > div.horizontal-frame {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
</style>


const navigation = require('./translations/navigation').default;
const connect = require('./translations/connect').default;
const dashboard = require('./translations/dashboard').default;
const tables = require('./translations/tables').default;
const rewards = require('./translations/rewards').default;
const support = require('./translations/support').default;
const forms = require('./translations/forms').default;
const profile = require('./translations/profile').default;
const actions = require('./translations/actions').default;


const en = {
    current: "EN",

    navigation: navigation.en,
    connect: connect.en,
    dashboard: dashboard.en,
    tables: tables.en,
    rewards: rewards.en,
    support: support.en,
    forms: forms.en,
    profile: profile.en,
    actions: actions.en,

    /* mapping ISO 2366 Alpha 2 codes to name of the country in the language */
    /*
     * AD: "Andorra"
     * AE: "United Arab Emirates"
     * ...
     * 
     * */
    i18nCountriesISO: require("i18n-iso-countries/langs/en.json").countries
};

export { en };

<template>
    <div class="user-tier-card custom-device-padding">
        <h2 v-if="rewardStep < 3" class="headline-small black">
            {{ $t('rewards.win_unique_rewards') }}
        </h2>
        <h2 v-if="rewardStep === 3" class="headline-small black">
            {{ $t('rewards.vote_your_feature') }}
        </h2>
        <adventurer-tier-component v-if="rewardStep < 3" :rewardStep="rewardStep" />
        <prince-elector-tier-component v-if="rewardStep === 3" />
    </div>
</template>

<script>

    import AdventurerTierComponent from '@/components/profile/rewards/tiers/AdventurerTier.vue';
    import PrinceElectorTierComponent from '@/components/profile/rewards/tiers/PrinceElectorTier.vue';

    export default {
        name: "User-Tier-Component",
        props: ['rewardStep'],
        components: {
            AdventurerTierComponent,
            PrinceElectorTierComponent,
        },
    }
</script>

<style scoped>

    /* the card */
    div.user-tier-card {
        background-color: var(--white);
        border-radius: 12px;

        display: flex;
        flex-direction: column;
    }

    div.user-tier-card h2 {
        margin: auto 24px auto 24px;
    }

    @media (min-width: 905px) {
        div.user-tier-card.custom-device-padding {
            padding: 24px calc(5% - 16px) 24px calc(5% - 16px);
        }
    }
    @media (max-width: 904px) {
        div.user-tier-card.custom-device-padding {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }

</style>
<template>
    <div class="strength-bar">

        <template v-if="passwordScore === 0">
            <span class="bar-item" style="background-color: red;" />
            <span class="material-icons" style="color: red;">
                block
            </span>
            <span class="bar-item" />
            <span class="bar-item" />
            <span class="bar-item" />
            <span class="bar-item" />
        </template>

        <template v-if="passwordScore === 1">
            <span class="bar-item" style="background-color: orange;" />
            <span class="bar-item" style="background-color: orange;" />
            <span class="material-icons" style="color: orange;">
                block
            </span>
            <span class="bar-item" />
            <span class="bar-item" />
            <span class="bar-item" />
        </template>

        <template v-if="passwordScore === 2">
            <span class="bar-item" style="background-color: yellow;" />
            <span class="bar-item" style="background-color: yellow;" />
            <span class="bar-item" style="background-color: yellow;" />
            <span class="material-icons" style="color: yellow;">
                block
            </span>
            <span class="bar-item" />
            <span class="bar-item" />
        </template>

        <template v-if="passwordScore === 3">
            <span class="bar-item" style="background-color: lime;" />
            <span class="bar-item" style="background-color: lime;" />
            <span class="bar-item" style="background-color: lime;" />
            <span class="bar-item" style="background-color: lime;" />
            <span class="material-icons" style="color: lime;">
                check
            </span>
            <span class="bar-item" />
        </template>

        <template v-if="passwordScore === 4">
            <span class="bar-item" style="background-color: green;" />
            <span class="bar-item" style="background-color: green;" />
            <span class="bar-item" style="background-color: green;" />
            <span class="bar-item" style="background-color: green;" />
            <span class="bar-item" style="background-color: green;" />
            <span class="material-icons" style="color: green;">
                check
            </span>
        </template>

    </div>
</template>

<script>
    export default {
        name: "PasswordStrengthBar-Component",
        props: ['passwordScore'],
    }
</script>

<style scoped>
    div.strength-bar {
        display: flex;
        width: fit-content;
        height: fit-content;
        align-self: flex-start;
        align-items: center;
    }

    div.strength-bar > span.bar-item {
        height: 8px;
        width: 24px;
    }

    div.strength-bar > span.material-icons {
        font-size: 16px;
    }
</style>


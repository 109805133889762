import api from '../../../api';

class PostUsersCryptoExchanges {

  post(id, data) {
    return api.post('/v1/users/'+id+'/crypto/exchange', data);
  }
}

export default new PostUsersCryptoExchanges();

import api from '../../../api';

class PlatformEtfSymbols {

    get(query) {

        let queryString = '';
        if (query) {
            const symbol = query.symbol;
            const tickers = query.tickers;
            const name = query.name;
            const items = query.items;
            const mic_code = query.mic_code;


            if (symbol || tickers || name || items || mic_code) {
                queryString = '?';

                if (symbol) {
                    queryString = queryString + 'symbol=' + symbol + '&';
                }
                if (tickers) {
                    queryString = queryString + 'tickers=' + tickers + '&';
                }
                if (name) {
                    queryString = queryString + 'name=' + name + '&';
                }
                if (items) {
                    queryString = queryString + 'items=' + items + '&';
                }
                if (mic_code) {
                    queryString = queryString + 'mic_code=' + mic_code + '&';
                }

                queryString = queryString.substring(0, queryString.length - 1); // remove the last &
            }
        }

        return api.get('/v1/platform/etfs/symbols' + queryString);
    }
}

export default new PlatformEtfSymbols();

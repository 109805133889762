<template>

    <tr>
        <td class="minimum-width">
            <div class="icon-cell">
                <div>
                    <span class="material-icons black title-medium">
                        badge
                    </span>
                    <p class="body-medium black">
                        {{ $t('tables.legal_names') }}
                    </p>
                </div>
                <span class="material-icons-outlined black body-small lock-icon">
                    lock
                </span>
            </div>
        </td>
        <td class="input-cell">
            <p class="body-medium black"
               v-text="value"/>
            <p v-if="!value"
               class="placeholder-text body-medium placeholder">
                {{ $t('tables.legal_names') }}
            </p>
        </td>
    </tr>

</template>

<script>

    export default {
        name: 'Legal-Names-Component',
        props: ['owner', 'legalNames'],
        data() {
            return {
                value: undefined
            };
        },
        mounted() {
            this.value = this.initializeLegalNames
        },
        computed: {
            initializeLegalNames() {
                return this.legalNames.join(', ')
            },
        },
        watch: {
            initializeLegalNames: function (newValue, oldValue) {
                if (typeof oldValue === 'undefined' && typeof newValue !== 'undefined') {
                    this.value = newValue;
                }
            }
        },
    }
</script>

<style scoped>
    table > tbody > tr > td.minimum-width {
        width: 172px;
    }

    table > tbody > tr > td > div.icon-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        margin-bottom: 4px;
    }

        table > tbody > tr > td > div.icon-cell > div {
            display: flex;
            align-items: center;
            gap: 4px;
            width: fit-content;
        }

    table > tbody > tr > td.input-cell {
        vertical-align: middle;
        position: relative;
    }

    table > tbody > tr > td.input-cell > p[contenteditable]:focus ~ p.placeholder-text {
        display: none;
    }

    table > tbody > tr > td.input-cell > p[contenteditable]:hover ~ p.placeholder-text {
        padding-left: 8px;
    }

    @media (max-width: 904px) {
        table > tbody > tr > td.input-cell > p {
            padding-left: 8px;
        }
            table > tbody > tr > td.input-cell > p ~ p.placeholder-text {
                padding-left: 8px;
            }
    }

    table > tbody > tr > td > div.icon-cell > span.lock-icon {
        margin-right: 4px;
    }
    @media (max-width: 904px) {
        table > tbody > tr > td > div.icon-cell > span.lock-icon {
            display: none;
        }
    }
</style>
<template>
    <div class="poll-frame" v-if="title">
        <div>
            <h2 v-if="!hideTitle && overrideTitle" class="headline-small black">
                {{ overrideTitle }}
            </h2>
            <h2 v-else-if="!hideTitle" class="headline-small black">
                {{ title }}
            </h2>
            <h3 v-if="subtitle" class="title-medium black">
                {{ subtitle }}
            </h3>
        </div>
        <div class="answer-box">

            <new-answer-input-component :loading="loading" />

            <template v-if="typeof answerVotesNumber !== 'undefined'">
                <poll-answer-component v-for="answer in answers"
                                       :key="answer._id"
                                       :answerTitle="answer.title"
                                       :proposedBy="answer.proposed"
                                       :answerId="answer._id"
                                       :votes="answer.votes"
                                       :percentageUsersChosen="answer.votes.length/answerVotesNumber*100"
                                       :loading="answer.loading" />
            </template>

        </div>
    </div>
</template>

<script>

    import PollAnswerComponent from '@/components/profile/surveys/poll/PollAnswer.vue';
    import NewAnswerInputComponent from '@/components/profile/surveys/poll/NewAnswerInput.vue';

    import GetPlatformSurveysPoll from '@/services/platform/surveys/poll/get.service';
    import PostPlatformSurveysPollAnswer from '@/services/platform/surveys/poll/answer/post.service';
    import PutPlatformSurveysPollAnswer from '@/services/platform/surveys/poll/answer/put.service';

    import EventBus from "@/common/EventBus";

    export default {
        name: 'Poll-Component',
        props: ['pollId', 'overrideTitle', 'hideTitle', 'subtitle'],
        data() {
            return {
                loading: true,
                title: undefined,
                answers: [],
                answerVotesNumber: undefined
            }
        },
        components: {
            PollAnswerComponent,
            NewAnswerInputComponent
        },
        methods: {
            refreshPoll() {

                // contact the server
                GetPlatformSurveysPoll.get(this.pollId).then(
                    (response) => {
                        const data = response.data;

                        this.title = data.title;
                        this.answers = data.answers;
                        if (this.answers && this.answers.length > 0) {
                            this.answers.sort((a, b) => b.votes.length - a.votes.length);
                        }

                        this.answerVotesNumber = 0;
                        this.answers.forEach(x => {
                            this.answerVotesNumber += x.votes.length
                        });

                        this.loading = false;
                    },
                    (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } }
                );
            },
            voteAnswer(answerId) {

                // disable the answer
                this.answers.forEach(x => {
                    if (x.id === answerId) {
                        x.loading = true;
                    }
                });

                // contact the server
                const data = {
                    user_id: this.$store.state.auth.user.id,
                    answer_id: answerId
                }
                PutPlatformSurveysPollAnswer.put(this.pollId, data).then(
                    () => {
                        this.refreshPoll();
                    },
                    (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } }
                );
            },
            addOption(name) {

                // disable the button
                this.loading = true;

                // contact the server
                const data = {
                    user_id: this.$store.getters['auth/getUserId'],
                    answer_title: name
                }
                PostPlatformSurveysPollAnswer.post(this.pollId, data).then(
                    () => {
                        this.refreshPoll();
                    },
                    (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } }
                );
            }
        },
        created() {
            this.refreshPoll();
        }
    }
</script>

<style scoped>

    /* poll frame */

    div.poll-frame {
        display: flex;
        flex-direction: column;
        gap: 12px;
        background-color: var(--white);
        border-radius: 12px;
        padding: 24px 5% 24px 5%;
    }

    /* answer box */

    div.poll-frame > div.answer-box {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    div.poll-frame > div.answer-box button {
        margin: 4px 16px 4px 16px;
    }
</style>
<template>


    <form class="branded-input-form" @submit.prevent="submitForm">

        <div class="fields-frame">
            <new-portfolio-input-field-component v-model="portfolioName"
                                                 type="text"
                                                 :label="$t('forms.select_portfolio')"
                                                 id="inputPortfolioName"
                                                 :isValid="isValid.portfolioName"
                                                 :list="manualPortfolioListNames" />

        </div>

        <div class="fields-frame">
            <ticker-autocomplete-input-field-component v-model="name"
                                                       type="text"
                                                       :label="$t('forms.currency')"
                                                       :isValid="isValid.name"
                                                       :list="cashSymbols" />
        </div>

        <div class="fields-frame">
            <input-field-component v-model="amount"
                                   type="text"
                                   :label="$t('forms.amount')"
                                   :isValid="isValid.amount" />

        </div>


        <div class="buttons-frame">

            <!-- confirm button start -->
            <button class="tertiary full-rounded"
                    :disabled="isFormDisabled">
                <span v-if="isFormDisabled"
                      class="material-icons loading-icon">
                    sync
                </span>
                <p class="label-large">
                    {{ $t('forms.add') }}
                </p>
            </button>
            <!-- confirm button end -->

        </div>

    </form>

</template>

<script>

    import InputFieldComponent from '@/components/fields/InputField';
    import NewPortfolioInputFieldComponent from '@/components/fields/portfolio/NewPortfolioInputField';
    import TickerAutocompleteInputFieldComponent from '@/components/fields/autocomplete/InputFieldAutocompleteTicker';


    import PostUsersManualAsset from "@/services/users/manual/asset/post.service";
    import PlatformForexSymbols from "@/services/platform/forex/symbols/get.service";
    import EventBus from "@/common/EventBus";

    export default {
        name: 'Connect-Cash-Manual-Form',
        props: ['page', 'tableId'],
        components: {
            InputFieldComponent,
            NewPortfolioInputFieldComponent,
            TickerAutocompleteInputFieldComponent
        },
        data() {
            return {
                isFormDisabled: false,
                portfolioName: undefined,
                name: undefined,
                amount: undefined,
                cashSymbols: [],
                isValid: {
                    portfolioName: true,
                    name: true,
                    amount: true
                }
            };
        },
        created() {
            PlatformForexSymbols.get().then(
                (response) => {
                    const cash_symbols = response['data'].symbols;

                    // Put in the format: "Name - Ticker" "Euro - EUR" and remove duplicates
                    this.cashSymbols = [...cash_symbols.map(x => {
                        const baseQuote = x.symbol.split('/');
                        if (baseQuote[1] === 'USD') {
                            return x.currency_base + ' - ' + baseQuote[0];
                        }
                    }).filter(x => x != null)];
                },
                (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        computed: {
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            manualPortfolioListNames() {
                return this.connections.filter(connection => {
                    if (connection.service === 'manual') {
                        return connection.name;
                    }
                }).map(x => x.name);
            },
            manualPortfolioList() {
                return this.connections.filter(connection => {
                    if (connection.service === 'manual') {
                        return connection;
                    }
                });
            }
        },
        methods: {
            submitForm() {

                // validate

                if (this.portfolioName == null ||
                    this.portfolioName === '' ||
                    !this.manualPortfolioListNames.includes(this.portfolioName)) {
                    this.isValid.portfolioName = false;
                    return;
                } else {
                    this.isValid.portfolioName = true;
                }
                if (this.name == null ||
                    this.name === '' ||
                    !this.cashSymbols.includes(this.name)) {
                    this.isValid.name = false;
                    return;
                } else {
                    this.isValid.name = true;
                }
                if (this.amount == null ||
                    this.amount === '' ||
                    isNaN(this.amount)) {
                    this.isValid.amount = false;
                    return;
                } else {
                    this.isValid.amount = true;
                }

                // execute

                this.isFormDisabled = true;

                const name = this.name.split(' - ')[0];
                const ticker = this.name.split(' - ')[1];
                const source = this.manualPortfolioList.find(x => x.name === this.portfolioName).source;

                const data = {
                    source: source,
                    source_name: this.portfolioName,
                    name: name,
                    ticker: ticker,
                    amount: parseInt(this.amount),
                    avg_purchase_price: this.avg_purchase_price,
                    type: 'forex'
                };

                const user_id = this.$store.getters['auth/getUserId'];

                PostUsersManualAsset.post(user_id, data).then(
                    () => {

                        // Refresh Vuex Store with new data
                        this.$store.dispatch('connections/refresh').then(() => {
                            this.isFormDisabled = false;
                        }).then(() => {
                            this.$router.push('/connect/cash/manual/success');
                        })

                    },
                    (error) => {
                        this.isFormDisabled = false;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            }
        }
    }
</script>
export default {

    it: {
        create_new_view: "Crea nuova vista",
        view_name: "Nome della vista",
        update_view: "Modifica la vista",
        manage_views: 'Gestisci viste',
        add_view: 'Aggiungi vista',
    },

    es: {
        create_new_owner: "Crear nueva vista",
        view_name: "Nombre de la vista",
        update_view: "Cambiar la vista",
        manage_views: 'Administrar vistas',
        add_view: 'Añadir vista',

    },

    en:{
        create_new_owner: "Create new view",
        view_name: "View name",
        update_view: "Update the view",
        manage_views: 'Manage views',
        add_view: 'Add view',
    },

    fr:{
        create_new_owner: "Créer une nouvelle vision",
        view_name: "Nom de la vision",
        update_view: "Changer la vision",
        manage_views: 'Gérer vues',
        add_view: 'Ajouter vue',
    },

};
<template>

    <top-menu-component :pageId="pageId" />

    <!-- if the user has no assets, display a nudge to connect the first one -->
    <div v-if="isLoaded && connectionsAreLoaded && connections.length === 0" class="page-nudge">

        <nudge-to-connect-component />

    </div>
    <div v-else-if="isLoaded" class="page">



        <div class="content">

            <go-back-arrow-component v-if="!thePageIsDashboard" />

            <view-owners-page-component v-if="thePageIsDashboard" />

            <page-title-component v-if="currentPageTitle != null"
                                  :pageObject="currentPage"
                                  :pageTitle="currentPageTitle" />

            <asset-properties-component v-if="currentPage.properties.purpose === 'asset'"
                                        :asset="currentPage.properties.asset" />
            <connection-properties-component v-if="currentPage.properties.purpose === 'connection'"
                                             :connection="currentPage.properties.connection" />
            <owner-properties-component v-if="currentPage.properties.purpose === 'owner'"
                                             :owner="currentPage.properties.owner" />

            <section-component v-for="(section, index) in currentPage.sections"
                               v-bind:key="section.id"
                               :section="section"
                               :page="pageId"
                               :index="index" />

            <div class="blank-section"
                 @click="appendTextSection" />

        </div>
    </div>
    <div v-else class="page">

        <skeleton-full-page-component />

    </div>

    <add-section-button-component class="add-button-overlay"
                                  :show="showAddBlockButton"
                                  @addBlockAction="addBlockAction" />



    <Teleport v-if="showModalAddSection" to="body">
        <add-section-modal-component :show="showModalAddSection"
                                     @close="showModalAddSection = false"
                                     :page="pageId"
                                     :purpose="currentPage.properties.purpose"
                                     :assetType="(currentPage.properties.purpose === 'asset') ? currentPage.properties.asset.type : undefined" />
    </Teleport>
</template>

<script>

    import SkeletonFullPageComponent from '@/components/dashboard/page/skeleton/FullPage';
    import NudgeToConnectComponent from '@/components/dashboard/page/NudgeToConnect';
    import GoBackArrowComponent from '@/components/dashboard/page/GoBackArrow';
    import ViewOwnersPageComponent from '@/components/dashboard/page/ViewOwnersPage';
    import PageTitleComponent from '@/components/dashboard/page/PageTitle';

    import AssetPropertiesComponent from '@/components/dashboard/page/properties/AssetProperties';
    import ConnectionPropertiesComponent from '@/components/dashboard/page/properties/ConnectionProperties';
    import OwnerPropertiesComponent from '@/components/dashboard/page/properties/OwnerProperties';

    import SectionComponent from '@/components/dashboard/section/Section';
    import TopMenuComponent from '@/components/dashboard/page/TopMenu';

    import AddSectionButtonComponent from '@/components/dashboard/section/add/AddSectionButton';
    import AddSectionModalComponent from '@/components/dashboard/section/add/AddSectionModal';


    export default {
        name: "Page-Component",
        props: ['pageId'],
        components: {
            SectionComponent,
            GoBackArrowComponent,
            ViewOwnersPageComponent,
            TopMenuComponent,
            AssetPropertiesComponent,
            ConnectionPropertiesComponent,
            OwnerPropertiesComponent,
            SkeletonFullPageComponent,
            NudgeToConnectComponent,
            PageTitleComponent,
            AddSectionButtonComponent,
            AddSectionModalComponent
        },
        data() {
            return {
                notUpdatedFondamentalData: true,
                showModalAddSection: false,
            };
        },
        computed: {

            thePageIsDashboard() {
                return this.pageId.startsWith('dashboard');
            },

            isLoaded() {
                return this.$store.getters['pages/isLoaded'];
            },
            connectionsAreLoaded() {
                return this.$store.getters['connections/isLoaded'];
            },
            currentPage() {
                const page = {
                    properties: {
                        purpose: 'placeholder'
                    },
                    sections: []
                };
                return this.$store.getters['pages/getPages'][this.pageId] || page;
            },
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            currentPageTitle() {
                return (this.currentPage.properties) ? this.currentPage.properties.title : undefined;
            },
            showAddBlockButton() {
                return !this.showModalAddSection &&
                    this.currentPage.properties &&
                    this.currentPage.properties.purpose &&
                    this.currentPage.properties.purpose !== 'general' &&
                    this.currentPage.properties.purpose !== 'placeholder';
            }
        },
        watch: {
            isLoaded: function () {
                this.loadFundamentalData();
            }
        },
        created() {
            this.loadFundamentalData();
        },
        methods: {


            loadFundamentalData() {
                // a page can have many purposes
                // if the purpose of the page is assets and the type is stocks or etfs
                // then this page will use the fundamental data of the security
                // so, it is needed to ask the data to the server


                if (this.currentPage &&
                    this.currentPage.properties &&
                    this.currentPage.properties.purpose === 'asset' &&
                    this.currentPage.properties.asset.type === 'stocks' &&
                    this.notUpdatedFondamentalData) {

                    this.notUpdatedFondamentalData = false; // update the fundamental data only once
                    this.$store.dispatch('markets/askFundamentalData', this.currentPage.properties.asset.ticker);
                }
            },

            appendTextSection(clickEvent) {

                this.$store.dispatch('pages/appendSection', {
                    page: this.pageId,
                    sectionId: 'p',
                }).then(() => {
                
                    // focus on the just added last text section of the page
                    const target = clickEvent.target.previousElementSibling.previousElementSibling.childNodes[3];

                    if (target == null) {
                        return;
                    }
                    target.focus();
                });
            },

            updateTitle(keyboardEvent) {

                const keyCode = keyboardEvent.keyCode;
                const targetInnerText = keyboardEvent.target.innerText;

                // ignore if enter is pressed
                if (keyCode === 13) {
                    keyboardEvent.preventDefault();

                } else {

                    this.$store.dispatch('pages/updateTitle', {
                        page: this.pageId,
                        title: targetInnerText
                    });
                }
            },



            // add section modal
            addBlockAction() {
                this.showModalAddSection = true;
            }
        }
    }
</script>

<style scoped>

    /* nudge to connect assets */

    div.page-nudge {
        width: 100%;
        max-width: 808px;
        display: flex;
        flex-direction: column;
        margin: 16px auto auto auto;
    }

    /* whole page*/

    div.page {
        width: 100%;
        max-width: 808px;
        display: flex;
        flex-direction: column;
        margin: 16px auto auto auto;
        margin-top: 32px; /* take into account the top menu sticky bar */
        padding-top: 16px;
    }
    @media (min-width: 1900px) {
        div.page {
            max-width: 1008px;
        }
    }

    /* heading */

    div.page div.heading {
        display: flex;
        margin: auto auto auto 64px;
        gap: 16px;
        align-items: center;
    }
    div.page div.heading img {
        height: 32px;
    }
    div.page div.heading h1 {
        cursor: pointer;
    }

    /* content */

    div.page div.content {
        width: 100%;
    }

    div.add-button-overlay {
        position: sticky;
        bottom: 8px;
        left: 16px;
    }
    @media (max-width: 599px) {
        div.add-button-overlay {
            bottom: 72px; /* 64 bottombar + 8 pixel */
        }
    }

    /* blank section */

    div.page > div.content > div.blank-section {
        cursor: pointer;
        height: 128px;
        width: 100%;
    }
</style>
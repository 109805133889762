

import AuthService from '@/services/auth/auth.service';

import EventBus from "@/common/EventBus";


// get the initial state from the local storage
// the local storage persists between sessions
// vuex does not
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true, isLogged: false }, user }
    : { status: { loggedIn: false, isLogged: false }, user: null };

    /**
     * 
     * loggedIn - legacy field that is true when the user is present in the localstorage/vuex
     * isLogged - modern field that is true when the user has done the refresh authenticated ping to the backend and it is actually able to call the backend
     * user - the user information
     * 
     * 
     */


export const auth = {
    namespaced: true,
    state: initialState,
    actions: {

        refresh({ commit }) {

            commit('setIsNotLogged');
            return AuthService.refresh().then(() => {

                commit('setIsLogged');

            }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
        },


        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loginWithGoogle({ commit }, user) {
            commit('loginSuccess', user);
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, { user, language }) {
            return AuthService.register({ user, language }).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        refreshToken({ commit }, accessToken) {
            commit('refreshToken', accessToken);
        },
        setUserCurrency({ commit }, currency) {
            commit('setUserCurrency', currency);
        },
        setUserLanguage({ commit }, language) {
            commit('setUserLanguage', language);
        },
        setUserName({ commit }, name) {
            commit('setUserName', name);
        },
        setUserSurname({ commit }, surname) {
            commit('setUserSurname', surname);
        },
        setUserLegalNames({ commit }, legalNames) {
            commit('setUserLegalNames', legalNames);
        },
        setUserTimeframe({ commit }, timeframe) {
            commit('setUserTimeframe', timeframe);
        },
        setUsersRewardSteps({ commit }, rewardSteps) {
            commit('setUsersRewardSteps', rewardSteps);
        },
        setUsersCurrentView({ commit }, currentView) {
            commit('setUsersCurrentView', currentView);
        },
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        refreshToken(state, accessToken) {
            state.status.loggedIn = true;
            state.user = { ...state.user, accessToken: accessToken };
        },

        setIsNotLogged(state) {
            state.status.isLogged = false;
        },
        setIsLogged(state) {
            state.status.isLogged = true;
        },

        setUserCurrency(state, currency) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.currency = currency;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.currency = currency;
            }
        },
        setUserLanguage(state, language) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.language = language;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.language = language;
            }
        },
        setUserName(state, name) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.name = name;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.name = name;
            }
        },
        setUserSurname(state, surname) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.surname = surname;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.surname = surname;
            }
        },
        setUserLegalNames(state, legalNames) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.legalNames = legalNames;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.legalNames = legalNames;
            }
        },
        setUserTimeframe(state, timeframe) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.timeframe = timeframe;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.timeframe = timeframe;
            }

        },
        setUserRewardSteps(state, rewardSteps) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.rewardSteps = rewardSteps;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.rewardSteps = rewardSteps;
            }
        },
        setUsersCurrentView(state, currentView) {
            // update local storage
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.currentView = currentView;
                localStorage.setItem('user', JSON.stringify(user));
                // update auth store
                state.user.currentView = currentView;
            }
        }
    },
    getters: {
        getUserId(state) {
            return (state.user) ? state.user.id : undefined;
        },
        getUsername(state) {
            return (state.user) ? state.user.username : undefined;
        },
        getEmail(state) {
            return (state.user) ? state.user.email : undefined;
        },
        getUserCurrency(state) {
            return (state.user) ? (state.user.currency) ? state.user.currency : 'USD' : undefined;
        },
        getUserLanguage(state) {
            return (state.user) ? state.user.language : undefined;
        },
        getUserTimeframe(state) {
            return (state.user) ? (state.user.timeframe) ? state.user.timeframe : '1D' : undefined;
        },
        getUserRewardStep(state) {
            return (state.user) ? (state.user.rewardSteps) ? state.user.rewardSteps : 0 : undefined;
        },
        getUserCurrentView(state) {
            return (state.user) ? (state.user.currentView) ? state.user.currentView : '64a6c39748a115cc1611781e' : undefined;
        },
        isLogged(state) {
            return state.status.isLogged;
        },
        getUser(state) {
            return state.user;
        }
    }
};















/*


export const auth = {
    namespaced: true,
    state: {
        userId: undefined,
        isLogged: false
    },
    actions: {

        refresh({ commit }) {
            commit('loadUserFromLocalStorage');
        },

        login({ commit }, userId) {
            commit('loginUser', userId);
            commit('saveUserToLocalStorage');
        },

        logout({ commit }) {
            commit('logoutUser');
            commit('saveUserToLocalStorage');
        }


    },
    mutations: {

        // TODO: implement these with the backend
        saveUserToLocalStorage(state) {
            localStorage.setItem('user', JSON.stringify(state.userId));
        },
        loadUserFromLocalStorage(state) {

            const user = localStorage.getItem('user');
            state.userId = (user) ? JSON.parse(user) : undefined;
        },


        loginUser(state, userId) {
            state.isLogged = true;
            state.userId = userId;
        },
        logoutUser(state) {
            state.isLogged = false;
            state.user = undefined;
        }

    },
    getters: {

        isLogged(state) {
            return state.isLogged;
        },
        getUser(state) {
            return state.userId;
        }

    }
};
*/
<template>
    <div v-if="percentageChange"
         :class="{
         'connection-price-change' : true,
         'positive-variation' : percentageChange > 0,
        'neutral-variation': percentageChange === 0,
        'negative-variation': percentageChange < 0,
        }">

        <p class="body-medium">
            {{ (Math.round(percentageChange*100)/100).toLocaleString(userLanguage, {maximumFractionDigits: 2}) }} %
        </p>

        <span v-if="percentageChange && percentageChange > 0"
              class="material-icons">
            trending_up
        </span>
        <span v-else-if="percentageChange && percentageChange < 0"
              class="material-icons">
            trending_down
        </span>
        <span v-else
              class="material-icons">
            trending_flat
        </span>
    </div>
    <p v-else
       class="body-medium">
        -
    </p>
</template>

<script>
    export default {
        name: 'Connection-Percentage-Component',
        props: ['percentageChange'],
        computed: {

            userLanguage() {
                return this.$store.getters['auth/getUserLanguage'];
            }
        }
    }
</script>

<style scoped>
    div.connection-price-change {
        display: flex;
        align-items: center;
    }

        div.connection-price-change.positive-variation {
            color: var(--green);
        }
        div.connection-price-change.neutral-variation {
            color: var(--tertiary);
        }
        div.connection-price-change.negative-variation {
            color: var(--red);
        }
</style>
<template>


    <div class="wrapper"
         @click="goToDetailsPage">
        <div :class="{
                'profile-connection-card': true,
                'expired-connection': !isConnectionActive && isConnectionExpired,
                'error-connection': !isConnectionActive && !isConnectionExpired
             }">
            <div>
                <p class="title-medium white">
                    <connection-name-component :name="connectionName"
                                               :service="connectionService"
                                               :institutionId="connectionInstitutionId"
                                               :connection="connection"/>
                </p>
                <p class="title-medium white">
                    <connection-value-component :value="connectionValue" />
                </p>
            </div>
            <div>
                <div class="last-updated-field">
                    <span v-if="isConnectionActive"
                          class="material-icons secondary title-medium">
                        check_circle_outline
                    </span>
                    <span v-else-if="isConnectionExpired"
                          class="material-icons tertiary title-medium">
                        restore
                    </span>
                    <span v-else
                          class="material-icons error title-medium">
                        block
                    </span>
                    <p v-if="isConnectionExpired"
                       class="body-medium white">
                        {{ $t('profile.connection_expired') }}
                    </p>
                    <p v-else-if="connectionLastUpdated"
                       class="body-medium white">
                        {{ $t('profile.last_updated') }}
                        {{ connectionLastUpdated }}
                    </p>
                    <p v-else
                       class="body-medium white">
                        {{ $t('profile.connection_updated') }}
                    </p>
                </div>
                <div class="edit-icon-field">
                    <span class="material-icons white title-medium">
                        edit
                    </span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>


    import ConnectionNameComponent from '@/components/dashboard/table/row/connection/ConnectionName';
    import ConnectionValueComponent from '@/components/dashboard/table/row/connection/ConnectionValue';

    export default {
        name: 'Connection-Card-Component',
        props: ['connection'],
        components: {
            ConnectionNameComponent,
            ConnectionValueComponent
        },
        methods: {
            goToDetailsPage() {

                
                if (this.connection.id in this.pages) {
                    
                    this.$router.push('/profile?p=' + this.connection.id);

                } else {

                    // create the page - the go to it
                    const params = {
                        name: this.connection.name,
                        id: this.connection.id,
                        icon: undefined,
                        service: this.connection.service,
                    };
                    this.$store.dispatch('pages/createConnectionPage', params).then(() => {

                        this.$router.push('/profile?p=' + this.connection.id);
                    });
                }

            }
        },
        computed: {
            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
            connectionName() {
                return this.connection.name;
            },
            connectionService() {
                return this.connection.service;
            },
            connectionInstitutionId() {
                if (this.connection.service === 'openbanking') {
                    return this.connection.institutionId;
                }
                return this.connection.source;
            },
            connectionValue() {
                let value = 0;

                this.connection.balance.forEach(asset => {
                    if (asset.amount == null || asset.ticker == null) {
                        return;
                    }

                    if (asset.type === 'forex' && this.markets[asset.ticker + '/USD']) {
                        value += asset.amount * this.markets[asset.ticker + '/USD'].price
                    } else if (this.markets[asset.ticker]) {
                        value += asset.amount * this.markets[asset.ticker].price;
                    }
                });

                return value;
            },
            connectionLastUpdated() {
                if (this.connection == null || this.connection.updatedAt == null) {
                    return undefined;
                }

                const today = new Date();
                const updatedAt = new Date(this.connection.updatedAt);

                const daysInBetween = (updatedAt.getTime() - today.getTime()) / (1000 * 3600 * 24);
                if (daysInBetween > 1) {
                    
                    if (this.$i18n.locale === 'es') {
                        return 'hace ' + Math.floor(daysInBetween) + ' d�as';
                    }
                    if (this.$i18n.locale === 'fr') {
                        return 'il ya ' + Math.floor(daysInBetween) + ' jours';
                    }
                    return Math.floor(daysInBetween) + ' ' + this.$i18n.getLocaleMessage(this.$i18n.locale).profile.days_ago;
                }
                
                return this.$i18n.getLocaleMessage(this.$i18n.locale).profile.today_at + ' ' + this.connection.updatedAt.split('T')[1].slice(0, 5);
            },
            isConnectionActive() {
                if (typeof this.connection.active !== 'undefined') {
                    return this.connection.active;
                }

                // if the field is not present, if a connection is updated more than 3 days ago, it needs attention
                const today = new Date();
                const updatedAt = new Date(this.connection.updatedAt);

                const daysInBetween = (updatedAt.getTime() - today.getTime()) / (1000 * 3600 * 24);

                return (daysInBetween > 3) ? false : true;
            },
            isConnectionExpired() {
                return this.connection.status &&
                    this.connection.status === 'EX';
            }
        }
    }
</script>

<style scoped>

    div.wrapper {
        display: flex;
        width: 100%;
    }

    @media(min-width: 600px) {
        div.wrapper {
            width: 400px;
        }
    }

    div.profile-connection-card {
        display: flex;
        flex-direction: column;
        background-color: var(--primary);
        border-radius: var(--high-rounded);
        width: 100%;
        padding: 16px 32px;
        gap: 8px;
        cursor: pointer;
    }

        div.profile-connection-card.error-connection {
            border: 2px solid var(--error);
        }

        div.profile-connection-card.expired-connection {
            border: 2px solid var(--tertiary);
        }

        div.profile-connection-card > div {
            display: flex;
            justify-content: space-between;
        }

    div.last-updated-field {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    div.edit-icon-field {
        display: flex;
        align-items: center;
    }
</style>
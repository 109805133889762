



export default {

    it: {
        the_connections_of: 'Le connessioni di',
        owner_assets: 'Asset dell\'intestatario',
    },

    es: {
        the_connections_of: 'Las conexiones de',
        owner_assets: 'Activos del propietario',
    },

    en: {
        the_connections_of: 'The connections of',
        owner_assets: 'Holder assets',
    },

    fr: {
        the_connections_of: 'Les connexions de',
        owner_assets: 'Actifs du propri�taire',
    },
};
<template>
    <form class="add-poll-form">

        <div class="input-frame">
            <input-field v-model="pollOption"
                         type="text"
                         :label="$t('rewards.add_an_option')"
                         isValid="true" />
        </div>


        <button v-if="userIsTyping"
                :disabled="isLoading"
                class="secondary full-rounded"
                @click.prevent="addPollOption">
            <span v-if="isLoading"
                  class="material-icons loading-icon">
                sync
            </span>
            <p class="label-large">
                {{ $t('rewards.add_new_option')}}
            </p>
        </button>
    </form>
</template>

<script>

    import InputField from '@/components/fields/InputField';

    export default {
        name: 'New-Answer-Input',
        props: ['loading'],
        components: {
            InputField
        },
        data() {

            return {
                pollOption: ''
            }
        },
        methods: {
            addPollOption() {
                if (this.loading || this.pollOption == null || this.pollOption === '') {
                    this.pollOption = undefined;
                    return;
                }
                this.$parent.addOption(this.pollOption);
                this.pollOption = undefined;
            }
        },
        computed: {
            userIsTyping() {
                return this.pollOption && this.pollOption.length > 0;
            },
            isLoading() {
                return this.loading;
            }

        }
    }
</script>

<style scoped>
    form.add-poll-form {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
    }
        form.add-poll-form > div.input-frame {
            display: flex;
            align-items: center;
        }
        form.add-poll-form button {
            justify-content: center;
            width: 100%;
        }
</style>
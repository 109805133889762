<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <h2 class="headline-large black">
                        {{$t("connect.add_stock")}}
                    </h2>

                    <form class="branded-input-form" @submit.prevent="saveStocksETFsSymbol">


                        <div class="fields-frame">

                            <new-portfolio-input-field-component v-model="input_value.source_name"
                                                                 type="text"
                                                                 id="source_name"
                                                                 :label="$t('forms.add_portfolio')"
                                                                 :list="manualPortfolioListNames"
                                                                 :isValid="isValid.source_name" />

                        </div>


                        <div class="fields-frame">

                            <input-field-component v-model="input_value.amount"
                                                   type="text"
                                                   id="amount"
                                                   :label="$t('forms.amount')"
                                                   :isValid="isValid.amount" />

                        </div>

                        <div class="fields-frame">

                            <input-field-component v-model="input_value.name"
                                                   type="text"
                                                   :readonly="true"
                                                   :label="$t('forms.stock')"
                                                   :isValid="true" />

                        </div>

                        <div class="fields-frame">

                            <input-field-component v-model="input_value.exchange"
                                                   type="text"
                                                   :readonly="true"
                                                   :label="$t('forms.exchange')"
                                                   :isValid="true" />

                        </div>

                        <div class="buttons-frame">


                            <button class="tertiary full-rounded"
                                    :disabled="loading">
                                <span v-if="loading"
                                      class="material-icons loading-icon">
                                    sync
                                </span>
                                <p class="label-large">
                                    {{ $t('forms.add') }}
                                </p>
                            </button>
                            <button class="secondary outlined full-rounded"
                                    @click.prevent="closeModal">
                                <p class="label-large">
                                    {{ $t('forms.back') }}
                                </p>
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </Transition>
</template>

<script>

    import PostUsersManualAsset from "@/services/users/manual/asset/post.service";
    import PatchMarketsStocksFundamentals from "@/services/markets/stocks/fundamentals/patch.service";
    import EventBus from "@/common/EventBus";

    import InputFieldComponent from '@/components/fields/InputField';
    import NewPortfolioInputFieldComponent from '@/components/fields/portfolio/NewPortfolioInputField';

    export default {
        name: 'Add-Stocks-ETFs-Modal',
        props: ['show', 'type', 'exchange_name', 'mic_code', 'name', 'symbol'],
        components: {
            InputFieldComponent,
            NewPortfolioInputFieldComponent
        },
        data() {


            return {
                input_value: {
                    name: this.name + ' - ' + this.symbol,
                    exchange: this.exchange_name + ' - ' + this.mic_code
                },
                loading: false,
                isValid: {
                    name: true,
                    exchange: true,
                    source_name: true,
                    amount: true
                }
            };
        },
        methods: {
            closeModal() {
                this.$parent.closeModal();
            },
            saveStocksETFsSymbol() {

                // validate

                if (this.input_value.source_name == null ||
                    this.input_value.source_name === '' ||
                    !this.manualPortfolioListNames.includes(this.input_value.source_name)) {
                    this.isValid.source_name = false;
                    return;
                } else {
                    this.isValid.source_name = true;
                }
                if (this.input_value.amount == null ||
                    this.input_value.amount === '' ||
                    isNaN(this.input_value.amount)) {
                    this.isValid.amount = false;
                    return;
                } else {
                    this.isValid.amount = true;
                }

                // execute

                this.loading = true;

                const source = this.manualPortfolioList.find(x => x.name === this.input_value.source_name).source;

                const data = {
                    source: source,
                    source_name: this.input_value.source_name,
                    name: this.name,
                    ticker: this.mic_code + ':' + this.symbol,
                    amount: this.input_value.amount,
                    type: this.type
                }

                const user_id = this.$store.getters['auth/getUserId'];
                PostUsersManualAsset.post(user_id, data).then(
                    () => {

                        PatchMarketsStocksFundamentals.patch([this.mic_code + ':' + this.symbol]).then(
                            () => {
                                // do nothing on success
                            }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });


                        // close modal and update the store
                        this.$store.dispatch('connections/refresh').then(() => {
                            this.loading = false;
                            this.closeModal();
                        }).then(() => {
                            this.$router.push('/connect/stocks/manual/success');
                        });


                    }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

            }
        },
        computed: {
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            manualPortfolioListNames() {
                return this.connections.filter(connection => {
                    if (connection.service === 'manual') {
                        return connection.name;
                    }
                }).map(x => x.name);
            },
            manualPortfolioList() {
                return this.connections.filter(connection => {
                    if (connection.service === 'manual') {
                        return connection;
                    }
                });
            }
        }
    }

</script>
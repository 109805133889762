<template>
    <div id="connection-success-page">
        <h1 class="headline-small black">
            {{ $t('connect.result.action_required') }}
        </h1>

        <div id="image-container">
            <img :src="require('@/assets/img/illustrations/empty-state.svg')"/>
        </div>

        <p class="body-large black">
            {{ $t('connect.result.no_cryptocurrency_found') }}
        </p>
        <div class="horizontal-frame">
            <button class="secondary full-rounded"
                    @click="goToDashboard">
                <p class="label-large">
                    {{ $t('connect.result.go_to_dashboard') }}
                </p>
            </button>
            <button class="tertiary full-rounded"
                    @click="goToConnectWallet">
                <p class="label-large">
                    {{ $t('connect.result.connect_wallet') }}
                </p>
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Connect-Cash-Manual-Warning',
        methods: {
            goToDashboard() {
                this.$router.push('/dashboard');
            },
            goToConnectWallet() {
                this.$router.push('/connect/crypto/wallet');
            }
        }
    }
</script>

<style scoped>
    div#connection-success-page {
        width: 100%;
        max-width: 808px;
        margin: 32px auto auto auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        text-align: center;
    }

    div#connection-success-page > div.horizontal-frame {
        display: flex;
        justify-content: center;
        gap: 16px;
    }

    #image-container{
        width: 100%;
        max-width: 320px;
    }

</style>
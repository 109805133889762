<template>
    <div id="connect-exchange-view">
        <info-card v-if="!$breakpoints.isMDOrLarger()"
                   :www_url="www_url"
                   :api_url="api_url"
                   :form_step="form_step"
                   :exchange_name="exchange_name" />

        <div>
            <h1 v-if="$breakpoints.isMDOrLarger()"
                class="headline-large black">
                {{ $t('connect.add_exchange') }}
            </h1>
            <exchange-form />
        </div>

        <info-card v-if="$breakpoints.isMDOrLarger()"
                   :www_url="www_url"
                   :api_url="api_url"
                   :form_step="form_step"
                   :exchange_name="exchange_name" />
    </div>
</template>

<script>
    import ExchangeForm from '@/components/connect/crypto/exchange/Form';
    import InfoCard from '@/components/connect/crypto/exchange/InfoCard';

    export default {
        name: 'Exchanges-Page',
        data() {
            return {
                www_url: '',
                api_url: '',
                exchange_name: '',
                form_step: 0
            }
        },
        components: {
            ExchangeForm,
            InfoCard
        },
        methods: {
            setWebsiteUrl(www_url) {
                this.www_url = www_url;
            },
            setApiManagementUrl(api_url) {
                this.api_url = api_url;
            },
            setExchangeName(exchange_name) {
                this.exchange_name = exchange_name;
            },
            setFormStep(form_step) {
                this.form_step = form_step;
            }
        }
    }
</script>

<style scoped>
    div#connect-exchange-view {
        margin: 32px auto auto auto;
        display: flex;
        justify-content: space-around;
        padding: 0px 16px;
    }

        @media (max-width: 904px) {
            div #connect-exchange-view {
                flex-direction: column;
            }
        }

        @media (min-width: 905px) {
            div#connect-exchange-view > div {
                width: 45%;
            }
        }
</style>

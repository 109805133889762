import api from '../../../../api';

class PatchUsersCryptoExchangesBalance {

  patch(id, data) {
    return api.patch('/v1/users/'+id+'/crypto/exchange/balance', data);
  }
}

export default new PatchUsersCryptoExchangesBalance();

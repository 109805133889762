import api from '../../../../api';

class PatchMarketsCryptoHistorical {

  patch(tickers) {
    return api.patch('/v1/markets/crypto/historical/' + tickers.join(',') );
  }
}

export default new PatchMarketsCryptoHistorical();





export default {

    it: {
        change_password: "Reimposta Password",
        insert_and_confirm: "Inserisci la tua nuova password",

        password: "Password",
        password_required: "La password è obbligatoria",
        password_short: "La password deve avere almeno 6 caratteri",
        password_maximum_40_characters: "La password può avere al massimo 40 caratteri",
        weak_password: "La password è troppo debole",
        password_not_valid: "La password è troppo debole oppure non sono uguali",

        password_confirmation: "Conferma password",
        passwords_must_match: "Le password non corrispondono",

        password_must: "La password deve essere composta da minimo 6 e massimo 40 caratteri.",
        password_suggestion: "Usa caratteri minuscoli/maiuscoli, numeri e caratteri speciali.",

        change_my_password: "Reimposta Password",

        error_token: "Il link per reimpostare la password è scaduto",
        generic_error: "Si è verificato un errore",

        popup_title: "Password reimpostata con successo",
        popup_message: "Effettua il login usando la tua nuova password",
        popup_cta: "Vai al login"
    },

    es: {
        change_password: "Restablecer contraseña",
        insert_and_confirm: "Inserta una nueva contraseña",

        password: "Contraseña",
        password_required: "La contraseña es obligatoria",
        password_short: "6 caracteres como mínimo",
        password_maximum_40_characters: "40 caracteres como máximos",
        weak_password: "Contraseña débil",
        password_not_valid: "Contraseña débil, las contraseñas no coinciden",

        password_confirmation: "Confirma contraseña",
        passwords_must_match: "Las contraseñas no coinciden",

        password_must: "Tu contraseña debe contener entre 6 a 40 carácteres.",
        password_suggestion: "Combina números, letras minúsculas y mayúsculas, y carácteres especiales.",

        change_my_password: "Restablecer contraseña",

        error_token: "El enlace para restablecer tu contraseña ha caducado",
        generic_error: "Se ha producido un error",

        popup_title: "Contraseña modificada",
        popup_message: "Inicia sesión con tu nueva contraseña",
        popup_cta: "Iniciar sesión"
    },

    en: {
        change_password: "Reset Password",
        insert_and_confirm: "Insert your new password",

        password: "Password",
        password_required: "The password is required",
        password_short: "The password must be at least 6 characters",
        password_maximum_40_characters: "The password must be maximum 40 characters",
        weak_password: "The password is too weak",
        password_not_valid: "The password is too weak or does not match",

        password_confirmation: "Confirm password",
        passwords_must_match: "Passwords must match",

        password_must: "Your password must contain from 6 to 40 characters.",
        password_suggestion: "Use a combination of lowercase/uppercase characters, digits, and special characters.",

        change_my_password: "Reset Password",

        error_token: "The link to reset your password has expired",
        generic_error: "An error occurred",

        popup_title: "Password changed",
        popup_message: "Now you can login using the new password",
        popup_cta: "Go to login"
    },

    fr: {
        change_password: "Réinitialiser mot de passe",
        insert_and_confirm: "Insérez un nouveau mot de passe",

        password: "Mot de passe",
        password_required: "Le mot de passe est obligatoire",
        password_short: "Au moins 6 caractères",
        password_maximum_40_characters: "Maximum de 40 caractères permis",
        weak_password: "Le mot de passe est trop faible",
        password_not_valid: "Mot de passe trop faible ou les mots ne correspondent pas",

        password_confirmation: "Confirmez le mot de passe",
        passwords_must_match: "Les mots de passe doivent correspondre",

        password_must: "Votre mot de passe doit être composé de 6 à 40 caractères.",
        password_suggestion: "Utilisez une combinaison de chiffres et de lettres majuscules/minuscules.",

        change_my_password: "Réinitialiser mot de passe",

        error_token: "Le lien pour réinitialiser votre mot de passe est expiré",
        generic_error: "Une erreur s'est produite",

        popup_title: "Mot de passe modifié avec succès",
        popup_message: "Connectez-vous avec votre nouveau mot de passe",
        popup_cta: "Connectez-vous"
    },

};

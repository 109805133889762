<template>
    <template v-if="!isLoaded">

        <animated-placeholder-component height="176px"
                                        width="100%" />

    </template>
    <template v-else>

        <div>
            <div v-if="filteredOwners && filteredOwners.length > 0">
                <div class="owners-frame">
                    <owner-card-component v-for="(owner, index) in filteredOwners"
                                          v-bind:key="index"
                                          :owner="owner" />
                </div>
            </div>
        </div>


    </template>
</template>

<script>

    import AnimatedPlaceholderComponent from '@/components/AnimatedPlaceholder';

    import OwnerCardComponent from '@/components/profile/views/OwnerCard';

    export default {
        name: 'Profile-Views-Component',
        components: {
            AnimatedPlaceholderComponent,
            OwnerCardComponent
        },
        computed: {
            isLoaded() {
                return this.$store.getters['views/isLoaded'];
            },
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            personalOwnerId() {
                return this.$store.getters['views/getUserPersonalOwnerId'];
            },
            filteredOwners() {
                return this.owners.filter(x => x._id !== this.personalOwnerId);
            }
        },
    }
</script>

<style scoped>
    div.owners-frame {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
</style>
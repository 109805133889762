<template>
    <div class="connection-card"
         @click="nextPage"
         v-bind:style="{ 'background-image': 'linear-gradient(to right, rgba(24, 35, 42, 1) 42%, rgba(24, 35, 42, 0) 100% ), url(' + backgroundImage + ')' }">
        <h2 class="title-medium white">
            {{ title }}
        </h2>
        <p class="body-medium white">
            {{ description }}
        </p>
        <div>
            <div class="image-frame">
                <img v-if="logo1" :src="logo1" />
                <img v-if="logo2" :src="logo2" />
                <img v-if="logo3" :src="logo3" />
                <img v-if="logo4" :src="logo4" />
                <img v-if="logo5" :src="logo5" />
            </div>
            <button class="tertiary full-rounded">
                <p v-if="cta === 'connect'" class="label-large">
                    {{ $t('forms.connect') }}
                </p>
                <p v-else class="label-large">
                    {{ $t('forms.add') }}
                </p>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Card-Component',
        props: ['title', 'description', 'to', 'logos', 'backgroundImage', 'page', 'tableId', 'cta'],
        methods: {
            nextPage() {
                this.$router.push({
                    path: this.to,
                    query: {
                        'page': this.page,
                        'table': this.tableId
                    }
                });
            }
        },
        computed: {
            logo1() {
                return (this.logos && this.logos.length > 0) ? this.logos[0] : undefined;
            },
            logo2() {
                return (this.logos && this.logos.length > 1) ? this.logos[1] : undefined;
            },
            logo3() {
                return (this.logos && this.logos.length > 2) ? this.logos[2] : undefined;
            },
            logo4() {
                return (this.logos && this.logos.length > 3) ? this.logos[3] : undefined;
            },
            logo5() {
                return (this.logos && this.logos.length > 4) ? this.logos[4] : undefined;
            },
            cssProps() {
                return {
                    '--background-image': this.backgroundImage
                }
            }
        }
    }
</script>

<style scoped>
    div.connection-card {
        padding: 16px;
        margin: auto 8px;
        border-radius: var(--high-rounded);
        cursor: pointer;
        /* background image is binded in the html tag */
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
    }

        div.connection-card > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        div.connection-card div.image-frame {
            display: flex;
            gap: 4px;
            margin-top: 8px;
        }

            div.connection-card div.image-frame img {
                border-radius: var(--full-rounded);
                height: 24px;
                width: 24px;
            }
</style>
<template>
    <div class="central">
        <div>
            <h1 class="headline-large black">
                ERROR 404
            </h1>
            <p class="body-large black">
                Page not found
            </p>
        </div>
        <button class="secondary full-rounded"
                @click="goBack">
            <span class="material-icons label-large">
                arrow_back
            </span>
            <p class="label-large">
                Go back
            </p>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Error-404-Page',
        methods: {
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
    div.central {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding-top: 64px;
        gap: 16px;
    }
</style>



export default {


    it: {
        check_your_email: "Controlla la tua email",
        verify_your_email_address: "Verifica il tuo indirizzo email per unirti a 360Track.",
        register_success: 'Registrato con successo',
        enter_the_platform: 'Entra nella dashboard',
    },

    es: {
        check_your_email: "Consulta tu correo electrónico",
        verify_your_email_address: "Verifica tu dirección de correo electrónico para unirte a 360Track.",
        register_success: 'Registrado correctamente',
        enter_the_platform: 'Entrar en la Dashboard'
    },

    en: {
        check_your_email: "Check your email",
        verify_your_email_address: "Verify your email address to join 360Track.",
        register_success: 'Registered successfully',
        enter_the_platform: 'Enter the Dashboard'
    },

    fr: {
        check_your_email: "Vérifiez votre addresse mail",
        verify_your_email_address: "Vérifiez votre adresse email pour rejoindre 360Track.",
        register_success: 'Enregistré avec succès',
        enter_the_platform: 'entrer dans le Dashboard'
    },

};
import api from '@/services/api';

class PutUsersProfileView {

    put(id, data) {
        return api.put('/v1/users/' + id + '/profile/view', data);
    }
}

export default new PutUsersProfileView();

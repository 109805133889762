<template>
    <div class="asset-connection-toggle-item">
        <p :class="{
       'label-large': true,
       'black inactive': !active,
       'active secondary': active }">
            {{ label }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'Asset-Connection-Toggle-Component',
        props: ['label', 'active']
    }
</script>

<style scoped>
    div.asset-connection-toggle-item {
        padding: 8px;
        min-width: 64px;
        text-align: center;
    }

        div.asset-connection-toggle-item:has(> p.active) {
            border-bottom: 2px solid var(--secondary);
        }

        div.asset-connection-toggle-item:has(> p.inactive) {
            border-bottom: 1px solid var(--secondary);
        }

        div.asset-connection-toggle-item > p.inactive {
            cursor: pointer;
        }
</style>




export default {

    it: {
        reset_password: "Recupera password",
        enter_email: "Inserisci la tua email o il tuo username per recuperare le credenziali d'accesso",

        email: "Email",
        email_invalid: "L'email non è valida",
        email_maximum_50_characters: "L'email può avere al massimo 50 caratteri",

        or: "Oppure",

        username: "Username",
        username_short: "L'username deve avere almeno 3 caratteri",
        username_maximum_20_characters: "L'username può avere a massimo 20 caratteri",

        need_help: "Hai bisogno di aiuto?",
        contact_us: "Contattaci!",

        insert_either: "Inserisci soltanto l'email oppure l'username. Lascia un campo vuoto",

        reset_button: "Reimposta password",

        popup_title: "QUASI FINITO",
        popup_message: "Se l'indirizzo e-mail (o l'username) fornito coincide con quello in nostro possesso, hai ricevuto una mail con la procedura per cambiare password",
        popup_cta: "CHIUDI",
    },

    es: {
        reset_password: "Restablecer contraseña",
        enter_email: "Introduce tu correo electrónico o nombre de usuario para restablecer tu contraseña",

        email: "Tu correo habitual",
        email_invalid: "El correo electrónico no es válido",
        email_maximum_50_characters: "50 caracteres como máximo",

        or: "O",

        username: "Nombre de usuario",
        username_short: "3 caracteres como mínimo",
        username_maximum_20_characters: "20 caracteres como máximo",

        need_help: "¿Necesitas ayuda?",
        contact_us: "¡Contáctanos!",

        insert_either: "Introduce únicamente tu correo o tu nombre de usuario. Deja un campo vacío.",

        reset_button: "Restablecer contraseña",

        popup_title: "YA CASI TERMINAMOS",
        popup_message: "Si el correo (o nombre de usuario) integrado coincide con los registros en nuestra base de datos, Le hemos enviado un email con las informacionés para restablecer su contraseña",
        popup_cta: "CIERRA",
    },

    en: {
        reset_password: "Reset password",
        enter_email: "Enter either your username or your email.",

        email: "Email",
        email_invalid: "The email is invalid",
        email_maximum_50_characters: "The email must be maximum 50 characters",

        or: "OR",

        username: "Username",
        username_short: "The username must be at least 3 characters",
        username_maximum_20_characters: "The username must be maximum 20 characters",

        need_help: "Need help?",
        contact_us: "Contact us",

        insert_either: "Insert either your email or username. Leave one field empty.",

        reset_button: "Reset my password",

        popup_title: "ALMOST DONE",
        popup_message: "If the e-mail (or username) you provided us matches our records, you will find the procedure to reset your password in your mailbox",
        popup_cta: "CLOSE",
    },

    fr: {
        reset_password: "Réinitialiser votre mot de passe",
        enter_email: "Entrez soit votre email soit votre nom d'utilisateur pour réinitialiser votre mot de passe",

        email: "Email",
        email_invalid: "Cette adresse email est invalide",
        email_maximum_50_characters: "Maximum de 50 caractères permis",

        or: "Ou",

        username: "Nom d'utilisateur",
        username_short: "Au moins 3 caractères",
        username_maximum_20_characters: "Maximum de 20 caractères permis",

        need_help: "Avez-vous besoin d'aide ?",
        contact_us: "Contactez-nous !",

        insert_either: "Entrez soit votre email soit votre nom d'utilisateur. Laissez l'un des champs vide.",

        reset_button: "Réinitialiser mot de passe",

        popup_title: "PRESQUE TERMINÉ",
        popup_message: "Si un compte lié à cet email (ou nom d'utilisateur) existe, on vous a envoyé un mail de réinitialisation de mot de passe",
        popup_cta: "FERME",
    },

};

<template>

    <top-bar />
    
    <div id="login-view">
        <img v-if="$breakpoints.isMDOrLarger()"
             class="img-responsive" 
             :src="require('@/assets/img/illustrations/guy_on_a_cloud_with_laptop.svg')" />
        <div class="form-wrapper">
            <login-form />
        </div>
    </div>

</template>

<script>
    import TopBar from "@/components/auth/TopBar";
    import LoginForm from "@/components/auth/login/LoginForm";

    export default {
        name: "Login-Page",
        components: {
            TopBar,
            LoginForm
        },
        beforeCreate: function () {
            document.body.className = 'login';
        },
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        },
        created() {
            if (this.loggedIn) {
                this.$router.push("/dashboard");
            }
        },
    };
</script>

<style scoped>

    div#login-view {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 80px auto auto auto;
    }

        div#login-view > div.form-wrapper {
            padding-left: 16px;
            padding-right: 16px;
        }

    @media (min-width: 600px) {
        div#login-view > div.form-wrapper {
            width: 40%;
        }
    }

    div.img-responsive {
        object-fit: contain;
        width: 80%;
        max-width: 70vh;
        margin: 0 0 0 8vw;
    }
</style>

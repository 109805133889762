<template>

    <div class="input-field high-rounded">
        <input :id="inputFieldId"
               :type="type"
               :class="{
                    'body-medium black': true,
                    'invalid': !isValid,
                    'readonly': readonly
               }"
               :value="modelValue"
               placeholder=" "
               @input="emitEvent($event.target.value)">
        <label v-if="label"
               :for="inputFieldId"
               class="label-large black">
            {{ label }}
        </label>
        <button type="button"
                v-if="readonly == null || readonly === false"
                @click="emitEvent('')">
            <span class="material-icons black">
                clear
            </span>
        </button>
        <button type="button"
                v-else>
            <span class="material-icons-outlined">
                lock
            </span>
        </button>
        <p class="body-small error"
           v-if="!isValid && errorMessage">
            {{ errorMessage }}
        </p>
    </div>

</template>

<script>
    export default {
        name: 'Input-Field-Component',
        props: ['label', 'modelValue', 'type', 'id', 'isValid', 'errorMessage', 'readonly'],

        /*
         *  label - the label - optional
         *  modelValue (not passed) - bind it using v-model from the parent
         *  type - the type of the input - optional
         *  id - the id of the input - optional, but it is safer to pass it
         *  isValid - true or false. Must control it in the parent component - mandatory
         * 
         * */

        data() {
            return {
                inputFieldId: (this.id) ? this.id : this.randomString()
            };
        },
        methods: {
            randomString() {
                // generate a random 7 characters string
                return (Math.random() + 1).toString(36).substring(2);
            },

            emitEvent(value) {
                if(this.readonly == null || this.readonly === false) {
                    this.$emit('update:modelValue', value);
                }
            }
        }
    }
</script>

<style scoped>
    div.input-field:has(> input.readonly) > label,
    div.input-field:has(> input.readonly) > button,
    div.input-field > input.readonly {
        pointer-events: none;
        color: var(--placeholder);
    }
</style>
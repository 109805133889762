<template>

    <animated-placeholder-component v-if="isLoading"
                                    :height="'256px'" />
    <div v-else
         class="historical-graph-section">
        <div class="title-card">
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.price_history_title') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>
        <div v-if="(typeof assetHistoricalPrices !== 'undefined') && assetHistoricalPrices.length > 0"
             class="asset-historical-price-information">

            <div class="align-horizontally">
                <p @click="numberDays = 30"
                   :class="{
                    'label-large clickable-element': true,
                    'black': numberDays !== 30,
                    'secondary': numberDays === 30
                   }">
                    {{ $t('dashboard.asset_details.one_month_label') }}
                </p>
                <p @click="numberDays = 90"
                   :class="{
                    'label-large clickable-element': true,
                    'black': numberDays !== 90,
                    'secondary': numberDays === 90
                   }">
                    {{ $t('dashboard.asset_details.three_months_label') }}
                </p>
                <p @click="numberDays = 360"
                   :class="{
                    'label-large clickable-element': true,
                    'black': numberDays !== 360,
                    'secondary': numberDays === 360
                   }">
                    {{ $t('dashboard.asset_details.one_year_label') }}
                </p>
                <p @click="numberDays = 1080"
                   :class="{
                    'label-large d-none d-lg-flex clickable-element': true,
                    'black': numberDays !== 1080,
                    'secondary': numberDays === 1080
                   }">
                    {{ $t('dashboard.asset_details.three_years_label') }}
                </p>
                <p @click="numberDays = 1800"
                   :class="{
                    'label-large d-none d-lg-flex clickable-element': true,
                    'black': numberDays !== 1800,
                    'secondary': numberDays === 1800
                   }">
                    {{ $t('dashboard.asset_details.five_years_label') }}
                </p>
                <p @click="numberDays = -1"
                   :class="{
                    'label-large d-none d-lg-flex clickable-element': true,
                    'black': numberDays !== -1,
                    'secondary': numberDays === -1
                   }">
                    {{ $t('dashboard.asset_details.max_label') }}
                </p>
            </div>
            <canvas :id="historicalPriceChartId" />
        </div>
        <div v-else>
            <p class="body-large black">
                {{ $t('dashboard.asset_details.could_not_fetch_historicals') }}
            </p>
        </div>

    </div>

</template>


<script>

    import AnimatedPlaceholderComponent from '@/components/AnimatedPlaceholder';

    import Chart from 'chart.js/auto';

    export default {
        name: "Asset-Historical-Price-Chart-Component",
        props: ['asset', 'page', 'path'],
        components: {
            AnimatedPlaceholderComponent
        },
        data() {
            return {
                alreadyDrawnPriceChart: false,
                numberDays: 90,
                chartElement: undefined,
                historicalPriceChartId: 'historical-graph-' + (Math.random() + 1).toString(36).substring(2),
                isLoading: true
            }
        },
        beforeCreate() {
            this.$store.dispatch('markets/askHistoricalData', {
                type: this.asset.type,
                ticker: this.asset.ticker
            });
        },
        computed: {
            assetHistoricalPrices() {
                const ticker = (this.asset.type === 'forex') ? this.asset.ticker + '/USD' : this.asset.ticker;
                const market = this.$store.getters['markets/getMarkets'];

                const marketTicker = market[ticker];

                return (marketTicker) ? marketTicker.historical || [] : [];
            }
        },
        watch: {
            assetHistoricalPrices: async function (historicalPrices) {

                if (historicalPrices.length > 10) {
                    this.isLoading = false;
                }
            },

            numberDays: async function (days) {

                if (this.isLoading) {
                    return; // do not force reload if the data is loading
                }
                this.drawHistoricalPriceChart(this.assetHistoricalPrices, days, true);
            },

            isLoading: async function (newValue, oldValue) {

                // after the data finishes loading, draw the chart
                if (newValue === false && oldValue === true) {
                    this.drawHistoricalPriceChart(this.assetHistoricalPrices, this.numberDays, false);
                }
            }
        },
        methods: {

            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },

            drawHistoricalPriceChart(historicalPrices, maxDays, forceRedraw) {

                /*************************
                  POPULATE THE CHARTJS
                *************************/

                if (!forceRedraw) {
                    if (typeof historicalPrices === 'undefined' ||
                        historicalPrices.length === 0 ||
                        this.alreadyDrawnPriceChart) {
                        return;
                    }
                } else if (this.chartElement) {
                    this.chartElement.destroy();
                }

                const htmlGraphElement = document.getElementById(this.historicalPriceChartId);
                if (htmlGraphElement == null) {
                    return;
                }

                const ctx = htmlGraphElement.getContext('2d');
                this.alreadyDrawnPriceChart = true;

                /*************************
                  CHART COLOR GRANDIENT
                *************************/

                const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                gradient.addColorStop(0, 'rgba(40, 97, 253, 0.2)');
                gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

                /*************************
                    CHART DATA
                *************************/
                const _chart_labels = [];
                const _chart_data = [];
                const totalDays = (maxDays == null || maxDays === -1) ? historicalPrices.length - 1 : (maxDays < historicalPrices.length - 1) ? maxDays : historicalPrices.length - 1;

                for (let i = totalDays; i >= 0; i--) {
                    _chart_data.push(historicalPrices[i].close);
                    _chart_labels.push(new Date(historicalPrices[i].timestamp).toLocaleDateString('en-GB', {
                        day: 'numeric', month: 'short', year: 'numeric'
                    }).replace(/ /g, ' '));
                }

                const chart_data = {
                    labels: _chart_labels,
                    datasets: [{
                        data: _chart_data,
                        backgroundColor: gradient,
                        fill: true,
                        borderColor: 'rgba(24, 35, 42, 1)',
                        tension: 0.2
                    }]
                };

                /*****************************
                  DECLARE CHART TO BE USED
                *****************************/

                this.chartElement = new Chart(ctx, {
                    type: 'line',
                    data: chart_data,
                    options: {
                        //animation: chart_animation,
                        interaction: {
                            intersect: false,
                            mode: 'index',
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            tooltip: {
                                callbacks: {
                                    label: function () {
                                        return 0;
                                    },
                                    footer: (tooltipItems) => {
                                        let sum = 0;

                                        tooltipItems.forEach(function (tooltipItem) {
                                            sum += tooltipItem.parsed.y;
                                        });

                                        return '$' + sum;
                                    }
                                }
                            },
                        },
                        scales: {
                            x: {
                                display: true
                            },
                            y: {
                                display: true,
                                position: 'right',
                                type: 'linear'
                            }
                        },
                        elements: {
                            point: {
                                radius: 0
                            }
                        },
                        maintainAspectRatio: false,
                        responsive: true
                    }
                });
            }
        },
        mounted() {
            if (this.assetHistoricalPrices.length > 10) {
                this.isLoading = false;
                if (this.chartElement) {
                    this.chartElement.destroy();
                }
                setTimeout(() => {
                    this.drawHistoricalPriceChart(this.assetHistoricalPrices, this.numberDays, false);
                }, 128);
            }
        }
    }
</script>

<style scoped>

    div.historical-graph-section {
        padding: 16px;
        background-color: white;
        border-radius: 12px;
        width: calc(100% - 64px);
    }

        div.historical-graph-section > h2 {
            margin-left: 32px;
        }

        div.historical-graph-section > div.asset-historical-price-information {
            width: 100%;
            height: fit-content;
            max-height: 400px;
        }

            div.historical-graph-section > div.asset-historical-price-information > canvas {
                height: 356px;
                width: 100%;
            }

        div.historical-graph-section > div.title-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
        }

            div.historical-graph-section > div.title-card > span {
                cursor: pointer;
            }

        div.align-horizontally {
            display: flex;
            justify-content: space-around;
        }

    .clickable-element {
        cursor: pointer;
    }
</style>
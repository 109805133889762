<template>
	<div />
</template>

<script>
	export default {
		name: 'Terms-And-Conditions',
		beforeCreate() {
			window.open('https://360track.me/en/legal', '_blank').focus();
			this.$router.go(-1);
		}
	}
</script>
import api from '@/services/api';

class DeleteUsersPages {

    delete(id, page) {
        return api.delete('/v1/users/' + id + '/pages/' + page);
    }
}

export default new DeleteUsersPages();



export default {

    it: {
        win_unique_rewards: 'Sblocca la Voting Room',
        //win_unique_rewards: "Vinci ricompense uniche",
        step_1: "Step 1/2: Connetti una banca, app di pagamento, exchange o wallet cripto",
        step_2: "Step 2/2: Rispondi a un brevissimo questionario. Meno di 30 secondi",
        lets_do_it: "Vai",

        current_tier: "Il tuo Badge",
        //next_tier: "Prossimo livello",
        prince_elector: "Principe Elettore",
        //adventurer: "Avventuriero",
        //points: "punti",
        //rewards: "Ricompense",
        //six_free_months: "Altri 6 mesi Premium, gratis!",
        //voting_power: "Potere di voto per decidere le nuove funzionalità",
        complete_missions: 'Vinci Badge e sblocca le Seasons:',
        get_special_badge: 'Ottieni un Badge speciale',
        see_future_developments: 'Visiona in anteprima i futuri sviluppi',
        join_voting_room: 'Accedi alla Voting Room e alle Voting Seasons',

        vote_your_feature: "Vota, suggerisci, decidi.",
        season_2: 'Season 2',
        how_seasons_work: 'Come funzionano le Voting Seasons?',
        //app_for_you: "Un'app costruita con te e per te!",
        vote_and_suggest: 'Tramite il sondaggio, puoi votare o proporre nuove features. Tutte create dalla Community. A fine di ogni season, sviluppiamo quelle che hanno ricevuto più voti',
        //vote_and_suggest: "Vota le funzionalità che ritieni più utili. Suggeriscene di nuove. Osserva i risultati in tempo reale.",
        add_new_option: "Aggiungi opzione",
        add_an_option: "Aggiungi un'opzione al sondaggio",
        added_by: "Aggiunto da",

        // poll

        poll_title: 'Season 2',
        poll_subtitle: 'Termina il 15 Settembre 2023',

        // previous season

        recap_season_1: 'Riepilogo Season 1',
        next_developments: 'Prossimi Sviluppi',
        development_1: 'Poter personalizzare le pagine, aggiungere note e appunti',
        development_2: 'Sarebbe bello poter specificare i proprietari degli asset e avere sottoprofili, per gestire l’intero patrimonio familiare',
        development_3: 'Connessioni dirette di Broker (BitPanda, Degiro, etc)',
        development_4: 'Vedere il P&L complessivo per ogni connessione o asset',
    },

    es: {
        win_unique_rewards: 'Entra en la sala de votación',
        //win_unique_rewards: "Vinci ricompense uniche",
        step_1: "Paso 1/2: Conectar un banco, una aplicación de pago, o un monedero de criptomonedas",
        step_2: "Paso 2/2: Responder a un cuestionario muy breve. Menos de 30 segundos",
        lets_do_it: "¡Vámonos!",

        current_tier: "Tu Insignia",
        //next_tier: "Prossimo livello",
        prince_elector: "Príncipe Elector",
        //adventurer: "Avventuriero",
        //points: "punti",
        //rewards: "Ricompense",
        //six_free_months: "Altri 6 mesi Premium, gratis!",
        //voting_power: "Potere di voto per decidere le nuove funzionalità",
        complete_missions: 'Gana Insignias y sube de nivel' ,
        get_special_badge: 'Consiga una insignia especial',
        see_future_developments: 'Decida las próximas funciones a desarrollar',
        join_voting_room: 'Acceda a la sala de votación',

        vote_your_feature: "Vota, sugiere, decide.",
        season_2: 'Season 2',
        how_seasons_work: '¿Cómo funcionan los periodos de votación?',
        //app_for_you: "Un'app costruita con te e per te!",
        vote_and_suggest: 'A través de la encuesta, puede votar o proponer nuevas funciones. Todas creadas por la comunidad. Al final de cada temporada, desarrollamos las que han recibido más votos',
        //vote_and_suggest: "Vota le funzionalità che ritieni più utili. Suggeriscene di nuove. Osserva i risultati in tempo reale.",
        add_new_option: "Añadir opción",
        add_an_option: "Añadir una opción a la encuesta",
        added_by: "Añadido por",

        // poll

        poll_title: 'Season 2',
        poll_subtitle: 'Expira el 15 de septiembre de 2023',

        // previous season

        recap_season_1: 'Resumen Season 1',
        next_developments: 'Próximas Funciones',
        development_1: 'Poder personalizar las páginas, añadir notas y apuntes',
        development_2: 'Estaría bien poder especificar los propietarios de los bienes y tener subperfiles, para gestionar todo el patrimonio familiar',
        development_3: 'Conexiones directas con brokers (BitPanda, Degiro, etc.)',
        development_4: 'Ver la cuenta de resultados global de cada conexión o activo',
    },

    en: {
        win_unique_rewards: 'Unlock the Voting Room',
        //win_unique_rewards: "Vinci ricompense uniche",
        step_1: "Step 1/2: Connect a bank, payment app, exchange or crypto wallet",
        step_2: "Step 2/2: Answer a very short questionnaire. Less than 30 seconds",
        lets_do_it: "Let's go!",

        current_tier: "Your Badge",
        //next_tier: "Prossimo livello",
        prince_elector: "Great Elector",
        //adventurer: "Avventuriero",
        //points: "punti",
        //rewards: "Ricompense",
        //six_free_months: "Altri 6 mesi Premium, gratis!",
        //voting_power: "Potere di voto per decidere le nuove funzionalità",
        complete_missions: 'Win Badges and unlock Voting Seasons:',
        get_special_badge: 'Get a Special Badge',
        see_future_developments: 'Decide future developments',
        join_voting_room: 'Access the Voting Room and Voting Seasons',

        vote_your_feature: "Vote, suggest, decide.",
        season_2: 'Season 2',
        how_seasons_work: 'How do Voting Seasons work?',
        //app_for_you: "Un'app costruita con te e per te!",
        vote_and_suggest: 'Via the poll, you can vote or propose new features. All created by the community. At the end of each season, we develop the ones that received the most votes.',
        //vote_and_suggest: "Vota le funzionalità che ritieni più utili. Suggeriscene di nuove. Osserva i risultati in tempo reale.",
        add_new_option: "Add option",
        add_an_option: "Add an option to the poll",
        added_by: "Added by",

        // poll

        poll_title: 'Season 2',
        poll_subtitle: 'Ends on Sepetmber 15',

        // previous season

        recap_season_1: 'Season 1 Summary',
        next_developments: 'Upcoming Developments',
        development_1: 'Being able to customise pages, add notes and notes',
        development_2: 'It would be nice to be able to specify the owners of the assets and have subprofiles, to manage the entire family wealth',
        development_3: 'Direct Broker Connections (BitPanda, Degiro, etc.)',
        development_4: 'See the overall P&L for each connection or asset',
    },

    fr: {
        win_unique_rewards: 'Déverrouillez la salle de vote',
        //win_unique_rewards: "Vinci ricompense uniche",
        step_1: "Étape 1/2 : Connecter une banque, une application de paiement, ou un wallet/exchange de crypto-monnaies",
        step_2: "Étape 2/2 : Répondez à un questionnaire très court. Moins de 30 secondes",
        lets_do_it: "Allons-y !",

        current_tier: "Votre badge",
        //next_tier: "Prossimo livello",
        prince_elector: "Grand électeur",
        //adventurer: "Avventuriero",
        //points: "punti",
        //rewards: "Ricompense",
        //six_free_months: "Altri 6 mesi Premium, gratis!",
        //voting_power: "Potere di voto per decidere le nuove funzionalità",
        complete_missions: 'Gagnez des badges et débloquez la Salle de Vote :',
        get_special_badge: 'Obtenez un badge spécial',
        see_future_developments: 'Décidez les développements futurs',
        join_voting_room: 'Accédez à la salle de vote et aux saisons de vote',

        vote_your_feature: "Votez, suggérez, décidez 1",
        season_2: 'Saison 2',
        how_seasons_work: 'Comment fonctionnent les saisons de vote ?',
        //app_for_you: "Un'app costruita con te e per te!",
        vote_and_suggest: 'Via le sondage, vous pouvez voter ou proposer de nouvelles fonctionnalités. Toutes créées par la communauté. A la fin de chaque saison, nous développons celles qui ont reçu le plus de votes.',
        //vote_and_suggest: "Vota le funzionalità che ritieni più utili. Suggeriscene di nuove. Osserva i risultati in tempo reale.",
        add_new_option: "Ajoutez une option",
        add_an_option: "Ajoutez une option au sondage",
        added_by: "Ajoutée par",

        // poll

        poll_title: 'Saison 2',
        poll_subtitle: 'Fin de la Saison: 15 Septembre',

        // previous season

        recap_season_1: 'Résumé de la Saison 1',
        next_developments: 'Développements à venir',
        development_1: "Possibilité de personnaliser les pages, d'ajouter des notes et des commentaires",
        development_2: "Il serait intéressant de pouvoir spécifier les propriétaires des actifs et d'avoir des sous-profils pour gérer l'ensemble du patrimoine familial",
        development_3: 'Connexions directes avec des courtiers (BitPanda, Degiro, etc.)',
        development_4: 'Voir le compte de résultat global pour chaque connexion ou actif',
    }
};
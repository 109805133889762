<template>
    <div class="timeframe-switcher">

        <div @click="showDropdown = !showDropdown"
             class="switcher">
            <p class="title-medium black bold">
                {{ labelValue }}
            </p>
            <span v-if="showDropdown"
                  class="material-icons title-large">
                arrow_drop_up
            </span>
            <span v-else
                  class="material-icons title-large">
                arrow_drop_down
            </span>
        </div>

        <dropdown-menu-component :show="showDropdown"
                                 :list="[{
									name: '1D Var.',
									value: '1D'
                                 },
                                 {
									name: '1W Var.',
									value: '1W'
                                 },
                                 {
									name: '3M Var.',
									value: '3M'
                                 },
                                 {
									name: 'YTD Var.',
									value: 'YTD'
                                 }]"
                                 @onItemClick="setUserTimeframe"
                                 class="dropdown"/>
    </div>
</template>

<script>

    import PostUsersProfileTimeframe from '@/services/users/profile/timeframe/post.service';
    import EventBus from "@/common/EventBus";

    import DropdownMenuComponent from '@/components/fields/DropdownMenu';

    export default {
        name: 'Timeframe-Switcher-Component',
        components: {
            DropdownMenuComponent
        },
        data() {
            return {
                showDropdown: false
            };
        },
        computed: {
            labelValue() {

                const currentTimeframe = this.$store.getters['auth/getUserTimeframe'];

                switch (currentTimeframe) {
                    case '1D':
                        return '1D Var.';
                    case '1W':
                        return '1W Var.';
                    case '3M':
                        return '3M Var.';
                    case 'YTD':
                        return 'YTD Var.';
                    default:
                        return '';
                }
            }
        },
        methods: {
            setUserTimeframe(timeframe) {

                PostUsersProfileTimeframe.post(this.$store.getters['auth/getUserId'], timeframe).then(
                    () => {
                        this.$store.dispatch('auth/setUserTimeframe', timeframe);
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    });
            }
        }
    }
</script>

<style scoped>
    div.timeframe-switcher {
        position: relative;
    }
    div.timeframe-switcher > div.switcher {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
    }
        div.timeframe-switcher > div.dropdown {
            margin-left: -72px;
        }
</style>
import api from '@/services/api';

class PutUsersCryptoExchanges {

    put(id, data) {
        return api.put('/v1/users/' + id + '/crypto/exchange', data);
    }
}

export default new PutUsersCryptoExchanges();

import api from '@/services/api';

class DeleteUsersManualAssetBalance {

  delete(id, source, item_id) {
    return api.delete('/v1/users/'+id+'/manual/asset/'+source+'/'+item_id);
  }
}

export default new DeleteUsersManualAssetBalance();

<template>

    <div class="input-field high-rounded">
        <input :id="inputFieldId"
               :type="type"
               :class="{
                    'body-medium black': true
               }"
               :value="modelValue"
               placeholder=" "
               autocomplete="off"
               readonly
               @focus="showSuggestionList"
               @blur="hideSuggestionList"
               @input="$emit('update:modelValue', $event.target.value);
                       showSuggestionList">
        <label v-if="label"
               :for="inputFieldId"
               class="label-large black">
            {{ label }}
        </label>

        <span v-if="displaySuggestionList"
              class="material-icons">
            arrow_drop_up
        </span>
        <span v-else
              class="material-icons">
            arrow_drop_down
        </span>

        <!-- autocomplete list -->

        <div class="suggestions-list"
             v-if="list.length > 0 && displaySuggestionList">
            <div class="suggestion-item"
                 v-for="(suggestion, index) in list"
                 v-bind:key="index"
                 @click="hideSuggestionList; onItemClick(suggestion.value)">

                <p class="body-medium black">
                    {{ suggestion.name }}
                </p>

            </div>
        </div>



    </div>


</template>

<script>
    export default {
        name: 'Input-Field-Component',
        emits: ['onItemClick'],
        props: ['label', 'modelValue', 'type', 'id', 'list'],

        /*
         *  label - the label - optional
         *  modelValue (not passed) - bind it using v-model from the parent
         *  type - the type of the input - optional
         *  id - the id of the input - optional, but it is safer to pass it
         *
         * */


        /*
         * list in format
         * 
         * {
         *      name: String     // what to display
         *      value: String    // what is passed to the parent when the element is clicked
         * }
         * 
         * 
         * 
         * function: the function to call
         * 
         * 
         * 
         * */

        /**
         * 
         * 
                    <toggle-field-component v-model="profileLanguage"
                                            :list="[{
                                                name: 'Italiano',
                                                value: 'it'
                                             },
                                             {
                                                name: 'Fran�ais',
                                                value: 'fr'
                                             },
                                             {
                                                name: 'English',
                                                value: 'en'
                                             },
                                             {
                                                name: 'Espa�ol',
                                                value: 'es'
                                             }]"
                                             @onItemClick="setLanguage"/>
         * 
         * */

        data() {
            return {
                inputFieldId: (this.id) ? this.id : this.randomString(),
                displaySuggestionList: false
            };
        },
        methods: {
            randomString() {
                // generate a random 7 characters string
                return (Math.random() + 1).toString(36).substring(2);
            },
            hideSuggestionList() {
                setTimeout(() => {
                    this.displaySuggestionList = false;
                }, 128);
            },
            showSuggestionList() {
                this.displaySuggestionList = true;
            },
            onItemClick(value) {
                this.$emit('onItemClick', value);
            },
        }
    }
</script>

<style scoped>

    div.input-field,
    div.input-field > input {
        cursor: pointer;
    }

    div.input-field > span.material-icons {
        position: absolute;
        right: 12px;
        top: calc(50% - 0.75rem);
    }

    div.suggestions-list {
        position: absolute;
        z-index: 50;
        margin: 16px auto auto -8px;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 100%;
        border: 1px solid var(--primary);
        background-color: var(--white);
    }

        div.suggestions-list > div.suggestion-item {
            padding: 2px 8px 2px 8px;
        }

            div.suggestions-list > div.suggestion-item:hover {
                background-color: var(--secondary);
                cursor: pointer;
            }
</style>
<template>
    <div id="sidebar-menu">
        <div>

            <collapsed-sidebar-navigation-item-special-component :to="'/connect'"
                                                                 :icon="'add_circle'"
                                                                 :name="$t('navigation.connect')" />
            <collapsed-sidebar-navigation-item-component :to="'/dashboard'"
                                                         :icon="'dashboard'"
                                                         :name="$t('navigation.dashboard')" />
            <collapsed-sidebar-navigation-item-component :to="'/rewards'"
                                                         :icon="'how_to_vote'"
                                                         :name="$t('navigation.vote')" />
        </div>
        <div>

            <collapsed-sidebar-navigation-item-component :to="'/support'"
                                                         :icon="'help_outline'"
                                                         :name="$t('navigation.support')" />
            <collapsed-sidebar-navigation-item-component :to="'/terms_and_conditions'"
                                                         :icon="'policy'"
                                                         :name="$t('navigation.t_e_c')" />
        </div>
    </div>
</template>

<script>

    import CollapsedSidebarNavigationItemComponent from '@/components/navigation/sidebar/CollapsedNavigationItem';
    import CollapsedSidebarNavigationItemSpecialComponent from '@/components/navigation/sidebar/CollapsedNavigationItemSpecial';

    export default {
        name: 'Sidebar-Component',
        components: {
            CollapsedSidebarNavigationItemComponent,
            CollapsedSidebarNavigationItemSpecialComponent
        },
    }
</script>

<style scoped>
    div#sidebar-menu {
        width: 72px;
        height: calc(100% - 48px); /* remove top sidebar height from the height*/
        position: fixed;
        top: 48px;
        left: 0;
        overflow-x: hidden;
        z-index: 2;
        background-color: var(--primary);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

        div#sidebar-menu div {
            margin: 16px auto 16px auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1px;
        }
</style>
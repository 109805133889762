import api from '@/services/api';

class GetPlatformSurveysPoll {

    get(id) {
        return api.get('/v1/platform/surveys/poll/' + id);
    }
}

export default new GetPlatformSurveysPoll();

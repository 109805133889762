<template>

    <div class="skeleton-wrapper">
        <animated-placeholder-component height="96px"
                                        width="100%" />
        <animated-placeholder-component height="2rem"
                                        width="100%" />
        <animated-placeholder-component height="1.5rem"
                                        width="100%" />
        <animated-placeholder-component height="1.5rem"
                                        width="100%" />
        <animated-placeholder-component height="1.5rem"
                                        width="100%" />
        <animated-placeholder-component height="2rem"
                                        width="100%" />
        <animated-placeholder-component height="194px"
                                        width="100%" />
        <animated-placeholder-component height="2rem"
                                        width="100%" />
        <animated-placeholder-component height="1.5rem"
                                        width="100%" />
        <animated-placeholder-component height="1.5rem"
                                        width="100%" />
    </div>

</template>

<script>

    import AnimatedPlaceholderComponent from '@/components/AnimatedPlaceholder';

    export default {
        name: 'Skeleton-Full-Page-Component',
        components: {
            AnimatedPlaceholderComponent
        }
    }
</script>

<style scoped>

    div.skeleton-wrapper {
        height: fit-content;
        width: calc(100% - 104px);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 18px 52px 2px 52px;
    }
    @media (max-width: 599px) {
        div.skeleton-wrapper {
            width: calc(100% - 16px);
            padding: 18px 8px 2px 8px;
        }
    }

</style>
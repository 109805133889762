<template>
    <div class="company-insider-transactions-card"
         v-if="companyInsiderTransactions && companyInsiderTransactions.length > 0">
        <div class="title-card">
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.insider_transactinos_title') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>
        <div class="table-wrapper">
            <table class="branded-table">
                <thead>
                    <tr>
                        <th>
                            <p class="title-medium black bold">
                                {{ $t('dashboard.asset_details.insider') }}
                            </p>
                        </th>
                        <th>
                            <p class="title-medium black bold">
                                {{ $t('dashboard.asset_details.transaction') }}
                            </p>
                        </th>
                        <th>
                            <p class="title-medium black bold">
                                {{ $t('dashboard.asset_details.shares') }}
                            </p>
                        </th>
                        <th>
                            <p class="title-medium black bold">
                                {{ $t('dashboard.asset_details.date') }}
                            </p>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(insiderTransaction, index) in companyInsiderTransactionsFiltered"
                        :key="index">

                        <td>
                            <p class="body-medium black d-none d-md-block">
                                {{ insiderTransaction.full_name }}
                            </p>
                            <p class="body-small black d-none d-md-block">
                                {{ insiderTransaction.position }}
                            </p>
                        </td>
                        <td>
                            <div v-if="insiderTransaction.description.startsWith('Purchase')" class="buy-sell-item buy-color">
                                <span class="material-icons">
                                    arrow_drop_up
                                </span>
                                <p class="body-medium bold">
                                    {{ $t('dashboard.asset_details.buy') }}
                                </p>
                            </div>
                            <div v-if="insiderTransaction.description.startsWith('Sale')" class="buy-sell-item sell-color">
                                <span class="material-icons">
                                    arrow_drop_down
                                </span>
                                <p class="body-medium bold">
                                    {{ $t('dashboard.asset_details.sell') }}
                                </p>
                            </div>
                            <p class="body-small black d-block d-md-none">
                                <locale-date-component :isoDate="insiderTransaction.date_reported" />
                            </p>

                        </td>
                        <td>
                            <p class="body-medium black">
                                $ {{ insiderTransaction.value }}
                            </p>
                            <p class="body-small black">
                                {{ insiderTransaction.shares }}
                                {{ $t('dashboard.asset_details.units') }}
                            </p>
                        </td>
                        <td>
                            <p class="body-medium black">
                                <locale-date-component :isoDate="insiderTransaction.date_reported" />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div class="buttons-wrapper">
            <button v-if="!seeAllTransactions"
                    class="secondary full-rounded"
                    @click.prevent="seeAllTransactions = !seeAllTransactions">
                <p class="label-large">
                    {{ $t('dashboard.asset_details.see_all_transactions') }}
                </p>
            </button>
            <button v-else
                    class="secondary full-rounded"
                    @click="seeAllTransactions = !seeAllTransactions">
                <p class="label-large">
                    {{ $t('dashboard.asset_details.see_less_transactions') }}
                </p>
            </button>
        </div>
        

    </div>
    <div v-else
         class="company-insider-transactions-card-white">
        <div class="title-card">
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.insider_transactinos_title') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>
        <p class="body-medium black">
            {{ $t('dashboard.asset_details.no_insider_transactions') }}
        </p>
    </div>
</template>

<script>

import LocaleDateComponent from '@/components/dashboard/table/row/LocaleDate';

export default {
        name: "Company-Insider-Transactions",
        props: ['asset', 'page', 'path'],
        components: {
            LocaleDateComponent
        },
        data() {
            return {
                seeAllTransactions: false
            }
        },
        methods: {

            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },
        },
        computed: {

            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            company() {
                return (this.markets) ? this.markets[this.asset.ticker] : undefined;
            },
            companyFundamentals() {
                return (this.company) ? this.company.fundamentals : undefined;
            },
            companyInsiderTransactions() {
                return (this.companyFundamentals) ? this.companyFundamentals.insider_transactions : [];
            },

            companyInsiderTransactionsFiltered() {
                const filteredItems = [];

                // return up to 10 transactions, but only BUY or SELL ones
                if (this.companyInsiderTransactions != null && this.companyInsiderTransactions.length > 0) {
                    for (const transaction of this.companyInsiderTransactions) {
                        if (transaction.description.startsWith('Purchase') || transaction.description.startsWith('Sale')) {
                            filteredItems.push(transaction);
                        }
                        if (!this.seeAllTransactions && filteredItems.length > 10) {
                            break;
                        }
                    }
                }

                return filteredItems;
            }
        }
}
</script>

<style scoped>
    div.company-insider-transactions-card {
        gap: 16px;
        width: calc(100% - 24px);
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    div.company-insider-transactions-card-white {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px;
        border-radius: var(--high-rounded);
        background-color: var(--white);
        padding: 16px 32px;
        width: calc(100% - 64px);
    }

        div.company-insider-transactions-card-white > div.title-card,
        div.company-insider-transactions-card > div.title-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

            div.company-insider-transactions-card-white > div.title-card,
            div.company-insider-transactions-card > div.title-card > span {
                cursor: pointer;
            }

    div.buttons-wrapper {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    div.buy-sell-item {
        display: flex;
        justify-content: center;
    }

        div.buy-sell-item.buy-color {
            color: green;
        }

        div.buy-sell-item.sell-color {
            color: red;
        }
</style>
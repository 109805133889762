<template>

    <div class="previous-season-frame-card">
        <div class="previous-season">
            <div class="information-frame">
                <stylish-element-component :width="'16px'" />
                <div class="review-frame">
                    <p class="title-small">
                        {{ $t('rewards.recap_season_1') }}
                    </p>
                    <stylish-element-component :height="'12px'"
                                               :variant="'neutral'" />
                    <p class="title-medium">
                        {{ $t('rewards.next_developments') }}
                    </p>
                    <stylish-element-component :height="'12px'"
                                               :variant="'neutral'" />
                    <stylish-element-component :height="'12px'"
                                               :variant="'neutral'" />
                </div>
                <div class="description-frame">
                    <div>
                        <span class="material-icons-outlined title-large">
                            looks_one
                        </span>
                        <p class="body-medium">
                            {{ $t('rewards.development_1') }}
                        </p>
                    </div>
                    <div>
                        <span class="material-icons-outlined title-large">
                            looks_two
                        </span>
                        <p class="body-medium">
                            {{ $t('rewards.development_2') }}
                        </p>
                    </div>
                    <div>
                        <span class="material-icons-outlined title-large">
                            looks_3
                        </span>
                        <p class="body-medium">
                            {{ $t('rewards.development_3') }}
                        </p>
                    </div>
                    <div>
                        <span class="material-icons-outlined title-large">
                            looks_4
                        </span>
                        <p class="body-medium">
                            {{ $t('rewards.development_4') }}
                        </p>
                    </div>
                </div>
            </div>
            <img :src="require('@/assets/img/illustrations/team_building_1.svg')" />
        </div>
    </div>

</template>

<script>

    import StylishElementComponent from '@/components/StylishElement';

    export default {
        name: 'Previous-Season-Frame',
        components: {
            StylishElementComponent
        }
    }
</script>

<style scoped>

    /* wrapper */

    div.previous-season-frame-card {
        background-color: var(--white);
        border-radius: var(--high-rounded);
        padding: 24px calc(5% - 16px) 24px calc(5% - 16px);
    }

    /* internal element */

    div.previous-season {
        margin: 0px 24px;
        display: flex;
        width: calc(100% - 48px);
        justify-content: space-between;
    }

    div.previous-season > img {
        height: 128px;
    }

    /* internal elemenets */
    div.information-frame {
        display: flex;
        gap: 24px;
    }

    div.review-frame,
    div.description-frame {
        display: flex;
        flex-direction: column;
        min-width: 144px;
    }

        div.review-frame {
            gap: 4px;
        }

        div.description-frame > div {
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }

</style>
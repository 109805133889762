<template>

    <div class="container">


        <template v-if="areMarketsLoaded &&
                  personalOwnerCredentialsUnknown &&
                  inferredOwners.length>
            1 &&
            !skipToShowTheAccounts">

            <div class="content align-center">
                <h1 class="headline-small black">
                    The accounts you connected belong to different people.
                    <br />
                    Please select who you are:
                </h1>
                <div class="multiple-accounts-selector">


                    <button v-for="(owner, index) in inferredOwners"
                            v-bind:key="index"
                            @click="selectedOwnerAtIndex(index)">
                        <p class="label-large">
                            {{ owner.name }}
                        </p>
                    </button>
                    <button class="special-case"
                            @click="personalOwnerNotPresentInBankAccounts">
                        <p class="label-large">
                            {{ $t('connect.openbanking.none_of_above') }}
                        </p>
                    </button>


                </div>
            </div>
            <standout-lottie-component class="support-image-width" />

        </template>
        <template v-else-if="areMarketsLoaded &&
                  personalOwnerCredentialsUnknown &&
                  inferredOwners.length === 1 &&
                  !skipToShowTheAccounts">

            <div class="content align-center">
                <h1 class="headline-small black">
                    {{ $t('connect.openbanking.hello') }},
                    <span class="bold">
                        {{ userNickname }}
                    </span>,
                    <br />
                    {{ $t('connect.openbanking.from_nordigen_seems') }}
                    "<span class="bold">
                        {{ inferredOwners[0].name }}
                    </span>".
                    {{ $t('connect.openbanking.is_it_you') }}
                </h1>

                <div class="buttons-frame-accounts">
                    <button class="secondary full-rounded">
                        <p class="label-large"
                           @click="selectedOwnerAtIndex(0)">
                            {{ $t('connect.openbanking.yes') }}
                        </p>
                    </button>
                    <button class="secondary full-rounded outlined">
                        <p class="label-large"
                           @click="personalOwnerNotPresentInBankAccounts">
                            {{ $t('connect.openbanking.no') }}
                        </p>
                    </button>
                </div>
            </div>
            <standout-lottie-component class="support-image-width" />

        </template>
        <template v-else-if="areMarketsLoaded">
            <div class="content">

                <h1 class="headline-small black">
                    {{ $t('connect.openbanking.success_title') }}
                </h1>

                <div class="cards-frame">
                    <connected-box v-for="account in accountsToDisplay"
                                   :key="account.source"
                                   class="margin-connected-box"
                                   :iban="account.iban"
                                   :name="account.name"
                                   :logoUrl="account.logoUrl"
                                   :amountString="account.amountString"
                                   :accountId="account.id"
                                   :pageId="account.id"
                                   :requisitionId="requisitionId"
                                   :owners="account.owners" />
                </div>

                <div class="buttons-frame">
                    <button class="tertiary full-rounded" @click.prevent="goToConnectOpenbankingCountry">
                        <span class="material-icons">
                            add
                        </span>
                        <p class="label-large">
                            {{ $t('connect.openbanking.success_add_button') }}
                        </p>
                    </button>
                    <button class="secondary outlined full-rounded" @click.prevent="goToDashboard">
                        <p class="label-large">
                            {{ $t('connect.openbanking.success_done_button') }}
                        </p>
                    </button>
                </div>


            </div>
            <div>
                <img :src="require('@/assets/img/illustrations/success.svg')" class="support-image-width" />
            </div>
        </template>



        <div v-else>
            <loading-spinner :active="!areMarketsLoaded"
                             :text="'Extablishing secure connection with your Financial Institution'" />
        </div>
    </div>

    <page-overlay-component v-if="pageQuery"
                            :pageId="pageQuery" />

</template>

<script>

    import ConnectedBox from "@/components/connect/openbanking/account/ConnectedBox";
    import LoadingSpinner from "@/components/LoadingSpinner";
    import PageOverlayComponent from "@/components/dashboard/PageOverlay";
    import StandoutLottieComponent from '@/components/lottieImages/Standout';

    import PostUserProfileName from '@/services/users/profile/name/post.service';
    import PostUserProfileSurname from '@/services/users/profile/surname/post.service';
    import PostUsersProfileLegalNames from '@/services/users/profile/legalNames/post.service';

    import PutUsersProfileOwner from '@/services/users/profile/owner/put.service';
    import PostUserProfileOwner from '@/services/users/profile/owner/post.service';

    import PutUserOpenbankingAccountOwner from '@/services/users/openbanking/account/owners/put.service';

    import PlatformExternalEntityTokenizationSpacyPost from '@/services/platform/external/entityTokenization/spacy/post.service';

    import EventBus from '@/common/EventBus';

    export default {
        name: "Bank-Connected",
        components: {
            ConnectedBox,
            LoadingSpinner,
            PageOverlayComponent,
            StandoutLottieComponent
        },
        data() {
            return {
                inferredOwners: [],
                skipToShowTheAccounts: false
            };
        },
        methods: {

            goToConnectOpenbankingCountry() {
                this.$router.push('/connect/openbanking/country');
            },
            goToDashboard() {
                this.$router.push('/dashboard');
            },

            selectedOwnerAtIndex(index) {
                this.updatingOwnerInformation(index);
                this.createNewOwnersFromInferredOnesAndLinkingThemToAccounts(index);

                this.skipToShowTheAccounts = true;
            },
            personalOwnerNotPresentInBankAccounts() {
                this.createNewOwnersFromInferredOnesAndLinkingThemToAccounts();

                this.skipToShowTheAccounts = true;
            },

            updatingOwnerInformation(index) {
                let fullName = this.inferredOwners[index].name;
                if (fullName == null) {
                    return;
                }
                fullName = fullName.split(' ');

                const name = (fullName.length > 0) ? fullName[0] : '';
                let surname = (fullName.length > 1) ? fullName[1] : '';
                for (let i = 2; i < fullName.length; i++) {
                    surname += ' ' + fullName[i];
                }

                const userId = this.$store.getters['auth/getUserId'];
                PostUserProfileName.post(userId, name).then(
                    () => {
                        // update local storage
                        this.$store.dispatch('auth/setUserName', name);
                        // update also the personal owner
                        this.$store.dispatch('views/setPersonalOwnerName', name);
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );

                PostUserProfileSurname.post(userId, surname).then(
                    () => {
                        // update local storage
                        this.$store.dispatch('auth/setUserSurname', surname);
                        // update also the personal owner
                        this.$store.dispatch('views/setPersonalOwnerSurname', surname);
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );


                // add the legalname of the owner to the profile database
                const legalNames = JSON.parse(JSON.stringify(this.personalOwner.legalNames));
                const nameOnTheAccount = this.inferredOwners[index].name;
                if (!legalNames.includes(nameOnTheAccount)) {
                    legalNames.push(nameOnTheAccount);
                }
                PostUsersProfileLegalNames.post(userId, legalNames).then(
                    () => {
                        // update local storage
                        this.$store.dispatch('auth/setUserLegalNames', legalNames);
                        // update also the personal owner
                        this.$store.dispatch('views/setPersonalOwnerLegalNames', legalNames);
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );


            },


            /** get all the permutations of an array

             input
             inputArr = ['a','b','c']

             output
             result = ['abc','acb','bca','bac','cab','cba']


             */
            _getStringArrayPermutations(inputArr) {
                const result = [];

                const permute = (arr, m = []) => {
                    if (arr.length === 0) {
                        result.push(m)
                    } else {
                        for (let i = 0; i < arr.length; i++) {
                            let curr = arr.slice();
                            let next = curr.splice(i, 1);
                            permute(curr.slice(), m.concat(next))
                        }
                    }
                }

                permute(inputArr);
                return result.map(x => x.join(' '));
            },



            async createNewOwnersFromInferredOnesAndLinkingThemToAccounts(indexOfThePersonalOwner) {


                for (let i = 0; i < this.inferredOwners.length; i++) {

                    const account = this.accountsToDisplay[this.inferredOwners[i].accountIndex];
                    const userId = this.$store.getters['auth/getUserId'];

                    if (typeof indexOfThePersonalOwner !== 'undefined' &&
                        indexOfThePersonalOwner === i) {

                        // link personal owner to current account

                        if (!account.owners.includes(this.personalOwnerId)) {
                            this.$store.dispatch('connections/addOwnerToConnection', {
                                connectionId: account.id,
                                ownerId: this.personalOwnerId
                            });
                        }


                    } else {


                        const ownerFullName = this.inferredOwners[i].name;
                        const ownerFullNameTokens = ownerFullName.split(' ');

                        let name = '';
                        let surname = '';
                        for (let i = 0; i < ownerFullNameTokens.length; i++) {
                            if (i < Math.floor(ownerFullNameTokens.length / 2)) {
                                name += ownerFullNameTokens[i] + ' ';
                            } else {
                                surname += ownerFullNameTokens[i] + ' ';
                            }
                        }

                        name = name.slice(0, -1);
                        surname = surname.slice(0, -1);

                        // all permutations of the fullNameTokens
                        const allPermutationsFullName = this._getStringArrayPermutations(ownerFullNameTokens);

                        let inferredOwnerPresentInSystem = false;
                        let inferredOwnerIds = [];
                        // check if the owner is present in the system
                        this.owners.forEach(owner => {
                            for (const permutationFullName of allPermutationsFullName) {

                                if (owner.legalNames && owner.legalNames.length > 0) {
                                    if (owner.legalNames
                                        .map(x => x.toLowerCase())
                                        .includes(permutationFullName.toLowerCase())) {

                                        inferredOwnerPresentInSystem = true;
                                        inferredOwnerIds.push(owner._id);
                                    }

                                } else {
                                    const ownerLegalName = [owner.name || '', owner.surname || ''].join(' ');
                                    if (ownerLegalName.toLowerCase() === permutationFullName.toLowerCase()) {
                                        inferredOwnerPresentInSystem = true;
                                        inferredOwnerIds.push(owner._id);
                                    }

                                }

                                if (inferredOwnerPresentInSystem) {
                                    break;
                                }
                            }

                        });


                        if (inferredOwnerPresentInSystem) {
                            // if it is present in the system

                            // link owner to current connection
                            inferredOwnerIds.forEach(inferredOwnerId => {

                                // do not add the owner if it is already in the account
                                if (account.owners.includes(inferredOwnerId)) {
                                    return;
                                }

                                this.$store.dispatch('connections/addOwnerToConnection', {
                                    connectionId: account.id,
                                    ownerId: inferredOwnerId
                                });
                            })


                            // add the legal name (ownerFullName) to the owner
                            inferredOwnerIds.forEach(ownerId => {

                                const owner = this.owners.find(x => x._id === ownerId);

                                const legalNames = JSON.parse(JSON.stringify(owner.legalNames));
                                if (!legalNames.includes(ownerFullName)) {
                                    legalNames.push(ownerFullName);
                                }
                                PutUsersProfileOwner.put(userId, {
                                    ownerId: owner._id,
                                    legalNames: legalNames
                                }).then(
                                    () => {
                                        // pass
                                    },
                                    (error) => {
                                        if (error.response && error.response.status === 403) {
                                            EventBus.dispatch("logout");
                                        }
                                    }
                                );
                            });


                        } else {

                            // create the owner with already the legal name (ownerFullName)
                            await PostUserProfileOwner.post(userId, {
                                nickname: ownerFullName,
                                name: name,
                                surname: surname,
                                legal_names: [ownerFullName]
                            }).then(
                                response => {

                                    // get the id of the owner
                                    const newlyCreatedOwner = response.data;
                                    const newlyCreatedOwnerId = newlyCreatedOwner._id;

                                    // add the owner to the current ones in the store
                                    this.$store.dispatch('views/addOwner', newlyCreatedOwner);

                                    // link owner to current account in the store
                                    this.$store.dispatch('connections/addOwnerToConnection', {
                                        connectionId: account.id,
                                        ownerId: newlyCreatedOwnerId
                                    });
                                },
                                (error) => {
                                    if (error.response && error.response.status === 403) {
                                        EventBus.dispatch("logout");
                                    }
                                }
                            );

                        }

                        // in any case

                        // save the owners of the connection to the database from the store
                        await PutUserOpenbankingAccountOwner.put(userId, {
                            accountId: account.id,
                            owners: this.accountsToDisplay[this.inferredOwners[i].accountIndex].owners,
                        }).then(
                            () => {
                                // pass
                            },
                            (error) => {
                                if (error.response && error.response.status === 403) {
                                    EventBus.dispatch("logout");
                                }
                            }
                        );


                    }
                }
            },

        },
        computed: {

            personalOwnerId() {
                return this.$store.getters['views/getUserPersonalOwnerId'];
            },
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            personalOwner() {
                return this.owners.find(x => x._id === this.personalOwnerId)
            },
            personalOwnerCredentialsUnknown() {
                return this.personalOwner == null
                    ||
                    (
                        (
                            this.personalOwner.name == null ||
                            this.personalOwner.name.trim().length === 0 ||
                            this.personalOwner.surname == null ||
                            this.personalOwner.surname.trim().length === 0
                        )
                        &&
                        (
                            this.personalOwner.legalNames == null ||
                            this.personalOwner.legalNames.length === 0
                        )
                    );
            },
            userNickname() {
                return this.$store.getters['auth/getUsername'];
            },

            pageQuery() {
                return this.$route.query.p;
            },


            accountsToDisplay() {
                const accounts = [];
                const financialInstitutions = this.financialInstitutions;

                this.connections.forEach(connection => {

                    if (connection.requisitionId == null ||
                        connection.requisitionId !== this.requisitionId) {
                        return; // break
                    }

                    const institution = financialInstitutions.find(x => x.id === connection.institutionId);

                    if (institution == null) {
                        return; // break;
                    }

                    let amountString = '';

                    connection.balance.forEach(balance => {
                        amountString += balance.amount.toLocaleString(this.userLanguage) + ' ' + balance.ticker + ', ';
                    });
                    amountString = amountString.slice(0, -2);

                    if (institution) {
                        accounts.push({
                            iban: (connection.iban) ? (connection.iban) : connection.bban,
                            name: institution.name,
                            logoUrl: institution.logo,
                            amountString: amountString,
                            owners: connection.owners,
                            ownerName: connection.ownerName,
                            id: connection.source,
                        });
                    }

                });

                return accounts;
            },
            areMarketsLoaded() {
                return this.$store.getters['markets/isLoaded'];
            },
            userLanguage() {
                return this.$store.getters['auth/getUserLanguage'];
            },
            connections() {
                return this.$store.getters['connections/getAllConnections'];
            },
            financialInstitutions() {
                return this.$store.getters['platform/getFinancialInstitutions'];
            },
            requisitionId() {
                return this.$route.query.ref;
            }
        },
        watch: {
            areMarketsLoaded(newValue, oldValue) {
                if (newValue === true && (oldValue == null || oldValue === false)) {
                    if (!this.personalOwnerCredentialsUnknown) {

                        let indexOfTheOwner = -1;

                        const legalNames = this.personalOwner.legalNames;

                        if (legalNames &&
                            legalNames.length > 0) {

                            legalNames.forEach(legalName => {

                                if (indexOfTheOwner > -1) {
                                    return;
                                }

                                const allPermutationsLegalName = this._getStringArrayPermutations(legalName.split(' '));

                                for (let i = 0; i < this.inferredOwners.length; i++) {
                                    if (allPermutationsLegalName.map(x => x.toLowerCase())
                                        .includes(this.inferredOwners[i].name.toLowerCase())) {

                                        indexOfTheOwner = i;
                                        break;
                                    }
                                }
                            });

                        } else {

                            // if legalnames are not present - check name and surname
                            const name = this.personalOwner.name.toLowerCase();
                            const surname = this.personalOwner.surname.toLowerCase();

                            const fullNames = [];
                            fullNames.push(name + ' ' + surname);
                            fullNames.push(surname + ' ' + name);

                            for (let i = 0; i < this.inferredOwners.length; i++) {
                                if (fullNames.includes(this.inferredOwners[i].name.toLowerCase())) {
                                    indexOfTheOwner = i;
                                    break;
                                }
                            }
                        }


                        if (indexOfTheOwner === -1) {
                            // the current owner is not present
                            this.createNewOwnersFromInferredOnesAndLinkingThemToAccounts();
                        } else {
                            // the current owner is present
                            this.createNewOwnersFromInferredOnesAndLinkingThemToAccounts(indexOfTheOwner);
                        }
                    }

                }
            },

            accountsToDisplay: function (newValue) {


                const inferredOwners = [];

                for (let i = 0; i < newValue.length; i++) {

                    // infer the owner names from co-owned accounts
                    const completeOwnerName = newValue[i].ownerName;


                    PlatformExternalEntityTokenizationSpacyPost.post({
                        name: completeOwnerName
                    }).then(response => {
                        const data = response.data;
                        let inferredOwnersFromAccount = [];

                        if (data == null || data.people == null || data.people.length === 0) {
                            inferredOwnersFromAccount = [completeOwnerName];
                        } else {
                            inferredOwnersFromAccount = [data.people];
                        }


                        inferredOwnersFromAccount.forEach(inferredOwner => {
                            inferredOwners.push({
                                name: inferredOwner,
                                accountIndex: i
                            });
                        });
                    })


                }

                this.inferredOwners = inferredOwners;
            }
        }
    }
</script>

<style scoped>

    div.container {
        display: flex;
        justify-content: center;
        padding-top: 5%;
        width: 100%;
    }

    @media(max-width: 1039px) {
        div.container {
            padding-left: 16px;
            padding-right: 16px;
            flex-direction: column;
            width: calc(100%-32px);
        }
    }

    div.content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 520px;
    }

        div.content.align-center {
            justify-content: center;
        }

    div.cards-frame {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    div.buttons-frame {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    div.buttons-frame-accounts {
        display: flex;
        justify-content: center;
        gap: 24px;
    }

    div.multiple-accounts-selector {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

        div.multiple-accounts-selector > button {
            background-color: var(--primary);
            color: var(--white);
            border: 2px solid transparent;
            cursor: pointer;
            width: 100%;
            padding: 8px 16px;
            border-radius: var(--full-rounded);
        }

            div.multiple-accounts-selector > button.special-case {
                border: 2px solid var(--secondary);
                background-color: transparent;
                color: var(--black);
            }

            div.multiple-accounts-selector > button:hover {
                background-color: var(--secondary);
                color: var(--white);
            }

    @media(min-width: 905px) {
        div .support-image-width {
            max-width: 35vw;
        }
    }

    img.support-image-width {
        max-width: 96vw;
    }
</style>
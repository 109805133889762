<template>


    <div class="wrapper">
        <div class="view-card">
            <div class="view-title">
                <p class="title-medium white">
                    {{ view.name }}
                </p>
                <div v-if="isLocked">
                    <span class="material-icons title-medium white">
                        lock
                    </span>
                </div>
                <div v-else>
                    <span class="material-icons title-medium white clickable"
                          @click.prevent="showUpdateViewModal = true">
                        edit
                    </span>
                    <span class="material-icons title-medium error clickable"
                          @click.prevent="showDeleteViewModal = true">
                        delete
                    </span>
                </div>
            </div>
            <div class="owner-names">
                <p class="body-medium white">
                    Gli asset di:
                </p>
                <div v-for="(name, index) in viewOwnerNames"
                     v-bind:key="index"
                     class="item-owner"
                     :style="`background-color: ${colors[index%3]}`">
                    <p class="label-large black">
                        {{name}}
                    </p>
                </div>
            </div>
        </div>
    </div>


    <Teleport v-if="showUpdateViewModal" to="body">
        <update-view-modal-component :show="showUpdateViewModal"
                                     :view="view"
                                     @close="showUpdateViewModal = false" />
    </Teleport>


    <Teleport v-if="showDeleteViewModal" to="body">
        <delete-view-modal-component :show="showDeleteViewModal"
                                      :view="view"
                                      @close="showDeleteViewModal = false" />
    </Teleport>

</template>

<script>

    import DeleteViewModalComponent from '@/components/fields/view/DeleteViewModal';
    import UpdateViewModalComponent from '@/components/fields/view/UpdateViewModal';

    import { ownerColors } from '@/assets/js/fields/ownerColors';

    export default {
        name: 'Connection-Card-Component',
        props: ['view'],
        components: {
            UpdateViewModalComponent,
            DeleteViewModalComponent,
        },
        data() {
            return {
                showUpdateViewModal: false,
                showDeleteViewModal: false,
            };
        },
        computed: {

            owners() {
                return this.$store.getters['views/getOwners'];
            },
            colors() {
                return ownerColors();
            },
            userPersonalViewIds() {
                return this.$store.getters['views/getUserPersonalViewIds'];
            },
            isLocked() {
                return this.userPersonalViewIds.includes(this.view._id);
            },

            viewOwnerNames() {

                if (this.view._id === '64a6c39748a115cc1611781e') {
                    return this.owners.map(x => x.nickname);
                }

                const ownerNames = [];

                this.view.owners.forEach(owner => {
                    const thisOwner = this.owners.find(x => x._id === owner);

                    if (thisOwner) {
                        ownerNames.push(thisOwner.nickname);
                    }
                });

                return ownerNames;

            }
        }
    }
</script>

<style scoped>

    div.wrapper {
        display: flex;
        width: 100%;
        height: fit-content;
    }

    @media(min-width: 599px) {
        div.wrapper {
            width: 400px;
        }
    }

    div.view-card {
        display: flex;
        flex-direction: column;
        background-color: var(--primary);
        border-radius: var(--high-rounded);
        width: 100%;
        padding: 16px 32px;
        gap: 8px;
    }

        div.view-card > div.view-title {
            display: flex;
            justify-content: space-between;
        }

            div.view-card > div.view-title > div {
                display: flex;
                gap: 8px;
            }

                div.view-card > div.view-title > div > span.material-icons.clickable {
                    cursor: pointer;
                }

        div.view-card > div.owner-names {
            display: flex;
            max-width: 336px;
            white-space: nowrap;
            overflow-x: hidden;
            gap: 8px;
        }

        @media(max-width: 599px) {
            div.view-card > div.owner-names {
                max-width: 280px;
            }
        }

            div.view-card > div.owner-names > div.item-owner {
                padding: 1px 8px;
                height: fit-content;
                border-radius: var(--middle-rounded);
            }

</style>


export default {

    it: {
        dashboard: 'Dashboard',
        vote: 'Vota',
        connect: 'Connetti',
        support: 'Supporto',
        t_e_c: 'T&C',
        terms_and_conditions: 'T&C',
        download: 'Scarica l\'app',
        profile: 'Profilo',
        logout: 'Logout',
        loading_data: 'Caricando i dati',
        saving: 'Sto salvando',
        saved: 'Salvato',
    },

    es: {
        dashboard: 'Dashboard',
        vote: 'Vota',
        connect: 'Conectar',
        support: 'Soporte',
        t_e_c: 'T&C',
        terms_and_conditions: 'T&C',
        download: 'Instalar App',
        profile: 'Perfil',
        logout: 'Logout',
        loading_data: 'cargando datos',
        saving: 'Guardando',
        saved: 'Guardó',
    },

    en: {
        dashboard: 'Dashboard',
        vote: 'Vote',
        connect: 'Connect',
        support: 'Support',
        t_e_c: 'T&C',
        terms_and_conditions: 'T&C',
        download: 'Install App',
        profile: 'Profile',
        logout: 'Logout',
        loading_data: 'Loading data',
        saving: 'Saving',
        saved: 'Saved',
    },

    fr: {
        dashboard: 'Dashboard',
        vote: 'Votez',
        connect: 'Connectez',
        support: 'Aide',
        t_e_c: 'CGV',
        terms_and_conditions: 'T&C',
        download: 'Installer App',
        profile: 'Profil',
        logout: 'Logout',
        loading_data: 'Chargement des données',
        saving: 'Sauvegarde',
        saved: 'Enregistré',
    },

};
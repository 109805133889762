<template>
    <div class="service-label"
         :style="labelColor">
        {{ connectionService }}
    </div>
</template>

<script>
    export default {
        name: 'Asset-Type-Component',
        props: ['service'],
        computed: {
            connectionService() {

                switch (this.service) {
                    case 'wallet': return 'Crypto Wallet';
                    case 'exchange': return 'Crypto Exchange';
                    case 'manual': return 'Manual';
                    case 'openbanking': return 'Bank';
                    default: return '-';
                }
            },
            labelColor() {

                let backgroundColor = 'transparent';
                let textColor = 'black';

                switch (this.service) {
                    case 'wallet':
                        backgroundColor = '#093145';
                        textColor = '#FEFEFE';
                        break;
                    case 'exchange':
                        backgroundColor = '#3C6478';
                        textColor = '#FEFEFE';
                        break;
                    case 'manual':
                        backgroundColor = '#C2571A';
                        textColor = '#FEFEFE';
                        break;
                    case 'openbanking':
                        backgroundColor = '#9A2617';
                        textColor = '#FEFEFE';
                        break;
                }

                return {
                    '--background-color': backgroundColor,
                    '--text-color': textColor
                };
            }
        }
    }
</script>

<style scoped>
    div.service-label {
        padding: 2px 8px;
        border-radius: var(--middle-rounded);
        background-color: var(--background-color);
        color: var(--text-color);
        width: fit-content;
    }
</style>
<template>
    <button @click.prevent="deleteProfile"
            class="error full-rounded outlined">
        {{ $t('profile.delete_account') }}
    </button>
</template>

<script>



    export default {

        name: 'Delete-Profile-Component',
        methods: {
            deleteProfile() {
                this.$router.push('/goodbye');
            }
        }
    }

</script>
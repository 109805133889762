<template>

    <div class="filtered-list-frame">

        <h3 class="headline-large black">
            {{ title }}
        </h3>

        <div class="fields-frame">
            <input-field-component type="text"
                                   :label="placeholder"
                                   v-model="user_input"
                                   :isValid="true" />
        </div>

        <div class="filtered-list-list-frame">
            <div v-for="item in filteredListItems" :key="item.id" class="filtered-list-list-item" @click="cta_action(item.id, item.name)">
                <div class="filtered-list-list-item-information">
                    <img v-if="item.logo" :src="item.logo" />
                    <p class="body-large white truncate-overflow-two-lines-ellipsis">
                        {{ item.name }}
                    </p>
                </div>
                <span class="material-icons tertiary">
                    arrow_forward_ios
                </span>
            </div>
        </div>
    </div>

</template>

<script>

    import InputFieldComponent from '@/components/fields/InputField';

    export default {
        name: "Filtered-List",
        props: ['list', 'placeholder', 'title'],
        /*
         * list: Array              mandatory - the list to show, which can be filtered and the user can click. See below for the structure
         * placeholder: String      mandatory - the placeholder to show in the input field
         * title: String            mandatory - the title of the filtered list
         *
         *
         *
         * "list" is an array of json having this structure
         * {
         *      id: String              mandatory - the id of the element (not displayed)
         *      name: String            mandatory - the name of the element (displayed)
         *      logo: png               optional - the logo of the element (displayed if provided)
         *      tokens: Array String    optional - an array of custom token strings, used for filtering (not displayed)
         * }
         *
         * returns the id (1st param) and the name (2nd param)
         *
         * */
        components: {
            InputFieldComponent
        },
        data() {
            return {
                user_input: ''
            };
        },
        computed: {
            /*
             * how tokenization of the list works with examples
             *
             * eg. 1:
             * name: "Tutti i mercati"
             * tokens: ["Tutti", "i", "mercati"]
             *
             * eg. 2:
             * name: "AB NASDAQ Vilinus (XLIT)"
             * tokens: ["AB", "NASDAQ", "Vilinus", "XLIT"]
             *
             * eg. 3:
             * name: "Alto Adige Banca - Sudtirol Bank"
             * tokens: ["Alto", "Adige", "Banca", "Sudtirol", "Bank"]
             *
             */
            filteredListItems() {
                if (typeof this.list === 'undefined') {
                    return [];
                }

                const filteredList = [];

                /* ES6 sintax -> forEach
                 *
                 * param item   the current item the foreach callback function is called on,
                 * param index  the current index of the item the foreach callback function is called on
                 * param list   the list on which the foreach method is called on
                 *
                 *
                 * we do not provide "index" and "list" in this specific case, but it is
                 * nice to set it as a reminder for myself because I'll forget about it
                 * */
                this.list.forEach(item => {

                    // compute the tokens to search from the split of the name and from the ones provided programmatically
                    const tokens = item.name ? item.name.split(/[^A-Za-z]/).filter(x => x.length > 0) : [];
                    tokens.push(...((item.tokens) ? item.tokens : [])); // add the ones passed programmatically

                    let score = 0;

                    // calculate the score with the tokens
                    tokens.forEach(token => {
                        if (token && token.toLowerCase().startsWith(this.user_input.toLowerCase())) {
                            score += 1;
                        }
                    });

                    // calculate the score on the name with a better priority than the one on tokens
                    if (item.name && item.name.toLowerCase().startsWith(this.user_input.toLowerCase())) {
                        score += 10;
                    }

                    // if there is at least a match, add it to the filtered list
                    if (score > 0) {
                        filteredList.push({
                            id: item.id,
                            name: item.name,
                            logo: item.logo,
                            score: score
                        });
                    }
                });

                // sort the filtered list by score
                //filteredList.sort((a, b) => {
                //    return b.score - a.score;
                //});

                return filteredList;
            }
        },
        methods: {
            /* The call to action function is "passed" from the parent */
            cta_action(clicked_item_id, clicked_item_name) {
                if (this.$parent.cta_action) {
                    this.$parent.cta_action(clicked_item_id, clicked_item_name);
                    return;
                } else if (this.$parent.$parent.cta_action) {
                    this.$parent.$parent.cta_action(clicked_item_id, clicked_item_name);
                    return;
                }
            }
        }
    }

</script>

<style scoped>

    @media (max-width:599px) {
        .filtered-list-frame {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 100%;
            gap: 24px;
        }
    }

    @media (min-width:600px) and (max-width:904px) {
        .filtered-list-frame {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 100%;
            gap: 24px;
        }
    }

    @media (min-width:905px) and (max-width:1239px) {
        .filtered-list-frame {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 60%;
            gap: 24px;
        }
    }

    @media (min-width:1240px) and (max-width:1439px) {
        .filtered-list-frame {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 60%;
            gap: 24px;
        }
    }

    @media (min-width:1440px) {
        .filtered-list-frame {
            display: flex;
            flex-direction: column;
            height: fit-content;
            width: 60%;
            gap: 24px;
        }
    }

    div.fields-frame {
        display: flex;
        width: 100%;
    }

    .filtered-list-input-frame {
        align-items: center;
        border: 2px solid;
        border-color: var(--black);
        border-radius: 16px;
        display: flex;
        gap: 2vh;
        justify-content: center;
        padding: 2vh;
        width: 100%;
    }

    .filtered-list-input-box {
        background-color: transparent;
        border: 0;
        flex: 1;
        height: fit-content;
        letter-spacing: 0;
        margin-top: -2px;
        width: 100%;
        padding: 0;
    }

        .filtered-list-input-box::placeholder {
            color: var(--black);
        }

    .filtered-list-input-boxfiltered-list-input-box:focus {
        outline: none;
    }

    /* new css */

    .filtered-list-list-frame {
        border-radius: 0px 0px 8px 8px;
        background-color: var(--white);
        width: 100%;
        height: fit-content;
    }

        .filtered-list-list-frame > div:last-child {
            border-radius: 0px 0px 8px 8px;
        }

    .filtered-list-list-item {
        background-color: var(--primary);
        color: var(--white);
        width: calc(100%-48px);
        height: 56px;
        margin-top: 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 24px;
        cursor: pointer;
    }

    .filtered-list-list-item-information {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
    }

        .filtered-list-list-item-information img {
            border-radius: 2px;
            height: 24px;
            width: 24px;
        }

    .truncate-overflow-two-lines-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>
import api from '@/services/api';

class PatchUsersOpenbankingAccountDetails {

  patch(userId, body) {
    return api.patch('/v1/users/'+ userId +'/openbanking/account/details', body);
  }
}

export default new PatchUsersOpenbankingAccountDetails();

import api from '@/services/api';

class GetUsersProfileRewardSteps {

    get(id) {
        return api.get('/v1/users/' + id + '/profile/rewardSteps');
    }
}

export default new GetUsersProfileRewardSteps();

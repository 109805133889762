import api from '@/services/api';

class MarketsStocksFundamentals {

    patch(tickers) {
        return api.patch('/v1/markets/stocks/fundamentals/' + tickers.join(','));
    }
}

export default new MarketsStocksFundamentals();

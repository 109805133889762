<template>
    <div class="company-dividends-card">

        <div class="title-card">
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.company_dividends_title') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>

        <div v-if="companyDividends && companyDividends.dividend_date"
             class="company-dividends-information">
            <p class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.dividend_rate') }}
                </span>
                {{ companyDividends.forward_annual_dividend_rate }}
            </p>
            <p class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.dividend_yield') }}
                </span>
                {{ Math.round(companyDividends.forward_annual_dividend_yield * 10000) / 100 }} %
            </p>
            <p class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.payout_ratio') }}
                </span>
                {{ Math.round(companyDividends.payout_ratio * 10000) / 100 }} %
            </p>
            <p class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.dividend_date') }}
                </span>
                <locale-date-component :isoDate="companyDividends.dividend_date" />
            </p>
        </div>
        <div v-else>
            <p class="body-large black">
                {{ $t('dashboard.asset_details.could_not_fetch_dividends') }}
            </p>
        </div>
    </div>
</template>

<script>

    import LocaleDateComponent from '@/components/dashboard/table/row/LocaleDate';

    export default {
        name: "Company-Dividends",
        props: ['asset', 'page', 'path'],
        components: {
            LocaleDateComponent
        },
        methods: {

            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },
        },
        computed: {

            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            company() {
                return (this.markets) ? this.markets[this.asset.ticker] : undefined;
            },
            companyFundamentals() {
                return (this.company) ? this.company.fundamentals : undefined;
            },

            companyDividends() {
                return (this.companyFundamentals) ? this.companyFundamentals.dividends : undefined;
            }
        }
    }
</script>

<style scoped>
    div.company-dividends-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 64px);
        border-radius: var(--high-rounded);
        background-color: var(--white);
        padding: 16px 32px;
    }

        div.company-dividends-card > div.title-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 8px;
        }

            div.company-dividends-card > div.title-card > span {
                cursor: pointer;
            }
</style>
<template>

    <div class="owner-connections">
        <div v-if="thisOwnerNickname">
            <h2 class="title-medium black bold">
                {{ $t('dashboard.owner_details.the_connections_of') }}
                {{ thisOwnerNickname }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>

        <table-component :table="{
                            properties: {
                                menuPreference: 'connection',
                                allConnections: false,
                                showNavigation: false
                            },
                            connections: ownerConnectionIds
                        }"
                         :page="page"
                         :path="path" />


    </div>
</template>

<script>

    import TableComponent from '@/components/dashboard/section/elements/Table';

    export default {
        name: 'Owner-Connections-Component',
        props: ['owner', 'page', 'path'],
        components: {
            TableComponent
        },
        computed: {
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            ownerConnectionIds() {

                if (this.connections == null) {
                    return [];
                }

                const ownerConnectionIds = [];

                this.connections.forEach(connection => {
                    if (connection.owners &&
                        connection.owners.includes(this.owner.id)) {
                        ownerConnectionIds.push(connection.id);
                    }
                });

                return ownerConnectionIds;
            },
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            thisOwnerNickname() {
                if (this.owners == null) {
                    return;
                }

                const index = this.owners.map(x => x._id).indexOf(this.owner.id);
                if (index < 0) {
                    return;
                }

                return this.owners[index].nickname;
            },
        },
        methods: {
            
            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },
        }
    }
</script>

<style scoped>

    div.owner-connections {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

        div.owner-connections > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            div.owner-connections > div > span {
                cursor: pointer;
            }

</style>
<template>
    <div class="stylish-element"
         :style="cssProps" />
</template>

<script>
    export default {
        name: 'Stylish-Element-Component',
        props: ['height', 'width', 'borderRadius', 'variant'],
        computed: {
            cssProps() {

                const props = {
                    '--height': this.height || '100%',
                    '--width': this.width || '100%',
                    '--border-radius': this.borderRadius || 'var(--high-rounded)',
                }

                switch (this.variant) {
                    case 'primary':
                        props['--background-color'] = 'var(--primary)';
                        break;
                    case 'secondary':
                        props['--background-color'] = 'var(--secondary)';
                        break;
                    case 'tertiary':
                        props['--background-color'] = 'var(--tertiary)';
                        break;
                    case 'error':
                        props['--background-color'] = 'var(--error)';
                        break;
                    case 'placeholder':
                        props['--background-color'] = 'var(--placeholder)';
                        break;
                    case 'neutral':
                        props['--background-color'] = 'var(--neutral)';
                        break;
                    default:
                        props['--background-color'] = 'var(--secondary)';
                }

                return props;
            }
        },
        methods: {
            randomInteger() {
                // between 0 and 10
                return Math.floor(Math.random() * 11);
            }
        }
    }
</script>

<style scoped>
    div.stylish-element {
        height: var(--height);
        width: var(--width);
        border-radius: var(--border-radius);
        background-color: var(--background-color);
    }
</style>
export default {

    it: {
        create_new_owner: "Crea nuovo intestario",
        nickname: "Nickname (es. papà)*",
        name: "Nome*",
        surname: "Cognome*",
        email: "E-mail",
        mobile: "Numero di telefono"

    },

    es: {
        create_new_owner: "Crear nuevo titular",
        nickname: "Nickname (por ejemplo, papá)*",
        name: "Nombre*",
        surname: "Apellido*",
        email: "E-mail",
        mobile: "Número de teléfono"

    },
    en:{
        create_new_owner: "Create new holder",
        nickname: "Nickname (e.g. dad)*",
        name: "Name*",
        surname: "Surname*",
        email: "E-mail",
        mobile: "Telephone number"
    },

    fr:{
        create_new_owner: "Créer un nouveau titulaire",
        nickname: "Surnom (par ex. Papa)*",
        name: "Prenom*",
        surname: "Nom*",
        email: "E-mail",
        mobile: "Numéro de téléphone"

    },

};
<template>
    <template v-if="!isLoaded">

        <animated-placeholder-component height="176px"
                                        width="100%" />

    </template>
    <template v-else-if="!thereIsAtLeastOneConnection">

        <p class="body-large black">
            {{ $t('profile.no_manual_portfolios') }}
        </p>

    </template>
    <template v-else>

        <div>
            <div v-if="manualConnections && manualConnections.length > 0">
                <h3 class="title-small black bold">
                    {{ $t('profile.manual_portfolios') }}
                </h3>
                <div class="connections-frame">
                    <connection-card-component v-for="(connection, index) in manualConnections"
                                               v-bind:key="index"
                                               :connection="connection" />
                </div>
            </div>
        </div>


    </template>
</template>

<script>

    import AnimatedPlaceholderComponent from '@/components/AnimatedPlaceholder';

    import ConnectionCardComponent from '@/components/profile/connections/ConnectionCard';


    export default {
        name: 'Connections-Table-Component',
        components: {
            AnimatedPlaceholderComponent,
            ConnectionCardComponent
        },
        computed: {
            isLoaded() {
                return this.$store.getters['markets/isLoaded'];
            },
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            manualConnections() {
                return this.connections.filter(connection => connection.service === 'manual');
            },
            thereIsAtLeastOneConnection() {
                return this.manualConnections && this.manualConnections.length > 0;
            }
        },
    }
</script>

<style scoped>
    h3 {
        margin: 8px auto 8px auto;
    }

    div.connections-frame {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
</style>
import api from '../../../api';

class UsersProfileOwner {

  get(id) {
    return api.get('/v1/users/'+id+'/profile/owner');
  }
}

export default new UsersProfileOwner();

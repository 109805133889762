<template>
    <div class="center-screen">
        <div class="help-frame custom-device-width">
            <h5 class="headline-large tertiary">
                {{ $t('connect.wallet_form.connect_your_wallet') }}
            </h5>

            <!-- DISPLAY WHEN THE EXCHANGE IS NOT PROVIDED  -->

            <div class="tutorial-frame">

                <!-- DISPLAY WHEN THE EXCHANGE IS PROVIDED  -->

                <div class="tutorial-step">
                    <p class="body-large black">
                        <span class="bold"> 1. </span>
                        {{ $t('connect.wallet_form.open_your_wallet') }}
                    </p>
                </div>
                <div class="tutorial-step">
                    <p class="body-large black">
                        <span class="bold"> 2. </span>
                        {{ $t('connect.wallet_form.copy_the_address') }}
                    </p>
                </div>
                <div class="tutorial-step">
                    <p class="body-large black">
                        <span class="bold"> 3. </span>
                        {{ $t('connect.wallet_form.type_the_blockchain') }}
                    </p>
                </div>
                <div class="tutorial-step d-none d-md-block">
                    <p class="body-large black">
                        <span class="bold"> 5. </span>
                        {{ $t('connect.wallet_form.missing_required_field') }}
                        <router-link to="/support">
                            <span class="tertiary bold">
                                {{ $t('connect.info_card.missing_contact_us') }}
                            </span>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'Wallet-Info-Card'
    }
</script>

<style scoped>

    .center-screen {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .help-frame {
        background-color: var(--white);
        border-radius: var(--high-rounded);
        padding: 32px 64px;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    /* the info card should display on 100% width if it is displayed vertically with the input form*/
    @media (max-width: 904px) {
        .help-frame.custom-device-width {
            width: calc(100%-64px);
            padding: 16px 32px;
        }
    }

    @media (min-width: 905px) {
        .help-frame.custom-device-width {
            margin: 64px auto auto auto;
            width: calc(90%-128px);
        }
    }

    .tutorial-frame {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        gap: 2px;
    }

    .tutorial-step {
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: left;
        align-items: center;
        gap: 1rem;
    }

    .connection-step {
        color: var(--white);
        background-color: var(--black);
        border-radius: 4px;
    }

    a.tertiary:hover {
        color: var(--secondary);
    }
</style>

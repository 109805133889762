<template>
    <div class="card-list">

        <connection-card-component :title="$t('connect.crypto_exchange_title')"
                                   :description="$t('connect.crypto_exchange_text')"
                                   :to="'/connect/crypto/exchange'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/egypt_day.svg')"
                                   :logos="[require('@/assets/img/logos/crypto/exchanges/binance.png'),
                                      require('@/assets/img/logos/crypto/exchanges/coinbase.jpg'),
                                      require('@/assets/img/logos/crypto/exchanges/kraken.png'),
                                      require('@/assets/img/logos/crypto/exchanges/kucoin.png'),
                                      require('@/assets/img/logos/crypto/exchanges/bitstamp.png')]"
                                   cta="connect" />


        <connection-card-component :title="$t('connect.crypto_wallet_address_title')"
                                   :description="$t('connect.crypto_wallet_address_text')"
                                   :to="'/connect/crypto/wallet'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/desert_day.svg')"
                                   :logos="[require('@/assets/img/logos/crypto/btc.png'),
                                      require('@/assets/img/logos/crypto/eth.png'),
                                      require('@/assets/img/logos/crypto/ltc.png'),
                                      require('@/assets/img/logos/crypto/xrp.png'),
                                      require('@/assets/img/logos/crypto/doge.png')]"
                                   cta="add" />


        <connection-card-component :title="$t('connect.crypto_manual_title')"
                                   :description="$t('connect.crypto_manual_text')"
                                   :to="'/connect/crypto/manual'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/indonesia_day.svg')"
                                   :logos="[require('@/assets/img/logos/crypto/algo.png'),
                                          require('@/assets/img/logos/crypto/sol.png'),
                                          require('@/assets/img/logos/crypto/ada.png'),
                                          require('@/assets/img/logos/crypto/matic.png'),
                                          require('@/assets/img/logos/crypto/shib.png')]"
                                   cta="add" />
    </div>
</template>

<script>

    import ConnectionCardComponent from '@/components/connect/navigation/Card';

    export default {
        name: 'Cryptocurrencies-List',
        props: ['page', 'tableId'],
        components: {
            ConnectionCardComponent
        }
    }
</script>

<style scoped>

    div.card-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
</style>
import api from '../../../api';

class PatchUsersCryptoExchanges {

  patch(id, exchange) {
    return api.patch('/v1/users/'+id+'/crypto/exchange', {exchange:exchange});
  }
}

export default new PatchUsersCryptoExchanges();

import api from '@/services/api';

class UsersProfileOwner {

    post(id, data) {
        return api.post('/v1/users/' + id + '/profile/owner', data);
    }
}

export default new UsersProfileOwner();

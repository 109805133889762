<template>

    <div id="app-loading-bar-wrapper"
         v-if="appLoadingProgress < 99.95">
        <div class="moving-bar"
             :style="computedStyle"/>
    </div>

</template>


<script>

    export default {
        name: 'Loading-Bar-Component',
        computed: {

            appLoadingProgress() {

                // compute the progress of the loading of the app
                const pingProgress = this.$store.getters['platform/isAlive'] ? 100 : 0;
                const authProgress = this.$store.getters['auth/isLogged'] ? 100 : 0;
                const pagesProgress = this.$store.getters['pages/isLoaded'] ? 100 : 0;
                const connectionsProgress = (pagesProgress >= 100) ? this.$store.getters['connections/getProgress'] : 0;
                const marketsProgress = (connectionsProgress >= 100) ? this.$store.getters['markets/getProgress'] : 0;

                const progress = (pingProgress + authProgress + pagesProgress + connectionsProgress + marketsProgress) / 5;

                return progress;
            },

            computedStyle() {
                return {
                    '--width': (this.appLoadingProgress) ? this.appLoadingProgress + '%' : '0%'
                };
            }
        }
    }


</script>

<style scoped>

    div#app-loading-bar-wrapper {
        width: 100%;
        height: 2px;
        overflow: hidden;
        position: fixed;
        top: 0;
        z-index: 2;
        background-color: var(--hover-item);
    }

    div.moving-bar {
        background-color: var(--secondary);
        width: var(--width);
        height: 100%;
        background-image: linear-gradient( to right, var(--secondary) 0%, var(--neutral) 10%, var(--secondary) 20%, var(--secondary) 100% );
        background-size: 200% 100%;
        animation: skeleton 1.2s linear infinite;
        border-end-end-radius: var(--full-rounded);
        border-start-end-radius: var(--full-rounded);
        transition: width 1.2s ease-in-out; /* added a transition among the different states of the loading bar */
    }

</style>
import api from '../../../../api';

class MarketsForexHistorical {

  patch(pair) {
    return api.patch('/v1/markets/forex/historical/' + pair );
  }
}

export default new MarketsForexHistorical();

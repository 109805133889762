<template>

    <div class="title-row">
        <div class="title-component">
            <img v-if="pageImage"
                 :src="pageImage" />
            <h1 :class="{
                'notranslate': true,
                'headline-small black bold': true,
                'editable': editable
                }"
                spellcheck="false"
                :contenteditable="editable"
                ref="title"
                @blur="changeTitleNameOnDB"
                @keydown="preventSpecialCharacters"
                @keyup="onType">
                {{ title }}
            </h1>
            <!-- to change the title -->
            <span v-if="editable"
                  class="material-icons title-medium black"
                  @click="focusTitleEditable">
                edit
            </span>
        </div>
        <!-- to delete the connection -->
        <div v-if="pageObject.properties.purpose === 'connection'"
             class="context-menu-connection">
            <span class="material-icons headline-small black"
                  @click="showDropdown = !showDropdown">
                more_horiz
            </span>
            <dropdown-menu-component :show="showDropdown"
                                     :list="[{
                                                name: $t('forms.delete'),
                                                value: 'delete'
                                            }]"
                                     @onItemClick="deleteConnection"
                                     class="dropdown" />
        </div>
        <!-- to delete the owner -->
        <div v-if="pageObject.properties.purpose === 'owner' &&
             personalOwnerId !== pageObject.properties.owner.id"
             class="context-menu-connection">
            <span class="material-icons headline-small black"
                  @click="showDropdown = !showDropdown">
                more_horiz
            </span>
            <dropdown-menu-component :show="showDropdown"
                                     :list="[{
                                                name: $t('forms.delete'),
                                                value: 'delete'
                                            }]"
                                     @onItemClick="deleteOwner"
                                     class="dropdown" />
        </div>
        <!-- to interact with the asset -->
        <div v-else-if="pageObject.properties.purpose === 'asset'">
            <amend-manual-asset-dropdown-component :asset="pageObject.properties.asset" />
        </div>
    </div>


    <Teleport v-if="showModalDeleteConnection" to="body">
        <delete-connection-modal-component @close="showModalDeleteConnection = false"
                                           :show="showModalDeleteConnection"
                                           :pageObject="pageObject"
                                           :connectionName="pageTitle" />
    </Teleport>
    <Teleport v-if="showModalDeleteOwner" to="body">
        <delete-owner-modal-component @close="showModalDeleteOwner = false"
                                           :show="showModalDeleteOwner"
                                           :ownerId="pageObject.properties.owner.id" />
    </Teleport>

</template>

<script>

    import DropdownMenuComponent from '@/components/fields/DropdownMenu';
    import DeleteConnectionModalComponent from '@/components/dashboard/page/connection/DeleteConnectionModal';
    import DeleteOwnerModalComponent from '@/components/fields/owner/DeleteOwnerModal';

    import AmendManualAssetDropdownComponent from '@/components/dashboard/page/asset/AmendManualAssetDropdown';

    import PatchUsersManualConnectionName from "@/services/users/manual/asset/name/patch.service";
    import PatchUsersCryptoWalletName from '@/services/users/crypto/wallet/name/patch.service';
    import PutUsersProfileOwner from '@/services/users/profile/owner/put.service';

    import EventBus from "@/common/EventBus";

    export default {
        name: 'Page-Title-Component',
        props: ['pageObject', 'pageTitle'],
        components: {
            DropdownMenuComponent,
            DeleteConnectionModalComponent,
            DeleteOwnerModalComponent,
            AmendManualAssetDropdownComponent
        },
        data() {
            return {
                title: '',
                showModalDeleteConnection: false,
                showModalDeleteOwner: false,
                showDropdown: false
            };
        },
        methods: {
            focusTitleEditable() {
                if (this.editable) {
                    this.$refs.title.focus();
                }
            },
            
            deleteConnection(value) {


                if (value !== 'delete') {
                    return;
                }

                this.showModalDeleteConnection = true;
            },

            deleteOwner(value) {



                if (value !== 'delete') {
                    return;
                }

                this.showModalDeleteOwner = true;
            },

            // title management


            preventSpecialCharacters(keyboardEvent) {

                if (keyboardEvent.keyCode === 13)   // enter
                {
                    this.$refs.title.blur();
                    return keyboardEvent.preventDefault();
                }
            },

            onType(keyboardEvent) {

                if (!this.editable) {
                    return;
                }

                this.title = keyboardEvent.target.innerText;
                let pageId = undefined;
                const purpose = this.pageObject.properties.purpose;
                switch (purpose) {
                    case 'owner':
                        pageId = 'o' + this.pageObject.properties.owner.id;
                        break;
                    case 'connection':
                        pageId = this.pageObject.properties.connection.id;
                        break;
                }

                if (pageId) {
                    this.$store.dispatch('pages/updateTitle', {
                        page: pageId,
                        title: this.title
                    });
                }
            },

            // communication with the database

            changeConnectionName() {

                // update the connection name to the backend
                const user_id = this.$store.getters['auth/getUserId'];
                const name = this.title;

                const connectionId = this.pageObject.properties.connection.id;
                const source = this.connections.find(x => x.id === connectionId).source;


                const service = this.pageObject.properties.connection.service;
                let ServiceController = undefined;

                switch (service) {
                    case 'manual':
                        ServiceController = PatchUsersManualConnectionName;
                        break;
                    case 'wallet':
                        ServiceController = PatchUsersCryptoWalletName;
                        break;
                    default:
                        return; // break the function
                }



                ServiceController.patch(user_id, {
                    source: source,
                    name: name
                }).then(() => {
                    this.$store.dispatch('connections/refresh');
                }, (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                });
            },

            changeOnwerName() {

                const userId = this.$store.getters['auth/getUserId'];
                const nickname = this.title;
                const ownerId = this.pageObject.properties.owner.id;

                PutUsersProfileOwner.put(userId, {
                    ownerId: ownerId,
                    nickname: nickname
                }).then(() => {
                    this.$store.dispatch('views/updateOwnerFields', {
                        _id: ownerId,
                        nickname: nickname
                    });
                }, (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                });
            },

            changeTitleNameOnDB() {

                const purpose = this.pageObject.properties.purpose;

                switch (purpose) {
                    case 'connection':
                        this.changeConnectionName();
                        break;
                    case 'owner':
                        this.changeOnwerName();
                        break;
                    default:
                        return;
                }

            }
        },
        created() {
            this.title = this.pageTitle;
        },
        watch: {
            pageTitle: function (newValue) {
                this.title = newValue;
            }
        },
        computed: {

            connections() {
                return this.$store.getters['connections/getConnections']
            },

            personalOwnerId() {
                return this.$store.getters['views/getUserPersonalOwnerId'];
            },

            editable() {

                if (this.pageObject &&
                    this.pageObject.properties &&
                    this.pageObject.properties.purpose) {


                    if (this.pageObject.properties.purpose === 'connection' &&
                        this.pageObject.properties.connection &&
                        this.pageObject.properties.connection.service && (
                            this.pageObject.properties.connection.service === 'manual' ||
                            this.pageObject.properties.connection.service === 'wallet')) {

                        return true;
                    }

                    const personalOnwerId = this.$store.getters['views/getUserPersonalOwnerId'];

                    if (this.pageObject.properties.purpose === 'owner' &&
                        this.pageObject.properties.owner &&
                        this.pageObject.properties.owner.id !== personalOnwerId) {

                        return true;
                    }

                } 

                return false;
            },


            // logo management

            cryptoLogos() {
                return this.$store.getters['platform/getCryptoLogos'];
            },
            forexLogos() {
                return this.$store.getters['platform/getForexLogoUrls'];
            },
            stocksEtfsLogos() {
                return this.$store.getters['platform/getStocksETFsLogos'];
            },

            pageImage() {


                if (this.pageObject == null ||
                    this.pageObject.properties == null) {
                    return undefined;
                }

                /*********************** */
                /** check for connection */
                /*********************** */

                // check if it is wallet
                if (this.pageObject.properties.purpose === 'connection' &&
                    this.pageObject.properties.connection.service === 'wallet' &&
                    this.pageObject.properties.connection.id != null) {

                    const walletId = this.pageObject.properties.connection.id;
                    const thisWallet = this.connections.find(x => x.id === walletId);
                    if (thisWallet == null) {
                        return undefined;
                    }
                    const cryptoLogos = this.$store.getters['platform/getCryptoLogos'];
                    if (cryptoLogos == null) {
                        return undefined;
                    }
                    const walletBalanceTicker = thisWallet.balance[0].ticker;
                    return cryptoLogos[walletBalanceTicker] || require('@/assets/img/logos/crypto/unknown.png');

                }

                /****************** */
                /** check for asset */
                /****************** */

                if (this.pageObject == null ||
                    this.pageObject.properties == null ||
                    this.pageObject.properties.purpose !== 'asset') {
                    return undefined;
                }

                const type = this.pageObject.properties.asset.type;
                const ticker = this.pageObject.properties.asset.ticker;


                // switch the image based on type
                switch (type) {
                    case 'cryptocurrency':
                        if (this.cryptoLogos && this.cryptoLogos[ticker]) {
                            return this.cryptoLogos[ticker];
                        }
                        break;
                    case 'forex':
                        if (this.forexLogos && this.forexLogos[ticker]) {
                            return this.forexLogos[ticker];
                        }
                        break;
                    case 'stocks':
                    case 'etfs':
                        if (this.stocksEtfsLogos && this.stocksEtfsLogos[ticker]) {
                            return this.stocksEtfsLogos[ticker];
                        }
                        break;
                }

                return undefined;
            }
        }
    }
</script>

<style scoped>

    div.title-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 52px;
    }

    @media (max-width: 904px) {
        div.title-row {
            padding: 0px 24px;
        }
    }

    div.title-row span {
        cursor: pointer;
    }

    div.title-row > div.title-component {
        display: flex;
        align-items: center;
        gap: 16px;
    }


        div.title-row > div.title-component > h1.editable:not(:focus) {
            cursor: pointer;
        }

        div.title-row > div.title-component > img {
            height: 32px;
        }

    div.title-row > div.context-menu-connection {
        position: relative;
    }

    div.dropdown {
        margin-left: -126px;
    }
</style>
import api from '../../../api';

class UsersProfileCurrency {

  post(id, currency) {
    return api.post('/v1/users/'+id+'/profile/currency',{
      currency: currency
    });
  }
}

export default new UsersProfileCurrency();

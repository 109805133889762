import api from '../../../api';

class MarketsForexPrices {

  get(pair) {
    return api.get('/v1/markets/forex/' + pair );
  }
}

export default new MarketsForexPrices();

import api from '@/services/api';

class PatchUsersOpenbankingRequisition {

  patch(userId, requisitionId) {
    return api.patch('/v1/users/'+ userId +'/openbanking/requisition/' + requisitionId);
  }
}

export default new PatchUsersOpenbankingRequisition();

<template>
    <div id="dashboard">
        <page-component :pageId="pageId" />

        <page-overlay-component v-if="pageQuery"
                                :pageId="pageQuery" />

    </div>
</template>

<script>

    import PageComponent from '@/components/dashboard/Page';
    import PageOverlayComponent from '@/components/dashboard/PageOverlay';

    export default {
        name: 'Dashboard-Page',
        components: {
            PageComponent,
            PageOverlayComponent
        },
        methods: {
            pagePurpose(pageId) {
                if (pageId.length === 24 || pageId.length === 36) {
                    return 'connection';
                }
                if (pageId.length === 25 && pageId[0] === 'o') {
                    return 'owner';
                }
                return 'asset';
            }
        },
        computed: {
            pageQuery() {
                let pageQuery = this.$route.query.p;

                if (pageQuery == null) {
                    return undefined;
                }

                // append the view id if necessary
                const purpose = this.pagePurpose(pageQuery);
                switch (purpose) {
                    case 'connection':
                        break;
                    case 'owner':
                        break;
                    case 'asset':
                        pageQuery = pageQuery + '-' + this.currentView;
                        break;
                    default:
                        pageQuery = pageQuery + '-' + this.currentView;
                }

                return pageQuery;
            },
            currentView() {
                return this.$store.getters['views/getCurrentView'];
            },
            pageId() {

                let pageId = undefined;

                // get the correct page id
                if (this.$route.params.pageId) {

                    pageId = this.$route.params.pageId;

                } else {

                    pageId = 'dashboard';
                }

                // append the view id if necessary
                const purpose = this.pagePurpose(pageId);
                switch (purpose) {
                    case 'connection':
                        break;
                    case 'owner':
                        break;
                    case 'asset':
                        pageId = pageId + '-' + this.currentView;
                        break;
                    default: 
                        pageId = pageId + '-' + this.currentView;
                }

                return pageId;
            }
        }
    }
</script>

<style scoped>
    div#dashboard {
        width: 100%;
        height: 100%;
        background-color: var(--neutral);
    }
</style>
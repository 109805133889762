<template>
    <div class="nudge-to-connect">

        <img :src="require('@/assets/img/illustrations/start.svg')" />
        <p class="body-large black">
            {{ $t('dashboard.empty_vault') }}
        </p>
        <p class="body-large black">
            {{ $t('dashboard.connect_assets') }}
        </p>
        <button class="secondary high-rounded"
                @click.prevent="goToConnectView">
            <p class="title-large">
                {{ $t('dashboard.connect_first_asset') }}
            </p>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Nudge-To-Connect',
        methods: {
            goToConnectView() {
                this.$router.push('/connect');
            }
        }
    }
</script>

<style scoped>
    div.nudge-to-connect {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div.nudge-to-connect > img {
        width: 100%;
        max-width: 328px;
    }

        div.nudge-to-connect > button {
            margin-top: 16px;
        }
</style>
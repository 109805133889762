<template>
    <div id="page-overlay-opaque" 
         @click="closeOverlay" />
    <div id="page-overlay">

        <div class="fake-navbar">
            <span class="material-icons white title-large"
                  @click="closeOverlay">
                keyboard_double_arrow_right
            </span>
            <span class="material-icons white title-large"
                  @click="goToFullPage">
                open_in_full
            </span>
        </div>

        <div class="page-wrapper">
            <page-component :pageId="pageId" />
        </div>

    </div>
</template>

<script>

    import PageComponent from '@/components/dashboard/Page';

    export default {
        name: 'Page-Overlay-Component',
        props: ['pageId'],
        components: {
            PageComponent
        },
        data() {
            return {
                fullyLoaded: false
            }
        },
        created() {
            if (!this.$breakpoints.isLGOrLarger()) {
                this.goToFullPage();
            }
        },
        methods: {
            closeOverlay() {


                if (this.fullyLoaded) {

                    let path = this.$route.path + '?';

                    for (const [key, value] of Object.entries(this.$route.query)) {
                        if (key !== 'p') {
                            path += key + '=' + value + '&';
                        }
                    }
                    this.$router.push(path);

                }


            },
            goToFullPage() {
                this.$router.push('/dashboard/' + this.$route.query.p);
            }
        },
        mounted() {
            setTimeout(() => {
                this.fullyLoaded = true;
            }, 120);
        }
    }
</script>

<style scoped>

    div#page-overlay-opaque {
        position: fixed;
        background-color: rgba(0,0,0,0.1);
        width: calc(100vw - 896px);
        height: 100%;
        left: 0;
        top: 0;
        z-index: 600;
    }

    div#page-overlay {
        position: fixed;
        height: 100%;
        width: 896px;
        top: 0;
        right: 0;
        background-color: var(--neutral);
        border-left: 1px solid var(--black);
        z-index: 600;
        /* the ability to scroll */
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    div#page-overlay > div.fake-navbar {
        position: fixed;
        width: 100%;
        background-color: var(--primary);
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0px 16px;
        gap: 8px;
        z-index: 601;
    }

        div#page-overlay > div.fake-navbar > span.material-icons {
            border-radius: var(--squared-rounded);
            cursor: pointer;
        }

            div#page-overlay > div.fake-navbar > span.material-icons:hover {
                background-color: var(--placeholder);
            }

    div#page-overlay > div.page-wrapper {
        margin-top: 72px; /* take into account the fake navbar and the topmenu */
    }
</style>
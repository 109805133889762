import api from '@/services/api';

class PlatformExternalEntityTokenizationSpacyPost {

    post(body) {
        return api.post('/v1/platform/external/entityTokenization/spacy', body);
    }
}

export default new PlatformExternalEntityTokenizationSpacyPost();

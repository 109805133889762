<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container custom-height">

                    <span class="material-icons modal-close-button"
                          @click="closeModal">
                        clear
                    </span>

                    <h2 class="headline-small black">
                        {{ $t("dashboard.asset_details.add_block") }}
                    </h2>

                    <div class="input-field-wrapper">
                        <input-field-component v-model="filterBlock"
                                               :isValid="true"
                                               :label="$t('dashboard.asset_details.search_block')" />
                    </div>

                    <div class="item-blocks-sections">

                        <add-section-item-component v-for="(item, index) in sectionsFiltered"
                                                    v-bind:key="index"
                                                    :title="item.title"
                                                    :value="item.value"
                                                    :icon="item.icon"
                                                    @itemClicked="appendSection" />

                    </div>


                </div>
            </div>
        </div>
    </Transition>
</template>

<script>

    import InputFieldComponent from '@/components/fields/InputField';
    import AddSectionItemComponent from '@/components/dashboard/section/add/AddSectionItem';

    import { i18n } from '@/plugins/i18n';

    export default {
        name: 'Add-Section-Modal',
        props: ['show', 'page', 'purpose', 'assetType'],
        emits: ['close'],
        components: {
            InputFieldComponent,
            AddSectionItemComponent
        },
        data() {
            return {
                filterBlock: ''
            }
        },
        computed: {
            sectionsFiltered() {

                const unfilteredList = [
                    {
                        title: i18n.global.t('dashboard.asset_details.units_in_your_portfolios'),
                        value: 'portofliosDistribution',
                        icon: 'table_view',
                        pages: ['asset'],
                    },
                    {
                        title: i18n.global.t('dashboard.asset_details.portfolio_distribution'),
                        value: 'portofliosDistributionChart',
                        icon: 'donut_large',
                        pages: ['asset'],
                    },
                    {
                        title: i18n.global.t('dashboard.asset_details.company_profile_title'),
                        value: 'companyProfile',
                        icon: 'business',
                        pages: ['asset'],
                        assetTypes: ['stocks'],
                    },
                    {
                        title: i18n.global.t('dashboard.asset_details.company_valuation_metrics_title'),
                        value: 'companyValuationMetrics',
                        icon: 'query_stats',
                        pages: ['asset'],
                        assetTypes: ['stocks'],
                    },
                    {
                        title: i18n.global.t('dashboard.asset_details.price_history_title'),
                        value: 'assetGraph',
                        icon: 'trending_up',
                        pages: ['asset'],
                    },
                    {
                        title: i18n.global.t('dashboard.asset_details.company_dividends_title'),
                        value: 'companyDividends',
                        icon: 'receipt_long',
                        pages: ['asset'],
                        assetTypes: ['stocks'],
                    },
                    {
                        title: i18n.global.t('dashboard.asset_details.insider_transactinos_title'),
                        value: 'insiderTransactions',
                        icon: 'groups',
                        pages: ['asset'],
                        assetTypes: ['stocks'],
                    },
                    {
                        title: i18n.global.t('dashboard.assets_in_portfolio'),
                        value: 'connectionAssets',
                        icon: 'donut_large',
                        pages: ['connection'],
                    },
                    {
                        title: i18n.global.t('dashboard.table'),
                        value: 'table',
                        icon: 'table_chart',
                        pages: ['general'],
                    },
                    {
                        title: i18n.global.t('dashboard.owner_details.owner_assets'),
                        value: 'ownerConnections',
                        icon: 'table_chart',
                        pages: ['owner'],
                    },
                    {
                        title: i18n.global.t('dashboard.h1'),
                        value: 'text',
                        icon: 'text_fields',
                        pages: ['general', 'asset', 'connection', 'owner'],
                    },
                    {
                        title: i18n.global.t('dashboard.h2'),
                        value: 'text',
                        icon: 'text_fields',
                        pages: ['general', 'asset', 'connection', 'owner'],
                    },
                    {
                        title: i18n.global.t('dashboard.h3'),
                        value: 'text',
                        icon: 'text_fields',
                        pages: ['general', 'asset', 'connection', 'owner'],
                    },
                    {
                        title: i18n.global.t('dashboard.paragraph'),
                        value: 'text',
                        icon: 'text_fields',
                        pages: ['general', 'asset', 'connection', 'owner'],
                    }
                ];

                const filteredList = [];

                unfilteredList.forEach(item => {

                    if (!item.pages.includes(this.purpose) || // filter by page purpose
                        !item.title.toLowerCase().startsWith(this.filterBlock.toLowerCase()) || // filter by page title
                        (item.assetTypes != null &&
                            !item.assetTypes.includes(this.assetType)))  // filter by asset type
                    {
                        return;
                    }

                    filteredList.push(item);
                });

                return filteredList;
            }
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },
            appendSection(sectionId) {

                this.$store.dispatch('pages/appendSection', {
                    page: this.page,
                    sectionId: sectionId,
                    tableObject: {
                        properties: {
                            menuPreference: 'asset',
                            allConnections: true,
                            showNavigation: true
                        },
                        connections: []
                    }
                    }).then(() => {
                        this.closeModal();
                    }
                );

            }
        }
    }
</script>

<style scoped>


    .modal-container.custom-height {
        max-height: 75vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    div.input-field-wrapper {
        margin-top: 16px;
        margin-bottom: 16px;
        display: flex;
        width: 100%;
    }

    div.item-blocks-sections {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

</style>
import api from '../../../api';

class StockLogo {

  get(tickers) {
    return api.get('/v1/markets/stocks/logos/' + tickers.join(',')  );
  }
}

export default new StockLogo();

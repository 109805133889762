<template>

    <div id="rewards-page">

        <div v-if="rewardStep || rewardStep === 0" class="user-tier-wrapper">
            <user-tier-component :rewardStep="rewardStep" />
        </div>

        <div v-if="rewardStep === 3" class="survey-wrapper">
            <poll-component :pollId="'63c986a9d539d13ee8068a99'"
                            :overrideTitle="$t('rewards.poll_title')"
                            :subtitle="$t('rewards.poll_subtitle')" />
        </div>

        <div class="previous-season-wrapper">
            <previous-season-desktop-component v-if="$breakpoints.isMDOrLarger()" />
            <previous-season-mobile-component v-else />
        </div>
    </div>

</template>

<script>
    import UserTierComponent from '@/components/profile/rewards/UserTier';
    import PollComponent from '@/components/profile/surveys/Poll';
    import PreviousSeasonDesktopComponent from '@/components/profile/rewards/previousSeason/PreviousSeasonDesktop';
    import PreviousSeasonMobileComponent from '@/components/profile/rewards/previousSeason/PreviousSeasonMobile';

    import GetUsersProfileRewardSteps from '@/services/users/profile/rewardSteps/get.service';
    import EventBus from "@/common/EventBus";

    export default {

        name: 'Tiers-Page',
        data() {
            return {
                rewardStep: undefined
            }
        },
        components: {
            UserTierComponent,
            PollComponent,
            PreviousSeasonDesktopComponent,
            PreviousSeasonMobileComponent
        },
        created() {
            const user_id = this.$store.getters['auth/getUserId'];
            GetUsersProfileRewardSteps.get(user_id)
                .then((response) => {
                    const rewardStep = response.data.rewardSteps;
                    this.rewardStep = (rewardStep) ? rewardStep : 0;
                },
                (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                });
        }
    };
</script>

<style scoped>

    div#rewards-page {
        width: 100%;
        max-width: 1008px;
        margin: auto;
    }

        div#rewards-page > div.user-tier-wrapper,
        div#rewards-page > div.previous-season-wrapper,
        div#rewards-page > div.survey-wrapper {
            padding: 16px;
        }
</style>

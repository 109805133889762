<template>

    <p :class="{
          'notranslate':            true,
          'body-large':             isParagraph,
          'headline-large bold':    isHeading1,
          'headline-medium bold':   isHeading2,
          'headline-small bold':    isHeading3
       }"
       placeholder="Scrivi qui..."
       contenteditable="true"
       spellcheck="false"
       @keydown="preventSpecialCharacters"
       @keyup.enter="splitRow"
       @keyup.delete="onDelete"
       @keyup.up="onArrowUp"
       @keyup.down="onArrowDown"
       @keyup.left="onArrowLeft"
       @keyup.right="onArrowRight"
       @keyup="onType">
        {{ content }}
    </p>

</template>

<script>
    export default {
        name: 'Row-Component',
        props: ['text', 'page', 'path'],
        data() {
            return {
                previousCaretPosition: 0
            }
        },
        computed: {

            isParagraph() {
                return this.text.type === 'p';
            },
            isHeading1() {
                return this.text.type === 'h1';
            },
            isHeading2() {
                return this.text.type === 'h2';
            },
            isHeading3() {
                return this.text.type === 'h3';
            },
            content() {
                return this.text.text;
            },
        },
        methods: {

            // util

            getCaretPosition() {
                return window.getSelection().focusOffset;
            },
            theCaretDidNotMove() {
                return window.getSelection().focusOffset === this.previousCaretPosition
                    && window.getSelection().anchorOffset === window.getSelection().focusOffset;
            },
            // business logic

            splitRow(keyboardEvent) {

                const targetInnerText = keyboardEvent.target.innerText;
                const caretPosition = this.getCaretPosition();

                const upperRowText = targetInnerText.slice(0, caretPosition);
                const bottomRowText = targetInnerText.slice(caretPosition, targetInnerText.length);

                // Change the text of the current row
                this.$store.dispatch('pages/updateText', {
                    page: this.page,
                    path: this.path,
                    text: bottomRowText

                }).then(() => {
                    // create a new row above
                    this.$store.dispatch('pages/createRowAbove', {
                        page: this.page,
                        path: this.path,
                        type: this.text.type,
                        text: upperRowText
                    });

                }).then(() => {

                    const path = this.path.slice();
                    path[path.length - 1] += 1;

                    // change the type of the current row
                    this.$store.dispatch('pages/updateType', {
                        page: this.page,
                        path: path,
                        type: 'p'
                    });

                }).then(() => {

                    // set the caret to the row below
                    const keyboardTarget = keyboardEvent.target.parentElement.nextElementSibling.nextElementSibling.childNodes[3];
                    // set caret to the right position
                    const caretPosition = 0;

                    if (keyboardTarget == null) {
                        return;
                    }

                    const range = document.createRange();
                    range.setStart(keyboardTarget, caretPosition);
                    range.setEnd(keyboardTarget, caretPosition);

                    const selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);

                });
                
            },

            onDelete(keyboardEvent) {

                if (keyboardEvent.keyCode === 46) {
                    return; // do nothing on backspace
                }

                if (this.theCaretDidNotMove()) {
                    if (this.text.type === 'p') {

                        const textBeforeMergeLength = this.text.text.length;

                        try {

                            // merge the current row with the one above
                            this.$store.dispatch('pages/mergeRowWithUpperOne', {
                                page: this.page,
                                path: this.path

                            }).then(() => {
                                this.$store.dispatch('pages/clearUpperRow', {
                                    page: this.page,
                                    path: this.path
                                });

                            }).then(() => {

                                // set the caret to the upper row
                                const previousElementSibling = (keyboardEvent.target.parentElement) ? keyboardEvent.target.parentElement.previousElementSibling : undefined;
                                if (previousElementSibling == null || previousElementSibling.previousElementSibling == null) {
                                    return;
                                }
                                const keyboardTarget = previousElementSibling.previousElementSibling.childNodes[3];

                                // set caret to the right position
                                const caretPosition = (keyboardTarget) ? keyboardTarget.length - textBeforeMergeLength : 0;

                                if (keyboardTarget == null) {
                                    return;
                                }

                                const range = document.createRange();
                                range.setStart(keyboardTarget, caretPosition);
                                range.setEnd(keyboardTarget, caretPosition);

                                const selection = window.getSelection();
                                selection.removeAllRanges();
                                selection.addRange(range);

                            });


                        } catch (error) {
                            error;
                            // pass
                        }
                        
                    } else {

                        // remove type from current row
                        this.$store.dispatch('pages/clearType', {
                            page: this.page,
                            path: this.path
                        });
                    }
                }
            },

            onArrowUp(keyboardEvent) {

                if (this.theCaretDidNotMove()) {

                    // focus the text of the previous section
                    const previousElementSibling = keyboardEvent.target.parentElement.previousElementSibling;
                    if (previousElementSibling == null || previousElementSibling.previousElementSibling == null) {
                        return;
                    }
                    const previousText = previousElementSibling.previousElementSibling.childNodes[3];

                    if (previousText && previousText.focus) {
                        previousText.focus();
                    }
                }
            },

            onArrowDown(keyboardEvent) {

                if (this.theCaretDidNotMove()) {

                    // focus the text of the following section
                    const nextElementSibling = keyboardEvent.target.parentElement.nextElementSibling;
                    if (nextElementSibling == null || nextElementSibling.nextElementSibling == null) {
                        return;
                    }
                    const followingText = nextElementSibling.nextElementSibling.childNodes[3];

                    if (followingText && followingText.focus) {
                        followingText.focus();
                    }
                }
            },

            onArrowLeft(keyboardEvent) {

                if (this.theCaretDidNotMove()) {

                    // focus the text of the previous section

                    const previousElementSibling = keyboardEvent.target.parentElement.previousElementSibling;
                    if (previousElementSibling == null || previousElementSibling.previousElementSibling == null) {
                        return;
                    }
                    const previousText = previousElementSibling.previousElementSibling.childNodes[3];

                    if (previousText && previousText.focus) {
                        previousText.focus();
                    }
                }
            },

            onArrowRight(keyboardEvent) {

                if (this.theCaretDidNotMove()) {

                    // focus the text of the following section
                    const nextElementSibling = keyboardEvent.target.parentElement.nextElementSibling;
                    if (nextElementSibling == null || nextElementSibling.nextElementSibling == null) {
                        return;
                    }
                    const followingText = nextElementSibling.nextElementSibling.childNodes[3];

                    if (followingText && followingText.focus) {
                        followingText.focus();
                    }
                }
            },

            openContextMenu(keyboardEvent) {
                keyboardEvent;
                //console.log('slash', keyboardEvent.keyCode);
            },

            preventSpecialCharacters(keyboardEvent) {

                if (keyboardEvent.keyCode === 13)   // enter
                {
                    return keyboardEvent.preventDefault();
                }

                // update
                this.previousCaretPosition = this.getCaretPosition();
            },

            onType(keyboardEvent) {

                // prevent special actions characters
                if (keyboardEvent.keyCode === 13 ||
                    (keyboardEvent.keyCode === 8 && this.theCaretDidNotMove()) ||
                    keyboardEvent.keyCode === 37 ||
                    keyboardEvent.keyCode === 38 ||
                    keyboardEvent.keyCode === 39 ||
                    keyboardEvent.keyCode === 40) {
                    return keyboardEvent.preventDefault();
                }

                // intercept slash command
                if (keyboardEvent.keyCode === 55) {
                    this.openContextMenu(keyboardEvent);
                }

                // save to vuex store
                this.$store.dispatch('pages/updateText', {
                    page: this.page,
                    path: this.path,
                    text: keyboardEvent.target.innerText
                });
            }
        }
    }
</script>

<style scoped>
    p {
        cursor: pointer;
        width: 100%;
    }

    p:focus {
        cursor: auto;
        outline: none;
    }

    p[placeholder]:empty:focus::before {
        content: attr(placeholder);
        color: var(--placeholder);
    }
</style>
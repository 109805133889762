import api from '../../../../api';

class GetMarketsCryptoHistorical {

  get(tickers, query) {
    if( query ) {
      const dates = query.dates;
      return api.get('/v1/markets/crypto/historical/' + tickers.join(',') + '?dates=' + dates.join(','));
    }
    else {
      return api.get('/v1/markets/crypto/historical/' + tickers.join(','));
    }
  }
}

export default new GetMarketsCryptoHistorical();

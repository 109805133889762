<template>
	<div />
</template>

<script>
	export default {
		name: 'Logout-View',
		beforeCreate() {
			this.$store.dispatch('auth/logout').then(() => {
				this.$router.push('/login');
			});
		}
	}
</script>
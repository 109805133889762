import api from '@/services/api';

class DeleteUsersProfileView {

    delete(id, viewId) {
        return api.delete('/v1/users/' + id + '/profile/view/' + viewId);
    }
}

export default new DeleteUsersProfileView();

import api from '@/services/api';

class DeleteUsersProfileOwner {

    delete(id, ownerId) {
        return api.delete('/v1/users/' + id + '/profile/owner/' + ownerId);
    }
}

export default new DeleteUsersProfileOwner();

import api from '@/services/api';

class GetUsersPages{

    get(id) {
        return api.get('/v1/users/' + id + '/pages');
    }
}

export default new GetUsersPages();

import api from '@/services/api';

class PutUsersManualAsset {

    put(id, data) {
        return api.put('/v1/users/' + id + '/manual/asset', data);
    }
}

export default new PutUsersManualAsset();

<template>
    <div class="company-valuation-section">
        <div class="title-card">
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.company_valuation_metrics_title') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>
        <p v-if="companyValuationMetrics == null ||
                 (companyValuationMetrics.trailing_pe == null &&
                 companyValuationMetrics.price_to_sales_ttm == null &&
                 companyValuationMetrics.price_to_book_mrq == null)"
           class="body-large black">
            {{ $t('dashboard.asset_details.could_not_find_valuations') }}
        </p>
        <div v-else
             class="company-profile-information">
            <div v-if="companyValuationMetrics.trailing_pe"
                    class="chart-overlay">
                <canvas :id="peChartId" />
                <div class="center-in-chart">
                    <span class="title-small black">
                        {{ $t('dashboard.asset_details.pe_ratio') }}
                    </span>
                    <p class="body-large black bold">
                        {{ Math.round(companyValuationMetrics.trailing_pe * 100) / 100 }}
                    </p>
                </div>
            </div>
            <div v-if="companyValuationMetrics.price_to_sales_ttm"
                    class="chart-overlay">
                <canvas :id="psChartId" />
                <div class="center-in-chart">
                    <span class="title-small black">
                        {{ $t('dashboard.asset_details.ps_ratio') }}
                    </span>
                    <p class="body-large black bold">
                        {{ Math.round(companyValuationMetrics.price_to_sales_ttm * 100) / 100 }}
                    </p>
                </div>
            </div>
            <div v-if="companyValuationMetrics.price_to_book_mrq"
                    class="chart-overlay">
                <canvas :id="pbChartId" />
                <div class="center-in-chart">
                    <span class="title-small black">
                        {{ $t('dashboard.asset_details.pb_ratio') }}
                    </span>
                    <p class="body-large black bold">
                        {{ Math.round(companyValuationMetrics.price_to_book_mrq * 100) / 100 }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Chart from 'chart.js/auto';

    export default {
        name: "Company-Valuation-Metrics",
        props: ['asset', 'page', 'path'],
        data() {
            return {
                peRatioChart: undefined,
                psRatioChart: undefined,
                pbRatioChart: undefined,

                peChartId: 'pe-ratio-chart-' + (Math.random() + 1).toString(36).substring(2),
                psChartId: 'ps-ratio-chart-' + (Math.random() + 1).toString(36).substring(2),
                pbChartId: 'pb-ratio-chart-' + (Math.random() + 1).toString(36).substring(2),
            };
        },
        computed: {
            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            company() {
                return (this.markets) ? this.markets[this.asset.ticker] : undefined;
            },
            companyFundamentals() {
                return (this.company) ? this.company.fundamentals : undefined;
            },
            companyValuationMetrics() {
                return (this.companyFundamentals) ? (this.companyFundamentals.valuation_metrics) : undefined;
            }
        },
        methods: {


            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },


            writeCharts() {

                this.writeChart(
                    'Price/Earnings Ratio',
                    this.companyValuationMetrics.trailing_pe,
                    this.companyValuationMetrics.market_capitalization,
                    'Price/Earnings',
                    this.peChartId,
                    ['#D7B058', '#E5ECF0']
                );
                this.writeChart(
                    'Price/Sales Ratio',
                    this.companyValuationMetrics.price_to_sales_ttm,
                    this.companyValuationMetrics.market_capitalization,
                    'Price/Sales',
                    this.psChartId,
                    ['#5ED5A8', '#E5ECF0']
                );
                this.writeChart(
                    'Price/Book Ratio',
                    this.companyValuationMetrics.price_to_book_mrq,
                    this.companyValuationMetrics.market_capitalization,
                    'Price/Book',
                    this.pbChartId,
                    ['#EE7671', '#E5ECF0']
                );
            },
            writeChart(title, ratio, marketCap, ratioName, chartID, colors) {

                if (ratio == null) {
                    return;
                }

                switch (ratioName) {
                    case 'Price/Earnings':
                        if (this.peRatioChart) {
                            this.peRatioChart.destroy();
                        } break;
                    case 'Price/Sales':
                        if (this.psRatioChart) {
                            this.psRatioChart.destroy();
                        } break;
                    case 'Price/Book':
                        if (this.pbRatioChart) {
                            this.pbRatioChart.destroy();
                        } break;
                }

                const ctx = document.getElementById(chartID);
                if (ctx == null) {
                    return;
                }

                const data = {
                    labels: [ratioName, 'Market Cap'],
                    datasets: [{
                        label: [ratioName, 'Market Cap'],
                        data: [marketCap / ratio, marketCap],
                        backgroundColor: colors,
                        hoverOffset: 4
                    }]
                };

                const options = {
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: title
                        }
                    }
                };

                this.assetLocationChart = new Chart(ctx, {
                    type: 'doughnut',
                    data: data,
                    options: options
                });
            }
        },
        watch: {

            companyValuationMetrics: async function () {

                // TODO: remove this abomination
                await new Promise(resolve => setTimeout(resolve, 2000));

                this.writeCharts();
            }
        },
        mounted() {
            setTimeout(() => {
                if (this.companyValuationMetrics) {
                    this.writeCharts();
                }
            }, 128);
        }
    }
</script>

<style scoped>

    div.company-valuation-section {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px 32px;
        width: calc(100% - 96px);
        background-color: var(--white);
        border-radius: var(--high-rounded);
    }

        div.company-valuation-section > div.title-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
        }

            div.company-valuation-section > div.title-card > span {
                cursor: pointer;
            }

    @media (max-width: 904px) {
        div.company-profile-information {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 824px;
            padding: 16px;
            width: 100%;
        }

        div.chart-overlay {
            width: 256px;
            height: 256px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
    }

    @media (min-width: 905px) {
        div.company-profile-information {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 256px;
            width: 100%;
        }

        div.chart-overlay {
            width: 192px;
            height: 192px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
    }

    div.chart-overlay div.center-in-chart {
        position: absolute;
        top: 50%;
        text-align: center;
    }
</style>
<template>
    <div id="connect-stocks-page">
        <stocks-ETFs-search-component />
    </div>
</template>

<script>
    import StocksETFsSearchComponent from '@/components/connect/stocks/manual/StocksETFsSearch';

    export default {
        name: "Connect-Stocks-Manual",
        components: {
            StocksETFsSearchComponent
        }
    };

</script>

<style scoped>
    div#connect-stocks-page {
        padding: 8px 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    @media (max-width: 904px) {
        div#connect-stocks-page {
            padding: 8px;
        }
    }
</style>



export default {


    it: {

        create_your_360_account: 'Unisciti a 360Track',
        your_journey_starts_here: "Parti all'avventura, gestisci il tuo patrimonio, fallo crescere",


        email: "Email",
        email_required: "L'email è obbligatoria",
        email_invalid: "L'email non è valida",
        email_maximum_50_characters: "L'email può avere al massimo 50 caratteri",
        email_already_in_use: "L'email è stata già utilizzata",

        username: "Username",
        username_required: "L'username è obbligatorio",
        username_short: "L'username deve avere almeno 3 caratteri",
        username_maximum_20_characters: "L'username può avere a massimo 20 caratteri",
        username_already_in_use: "L'username è stato già utilizzato",

        password: "Password",
        password_required: "La password è obbligatoria",
        password_short: "La password deve avere almeno 6 caratteri",
        password_maximum_40_characters: "La password può avere al massimo 40 caratteri",
        weak_password: "La password è troppo debole",
        password_not_valid: "La password è troppo debole oppure non sono uguali",

        password_confirmation: "Conferma password",
        passwords_must_match: "Le password non corrispondono",

        acceptance: "Accetto",
        terms_and_conditions: "T&C",
        and: "e",
        privacy_policy: "Privacy Policy",
        keep_me_updated: "Voglio ricevere aggiornamenti",
        must_accept_terms_and_conditions: "Per creare il tuo account, accetta Termini e Condizioni",

        create_account: "Crea account"
    },

    es: {

        create_your_360_account: '¡Empieza a usar 360Track!',
        your_journey_starts_here: 'Comienza tu viaje hasta riquezas a largo plazo',


        email: "Introduce tu correo habitual",
        email_required: "El correo electrónico es obligatorio",
        email_invalid: "El correo electrónico no es válido",
        email_maximum_50_characters: "50 caracteres como máximo",
        email_already_in_use: "Este correo eléctronico ya està en uso",

        username: "Nombre de usuario",
        username_required: "El nombre de usuario es obligatorio",
        username_short: "3 caracteres como mínimo",
        username_maximum_20_characters: "20 caracteres como máximo",
        username_already_in_use: "Este nombre de usuario ya està en uso",

        password: "Contraseña",
        password_required: "La contraseña es obligatoria",
        password_short: "6 caracteres como mínimo",
        password_maximum_40_characters: "40 caracteres como máximos",
        weak_password: "Contraseña débil",
        password_not_valid: "Contraseña débil, las contraseñas no coinciden",

        password_confirmation: "Confirma contraseña",
        passwords_must_match: "Las contraseñas no coinciden",

        acceptance: "Acepto los",
        terms_and_conditions: "T&C",
        and: "y la",
        privacy_policy: "Política de Confidencialidad",
        keep_me_updated: "Mantenme al tanto sobre community y news",
        must_accept_terms_and_conditions: "Debe aceptar los Términos y Condiciones y la Política de Confidencialidad",

        create_account: "Creas tu cuenta"
    },

    en: {
        create_your_360_account: 'Create your 360Track Account',
        your_journey_starts_here: 'Your journey towards long-term wealth starts here',


        email: "Email",
        email_required: "The email is required",
        email_invalid: "The email is invalid",
        email_maximum_50_characters: "The email must be maximum 50 characters",
        email_already_in_use: "The email is already in use",

        username: "Username",
        username_required: "The username is required",
        username_short: "The username must be at least 3 characters",
        username_maximum_20_characters: "The username must be maximum 20 characters",
        username_already_in_use: "The username is already in use",

        password: "Password",
        password_required: "The password is required",
        password_short: "The password must be at least 6 characters",
        password_maximum_40_characters: "The password must be maximum 40 characters",
        weak_password: "The password is too weak",
        password_not_valid: "The password is too weak or does not match",

        password_confirmation: "Confirm password",
        passwords_must_match: "Passwords must match",


        acceptance: "I accept 360Track",
        terms_and_conditions: "T&C",
        and: "and",
        privacy_policy: "Privacy Policy",
        keep_me_updated: "Keep me in the loop with next updates",
        must_accept_terms_and_conditions: "You must accept terms and conditions",

        create_account: "Create Account"
    },

    fr: {

        create_your_360_account: 'Rejoignez 360Track',
        your_journey_starts_here: "Richesses à long terme, aventure y communauté",

        email: "Email",
        email_required: "L'adresse email est obligatoire",
        email_invalid: "Cette adresse email est invalide",
        email_maximum_50_characters: "Maximum de 50 caractères permis",
        email_already_in_use: "L'adresse email entrée est déjà utilisée.",

        username: "Nom d'utilisateur",
        username_required: "Le nom d'utilisateur est obligatoire",
        username_short: "Au moins 3 caractères",
        username_maximum_20_characters: "Maximum de 20 caractères permis",
        username_already_in_use: "Le nom d'utilisateur entré est déjà utilisé",

        password: "Mot de passe",
        password_required: "Le mot de passe est obligatoire",
        password_short: "Au moins 6 caractères",
        password_maximum_40_characters: "Maximum de 40 caractères permis",
        weak_password: "Le mot de passe est trop faible",
        password_not_valid: "Mot de passe trop faible ou les mots ne correspondent pas",

        password_confirmation: "Confirmez le mot de passe",
        passwords_must_match: "Les mots de passe doivent correspondre",

        acceptance: "J'ai lu et j'accepte",
        terms_and_conditions: "les CGV",
        and: "et la",
        privacy_policy: "PP",
        keep_me_updated: "Tiens-moi au courant de futurs développements",
        must_accept_terms_and_conditions: "Vous devez accepter les conditions générales de 360Track",

        create_account: "Creér Compte"
    },

};
import api from "../api";
import TokenService from "./token.service";

class AuthService {
    login({ username, password }) {
        return api
            .post("/v1/auth/signin", {
                username,
                password
            }).then(
                (response) => {
                    if (response.data.accessToken) {
                        TokenService.setUser(response.data);
                    }
                    return response.data;
                }
            );
    }

    refresh() {
        return api.get("/v1/auth/ping");
    }

    logout() {
        TokenService.removeUser();
    }

    register({ user, language }) {
        return api.post("/v1/auth/signup", {
            username: user.username,
            email: user.email,
            password: user.password,
            passwordConfirmation: user.passwordConfirmation,
            terms: user.terms,
            updates: user.updates,
            language: language,
            timeframe: '1D',
            currency: 'EUR',
            rewardStep: 0,
            utmSource: user.utmSource,
            utmMedium: user.utmMedium,
            utmCampaign: user.utmCampaign
        });
    }

    verifyUser(code) {
        return api.get("/v1/auth/confirm/" + code);
    }
}

export default new AuthService();

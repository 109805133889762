<template>

    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <span class="material-icons modal-close-button" @click="closeModal">
                        close
                    </span>

                    <h2 class="headline-small black">
                        {{ $t('forms.new_view.update_view') }}
                    </h2>

                    <form class="branded-input-form" @submit.prevent="submitForm">

                        <div class="fields-frame">
                            <input-field-component v-model="inputFields.name"
                                                   type="text"
                                                   :label="$t('forms.new_view.view_name')"
                                                   :isValid="isValid.name" />

                        </div>

                        <div class="fields-frame">
                            <add-owner-multiselect-field-component v-model="inputFields.ownerIds"
                                                                   @onAddItem="onAddItem"
                                                                   @onRemoveItem="onRemoveItemIndex"
                                                                   :isValid="isValid.ownerIds"/>
                        </div>


                        <div class="buttons-frame">
                            <!-- confirm button start -->
                            <button class="tertiary full-rounded"
                                    :disabled="isFormDisabled">
                                <span v-if="isFormDisabled"
                                      class="material-icons loading-icon">
                                    sync
                                </span>
                                <p class="label-large">
                                    {{ $t('forms.add') }}
                                </p>
                            </button>
                            <!-- confirm button end -->
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </Transition>

</template>

<script>

    import InputFieldComponent from '@/components/fields/InputField';
    import AddOwnerMultiselectFieldComponent from '@/components/fields/owner/AddOwnerMultiselectField';

    import PutUsersProfileView from "@/services/users/profile/view/put.service";
    import EventBus from "@/common/EventBus";

    export default {
        name: 'Update-Profile-View',
        emits: ['close'],
        props: ['show', 'view'],
        components: {
            InputFieldComponent,
            AddOwnerMultiselectFieldComponent,
        },
        data() {
            return {
                isFormDisabled: false,
                inputFields: {
                    name: this.view.name,
                    ownerIds: this.view.owners
                },
                isValid: {
                    name: true,
                    ownerIds: true
                }
            };
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },
            submitForm() {


                if (this.inputFields.name == null ||
                    this.inputFields.name === '' ||
                    !this.inputFields.name.trim()
                    ) {
                    this.isValid.name = false;
                    return;
                } else {
                    this.isValid.name = true;
                }


                if (this.inputFields.ownerIds == null ||
                    this.inputFields.ownerIds.length === 0
                    ) {
                    this.isValid.ownerIds = false;
                    return;
                } else {
                    this.isValid.ownerIds = true;
                }

                // execute

                this.isFormDisabled = true;

                const data = {
                    viewId: this.view._id,
                    name: this.inputFields.name,
                    ownerIds: this.inputFields.ownerIds
                };

                const user_id = this.$store.getters['auth/getUserId'];

                PutUsersProfileView.put(user_id, data).then(
                    response => {

                        const newlyUpdatedView = response.data;

                        this.$store.dispatch('views/updateView', newlyUpdatedView);
                        this.$emit('close');

                    },
                    (error) => {
                        this.isFormDisabled = false;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            },

            /** owner ids */

            onAddItem(value) {
                if (!this.inputFields.ownerIds.includes(value)) {
                    this.inputFields.ownerIds.push(value);
                }
            },
            onRemoveItemIndex(index) {
                this.inputFields.ownerIds.splice(index, 1);
            },
        }
    }
</script>
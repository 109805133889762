


export default {

    it: {
        country_title: "Seleziona un paese",
        country_placeholder: "Cerca",

        bank_title: "Seleziona una banca",
        bank_placeholder: "Cerca",

        none_of_above: "Nessuno di cui sopra",
        hello: "Ciao",
        from_nordigen_seems: "da Nordigen sembra che il conto connesso appartenga a",
        is_it_you: "Sei tu?",
        yes: "S�",
        no: "No",

        success_title: "Successo! Controlla gli account che abbiamo trovato:",
        success_add_button: "Aggiungi altro",
        success_done_button: "Fatto"
    },

    es: {
        country_title: "Seleccionar el pais",
        country_placeholder: "B�squeda",

        bank_title: "Seleccionar un banco",
        bank_placeholder: "B�squeda",

        none_of_above: "Ninguna de las anteriores",
        hello: "Hola",
        from_nordigen_seems: "de Nordigen parece que las cuentas conectadas pertenecen a",
        is_it_you: "�Eres t�?",
        yes: "S�",
        no: "No",

        success_title: "��xito! Revisa las cuentas que hemos encontrado:",
        success_add_button: "A�adir m�s",
        success_done_button: "Terminado"
    },

    en: {
        country_title: "Select a country",
        country_placeholder: "Search",

        bank_title: "Select a bank",
        bank_placeholder: "Search",

        none_of_above: "None of above",
        hello: "Hello",
        from_nordigen_seems: "from Nordigen it seems the accounts connected belong to",
        is_it_you: "Is it you?",
        yes: "Yes",
        no: "No",

        success_title: "Success! Check the accounts connected:",
        success_add_button: "Add more",
        success_done_button: "Done"
    },

    fr: {
        country_title: "S�lectionner un pays",
        country_placeholder: "Recherche",

        bank_title: "S�lectionner une banque",
        bank_placeholder: "Recherce",

        none_of_above: "Aucune des choses ci-dessus",
        hello: "Salut",
        from_nordigen_seems: "de Nordigen, il semble que les comptes connect�s appartiennent �",
        is_it_you: "Est-ce toi?",
        yes: "Oui",
        no: "Non",

        success_title: "Succ�s! V�rifiez les comptes que nous avons trouv�s:",
        success_add_button: "A�adir m�s",
        success_done_button: "Ajouoter plus"
    },
};
<template>
    <img :src="require('@/assets/img/illustrations/Error.svg')" />
</template>

<script>
    export default {
        name: 'Connect-Error-Animation-Component'
    }
</script>

<style scoped>
   img {
       width: 100%;
       max-width: 480px;
   }
</style>
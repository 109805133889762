<template>

    <div class="component-frame responsive-height"
         @click="goToConnectionPage">
        <div>
            <img class="logo-size" :src="logoUrl" />
            <div>
                <p class="label-large white">
                    {{iban}}
                </p>
                <p class="body-large white">
                    {{name}}
                </p>
                <p class="body-large white">
                    {{amountString}}
                </p>

                <div class="owner-names">
                    <div v-for="(name, index) in bankOwnerNames"
                         v-bind:key="index"
                         class="item-owner"
                         :style="`background-color: ${colors[index%3]}`">
                        <p class="label-large black">
                            {{name}}
                        </p>
                    </div>
                </div>


            </div>
        </div>
        <div v-if="!$breakpoints.isXS()">
            <span class="material-icons-outlined headline-large green">
                verified
            </span>
            <span class="material-icons white">
                more_vert
            </span>
        </div>
    </div>

</template>

<script>

    import { ownerColors } from '@/assets/js/fields/ownerColors';

    export default {
        name: "Connected-Box",
        props: ['iban', 'name', 'amountString', 'logoUrl', 'accountId','requisitionId', 'owners'],
        methods: {
            goToConnectionPage() {

                if (this.accountId in this.pages) {

                    this.routeToPage();

                } else {

                    // create the page - the go to it
                    const params = {
                        name: name,
                        id: this.accountId,
                        icon: undefined,
                        service: 'openbanking',
                    };
                    this.$store.dispatch('pages/createConnectionPage', params).then(() => {
                        this.routeToPage();
                    });

                }

            },

            routeToPage() {


                if (this.$breakpoints.isLGOrLarger()) {

                    // if the screen is large enough, open it as a side panel
                    this.$router.push('/connect/openbanking/success/success?p=' + this.accountId + '&ref=' + this.requisitionId);


                } else {

                    // else, just go to the page
                    this.$router.push('/dashboard/' + this.accountId);
                }

            }
        },
        computed: {

            allOwners() {
                return this.$store.getters['views/getOwners'];
            },
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            bankOwnerNames() {

                const ownerNames = [];

                this.owners.forEach(owner => {
                    const thisOwner = this.allOwners.find(x => x._id === owner);

                    if (thisOwner) {
                        ownerNames.push(thisOwner.nickname);
                    }
                });

                return ownerNames;

            },
            colors() {
                return ownerColors();
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
        }
    }
</script>

<style scoped>

    @media (max-width:904px) {
        .responsive-height {
            height: 120px;
        }

        .logo-size {
            height: 36px;
            width: 36px;
        }
    }

    @media (min-width:905px) {
        .responsive-height {
            height: 138px;
        }

        .logo-size {
            height: 40px;
            width: 40px;
        }
    }

    div.component-frame {
        background-color: var(--primary);
        border-radius: 16px;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

        div.component-frame > div {
            display: flex;
            align-items: center;
            gap: 16px;
        }

            div.component-frame > div > div > p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 320px;
            }

    @media (max-width: 904px) {
        div.component-frame > div > div > p {
            max-width: 256px;
        }
    }

    div.owner-names {
        display: flex;
        max-width: 336px;
        white-space: nowrap;
        overflow-x: hidden;
        gap: 8px;
        margin-top: 8px;
    }

    @media(max-width: 599px) {
        div.owner-names {
            max-width: 280px;
        }
    }

    div.owner-names > div.item-owner {
        padding: 1px 8px;
        height: fit-content;
        border-radius: var(--middle-rounded);
    }

    /* DROPDOWN */

    .clickable-icon {
        cursor: pointer;
    }
</style>
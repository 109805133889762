<template>
    <div v-if="isFullyShown"
         class="dropdown-menu"
         :style="style"
         v-click-outside="closeDropdown">
        <div class="menu-item"
             v-for="(element, index) in list"
             v-bind:key="index"
             @click="onItemClick(element.value)"
             :style="elementCssProps(index)">

            <span v-if="element.options && element.options.materialIcon"
                    class="material-icons body-medium">
                {{ element.options.materialIcon }}
            </span>
            <p class="body-medium">
                {{ element.name }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Dropdown-Menu-Component',
        emits: ['onItemClick'],
        props: ['show', 'list', 'style'],
        /*
         * list in format
         * 
         * [{
         *      name: String     // what to display
         *      value: String    // what is passed to the parent when the element is clicked,
         *      
         *      // options are optional - if not passed, defaults are passed
         *      options: {
         *          materialIcon: String            // material icon code
         *          
         *          backgroundColor: String         // background color - in hexadecimal
         *          color: String                   // color of the text - in hexadecimal
         *          
         *          hoverBackgroundColor: String    // background color when hovering - in hexadecimal
         *          hoverColor: string              // color of the text when hovering - in hexadecimal
         *      }
         * }]
         * 
         * 
         * 
         * 
         * 
         * */
        data() {
            return {
                isFullyShown: false
            };
        },
        methods: {
            onItemClick(value) {
                this.closeDropdown();
                this.$emit('onItemClick', value);
            },
            closeDropdown() {
                this.isFullyShown = false;
            },
            elementCssProps(index) {
                return {
                    '--color': (this.list[index].options && this.list[index].options.color) ? this.list[index].options.color : 'var(--black)',
                    '--background-color': (this.list[index].options && this.list[index].options.backgroundColor) ? this.list[index].options.backgroundColor : 'transparent',

                    '--hover-color': (this.list[index].options && this.list[index].options.hoverColor) ? this.list[index].options.hoverColor : 'var(--black)',
                    '--hover-background-color': (this.list[index].options && this.list[index].options.hoverBackgroundColor) ? this.list[index].options.hoverBackgroundColor : 'var(--secondary)',
                }
            }
        },
        watch: {
            show: function () {
                setTimeout(() => {
                    this.isFullyShown = true;
                }, 100);
            }
        }
    }
</script>

<style scoped>
    div.dropdown-menu {
        position: absolute;
        background-color: var(--white);
        padding: 8px 0px;
        top: 24px;
        left: 0px;
        z-index: 200;
        border: 1px solid var(--primary);
        min-width: 160px;
        display: flex;
        flex-direction: column;
    }

        div.dropdown-menu > div.menu-item {
            padding: 2px 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 2px;
            color: var(--color);
            background-color: var(--background-color);
        }

            div.dropdown-menu > div.menu-item:hover {
                background-color: var(--hover-background-color);
                color: var(--hover-color);
            }
</style>
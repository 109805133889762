<template>
    {{ localeDate }}
</template>

<script>
    export default {
        name: 'Locale-Date-Component',
        props: ['isoDate'],
        computed: {
            localeDate() {
                if (this.isoDate) {
                    const localeLanguage = this.$store.getters['auth/getUserLanguage'];
                    const options = {
                        year: "numeric",
                        month: "2-digit",
                        day: "numeric"
                    }

                    return new Date(this.isoDate).toLocaleDateString(localeLanguage, options);
                }
                return '-';
            }
        }
    }
</script>


import GetUsersManualAsset from "@/services/users/manual/asset/get.service";

import PatchUsersCryptoWalletAddress from "@/services/users/crypto/wallet/address/patch.service";
import GetUsersCryptoWalletAddress from "@/services/users/crypto/wallet/address/get.service";

import PatchUsersCryptoExchanges from "@/services/users/crypto/exchanges/patch.service";
import GetUsersCryptoExchanges from "@/services/users/crypto/exchanges/get.service";

import PatchUsersOpenbankingAccountBalance from "@/services/users/openbanking/account/balances/patch.service";
import GetUsersOpenbankingAccount from "@/services/users/openbanking/account/get.service";
import PatchUsersOpenbankingRequisition from '@/services/users/openbanking/requisition/patch.service';
import GetUsersOpenbankingRequisition from '@/services/users/openbanking/requisition/get.service';


import EventBus from "@/common/EventBus";


export const connections = {
    namespaced: true,
    state: {
        isLoaded: false,
        connections: [],
        // used to assess that all the async calls have finished properly
        // increases by 1 before each external call
        // decreases by 1 after each external call
        _concurrentCallsCounter: 1, // starts at 1 for technical reasons - to avoid an initial glitch
        maxConcurrentCallsCounter: 1, // the maximum number of concurrent calls that happened
    },
    actions: {

        refresh({ commit, rootGetters }) {


            /****************************************/
            /******** Preliminary operations ********/
            /****************************************/


            const user_id = rootGetters['auth/getUserId'];
            commit('clearConnections');


            /*****************************************/
            /**************** WALLETS ****************/
            /*****************************************/


            commit('increaseCallCounter'); // start wallets call
            GetUsersCryptoWalletAddress.get(user_id).then(
                async (response) => {
                    const wallets = response.data.wallets;

                    if (wallets.length === 0) {
                        // there are no wallets
                        commit('decreaseCallCounter'); // end wallets call
                        return;
                    }

                    // patch the balance of the wallets
                    for (const wallet of wallets) {
                        await PatchUsersCryptoWalletAddress.patch(user_id, wallet.source, wallet.blockchain).then(() => { },
                            (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }


                    // get the balances in one shot
                    GetUsersCryptoWalletAddress.get(user_id).then(
                        (response) => {

                            const wallets = response.data.wallets;

                            for (const wallet of wallets) {

                                // update the wallet with the useful information
                                wallet.service = 'wallet';
                                wallet.name = wallet.source_name;   // copy source_name in name
                                wallet.updatedAt = (wallet.updatedAt) ? new Date(wallet.updatedAt).toISOString() : undefined;
                                delete wallet.source_name;          // delete source_name
                                for (let i = 0; i < wallet.balance.length; i++) {
                                    wallet.balance[i].type = 'cryptocurrency'
                                }

                                commit('addConnection', wallet);
                            }

                            // procedure completed
                            commit('decreaseCallCounter'); // end wallets call

                        }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });


            /*****************************************/
            /*************** EXCHANGES ***************/
            /*****************************************/


            commit('increaseCallCounter'); // start exchanges call
            GetUsersCryptoExchanges.get(user_id).then(
                async (response) => {
                    const exchanges = response.data.exchanges;

                    if (exchanges.length === 0) {
                        // there are no wallets
                        commit('decreaseCallCounter'); // end exchanges call
                        return;
                    }


                    // patch the balance of the exchanges
                    for (const exchange of exchanges) {
                        await PatchUsersCryptoExchanges.patch(user_id, exchange.source).then(() => { },
                            (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }


                    // get user's exchanges
                    GetUsersCryptoExchanges.get(user_id).then(
                        (response) => {


                            const exchanges = response.data.exchanges;
                            for (const exchange of exchanges) {


                                exchange.service = 'exchange';
                                exchange.active = (exchange.active === 200) ? true : false;
                                exchange.updatedAt = new Date(exchange.updatedAt).toISOString();
                                for (let i = 0; i < exchange.balance.length; i++) {
                                    exchange.balance[i].type = 'cryptocurrency';
                                }


                                commit('addConnection', exchange);
                            }

                            // procedure completed
                            commit('decreaseCallCounter'); // end exchanges call


                        }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });


            /*****************************************/
            /************* MANUAL ASSETS *************/
            /*****************************************/


            commit('increaseCallCounter'); //start manual assets call
            GetUsersManualAsset.get(user_id).then(
                (response) => {
                    const portfolios = response.data;


                    if (portfolios.length === 0) {
                        // there are no wallets
                        commit('decreaseCallCounter'); // end manual assets call
                        return;
                    }

                    for (const portfolio of portfolios) {

                        portfolio.service = 'manual';
                        commit('addConnection', portfolio);
                    }

                    // procedure completed
                    commit('decreaseCallCounter'); // end exchanges call

                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });


            /*****************************************/
            /***************** BANKS *****************/
            /*****************************************/


            commit('increaseCallCounter'); //start banks call
            GetUsersOpenbankingAccount.get(user_id).then(
                async (response) => {
                    const accounts = response.data;

                    if (accounts.length === 0) {
                        // there are no wallets
                        commit('decreaseCallCounter'); // end banks call
                    }

                    // manage the status of the requisition
                    const requisitionIds = accounts.map(x => x.requisitionId);
                    const requisitionsStatus = {};

                    // patch and get the status of the requisitions
                    for (const requisitionId of requisitionIds) {
                        await PatchUsersOpenbankingRequisition.patch(user_id, requisitionId).then(() => { },
                            (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                        // save status of the requisition
                        await GetUsersOpenbankingRequisition.get(user_id, { requisition_id: requisitionId }).then(response => {
                            requisitionsStatus[requisitionId] = response.data.status;
                        },(error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }

                    // patch the balance of the accounts
                    for (const account of accounts) {
                        await PatchUsersOpenbankingAccountBalance.patch(user_id, { account_id: account.accountId }).then(() => { },
                            (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }

                    // get user's accounts
                    GetUsersOpenbankingAccount.get(user_id).then(
                        (response) => {
                            const accounts = response.data;

                            // aggregate by iban or bban
                            const aggregatedAccounts = [];

                            for (const account of accounts) {

                                /** sort out the balance of the account - with priority */
                                let balance = null;
                                const balancePriorities = ['interimAvailable', 'interimBooked', 'expected', 'forwardAvailable', 'openingBooked', 'closingBooked', 'nonInvoiced'];
                                balancePriorities.forEach(balancePriority => {
                                    balance = (balance) ? balance : account.balances.filter(x => x.balanceType === balancePriority)[0];
                                });


                                /** find the current account iban in the aggregated ones */
                                let index = -1;
                                if (account.iban) {
                                    index = aggregatedAccounts.map(x => x.iban).indexOf(account.iban);
                                }
                                else if (account.bban) {
                                    index = aggregatedAccounts.map(x => x.bban).indexOf(account.bban);
                                }

                                if (index === -1) {
                                    /** if could not find, push a brand new */

                                    aggregatedAccounts.push({
                                        name: account.institutionName,
                                        source: account.accountId,
                                        id: account.accountId,
                                        ownerName: account.ownerName || account.name || undefined,

                                        institutionId: account.institutionId,
                                        requisitionId: account.requisitionId,
                                        resourceId: account.resourceId,
                                        iban: account.iban,
                                        bban: account.bban,
                                        isMulticurrency: false,

                                        updatedAt: account.balancesUpdatedAt,

                                        balance: (balance && balance.balanceAmount) ? [{
                                            accountId: account.accountId,
                                            amount: balance.balanceAmount.amount,
                                            name: balance.balanceAmount.currencyName,
                                            ticker: balance.balanceAmount.currency,
                                            type: 'forex'
                                        }] : [],

                                        owners: account.owners,

                                        // get the status from the requisition
                                        status: requisitionsStatus[account.requisitionId],
                                        active: (requisitionsStatus[account.requisitionId] && requisitionsStatus[account.requisitionId] === 'LN') ? true : false,

                                        service: 'openbanking'
                                    });

                                } else {
                                    /** else push the balance in the account */
                                    if (balance && balance.balanceAmount) {
                                        aggregatedAccounts[index].balance.push({
                                            accountId: account.accountId,
                                            amount: balance.balanceAmount.amount,
                                            name: balance.balanceAmount.currencyName,
                                            ticker: balance.balanceAmount.currency,
                                            type: 'forex'
                                        });

                                        aggregatedAccounts[index].isMulticurrency = true;
                                        aggregatedAccounts[index].updatedAt = (account.updatedAt < aggregatedAccounts[index].updatedAt) ? account.updatedAt : aggregatedAccounts[index].updatedAt;
                                    }

                                }
                            }

                            // remove empty currencies in a multicurrency account
                            for (const account of aggregatedAccounts) {
                                if (account.isMulticurrency) {
                                    account.balance = account.balance.filter(x => x.amount > 0);
                                }
                            }

                            // save them in the storage
                            for (const account of aggregatedAccounts) {
                                commit('addConnection', account);
                            }

                            // procedure completed
                            commit('decreaseCallCounter'); // end wallets call

                        }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });


            /******************************************/
            /********* Complete the procedure *********/
            /******************************************/


            // decrease by 1 for technical reasons - because it started from 1 at the beginning
            commit('decreaseCallCounter'); 
        },


        // start legacy - not used
        createConnection({ commit }, params) {
            params.connectionId = (Math.random() + 1).toString(36).substring(2);
            commit('createConnection', params)
            commit('saveConnectionsToLocalStorage');
            return params.connectionId;
        },
        addAssetToConnection({ commit }, params) {
            commit('addAssetToConnection', params);
            commit('saveConnectionsToLocalStorage');
        },
        // end legacy - not used


        addOwnerToConnection({ commit }, params) {
            commit('addOwnerToConnection', params);
        },
        removeOwnerFromConnection({ commit }, params) {
            commit('removeOwnerFromConnection', params);
        }
    },
    mutations: {

        /** TODO: implement these with the backend */
        saveConnectionsToLocalStorage(state) {
            localStorage.setItem('connections', JSON.stringify(state.connections));
        },

        addConnection(state, connection) {
            state.connections.push(connection);
        },


        /***************************************** */
        /*****    MANAGEMENT OF ASYNC CALLS    *****/
        /***************************************** */


        increaseCallCounter(state) {
            state._concurrentCallsCounter += 1;
            state.maxConcurrentCallsCounter += 1;
        },

        decreaseCallCounter(state) {
            state._concurrentCallsCounter -= 1;
            if (state._concurrentCallsCounter === 0) {
                state.isLoaded = true;
            }
        },


        /***************************************** */
        /*****    MANAGEMENT OF CONNECTIONS    *****/
        /***************************************** */


        clearConnections(state) {
            state._concurrentCallsCounter = 1;
            state.maxConcurrentCallsCounter = 1;
            state.isLoaded = false;
            state.connections = [];
        },

        createConnection(state, params) {

            const name = params.name;
            const type = params.type;
            const connectionId = params.connectionId;

            state.connections.push({
                id: connectionId,
                name: name,
                type: type,
                assets: []
            });
        },

        addAssetToConnection(state, params) {

            const connectionId = params.connectionId;
            const name = params.name;
            const ticker = params.ticker;
            const amount = params.amount;
            const type = params.type;
            const purchasePrice = params.purchasePrice;

            state.connections.filter(item => {
                return item.id === connectionId
            })[0].assets.push({
                id: (Math.random() + 1).toString(36).substring(2),
                name: name,
                ticker: ticker,
                amount: amount,
                type: type,
                purchasePrice: purchasePrice
            });
        },


        addOwnerToConnection(state, params) {
            const connectionId = params.connectionId;
            const ownerId = params.ownerId;

            const index = state.connections.map(x => x.id).indexOf(connectionId);
            if (index > -1) {
                state.connections[index].owners.push(ownerId);
            }
        },

        removeOwnerFromConnection(state, params) {
            const connectionId = params.connectionId;
            const ownerId = params.ownerId;

            const index = state.connections.map(x => x.id).indexOf(connectionId);
            if (index > -1) {
                const indexOwner = state.connections[index].owners.indexOf(ownerId);
                if (indexOwner > -1) {
                    state.connections[index].owners.splice(indexOwner, 1);
                }
            }
        }

    },
    getters: {

        isLoaded(state) {
            /**
             *  
             *  true when all the connections (not the market data) are loaded from the backend
             *  false otherwise
             * 
             * */
            return state.isLoaded;
        },

        getProgress(state) {
            return (state.maxConcurrentCallsCounter - state._concurrentCallsCounter) / state.maxConcurrentCallsCounter * 100;
        },

        getAllConnections(state) {
            return JSON.parse(JSON.stringify(state.connections));
        },

        getConnections(thisState, thisGetters, rootStates, rootGetters) {

            thisGetters; // the getters of this module - ignore this
            rootStates;  // the state of all the modules - ignore this

            const currentView = rootGetters['views/getCurrentView'];

            // if the current view is the 'all', then return everything
            if (currentView === '64a6c39748a115cc1611781e') {
                return JSON.parse(JSON.stringify(thisState.connections));
            }

            const views = rootGetters['views/getViews'];
            const thisView = views.find(x => x._id === currentView); // find the current view

            if (thisView == null || thisView.owners == null) {
                return [];
            }

            const thisViewConnections = [];
            thisState.connections.forEach(connection => {

                if (connection.owners == null) {
                    return; // skip this connection
                }
                // check if at least one owner of the connection is present in this view owners
                const found = connection.owners.some(owner => thisView.owners.includes(owner));
                if (found) {
                    thisViewConnections.push(connection);
                }
            });

            return JSON.parse(JSON.stringify(thisViewConnections));
        }

    }
};

<template>
    <div class="custom-paddings-form">
        <filtered-list-component :list="filteredBanks"
                                 :placeholder="$t('connect.openbanking.bank_placeholder')"
                                 :title="$t('connect.openbanking.bank_title')" />
    </div>
</template>

<script>

    import FilteredListComponent from '@/components/fields/FilteredList';
    import PostRequisition from '@/services/users/openbanking/requisition/post.service';
    import EventBus from "@/common/EventBus";

    export default {
        name: "Select-Openbanking-Institution",
        components: {
            FilteredListComponent
        },
        computed: {
            financialInstitutions() {
                const institutions = this.$store.getters['platform/getFinancialInstitutions'];
                return (institutions) ? institutions : [];
            },

            /* FILTER INSTITUTIONS BY THE USER SELECTED NATION */

            filteredBanks() {
                const countryCode = this.$route.query.countryCode;
                const financialInstitutions = this.financialInstitutions;
                const filteredInstitutions = []

                if (countryCode && countryCode !== '') {
                    financialInstitutions.forEach(institution => {

                        // add custom tokens to the search to make it easy for users to find their bank
                        const customSearchTokens = [];
                        if (institution.name.toLowerCase().includes('bcc')) {
                            customSearchTokens.push(...['Banca', 'Credito', 'Banca di Credito Cooperativo']);
                        }
                        if (institution.name.toLowerCase().includes('banca di credito cooperativo')) {
                            customSearchTokens.push(...['BCC']);
                        }

                        if (institution.countries && institution.countries.includes(countryCode)) {
                            filteredInstitutions.push({
                                id: institution.id,
                                name: institution.name,
                                logo: institution.logo,
                                tokens: customSearchTokens
                            });
                        }
                    });
                } else {
                    return financialInstitutions;
                }

                return filteredInstitutions;

            }
        },
        methods: {
            /* THE ACTION TO TRIGGER WHEN THE CTA BUTTON OF THE FILTERED LIST IS PRESSED */
            cta_action(clicked_item_id, clicked_item_name) {

                // post the requisition
                const user_id = this.$store.getters['auth/getUserId'];
                const body = {
                    institution_id: clicked_item_id,
                    user_language: this.$store.getters['auth/getUserLanguage'],
                    institution_name: clicked_item_name
                }

                PostRequisition.post(user_id, body).then(
                    (response) => {
                        // redirect the user to the redirect url provided by nordigen
                        window.location.href = response.data.link;
                    },
                    (error) => {
                        this.loading = true;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                )
            }
        }
    };
</script>

<style scoped>
    .custom-paddings-form {
        display: flex;
        justify-content: center;
        margin: 32px auto auto auto;
        width: calc(100%-32px);
        max-width: 808px;
        background-color: var(--white);
        border-radius: var(--high-rounded);
        padding: 16px;
    }
</style>
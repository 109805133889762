import { createI18n } from 'vue-i18n'

import { it } from '@/assets/langs/it.js'
import { en } from '@/assets/langs/en.js'
import { fr } from '@/assets/langs/fr.js'
import { es } from '@/assets/langs/es.js'


/**
 * 
 * The best way to organize i18n is a category based approach
 * 
 * 
 * group the translation files by categories based on the usage
 * in the platform rather than the page they land on. 
 * 
 * In this way it is better to avoid duplicate code.
 * 
 * A native category approach divides the code into categories like:
 *      titles, bodies, tables, ...
 *      
 *      
 *      
 * However, due to the nature of this infrastructure, categories may
 * be intended as: dashboard, navigation, connect, ...
 * 
 * These are functional areas of the platform that are tightly coupled
 * internally, but loosely coupled among each other. I will mostly
 * reuse the text of one functional area inside the functional area
 * and not outside it. E.g. I will reuse navigation texts inside
 * the navigation components and not in the connect pages.
 * 
 * */



const dictionary = {
    en,
    it,
    fr,
    es
}

const locale = (navigator.language || navigator.userLanguage).split('-')[0];

const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages: dictionary
});

export { i18n };
<template>
    <div id="account-delete-page">
        <h1 class="headline-small black">
            {{ $t('actions.delete_account_warning') }}
        </h1>
        <button-eliminate-animation-component />
        <p class="body-large black">
            {{ $t('actions.delete_account_message') }}
        </p>
        <div class="input-fields">
            <input-field-component v-model="inputValue.doubleCheck"
                                   type="text"
                                   :label="$t('actions.type_here')"
                                   :isValid="isValid.doubleCheck" />
        </div>
        <div class="horizontal-frame">
            <button class="primary full-rounded"
                    @click="deleteAccountForReal">
                <p class="label-large">
                    {{ $t('actions.delete_button') }}
                </p>
            </button>
            <button class="tertiary full-rounded"
                    @click="goToProfile">
                <p class="label-large">
                    {{ $t('actions.abort_button') }}
                </p>
            </button>
        </div>
    </div>
</template>

<script>
    import ButtonEliminateAnimationComponent from '@/components/lottieImages/ClickHere';
    import InputFieldComponent from '@/components/fields/InputField';

    import DeleteUsersProfile from '@/services/users/profile/delete.service';

    import { i18n } from '@/plugins/i18n';

    export default {
        name: 'Goodbye-View',
        components: {
            ButtonEliminateAnimationComponent,
            InputFieldComponent
        },
        data() {
            return {
                inputValue: {
                    doubleCheck: ''
                },
                isValid: {
                    doubleCheck: true
                }
            };
        },
        methods: {
            goToProfile() {
                this.$router.push('/profile');
            },

            deleteAccountForReal() {

                const finalCheck = i18n.global.t('actions.delete_account_boolean_check');

                if (this.inputValue.doubleCheck !== finalCheck) {
                    this.isValid.doubleCheck = false;
                    return;
                } else {
                    this.isValid.doubleCheck = true;
                }

                const user_id = this.$store.getters['auth/getUserId'];
                DeleteUsersProfile.delete(user_id).then(() => {

                    this.$router.push('/logout');

                }).catch(() => {

                    this.$router.push('/logout');
                });
            }
        },
    }
</script>

<style scoped>
    div#account-delete-page {
        width: calc(100% - 32px);
        max-width: 808px;
        margin: 32px auto 32px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        padding: 0px 16px;
    }

        div#account-delete-page > div.input-fields {
            width: calc(100% - 32px);
            max-width: 408px;
            display: flex;
            padding: 16px;
            background-color: var(--white);
            border-radius: var(--high-rounded);
        }

        div#account-delete-page > div.horizontal-frame {
            display: flex;
            justify-content: center;
            gap: 16px;
        }
</style>
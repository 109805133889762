import api from '@/services/api';

class GetUsersOpenbankingAccount {

  get(userId, query) {

    /*

    query is a json like this:


    {
      account_id: abcdefghifle
      requisition_id: abcdefghifle
    }

    */

    let url = '/v1/users/'+ userId +'/openbanking/account/'

    if( query ) {
        if( query.account_id && query.requisition_id ) {
            url += '?account_id=' + query.account_id + '&requisition_id=' + query.requisition_id;
        } else if ( query.account_id ) {
            url += '?account_id=' + query.account_id;
        } else if ( query. requisition_id ) {
            url += '?requisition_id=' + query.requisition_id;
        }
    }

    return api.get(url);
  }
}

export default new GetUsersOpenbankingAccount();

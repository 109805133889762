import { createWebHistory, createRouter } from "vue-router";


// lazy-loaded


// not-lazy-loaded
import Login from './views/auth/Login.vue';
import Confirm from "./views/auth/Confirm.vue";
import Register from "./views/auth/Register.vue";
import RegisterSuccess from "./views/auth/RegisterSuccess.vue";

const ResetPassword = () => import("./views/auth/password_recovery/ResetPassword.vue")
const RequestPasswordReset = () => import("./views/auth/password_recovery/RequestPasswordReset.vue")

//connect
import Connect from './views/Connect.vue';
// special connection page accessed from the rewards page
import ConnectConnections from './views/connect/Connections.vue';
// connect cash manual
import ConnectCashManual from './views/connect/cash/Manual.vue';
import ConnectCashManualSuccess from './views/connect/cash/manual/Success.vue';
// connect crypto exchange
import ConnectCryptoExchange from './views/connect/crypto/Exchange.vue';
import ConnectCryptoExchangeSuccess from './views/connect/crypto/exchange/Success.vue';
import ConnectCryptoExchangeWarning from './views/connect/crypto/exchange/Warning.vue';
import ConnectCryptoExchangeError from './views/connect/crypto/exchange/Error.vue';
// connect crypto wallet
import ConnectCryptoWallet from './views/connect/crypto/Wallet.vue';
import ConnectCryptoWalletSuccess from './views/connect/crypto/wallet/Success.vue';
import ConnectCryptoWalletWarning from './views/connect/crypto/wallet/Warning.vue';
import ConnectCryptoWalletError from './views/connect/crypto/wallet/Error.vue';
// connect crypto manual
import ConnectCryptoManual from './views/connect/crypto/Manual.vue';
import ConnectCryptoManualSuccess from './views/connect/crypto/manual/Success.vue';
// connect openbanking
import SelectCountry from './views/connect/openbanking/SelectCountry.vue';
import SelectBank from './views/connect/openbanking/SelectBank.vue';
import BankConnected from './views/connect/openbanking/connected/BankConnected.vue';
import BankConnectedSuccess from './views/connect/openbanking/connected/BankConnectedSuccess.vue';
import ConnectOpenbankingError from './views/connect/openbanking/connected/Error.vue';
// connect stocks manual
import ConnectStocksManual from './views/connect/stocks/Manual.vue';
import ConnectStocksManualSuccess from './views/connect/stocks/manual/Success.vue';
//import ConnectCryptoSuccess from './views/connect/manual/crypto/Success.vue';

import Dashboard from "./views/Dashboard.vue";

import Support from './views/Support.vue';

import Rewards from './views/Rewards.vue';

import Profile from './views/Profile.vue';


// status
import Page404 from "./views/status/404.vue";
//import Page503 from "./views/status/503.vue";
import Logout from "./views/status/Logout.vue";
import Goodbye from "./views/status/Goodbye.vue";
import Download from "./views/status/Download.vue";
import TermsAndConditions from "./views/status/TermsAndConditions.vue";
import RedirectToDashboard from "./views/status/RedirectToDashboard.vue";

const routes = [
    {
        path: "/goodbye",
        name: "Delete Account",
        component: Goodbye,
        meta: { title: '360Track | Delete Account' }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { title: '360Track | Login' }
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: { title: '360Track | Register' }
    },
    {
        path: "/register/success",
        name: "Successful Registration",
        component: RegisterSuccess,
        meta: { title: '360Track | Register Success' }
    },
    {
        path: "/confirm/:confirmationCode",
        name: "Confirm",
        component: Confirm,
        meta: { title: '360Track | Confirm Register' }
    },
    {
        path: "/reset-password/:resetToken/:id",
        name: "Reset Password",
        component: ResetPassword,
        meta: { title: '360Track | Reset Password' }
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        component: RequestPasswordReset,
        meta: { title: '360Track | Request Reset Password' }
    },
    {
        path: "/rewards",
        name: "Rewards",
        component: Rewards
    },
    {
        path: "/dashboard/:pageId?",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/support",
        name: "Support",
        component: Support,
    },
    {
        path: "/connect",
        name: "Connect",
        component: Connect,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/connect/connection",
        name: "Connect Connections",
        component: ConnectConnections,
    },
    {
        path: "/connect/cash/manual",
        name: "Connect Cash Manual",
        component: ConnectCashManual
    },
    {
        path: "/connect/cash/manual/success",
        name: "Connect Cash Manual Success",
        component: ConnectCashManualSuccess
    },
    {
        path: "/connect/crypto/manual",
        name: "Connect Crypto Manual",
        component: ConnectCryptoManual
    },
    {
        path: "/connect/crypto/manual/success",
        name: "Connect Crypto Manual Success",
        component: ConnectCryptoManualSuccess
    },
    {
        path: "/connect/stocks/manual",
        name: "Connect Stocks Manual",
        component: ConnectStocksManual
    },
    {
        path: "/connect/stocks/manual/success",
        name: "Connect Stocks Manual Success",
        component: ConnectStocksManualSuccess
    },
    {
        path: "/connect/crypto/exchange",
        name: "Connect Crypto Exchange",
        component: ConnectCryptoExchange
    },
    {
        path: "/connect/crypto/exchange/success",
        name: "Connect Crypto Exchange Success",
        component: ConnectCryptoExchangeSuccess
    },
    {
        path: "/connect/crypto/exchange/warning",
        name: "Connect Crypto Exchange Warning",
        component: ConnectCryptoExchangeWarning
    },
    {
        path: "/connect/crypto/exchange/error",
        name: "Connect Crypto Exchange Error",
        component: ConnectCryptoExchangeError
    },
    {
        path: "/connect/crypto/wallet",
        name: "Connect Crypto Wallet",
        component: ConnectCryptoWallet
    },
    {
        path: "/connect/crypto/wallet/success",
        name: "Connect Crypto Wallet Success",
        component: ConnectCryptoWalletSuccess
    },
    {
        path: "/connect/crypto/wallet/warning",
        name: "Connect Crypto Wallet Warning",
        component: ConnectCryptoWalletWarning
    },
    {
        path: "/connect/crypto/wallet/error",
        name: "Connect Crypto Wallet Error",
        component: ConnectCryptoWalletError
    },
    {
        path: "/connect/openbanking/country",
        name: "Select Country",
        component: SelectCountry,
    },
    {
        path: "/connect/openbanking/institution",
        name: "Select Bank",
        component: SelectBank,
    },
    {
        path: "/connect/openbanking/success",
        name: "Bank Connected",
        component: BankConnected
    },
    {
        path: "/connect/openbanking/success/success",
        name: "Bank Connected Success",
        component: BankConnectedSuccess
    },
    {
        path: '/connect/openbanking/error',
        name: 'Bank Error',
        component: ConnectOpenbankingError
    },
    /*
    {
        path: '/connect/manual/crypto/success',
        name: 'Connect Crypto Success',
        component: ConnectCryptoSuccess,
        meta: { title: '360Track | Connect Manual Crypto Success' }
    },*/


    /******************************** */
    /*****     STATUS ROUTES     **** */
    /******************************** */


    {
        path: "/",
        name: 'Redirect to Dashboard',
        component: RedirectToDashboard
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/download',
        name: 'Download',
        component: Download
    },
    {
        path: '/terms_and_conditions',
        name: 'Terms and Conditions',
        component: TermsAndConditions
    },
    {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: Page404,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        from;
        savedPosition;

        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
    }
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/forgot-password', '/register/success'];
    const authRequired = !publicPages.includes(to.path)
                            && !to.path.startsWith('/confirm/')
                            && !to.path.startsWith('/reset-password/');
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

/** SET THE CUSTOM TITLE TO EXTERNAL PAGE IF META TITLE PROPERTY IS PRESENT */

const DEFAULT_TITLE = '360Track';
router.afterEach((to) => {
    document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;

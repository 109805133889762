<template>
    <div class="connection-properties">
        <table>
            <tbody>


                <tr>
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                category
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.type') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium">
                            <connection-service-component :service="connection.service" />
                        </p>
                    </td>
                </tr>


                <tr v-if="connectionStatus">
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                cloud_done
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.status') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium">
                            <connection-status-component :status="connectionStatus" />
                        </p>
                    </td>
                </tr>


                <tr>
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                show_chart
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.value') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium black">
                            <connection-value-component :value="value" />
                        </p>
                    </td>
                </tr>


                <tr v-if="iban">
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                credit_card
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.iban') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium black">
                            {{ iban }}
                        </p>
                    </td>
                </tr>


                <tr v-if="bban">
                    <td class="minimum-width">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                credit_card
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.bban') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium black">
                            {{ bban }}
                        </p>
                    </td>
                </tr>


                <tr v-if="address">
                    <td>
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                dns
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.address') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <p class="body-medium black crypto-wallet-address">
                            {{ address }}
                        </p>
                    </td>
                </tr>


                <tr>
                    <td class="minimum-width align-top">
                        <div class="icon-cell">
                            <span class="material-icons black title-medium">
                                people
                            </span>
                            <p class="body-medium black">
                                {{ $t('tables.owners') }}
                            </p>
                        </div>
                    </td>
                    <td>
                        <linked-owners-multiselect-field-component :connection="connection" />
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- prompt to refresh an expired connection to the bank account -->
        
        <template v-if="connection.service === 'openbanking' &&
                        currentConnection &&
                        !currentConnection.active">

            <hr class="primary" />

            <div class="nudge-to-refresh-connection"
                 @click="refreshRequisition">

                <span class="material-icons-outlined white">
                    report_problem
                </span>

                <div>
                    <p class="body-small white">
                        {{ $t('dashboard.connection_details.your_connection_expired') }}
                    </p>

                    <p class="body-small white">
                        <span class="white bold">
                            {{ $t('dashboard.connection_details.click_here') }}
                        </span>
                        {{ $t('dashboard.connection_details.to_refresh_it') }}
                    </p>
                </div>

            </div>

        </template>

    </div>
</template>

<script>

    import ConnectionValueComponent from '@/components/dashboard/table/row/connection/ConnectionValue';
    import ConnectionServiceComponent from '@/components/dashboard/table/row/connection/ConnectionService';
    import ConnectionStatusComponent from '@/components/dashboard/table/row/connection/ConnectionStatus';
    import LinkedOwnersMultiselectFieldComponent from '@/components/dashboard/page/properties/connection/LinkedOwnersMultiselectField';

    import PostRequisition from '@/services/users/openbanking/requisition/post.service';
    import EventBus from "@/common/EventBus";

    export default {
        name: 'Connection-Properties-Component',
        props: ['connection'],
        components: {
            ConnectionServiceComponent,
            ConnectionValueComponent,
            ConnectionStatusComponent,
            LinkedOwnersMultiselectFieldComponent
        },
        methods: {
            refreshRequisition() {
                
                const oldRequisition = this.currentConnection.requisitionId;
                const userLanguage = this.$store.getters['auth/getUserLanguage'];
                const institutionId = this.currentConnection.institutionId;
                const institutionName = this.currentConnection.name;

                // post the requisition
                const user_id = this.$store.getters['auth/getUserId'];
                const body = {
                    institution_id: institutionId,
                    user_language: userLanguage,
                    institution_name: institutionName,
                    old_requisition: oldRequisition
                }

                PostRequisition.post(user_id, body).then(
                    (response) => {
                        // redirect the user to the redirect url provided by nordigen
                        window.location.href = response.data.link;
                    },
                    (error) => {
                        this.loading = true;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            }
        },
        computed: {
            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            currentConnection() {
                
                const index = this.connections.map(x => x.id).indexOf(this.connection.id);

                if (index === -1) {
                    return undefined;
                }

                return this.connections[index];
            },
            iban() {
                return (this.currentConnection) ? this.currentConnection.iban : undefined;
            },
            bban() {
                return (this.currentConnection) ? this.currentConnection.bban : undefined;
            },
            address() {
                return (this.currentConnection) ? this.currentConnection.address : undefined;
            },
            connectionStatus() {

                if (this.currentConnection == null ||
                    typeof this.currentConnection.active === 'undefined') {
                    return undefined;
                }

                if (this.currentConnection.status && this.currentConnection.status !== 'LN') {
                    return 'expired';
                }

                return this.currentConnection.active ? 'active' : 'inactive';
            },
            value() {

                let value = 0;

                if (this.currentConnection == null) {
                    return undefined;
                }

                this.currentConnection.balance.forEach(asset => {

                    let ticker = asset.ticker;
                    if (asset.type === 'forex') {
                        ticker += '/USD';
                    }
                    ticker = asset.staking_ticker || ticker;

                    if (ticker && this.markets[ticker]) {
                        value += this.markets[ticker].price * asset.amount;
                    }
                });

                return value;
            }
        }
    }
</script>


<style scoped>
    div.connection-properties {
        width: calc(100% - 104px);
        padding: 8px 52px;
        margin: 8px 0px;
        border-bottom: 1px solid var(--placeholder);
    }
    p.crypto-wallet-address {
        max-width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: 904px) {
        div.connection-properties {
            width: calc(100% - 48px);
            padding: 8px 24px;
        }
        p.crypto-wallet-address {
            max-width: 40vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    table {
        width: 100%;
    }

        table > tbody > tr > td {
            vertical-align: middle;
        }
        table > tbody > tr > td.align-top {
            vertical-align: top;
        }

            table > tbody > tr > td.minimum-width {
                width: 172px;
            }

            table > tbody > tr > td > div.icon-cell {
                display: flex;
                align-items: center;
                gap: 4px;
                width: fit-content;
                margin-top: 4px;
                margin-bottom: 4px;
            }


        div.connection-properties > div.nudge-to-refresh-connection {
            border-radius: var(--high-rounded);
            background-color: var(--error);
            width: fit-content;
            padding: 8px 16px;
            display: flex;
            gap: 8px;
            align-items: center;
            cursor: pointer;
            margin-top: 8px;
        }
</style>
<template>
    <template v-if="isLoaded">
        <div class="table-wrapper">

            <navigation-menu-component v-if="showTableNavigation"
                                       :table="table"
                                       :page="page"
                                       :path="path" />
            <asset-view-mobile-component v-if="activeItem === 'asset' &&
                                               $breakpoints.isXS()"
                                         :table="table"
                                         :page="page"
                                         :path="path"
                                         :filter="activeFilter" />
            <asset-view-component v-else-if="activeItem === 'asset'"
                                  :table="table"
                                  :page="page"
                                  :path="path"
                                  :filter="activeFilter" />
            <connection-view-mobile-component v-if="activeItem === 'connection' &&
                                                    $breakpoints.isXS()"
                                              :table="table"
                                              :page="page"
                                              :path="path"
                                              :filter="activeFilter" />
            <connection-view-component v-else-if="activeItem === 'connection'"
                                       :table="table"
                                       :page="page"
                                       :path="path"
                                       :filter="activeFilter" />

        </div>
    </template>
    <!--
        <template v-else-if="isLoaded && isEmpty">

            <empty-table-view-component :table="table"
                                        :page="page"
                                        :path="path" />

        </template>
    -->
    <template v-else>

        <skeleton-full-table-component />

    </template>
</template>

<script>

    //import EmptyTableViewComponent from '@/components/dashboard/table/views/EmptyTableView';
    import SkeletonFullTableComponent from '@/components/dashboard/table/skeleton/FullTable';

    import NavigationMenuComponent from '@/components/dashboard/table/NavigationMenu';
    import AssetViewComponent from '@/components/dashboard/table/views/AssetView';
    import AssetViewMobileComponent from '@/components/dashboard/table/views/AssetViewMobile';
    import ConnectionViewComponent from '@/components/dashboard/table/views/ConnectionView';
    import ConnectionViewMobileComponent from '@/components/dashboard/table/views/ConnectionViewMobile';

    export default {
        name: 'Table-Component',
        props: ['table', 'page', 'path'],
        components: {
            //EmptyTableViewComponent,
            AssetViewComponent,
            AssetViewMobileComponent,
            NavigationMenuComponent,
            ConnectionViewComponent,
            ConnectionViewMobileComponent,
            SkeletonFullTableComponent
        },
        data() {
            return {
                activeItem: (this.table &&
                    this.table.properties &&
                    this.table.properties.menuPreference)
                    ? this.table.properties.menuPreference
                    : 'asset',
                activeFilter: 'all'
            }
        },
        methods: {
            setActiveItem(activeItem) {
                this.activeItem = activeItem;
            },
            setActiveFilter(activeFilter) {
                this.activeFilter = activeFilter;
            }
        },
        computed: {
            isLoaded() {
                return this.$store.getters['markets/isLoaded'];
            },
            /*
            connections() {
                return this.$store.getters['connections/getConnections'];
            },*/
            showTableNavigation() {
                return this.table.properties.showNavigation;
            },
            /*
            isEmpty() {

                if (this.table.properties.allConnections) {
                    if (this.connections.length === 0) {
                        return true;
                    }
                    return false;
                }

                for (let i = 0; i < this.connections.length; i++) {
                    if (this.table.connections.includes(this.connections[i].id)) {
                        return false;
                    }
                }
                return true;
            }
            */
        }
    }
</script>
<template>
    <div class="context-menu" :style="cssProps" v-click-outside="hideContextMenu">
        <input class="body-medium black"
               type="text"
               placeholder="Cerca qui..."
               ref="inputField"
               @keyup="filterInputList" />

        <div class="context-menu-item"
             v-if="filterList.turnToH1"
             @click="transformInto('h1')">
            <p class="body-medium white">
                {{ $t('dashboard.transform_in_h1') }}
            </p>
        </div>

        <div class="context-menu-item"
             v-if="filterList.turnToH2"
             @click="transformInto('h2')">
            <p class="body-medium white">
                {{ $t('dashboard.transform_in_h2') }}
            </p>
        </div>

        <div class="context-menu-item"
             v-if="filterList.turnToH3"
             @click="transformInto('h3')">
            <p class="body-medium white">
                {{ $t('dashboard.transform_in_h3') }}
            </p>
        </div>

        <div class="context-menu-item"
             v-if="filterList.turnToParagraph"
             @click="transformInto('p')">
            <p class="body-medium white">
                {{ $t('dashboard.transform_in_paragraph') }}
            </p>
        </div>

        <div class="section-divider"
             v-if="(filterList.turnToH1 ||
                   filterList.turnToH2 ||
                   filterList.turnToH3 ||
                   filterList.turnToParagraph) &&
                   (filterList.addTable ||
                   filterList.deleteRow)" />

        <div class="context-menu-item"
             v-if="filterList.addTable"
             @click="createTableBelow">
            <p class="body-medium white">
                {{ $t('dashboard.add_table') }}
            </p>
        </div>

        <div class="context-menu-item"
             v-if="filterList.addRow"
             @click="createRowBelow('p')">
            <p class="body-medium white">
                {{ $t('dashboard.add_row') }}
            </p>
        </div>

        <div class="section-divider"
             v-if="(filterList.turnToH1 ||
                   filterList.turnToH2 ||
                   filterList.turnToH3 ||
                   filterList.turnToParagraph ||
                   filterList.addTable) &&
                   filterList.deleteRow" />

        <div class="context-menu-item"
             v-if="filterList.deleteRow"
             @click="deleteRow">
            <p class="body-medium white">
                {{ $t('dashboard.delete_row') }}
            </p>
            <p class="body-medium neutral">
                {{ $t('dashboard.del') }}
            </p>
        </div>
    </div>
</template>

<script>

    import { nextTick } from 'vue';

    export default {
        name: 'Context-Menu-Component',
        props: ['show', 'page', 'path', 'metatype', 'pagePurpose'],
        data() {
            return {
                isFullyShown: false,
                filterList: {}, // initialized in the watch method
                filterMap: [
                    {
                        token: 'trasforma in h1',
                        element: 'turnToH1',
                        metatypes: ['text'],
                        pagePurposes: ['general', 'asset', 'connection', 'owner']
                    },
                    {
                        token: 'trasforma in h2',
                        element: 'turnToH2',
                        metatypes: ['text'],
                        pagePurposes: ['general', 'asset', 'connection', 'owner']
                    },
                    {
                        token: 'trasforma in h3',
                        element: 'turnToH3',
                        metatypes: ['text'],
                        pagePurposes: ['general', 'asset', 'connection', 'owner']
                    },
                    {
                        token: 'trasforma in paragrafo',
                        element: 'turnToParagraph',
                        metatypes: ['text'],
                        pagePurposes: ['general', 'asset', 'connection', 'owner']
                    },
                    {
                        token: 'aggiungi tabella',
                        element: 'addTable',
                        metatypes: ['text', 'table'],
                        pagePurposes: ['general']
                    },
                    {
                        token: 'aggiungi riga',
                        element: 'addRow',
                        metatypes: ['text', 'table',
                            'portofliosDistribution',
                            'assetGraph', 'companyProfile',
                            'companyValuationMetrics',
                            'companyDividends',
                            'insiderTransactions'],
                        pagePurposes: ['general', 'asset', 'connection', 'owner']
                    },
                    {
                        token: 'cancella riga',
                        element: 'deleteRow',
                        metatypes: ['text', 'table',
                            'portofliosDistribution',
                            'assetGraph', 'companyProfile',
                            'companyValuationMetrics',
                            'companyDividends',
                            'insiderTransactions'],
                        pagePurposes: ['general', 'asset', 'connection', 'owner']
                    }
                ]
            }
        },
        computed: {
            cssProps() {
                return {
                    display: (this.show) ? 'block' : 'none'
                };
            }
        },
        watch: {

            /**
             * Setup a watcher to detect the popup to be fully shown.
             * 
             * 
             * It is necessary because otherwise the popup should not work until
             * it is actually shown and fully loaded in all its html parts.
             * 
             * 
             * */

            show: function (newValue, oldValue) {
                if (newValue === true && oldValue === false) {

                    // initialize filter list
                    this.initializeFilterList();

                    // set the focus to the input
                    nextTick(() => {
                        this.$refs.inputField.focus();
                    })

                    // set the timer to wait for the component to be fully loaded
                    setTimeout(() => {
                        this.isFullyShown = true;
                    }, 100);

                } else {
                    this.isFullyShown = false;
                }
            }
        },
        methods: {
            hideContextMenu() {

                if (this.show && this.isFullyShown) {
                    this.$refs.inputField.value = '';
                    this.$parent.hideContextMenu();
                }
            },


            /****************************************** */
            /****    FILTER MECHANISM OF THE LIST    ****/
            /****************************************** */

            initializeFilterList() {
                this.filterList = {
                    turnToH1: false,
                    turnToH2: false,
                    turnToH3: false,
                    turnToParagraph: false,
                    addTable: false,
                    addRow: false,
                    deleteRow: false
                };

                this.filterMap.forEach(item => {
                    if (item.metatypes.includes(this.metatype) &&
                        item.pagePurposes.includes(this.pagePurpose)) {
                        this.filterList[item.element] = true;
                    }
                });
            },

            filterInputList(keyboardEvent) {
                const value = keyboardEvent.target.value.toLowerCase();

                // escape condition if the value is not defined or empty
                if (value == null || value === '') {
                    return this.initializeFilterList();
                }

                this.filterMap.forEach(item => {
                    const tokens = item.token.split(' ');
                    for (let i = 0; i < tokens.length; i++) {
                        if (tokens[i].startsWith(value)) {
                            this.filterList[item.element] = true;
                            break;
                        } else {
                            this.filterList[item.element] = false;
                        }
                    }
                });
            },

            /*************************************************** */
            /***   LOGIC WHEN CLICKING THE ITEMS OF THE MENU   ***/
            /*************************************************** */

            deleteRow() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });

                this.hideContextMenu();
            },

            transformInto(newType) {

                this.$store.dispatch('pages/updateType', {
                    page: this.page,
                    path: this.path,
                    type: newType
                });

                this.hideContextMenu();
            },

            createTableBelow() {
                
                this.$store.dispatch('pages/createTableBelow', {
                    page: this.page,
                    path: this.path
                });

                this.hideContextMenu();
            },

            createRowBelow(type) {

                this.$store.dispatch('pages/createRowBelow', {
                    page: this.page,
                    path: this.path,
                    type: type,
                    text: ''
                });

                this.hideContextMenu();
            }


        }
    }
</script>

<style scoped>
    div.context-menu {
        background-color: var(--primary);
        padding: 8px 0px 8px 0px;
        border-radius: 8px;
        max-height: 512px;
        min-width: 192px;

        position: absolute;
        z-index: 900;
        left: 56px;
    }

    div.context-menu input {
        margin: 4px 16px 4px 16px;
    }

    div.context-menu div.context-menu-item {
        display: flex;
        justify-content: space-between;
        margin: 2px 0px 2px 0px;
        padding: 2px 16px 2px 16px;
        cursor: pointer;
    }

    div.context-menu div.context-menu-item:hover {
        background-color: var(--placeholder);
    }

    div.context-menu div.section-divider {
        height: 1px;
        background-color: var(--neutral);
        margin: 4px 16px 4px 16px;
    }
</style>
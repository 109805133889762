

export default {

    it: {

        // result
        asset_successfully_added: "Asset aggiunto con successo",
        connection_successfully_established: 'Connessione stabilita con successo',
        asset_failing_connecting: "Errore durante la connessione",
        go_to_dashboard: "Vai alla Dashboard",
        add_more: "Aggiungi altro",
        connect_wallet: "Connetti Wallet",
        action_required: "Exchange connesso, ma nessun asset trovato",
        no_cryptocurrency_found: "Exchange come Binance o Crypto.com conservano le cripto nel loro wallet per ragioni di sicurezza. Non siamo riusciti a trovare cripto nell'exchange, prova ad aggiungerle tramite la pagina wallet",
        contact_the_support: 'Contatta il supporto',

        // nordigen errors
        institution_error: 'Errore dell\'istituzione',
        technical_error: 'Errore Tecnico di Nordigen',
        session_cancelled: 'Sessione cancellata',
        session_rejected: 'Sessione respinta',
        unknown_error: 'Errore sconosciuto',
    },

    es: {

        // result
        asset_successfully_added: "Activo añadido correctamente",
        connection_successfully_established: 'Conexión establecida con éxito',
        asset_failing_connecting: "Error al conectar",
        go_to_dashboard: "Ir a la Dashboard",
        add_more: "Añadir más",
        connect_wallet: "Connectar Wallet",
        action_required: "Exchange conectado, pero no se encuentran activos",
        no_cryptocurrency_found: "Exchanges come Binance or Crypto.com almacenan su cripto en un wallet por razones de seguridad. No pudimos encontrar ninguna cripto en el exchange, intente añadirlas a través de la página de lo wallet.",
        contact_the_support: 'Soporte de contacto',

        // nordigen errors
        institution_error: 'Error de la institución',
        technical_error: 'Error técnico de Nordigen',
        session_cancelled: 'Sesión Cancelada',
        session_rejected: 'Sesión rechazada',
        unknown_error: 'Error desconocido',
    },

    en: {

        // result
        asset_successfully_added: "Asset added successfully",
        connection_successfully_established: 'Connection established successfully',
        asset_failing_connecting: "Error connecting",
        go_to_dashboard: "Go to Dashboard",
        add_more: "Add more",
        connect_wallet: "Connect Wallet",
        action_required: "Exchange connected, but no assets found",
        no_cryptocurrency_found: "Exchanges like Binance or Crypto.com store your crypto in a wallet for security reasons. We could not find any crypto on the exchange, try to add them through the wallet page.",
        contact_the_support: 'Contact the support',

        // nordigen errors
        institution_error: 'Institution Error',
        technical_error: 'Nordigen technical error',
        session_cancelled: 'Session cancelled',
        session_rejected: 'Session rejected',
        unknown_error: 'Unknown Error',
    },

    fr: {

        // result
        asset_successfully_added: "Actif ajouté avec succès",
        connection_successfully_established: 'Connexion établie avec succès',
        asset_failing_connecting: "Erreur de connexion",
        go_to_dashboard: "Aller à la Dashboard",
        add_more: "Ajouter plus",
        connect_wallet: "Connectez Wallet",
        action_required: "Exchange connecté, mais aucun actif n'a été trouvé",
        no_cryptocurrency_found: "Exchanges comme Binance ou Crypto.com stockent votre crypto dans un portefeuille pour des raisons de sécurité. Nous n'avons trouvé aucune crypto-monnaie sur l'échange, essayez de les ajouter via la page du Wallet.",
        contact_the_support: 'Contactez l\'assistance',

        // nordigen errors
        institution_error: 'Erreur d\'établissement',
        technical_error: 'Erreur technique de Nordigen',
        session_cancelled: 'Session annulée',
        session_rejected: 'Session rejetée',
        unknown_error: 'Erreur inconnue',
    }

};
import api from '@/services/api';

class PlatformMarketsExchanges {

  get() {
    return api.get('/v1/platform/markets/exchanges/');
  }
}

export default new PlatformMarketsExchanges();



export default {

    it: {
        need_help: "Hai bisogno di aiuto?",
        text_help_frame: "Per noi è sempre un piacere poter aiutare o ricevere feedback.",
        call_us: "Chiamaci",
        meet_us: "Videochiamata",
        write_us: "Scrivici",

        firefox_problem_title: "Siamo a conoscenza di alcuni problemi grafici sul browser Mozilla Firefox",
        firefox_problem_text: "Per una migliore esperienza consigliamo di utilizzare Google Chrome",
    },

    es: {
        need_help: "¿Necesitas ayuda?",
        text_help_frame: "Estamos aquí para ayudar de cualquiera manera que podemos.",
        call_us: "Llámenos",
        meet_us: "Videollamada",
        write_us: "Escríbenos",

        firefox_problem_title: "Somos conscientes de algunos problemas gráficos en el navegador Mozilla Firefox",
        firefox_problem_text: "Para una mejor experiencia, recomendamos usar Google Chrome",
    },

    en: {
        need_help: "Need help?",
        text_help_frame: "We are here to help you and hear your feedbacks.",
        call_us: "Call us",
        meet_us: "Meet us",
        write_us: "Write us",

        firefox_problem_title: "We are aware of some graphical issues on the Mozilla Firefox browser",
        firefox_problem_text: "For a better experience we recommend using Google Chrome",
    },

    fr: {
        need_help: "Avez-vous besoin d'aide ?",
        text_help_frame: "Nous sommes ici pour vous aider et connaître votre avis.",
        call_us: "Appelez-nous",
        meet_us: "Appel vidéo",
        write_us: "Écrivez-nous",

        firefox_problem_title: "Nous sommes conscients de certains problèmes graphiques sur le navigateur Mozilla Firefox",
        firefox_problem_text: "Pour une meilleure expérience, nous vous recommandons d'utiliser Google Chrome",
    }

};
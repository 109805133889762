<template>
    <div @click="appendSection">

        <h3 class="title-small black">
            {{ title }}
        </h3>
        <span class="material-icons black title-large">
            {{ icon }}
        </span>

    </div>
</template>

<script>
    export default {
        name: 'Add-Section-Item-Component',
        props: ['title', 'value', 'icon'],
        emits: ['itemClicked'],
        methods: {
            appendSection() {
                this.$emit('itemClicked', this.value);
            }
        }
    }
</script>

<style scoped>

    div {
        height: 72px;
        width: 96px;
        border-radius: var(--high-rounded);
        border: 1px dashed var(--primary);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4px;
    }
</style>
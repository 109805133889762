<template>


    <div class="wrapper">
        <div :class="{
                'owner-card': true,
                'clickable': !isLocked
             }"
             @click="goToOwnerPage">
            <div class="owner-title">
                <div>
                    <span class="material-icons title-large white">
                        account_circle
                    </span>
                    <p class="title-medium white">
                        {{ owner.nickname }}
                    </p>
                </div>

                <span v-if="isLocked"
                      class="material-icons title-medium white">
                    lock
                </span>
                <span v-else
                      class="material-icons title-medium white clickable">
                    edit
                </span>
            </div>
            <div>
                <p class="body-medium white">
                    {{ ownerConnectionsNumber }}
                    {{ $t('profile.connections') }}
                </p>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'Connection-Card-Component',
        props: ['owner'],
        computed: {

            pages() {
                return this.$store.getters['pages/getPages'];
            },
            connections() {
                return this.$store.getters['connections/getConnections'];
            },

            ownerConnectionsNumber() {

                let ownerConnectionsNumber = 0;

                this.connections.forEach(connection => {
                    if (connection.owners &&
                        connection.owners.includes(this.owner._id)) {
                        ownerConnectionsNumber += 1;
                    }
                });

                return ownerConnectionsNumber;
            },

            isLocked() {
                return false;
                //return this.owner._id === this.$store.getters['views/getUserPersonalOwnerId'];
            }
        },
        methods: {
            goToOwnerPage() {

                if (this.isLocked) {
                    return; // do not go to personal page
                }

                const ownerPageId = 'o' + this.owner._id;

                // go to the page
                if (ownerPageId in this.pages) {

                    this.$router.push('/profile?p=' + ownerPageId);

                } else {

                    // create the page - the go to it
                    const params = {
                        pageId: ownerPageId,
                        name: this.owner.nickname,
                        id: this.owner._id,
                        icon: undefined,
                    };
                    this.$store.dispatch('pages/createOwnerPage', params).then(() => {

                        this.$router.push('/profile?p=' + ownerPageId);
                    });
                }
            }
        },
    }
</script>

<style scoped>

    div.wrapper {
        display: flex;
        width: 100%;
    }

    @media(min-width: 600px) {
        div.wrapper {
            width: 400px;
        }
    }

    div.owner-card {
        display: flex;
        flex-direction: column;
        background-color: var(--primary);
        border-radius: var(--high-rounded);
        width: 100%;
        padding: 16px 32px;
        gap: 8px;
    }
    div.owner-card.clickable {
        cursor: pointer;
    }

        div.owner-card > div.owner-title {
            display: flex;
            justify-content: space-between;
        }

            div.owner-card > div.owner-title > div {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            div.owner-card > div.owner-title > span.material-icons.clickable {
                cursor: pointer;
            }

</style>
import api from '@/services/api';

class DeleteUsersOpenbankingAccount {

  delete(userId, accountId) {
    return api.delete('/v1/users/'+ userId +'/openbanking/account/' + accountId);
  }
}

export default new DeleteUsersOpenbankingAccount();

import api from '@/services/api';

class DeleteUsersProfile {

    delete(id) {
        return api.delete('/v1/users/' + id + '/profile');
    }
}

export default new DeleteUsersProfile();

<template>
    <div class="asset-location-card">
        <div>
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.units_in_your_portfolios') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>
        <div class="table-wrapper">
            <table class="branded-table">

                <thead>
                    <tr>
                        <th>
                            <p class="title-medium black bold">
                                {{ $t('dashboard.asset_details.portfolio') }}
                            </p>
                        </th>
                        <th>
                            <p class="title-medium black bold">
                                {{ $t('dashboard.asset_details.Units') }}
                            </p>
                        </th>
                        <th>
                            <p class="title-medium black bold">
                                {{ $t('dashboard.asset_details.percentage') }}
                            </p>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(item, index) in aggregatedAmounts"
                        :key="index"
                        @click="goToConnectionPage(item)">

                        <td>
                            <p class="body-medium black">
                                {{ item.portfolio }}
                            </p>
                        </td>
                        <td>
                            <p class="body-medium black">
                                <amount-component :amount="item.amount"
                                                  :precisionDigits="6" />
                            </p>

                        </td>
                        <td>
                            <p class="body-medium black">
                                {{ Math.round(item.percentage*10000)/100}}%
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
    import AmountComponent from '@/components/dashboard/table/row/asset/Amount';

    export default {
        name: 'Asset-Location-Card',
        props: ['asset', 'page', 'path'],
        components: {
            AmountComponent
        },
        methods: {
            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },

            routeToPage(connectionId) {


                if (this.$breakpoints.isLGOrLarger()) {

                    // if the screen is large enough, open it as a side panel
                    this.$router.push('/dashboard?p=' + connectionId);

                } else {

                    // else, just go to the page
                    this.$router.push('/dashboard/' + connectionId);
                }

            },

            goToConnectionPage(connection) {


                if (connection.id in this.pages) {

                    this.routeToPage(connection.id);

                } else {

                    // create the page - the go to it
                    const params = {
                        name: connection.portfolio,
                        id: connection.id,
                        icon: undefined,
                        service: connection.service,
                    };
                    this.$store.dispatch('pages/createConnectionPage', params).then(() => {
                        this.routeToPage(connection.id);
                    });
                }
            }
        },
        computed: {

            isLoaded() {
                return this.$store.getters['connections/isLoaded'];
            },
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
            tickerInformation() {
                const information = {
                    source_name: [],
                    source: [],
                    id: [],
                    service: [],
                    individual_amounts: [],
                    amount: 0
                };

                if (this.connections == null) {
                    return undefined;
                }

                this.connections.forEach(connection => {

                    const balances = connection.balance.filter(x => (x.staking_ticker || x.ticker) === this.asset.ticker);
                    if (balances.length === 0) {
                        return; // skip iteration
                    }

                    information.source_name.push(connection.name);
                    information.source.push(connection.source);
                    information.id.push(connection.id);
                    information.service.push(connection.service);
                    information.individual_amounts.push(balances.map(x => x.amount).reduce((a, b) => a + b, 0));
                    information.amount += balances.map(x => x.amount).reduce((a, b) => a + b, 0);
                });

                return information;
            },

            aggregatedAmounts() {

                if (!(this.tickerInformation) ||
                    !(this.tickerInformation.source_name.length > 0) ||
                    !(this.tickerInformation.individual_amounts.length > 0) ||
                    this.tickerInformation.source_name.length !== this.tickerInformation.individual_amounts.length ||
                    !(this.tickerInformation.amount)) {

                    return [];
                }

                const portfolios = this.tickerInformation.source_name;
                const sources = this.tickerInformation.source
                const ids = this.tickerInformation.id;
                const amounts = this.tickerInformation.individual_amounts;
                const total = this.tickerInformation.amount;
                const services = this.tickerInformation.service;
                const aggregated = [];

                for (let i = 0; i < portfolios.length; i++) {
                    aggregated.push({
                        portfolio: portfolios[i],
                        source: sources[i],
                        id: ids[i],
                        amount: amounts[i],
                        service: services[i],
                        percentage: amounts[i] / total,
                    });
                }

                return aggregated.sort((a, b) => b.percentage - a.percentage);
            }
        }
    }
</script>

<style scoped>

    div.asset-location-card {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

        div.asset-location-card > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            div.asset-location-card > div > span {
                cursor: pointer;
            }

    tbody > tr {
        cursor: pointer;
    }
</style>
<template>

    <div v-if="connectionOwnerNicknames"
         :class="{
             'display-labels-wrapper': true,
             'engaged': displaySuggestionList,
             'idle': !displaySuggestionList
         }"
         :style="cssProps"
         :ref="inputFieldId_"
         v-click-outside="hideSuggestionList">

        <template v-if="connectionOwnerNicknames.length > 0">


            <div class="display-labels-row"
                 v-for="(nickname, index) in connectionOwnerNicknames"
                 v-bind:key="nickname + '-' + index">


                <div class="display-labels"
                     :style="`background-color: ${colors[index%3]}`"
                     @click="labelItemClicked(index)">

                    <span v-if="!displaySuggestionList"
                          class="material-icons-outlined body-large black">
                        account_circle
                    </span>
                    <p class="body-medium black text-underlined">
                        {{ nickname }}
                    </p>
                    <span v-if="!displaySuggestionList"
                          class="material-icons body-large black">
                        arrow_outward
                    </span>
                    <span v-if="displaySuggestionList"
                          class="material-icons body-large black">
                        clear
                    </span>

                </div>

                <div v-if="!displaySuggestionList"
                     class="actions-tab">
                    <span class="material-icons body-large black"
                          @click="onRemoveItem(index)">
                        remove
                    </span>
                    <span v-if="$breakpoints.isMDOrLarger()"
                          class="material-icons body-large black"
                          @click="showSuggestionList">
                        add
                    </span>
                </div>


            </div>

            <div class="add-owner-mobile"
                 @click="showSuggestionList">
                <span class="material-icons body-large black">
                    add
                </span>
                <p class="label-large black">
                    {{ $t('dashboard.connection_details.add_holder') }}
                </p>
            </div>



        </template>
        <template v-else>


            <div class="no-owner-item"
                 @click="showSuggestionList">
                <span class="material-icons body-large placeholder">
                    add
                </span>
                <p class="label-large placeholder">
                    {{ $t('dashboard.connection_details.add_holder') }}
                </p>
            </div>


        </template>
        




        <!-- autocomplete list -->

        <div class="suggestions-list"
             v-if="displaySuggestionList">
            <div class="suggestion-item"
                 v-for="(suggestion, index) in suggestionList"
                 v-bind:key="index"
                 @click.prevent="onAddItem(suggestion.value)">

                <p class="body-medium black">
                    {{ suggestion.name }}
                </p>
                <span class="material-icons green body-large">
                    add
                </span>

            </div>

            <div class="add-new-owner-option"
                 @click.prevent="showAddNewOwnerModal">
                <p class="title-small bold">
                    {{ $t('dashboard.connection_details.add_holder') }}
                </p>
                <span class="material-icons body-large">
                    add
                </span>
            </div>

        </div>




    </div>



    <Teleport v-if="showModal" to="body">
        <add-owner-modal-component :show="showModal"
                                   @close="closeAddNewOwnerModal" />
    </Teleport>


</template>

<script>

    import AddOwnerModalComponent from '@/components/fields/owner/AddOwnerModal.vue';
    import { ownerColors } from '@/assets/js/fields/ownerColors';

    import PutUsersManualAsset from '@/services/users/manual/asset/put.service';
    import PutUsersCryptoExchanges from '@/services/users/crypto/exchanges/put.service';
    import PutUsersCryptoWalletAddress from '@/services/users/crypto/wallet/address/put.service';
    import PutUsersOpenbankingAccountOwners from '@/services/users/openbanking/account/owners/put.service';
    import EventBus from '@/common/EventBus';

    export default {
        name: 'Linked-Owners-Multiselect-Field',
        props: ['connection', 'id'],
        components: {
            AddOwnerModalComponent
        },
        data() {
            return {
                showModal: false,
                displaySuggestionList: false,


                // technical data fields
                inputBoxHeight_: 40,
                inputFieldId_: (this.id) ? this.id : this.randomString(),
                preventClosingSuggestionList_: false, // to prevent suggestion list to close via v-click-outside
            };
        },
        computed: {

            colors() {
                return ownerColors();
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            connections() {
                return this.$store.getters['connections/getConnections'];
            },

            currentConnection() {
                return this.connections ? this.connections.find(x => x.id === this.connection.id) : undefined;
            },
            currentConnectionOwnerIds() {
                return this.currentConnection ? this.currentConnection.owners : undefined;
            },

            connectionOwnerNicknames() {

                if (this.currentConnectionOwnerIds == null ||
                    this.owners == null) {
                    return [];
                }

                const ownerNicknames = [];

                this.currentConnectionOwnerIds.forEach(ownerId => {
                    const index = this.owners.map(x => x._id).indexOf(ownerId);
                    if (index > -1) {
                        ownerNicknames.push(this.owners[index].nickname);
                    }
                });

                return ownerNicknames;
            },


            // custom styling

            inputBoxHeight() {
                return this.$refs[this.inputFieldId_]
                    ? this.$refs[this.inputFieldId_].clientHeight
                    : 40;
            },
            cssProps() {
                return {
                    '--input-box-height': (this.inputBoxHeight_ + 4) + 'px'
                };
            },


            // list to display to the user to
            suggestionList() {

                if (this.currentConnectionOwnerIds == null) {
                    return [];
                }

                const suggestionList = [];

                // map all the owners
                const list = this.owners.map(x => {
                    return {
                        name: x.nickname,
                        value: x._id
                    };
                });

                // remove the ones in the model
                list.forEach(item => {
                    if (!this.currentConnectionOwnerIds.includes(item.value)) {
                        suggestionList.push(item);
                    }
                });

                // return the filtered list
                return suggestionList;
            },
        },
        methods: {
            randomString() {
                // generate a random 7 characters string
                return (Math.random() + 1).toString(36).substring(2);
            },
            refreshInputBoxHeight() {
                this.$nextTick(() => {
                    this.inputBoxHeight_ = this.$refs[this.inputFieldId_]
                        ? this.$refs[this.inputFieldId_].clientHeight
                        : 40;
                });
            },

            /** suggestion list management */

            hideSuggestionList() {
                if (this.preventClosingSuggestionList_) {
                    this.preventClosingSuggestionList_ = false;
                    return;
                }
                setTimeout(() => {
                    this.displaySuggestionList = false;
                    this.refreshInputBoxHeight();
                }, 128);
            },
            showSuggestionList() {
                setTimeout(() => {
                    this.displaySuggestionList = true;
                    this.refreshInputBoxHeight();
                }, 128);
            },

            /** modal management */

            showAddNewOwnerModal() {
                // hard close the suggestion list
                this.preventClosingSuggestionList_ = false;
                this.displaySuggestionList = false;

                // open the modal to add an owner
                this.showModal = true;
            },
            closeAddNewOwnerModal(newlyCreatedOwner) {

                this.showModal = false;

                // add new owner to the selected list
                this.$store.dispatch('connections/addOwnerToConnection', {
                    connectionId: this.connection.id,
                    ownerId: newlyCreatedOwner._id,
                }).then(() => {
                    this.updateConnectionOwnersBackend();
                });

                // hard open the suggestion list
                this.preventClosingSuggestionList_ = true;
                this.displaySuggestionList = true;
            },

            /** item in the field management */

            onAddItem(value) {
                if (this.displaySuggestionList) {
                    this.$emit('onAddItem', value);
                    this.$store.dispatch('connections/addOwnerToConnection', {
                        connectionId: this.connection.id,
                        ownerId: value,
                    }).then(() => {
                        this.updateConnectionOwnersBackend();
                    });
                    this.preventClosingSuggestionList_ = true;
                }
                this.refreshInputBoxHeight();
            },
            onRemoveItem(index) {

                const ownerId = this.currentConnectionOwnerIds[index];

                this.$store.dispatch('connections/removeOwnerFromConnection', {
                    connectionId: this.connection.id,
                    ownerId: ownerId,
                }).then(() => {
                    this.updateConnectionOwnersBackend();
                });
                if (this.showSuggestionList) {
                    this.preventClosingSuggestionList_ = true;
                }
                this.refreshInputBoxHeight();
            },

            // update the backend
            updateConnectionOwnersBackend() {

                let Service = null;
                switch (this.connection.service) {
                    case 'manual':
                        Service = PutUsersManualAsset;
                        break;
                    case 'exchange':
                        Service = PutUsersCryptoExchanges;
                        break;
                    case 'wallet':
                        Service = PutUsersCryptoWalletAddress;
                        break;
                    case 'openbanking':
                        Service = PutUsersOpenbankingAccountOwners;
                        break;
                    default:
                        return;
                }

                if (Service == null) {
                    return;
                }

                const userId = this.$store.getters['auth/getUserId'];
                const data = {
                    owners: this.currentConnectionOwnerIds
                };
                if (this.currentConnection.service === 'openbanking') {
                    data['accountId'] = this.currentConnection.id;
                } else {
                    data['source'] = this.currentConnection.source;
                }

                Service.put(userId, data).then(
                    () => {
                        // do nothing
                    },
                    (error) => {
                        this.loading = true;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    });
            },

            /** go to owner page */

            goToOwnerPageAtIndex(index) {

                if (this.displaySuggestionList) {
                    return; // the page browsing is disabled
                }


                // find the information of the owner
                const ownerId = this.currentConnectionOwnerIds[index];

                const ownerIndex = this.owners.map(x => x._id).indexOf(ownerId);
                if (ownerIndex < 0) {
                    return;
                }
                const currentOwner = this.owners[ownerIndex];
                const currentOwnerPageId = 'o' + currentOwner._id;
                // go to the page
                if (currentOwnerPageId in this.pages) {

                    this.pushOwnerPageRoute(currentOwnerPageId);

                } else {

                    // create the page - the go to it
                    const params = {
                        pageId: currentOwnerPageId,
                        name: currentOwner.nickname,
                        id: currentOwner._id,
                        icon: undefined,
                    };
                    this.$store.dispatch('pages/createOwnerPage', params).then(() => {

                        this.pushOwnerPageRoute(currentOwnerPageId);
                    });
                }
            },

            pushOwnerPageRoute(currentOwnerPageId) {


                if (this.$breakpoints.isLGOrLarger()) {

                    // if the screen is large enough, open it as a side panel

                    let path = this.$route.path + '?';

                    for (const [key, value] of Object.entries(this.$route.query)) {
                        if (key !== 'p') {
                            path += key + '=' + value + '&';
                        }
                    }

                    path += 'p=' + currentOwnerPageId;
                    this.$router.push(path);

                } else {

                    // else, just go to the page
                    this.$router.push('/dashboard/' + currentOwnerPageId);
                }
            },

            labelItemClicked(index) {
                if (this.displaySuggestionList) {
                    this.onRemoveItem(index);
                } else {
                    this.goToOwnerPageAtIndex(index);
                }
            }
        }
    }

</script>

<style scoped>

    div.no-owner-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: -2px;
    }

    div.display-labels-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 2px;
    }
    @media(min-width:600px) {
        div.display-labels-wrapper {
            width: 320px;
        }
    }

    div.engaged.display-labels-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
    }

        div.display-labels-wrapper > div.display-labels-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        div.display-labels-wrapper > div.add-owner-mobile {
            display: flex;
            align-items: center;
            display: none;
        }

        @media (max-width: 599px) {
            div.display-labels-wrapper > div.add-owner-mobile {
                display: flex;
            }
        }

        div.display-labels-wrapper > div.display-labels-row > div.display-labels {
        padding: 1px 8px;
        border-radius: var(--middle-rounded);
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 4px;
        align-items: center;
        cursor: pointer;
    }

        div.display-labels-wrapper.idle > div.display-labels-row > div.display-labels > .text-underlined {
            text-decoration: underline;
            text-decoration-color: var(--placeholder);
        }

    div.display-labels-wrapper > div.display-labels-row > div.actions-tab {
        display: flex;
        align-items: center;
        gap: 4px;
    }

        div.display-labels-wrapper > div.display-labels-row > div.actions-tab > span.material-icons {
            cursor: pointer;
        }

    @media(min-width: 600px) {
        div.display-labels-wrapper > div.display-labels-row:not(:hover) > div.actions-tab {
            display: none;
        }
    }

    div.display-labels-wrapper > div.display-labels-row > div.actions-tab > span.material-icons {
        border: 1px solid var(--black);
        border-radius: var(--middle-rounded);
    }

    /**************************************/
    /*****      SUGGESTIONS LIST      *****/
    /**************************************/

    div.suggestions-list {
        position: absolute;
        z-index: 50;
        margin: var(--input-box-height) auto auto -8px;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 50%;
        border: 1px solid var(--primary);
        background-color: var(--white);
    }

        div.suggestions-list > div.add-new-owner-option,
        div.suggestions-list > div.suggestion-item {
            padding: 2px 8px 2px 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            div.suggestions-list > div.suggestion-item:hover {
                background-color: var(--secondary);
                cursor: pointer;
            }


        div.suggestions-list > div.add-new-owner-option {
            color: var(--black);
            background-color: var(--tertiary);
        }


            div.suggestions-list > div.add-new-owner-option:hover {
                color: var(--black);
                background-color: var(--secondary);
                cursor: pointer;
            }

</style>
<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container custom-height">

                    <span class="material-icons modal-close-button" @click="closeModal">
                        close
                    </span>

                    <div class="filtered-list-component-container filter-list-component-custom-device-width">
                        <filtered-list-component :list="marketExchanges"
                                                 :placeholder="$t('connect.exchange_search')"
                                                 :title= "$t('connect.exchange_title')" />
                    </div>

                </div>
            </div>
        </div>
    </Transition>
</template>

<script>

    import FilteredListComponent from '@/components/fields/FilteredList';

    export default {
        name: 'Select-Exchange-Modal',
        props: ['show', 'currentSelection', 'allStockExchangesPlaceholderName'],
        data() {
            return {
                customTokenMapping: {
                    'IT': ['Milano'],
                    'US': ['USA', 'Stati Uniti']
                }
            };
        },
        components: {
            FilteredListComponent
        },
        computed: {
            marketExchanges() {
                const exchanges = this.$store.getters['platform/getMarketsExchanges'];
                const countries = this.$store.getters['platform/getCountries'];

                const allStockExchangesPlaceholder = {
                    id: '',
                    name: this.allStockExchangesPlaceholderName,
                    logo: require('@/assets/img/logos/nations/allWorld.png')
                };
                const processedExchanges = [];

                if (typeof exchanges === 'undefined') {
                    return [];
                }

                exchanges.forEach(exchange => {

                    // compute extra tokens useful for the user to search an exchange easily
                    const customSearchTokens = [exchange.country_code, exchange.country];
                    // take the extra custom tokens from the data of the file
                    if (this.customTokenMapping[exchange.country_code]) {
                        customSearchTokens.push(...this.customTokenMapping[exchange.country_code]);
                    }

                    // create array for the filtered list
                    processedExchanges.push({
                        id: exchange.code, // mic code
                        name: exchange.market_name + ' (' + exchange.code + ')',
                        logo: (countries) ? countries[exchange.country_code] : undefined,
                        tokens: customSearchTokens
                    });
                });

                // sort by name
                processedExchanges.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });

                return [allStockExchangesPlaceholder, ...processedExchanges];
            }
        },
        methods: {
            // callback from filtered list
            cta_action(clicked_item_id, clicked_item_name) {
                clicked_item_id;
                this.$parent.closeModalSelectExchange(clicked_item_name);
            },
            closeModal() {
                this.$parent.closeModalSelectExchange(this.currentSelection);
            }
        }
    }
</script>

<style scoped>
    /* override modal css */
    .modal-container.custom-height {
        height: 75vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    /* filtered list css */

    .filtered-list-component-container {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    @media (max-width: 904px) {
        .filter-list-component-custom-device-width {
            width: 100%;
        }
    }
    @media (min-width: 905px) {
        .filter-list-component-custom-device-width {
            width: 150%;
            margin-left: -25%;
        }
    }
</style>

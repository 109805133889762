<template>

    <form class="branded-input-form" @submit.prevent="saveCryptoExchangeApi">

        <div class="fields-frame">
            <input-field-autocomplete-component type="text"
                                                v-model="crypto_exchange_api.exchange_name"
                                                :label="$t('forms.type_your_exchange')"
                                                :isValid="isValid.exchange_name"
                                                :list="supported_crypto_exchanges_names" />
        </div>


        <div v-if="form_step === 0"
             class="buttons-frame">

            <button class="secondary full-rounded" @click.prevent="notifyParentInfoForm">
                <p class="label-large">
                    {{ $t('forms.continue') }}
                </p>
            </button>
        </div>

        <!-- owners -->

        <div v-if="form_step === 1" class="fields-frame">
            <add-owner-multiselect-field-component v-model="ownerIds"
                                                   @onAddItem="onAddItem"
                                                   @onRemoveItem="onRemoveItemIndex"
                                                   :isValid="isValid.ownerIds" />
        </div>

        <!-- Connection fields -->

        <div v-if="form_step === 1 && exchangeCredentials.apiKey" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.apiKey"
                                   type="text"
                                   id="apiKey"
                                   label="API Key"
                                   :isValid="isValid.apiKey" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.secret" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.secret"
                                   type="text"
                                   id="secret"
                                   label="Secret"
                                   :isValid="isValid.secret" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.uid" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.uid"
                                   type="text"
                                   id="uid"
                                   label="UID"
                                   :isValid="isValid.uid" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.login" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.login"
                                   type="text"
                                   id="login"
                                   label="Login"
                                   :isValid="isValid.login" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.password" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.password"
                                   type="text"
                                   id="password"
                                   label="Passphrase"
                                   :isValid="isValid.password" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.twofa" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.twofa"
                                   type="text"
                                   id="twofa"
                                   label="Two Factor Authentication"
                                   :isValid="isValid.twofa" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.privateKey" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.privateKey"
                                   type="text"
                                   id="privateKey"
                                   label="Private Key"
                                   :isValid="isValid.privateKey" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.walletAddres" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.walletAddress"
                                   type="text"
                                   id="walletAddress"
                                   label="Wallet Address"
                                   :isValid="isValid.walletAddress" />
        </div>

        <div v-if="form_step === 1 && exchangeCredentials.token" class="fields-frame">
            <input-field-component v-model="crypto_exchange_api.token"
                                   type="text"
                                   id="token"
                                   label="Token"
                                   :isValid="isValid.token" />
        </div>

        <div v-if="form_step === 1"
             class="buttons-frame">

            <!-- confirm button start -->
            <button class="tertiary full-rounded"
                    :disabled="isFormDisabled">
                <span v-if="isFormDisabled"
                      class="material-icons loading-icon">
                    sync
                </span>
                <p class="label-large">
                    {{ $t('forms.connect') }}
                </p>
            </button>
            <!-- confirm button end -->

        </div>

    </form>
</template>

<script>
    import EventBus from "@/common/EventBus";

    import InputFieldComponent from '@/components/fields/InputField';
    import AddOwnerMultiselectFieldComponent from '@/components/fields/owner/AddOwnerMultiselectField';
    import InputFieldAutocompleteComponent from '@/components/fields/autocomplete/InputFieldAutocomplete';

    import DeleteUsersCryptoExchanges from "@/services/users/crypto/exchanges/delete.service";
    import PostUsersCryptoExchanges from "@/services/users/crypto/exchanges/post.service";
    import PatchUsersCryptoExchanges from "@/services/users/crypto/exchanges/patch.service";
    import PlaftormCryptoExchanges from "@/services/platform/crypto/exchanges/get.service";

    export default {
        name: "TrackCryptoExchanges-Page",
        components: {
            InputFieldComponent,
            AddOwnerMultiselectFieldComponent,
            InputFieldAutocompleteComponent
        },
        data() {
            return {
                crypto_exchange_api: {},
                supported_crypto_exchanges_names: [],
                supported_crypto_exchanges: [],
                form_step: 0,
                isFormDisabled: false,
                ownerIds: [],
                isValid: {
                    exchange_name: true,
                    apiKey: true,
                    secret: true,
                    uid: true,
                    login: true,
                    password: true,
                    twofa: true,
                    privateKey: true,
                    walletAddress: true,
                    token: true,
                    ownerIds: true,
                }
            };
        },
        created() {
            PlaftormCryptoExchanges.get().then(
                (response) => {
                    this.supported_crypto_exchanges = response['data'].exchanges;
                    this.supported_crypto_exchanges_names = this.supported_crypto_exchanges.map(a => a.ccxt_name.toString());
                },
                (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        computed: {
            exchangeCredentials() {
                const correct_credentials =
                    this.supported_crypto_exchanges
                        .filter((item) => {
                            return item.ccxt_name === this.crypto_exchange_api.exchange_name
                        });

                const mock_credentials = {
                    apiKey: false,
                    secret: false,
                    uid: false,
                    login: false,
                    password: false,
                    twofa: false,
                    privateKey: false,
                    walletAddress: false,
                    token: false
                }

                return (correct_credentials.length === 0) ? mock_credentials : correct_credentials[0].credentials;
            }
        },
        methods: {


            /* owner ids multiselect field */
            onAddItem(value) {
                if (!this.ownerIds.includes(value)) {
                    this.ownerIds.push(value);
                }
            },
            onRemoveItemIndex(index) {
                this.ownerIds.splice(index, 1);
            },


            notifyParentInfoForm() {

                const exchange_name = this.crypto_exchange_api.exchange_name;

                let form_step = 0;

                if (typeof exchange_name !== 'undefined' &&
                    exchange_name.length > 0) {
                    const exchange_object = this.supported_crypto_exchanges.find(x => x.ccxt_name === exchange_name);

                    if (typeof exchange_object !== 'undefined') {
                        form_step = 1;
                        this.$parent.setWebsiteUrl(exchange_object.url_website);
                        this.$parent.setApiManagementUrl(exchange_object.url_api_management);
                        this.$parent.setExchangeName(exchange_object.ccxt_name);
                        this.$parent.setApiManagementUrl(exchange_object.url_api_management);
                    }
                }
                this.form_step = form_step;
                this.$parent.setFormStep(form_step);
            },
            saveCryptoExchangeApi() {

                // validate

                if (this.crypto_exchange_api.exchange_name == null ||
                    !this.supported_crypto_exchanges_names.includes(this.crypto_exchange_api.exchange_name)) {
                    this.isValid.exchange_name = false;
                    return;
                } else {
                    this.isValid.exchange_name = true;
                }

                if (this.ownerIds == null ||
                    this.ownerIds.length === 0) {
                    this.isValid.ownerIds = false;
                    return;
                } else {
                    this.isValid.ownerIds = true;
                }

                // get credentials to validate exchange by exchange
                const credentials = Object.keys(this.exchangeCredentials).filter(k => this.exchangeCredentials[k] === true);
                let failedValidation = false;

                credentials.forEach(credential => {
                    if (this.crypto_exchange_api[credential] == null ||
                        this.crypto_exchange_api[credential] === '') {
                        this.isValid[credential] = false;
                        failedValidation = true;
                    } else {
                        this.isValid[credential] = true;
                    }
                });

                if (failedValidation) {
                    return;
                }

                // execute

                this.isFormDisabled = true;

                this.supported_crypto_exchanges.forEach((item) => {
                    if (item.ccxt_name === this.crypto_exchange_api.exchange_name) {
                        this.crypto_exchange_api.exchange = item.ccxt_id;
                    }
                });
                const data = {
                    name: this.crypto_exchange_api.exchange_name,
                    exchange: this.crypto_exchange_api.exchange,
                    apiKey: this.crypto_exchange_api.apiKey,
                    secret: this.crypto_exchange_api.secret,
                    uid: this.crypto_exchange_api.uid,
                    login: this.crypto_exchange_api.login,
                    password: this.crypto_exchange_api.password,
                    twofa: this.crypto_exchange_api.twofa,
                    privateKey: this.crypto_exchange_api.privateKey,
                    walletAddress: this.crypto_exchange_api.walletAddress,
                    token: this.crypto_exchange_api.token,
                    owners: this.ownerIds,
                };

                const user_id = this.$store.getters['auth/getUserId'];
                PostUsersCryptoExchanges.post(user_id, data).then(
                    () => {

                        PatchUsersCryptoExchanges.patch(user_id, this.crypto_exchange_api.exchange).then(
                            (data) => {
                                let number_crypto_connected = 0;
                                if (data && data.data && data.data.balance) {
                                    number_crypto_connected = data.data.balance.length;
                                }

                                // Refresh Vuex store with new data
                                this.$store.dispatch('connections/refresh').then(() => {
                                    this.isFormDisabled = false;
                                }).then(() => {
                                    if (number_crypto_connected > 0) {
                                        // go to success
                                        this.$router.push('/connect/crypto/exchange/success');
                                    } else {
                                        // go to warning
                                        this.$router.push('/connect/crypto/exchange/warning');
                                    }
                                });

                            },
                            () => {

                                /** if the connection fails, destroy the record on the database */
                                DeleteUsersCryptoExchanges.delete(user_id, this.crypto_exchange_api.exchange).then(
                                    () => {
                                        // do nothing on success
                                    },
                                    () => {
                                        // do nothing on error
                                    }
                                ).then(() => {

                                    this.isFormDisabled = false;
                                    this.$router.push({
                                        path: '/connect/crypto/exchange/error',
                                        query: {
                                            exchange: this.crypto_exchange_api.exchange
                                        }
                                    });
                                });
                            }
                        );
                    },
                    (error) => {
                        this.loading = false;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            }
        }
    };
</script>

import api from '../../../../api';

class MarketsForexHistorical {

  get(pair, query) {
    if( query ) {
      const dates = query.dates;
      return api.get('/v1/markets/forex/historical/' + pair + '?dates=' + dates.join(','));
    }
    else {
      return api.get('/v1/markets/forex/historical/' + pair);
    }
  }
}

export default new MarketsForexHistorical();

<template>
    <div class="card-list">

        <connection-card-component :title="$t('connect.bank_api_title')"
                                   :description="$t('connect.bank_api_text')"
                                   :to="'/connect/openbanking/country'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/london_day.svg')"
                                   :logos="[require('@/assets/img/logos/banks/paypal.png'),
                                      require('@/assets/img/logos/banks/revolut.png'),
                                      require('@/assets/img/logos/banks/postepay.png'),
                                      require('@/assets/img/logos/banks/unicredit.png'),
                                      require('@/assets/img/logos/banks/hsbc.png')]"
                                   cta="connect" />


        <connection-card-component :title="$t('connect.stock_title')"
                                   :description="$t('connect.stock_text')"
                                   :to="'/connect/stocks/manual'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/new_york_city_day.svg')"
                                   :logos="[require('@/assets/img/logos/stocks/apple.png'),
                                      require('@/assets/img/logos/stocks/microsoft.png'),
                                      require('@/assets/img/logos/stocks/beyondmeat.png'),
                                      require('@/assets/img/logos/stocks/jnj.png'),
                                      require('@/assets/img/logos/stocks/corsair.png')]"
                                   cta="add" />


        <connection-card-component :title="$t('connect.cash_title')"
                                   :description="$t('connect.cash_text')"
                                   :to="'/connect/cash/manual'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/city_day.svg')"
                                   :logos="[require('@/assets/img/logos/fiat/eur.png'),
                                      require('@/assets/img/logos/fiat/usd.png'),
                                      require('@/assets/img/logos/fiat/gbp.png'),
                                      require('@/assets/img/logos/fiat/chf.png'),
                                      require('@/assets/img/logos/fiat/sek.png')]"
                                   cta="add" />
    </div>
</template>

<script>

    import ConnectionCardComponent from '@/components/connect/navigation/Card';

    export default {
        name: 'Banks-And-Titles-List',
        props: ['page', 'tableId'],
        components: {
            ConnectionCardComponent
        }
    }
</script>

<style scoped>
    div.card-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

</style>
<template>

    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">


                    <!-- Heading -->

                    <div class="heading-row">
                        <h3 class="headline-small black">
                            {{ $t('tables.add').charAt(0).toUpperCase() + $t('tables.add').slice(1).toLowerCase() }}
                            {{ $t('tables.data').charAt(0).toUpperCase() + $t('tables.data').slice(1).toLowerCase() }}
                        </h3>
                        <p v-if="maxNumberOfPages > 1"
                           class="title-small black">
                            {{ page_index + 1 }} {{ $t('forms.amend_modal.of')}} {{ maxNumberOfPages }}
                        </p>
                    </div>

                    <!-- Information Box -->

                    <div class="info-box">
                        <span class="material-icons black"> error </span>
                        <span class="input-box body-medium black">
                            <span class="bold">{{ symbolData.source_name[page_index] }}</span>
                            {{ $t('forms.amend_modal.handles_staking')}}

                            <template v-if="stakingSuggestions.length > 0">
                                <span class="bold">{{ symbolData.ticker }}</span>
                                {{ $t('forms.amend_modal.may_stand_for')}}
                                <span class="bold">{{ stakingSuggestions[0].name }} ({{ stakingSuggestions[0].ticker }}).</span>
                            </template>

                            {{ $t('forms.amend_modal.please_insert')}}
                        </span>
                    </div>

                    <!-- Display Other Suggestions in case it is needed -->

                    <template v-if="stakingSuggestions.length > 1">
                        <div class="other-suggestions-switcher"
                             @click="showOtherSuggestions = !showOtherSuggestions">
                            <p class="body-small black">
                                {{ $t('forms.amend_modal.other_suggestions')}}
                            </p>
                            <span v-if="showOtherSuggestions" class="material-icons inline-icon black">arrow_drop_up</span>
                            <span v-else class="material-icons inline-icon black">arrow_drop_down</span>

                        </div>

                        <div v-if="showOtherSuggestions"
                             class="other-suggestions-panel">

                            <div>
                                <p class="body-small black" v-for="(asset) in stakingSuggestions.slice(1,4)" :key="asset">
                                    {{ asset.name }} ({{ asset.ticker }})
                                </p>
                            </div>

                            <div v-if="stakingSuggestions.length > 4">
                                <p class="body-small black" v-for="(asset) in stakingSuggestions.slice(4,7)" :key="asset">
                                    {{ asset.name }} ({{ asset.ticker }})
                                </p>
                            </div>

                        </div>
                    </template>



                    <form class="branded-input-form">


                        <div class="fields-frame">

                            <input-field-component v-model="symbolData.source_name[page_index]"
                                                   :label="$t('forms.exchange')"
                                                   :readonly="true"
                                                   :isValid="true" />

                        </div>
                        <div class="fields-frame">
                            <input-field-component v-model="symbolData.ticker"
                                                   :label="$t('tables.ticker')"
                                                   :readonly="true"
                                                   :isValid="true" />
                            <input-field-component v-model="symbolData.individual_amounts[page_index]"
                                                   :label="$t('tables.amount')"
                                                   :readonly="true"
                                                   :isValid="true" />

                        </div>


                        <div class="fields-frame">
                            <input-field-autocomplete-ticker-component v-model="input_value.name"
                                                                       type="text"
                                                                       :label="$t('forms.cryptocurrency')"
                                                                       :isValid="isValid.name"
                                                                       :list="cryptoSymbols" />
                        </div>

                        <div class="buttons-frame">

                            <!-- confirm -->
                            <button class="secondary full-rounded"
                                    :disabled="loading"
                                    @click.prevent="patchUsersCryptoExchangesBalance">
                                <span v-if="loading"
                                      class="material-icons loading-icon">
                                    sync
                                </span>
                                <p class="label-large">
                                    {{ $t('forms.amend_modal.submit_button')}}
                                </p>
                            </button>


                            <!-- skip -->
                            <button class="secondary full-rounded outlined"
                                    @click.prevent="skipForNow"
                                    v-if="thereAreMorePages">
                                <p class="label-large">
                                    {{ $t('forms.amend_modal.skip_for_now')}}
                                </p>
                            </button>
                            <!-- close -->
                            <button class="secondary full-rounded outlined"
                                    @click.prevent="skipForNow"
                                    v-else>
                                <p class="label-large">
                                    {{ $t('forms.cancel')}}
                                </p>
                            </button>

                        </div>

                    </form>



                </div>
            </div>
        </div>
    </Transition>

</template>

<script>

    import PatchUsersCryptoExchangesBalance from "@/services/users/crypto/exchanges/balance/patch.service";
    import PlaftormCryptoSymbols from "@/services/platform/crypto/symbols/get.service";
    import EventBus from "@/common/EventBus";

    import InputFieldComponent from '@/components/fields/InputField';
    import InputFieldAutocompleteTickerComponent from '@/components/fields/autocomplete/InputFieldAutocompleteTicker';

    export default {
        name: 'Amend-Crypto-Staking-Modal-Component',
        props: ['show', 'data'],
        emits: ['close'],
        components: {
            InputFieldComponent,
            InputFieldAutocompleteTickerComponent
        },
        data() {
            return {
                loading: false,
                input_value: {
                    name: ''
                },
                isValid: {
                    name: true
                },
                page_index: 0,
                cryptoSymbols: [],
                showOtherSuggestions: false,
            }
        },
        created() {


            PlaftormCryptoSymbols.get().then(
                (response) => {
                    const cryptoSymbols = response['data'].symbols;

                    // Put in the format: "Name - Ticker" "Euro - EUR" and remove duplicates
                    this.cryptoSymbols = [...cryptoSymbols.map(x => {
                        const baseQuote = x.symbol.split('/');
                        if (baseQuote[1] === 'USD') {
                            return x.currency_base + ' - ' + baseQuote[0];
                        }
                    }).filter(x => x != null)];
                },
                (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },
        computed: {
            symbolData() {

                return JSON.parse((this.data) ? this.data : '{}');

                /*
                this.symbolData.ticker = data.ticker;
                this.symbolData.amount = data.amount;
                this.symbolData.source = data.source;
                this.symbolData.source_name = data.source_name;
                this.symbolData.service = data.service;
                this.symbolData.type = data.type;
                this.symbolData.individual_amounts = data.individual_amounts;
                this.symbolData.id = data.id;
                */
            },

            maxNumberOfPages() {
                return this.symbolData.source.length;
            },

            stakingSuggestions() {

                const suggestions = [];

                this.cryptoSymbols.forEach((crypto) => {

                    const ticker = crypto.split(" - ")[1];
                    const name = crypto.split(" - ")[0];

                    if (ticker &&
                        this.symbolData &&
                        this.symbolData.ticker && (
                            ticker.includes(this.symbolData.ticker) ||
                            this.symbolData.ticker.includes(ticker))) {

                        suggestions.push({
                            name: name,
                            ticker: ticker
                        });
                    }
                });

                return suggestions;
            },

            thereAreMorePages() {
                return this.page_index + 1 < this.maxNumberOfPages;
            }
        },
        methods: {

            skipForNow() {
                this.page_index += 1;
                if (this.page_index >= this.maxNumberOfPages) {
                    this.$store.dispatch('connections/refresh');
                    this.$emit('close');
                }
            },

            patchUsersCryptoExchangesBalance() {

                // validation
                if(this.input_value == null ||
                    this.input_value.name == null ||
                    this.input_value.name === '' ||
                    !this.cryptoSymbols.includes(this.input_value.name)) {

                    this.isValid.name = false;
                    return;
                }
                this.isValid.name = true;

                // logic
                const name = this.input_value.name.split(' - ')[0];
                const ticker = this.input_value.name.split(' - ')[1];
                const user_id = this.$store.state.auth.user.id;

                const data = {
                    exchange: this.symbolData.source[this.page_index],
                    balance_id: this.symbolData.id[this.page_index],
                    name: name,
                    staking_ticker: ticker
                };

                PatchUsersCryptoExchangesBalance.patch(user_id, data).then(
                    () => {
                        this.skipForNow();
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            }
        },
    }
</script>

<style scoped>

    div.heading-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }



    div.info-box {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid;
        border-radius: 16px;
        border-color: var(--tertiary);
        width: calc(100% - 16px);
        background-color: var(--tertiary);
        padding: 8px;
        margin: -2.5vh auto auto auto;
        gap: 8px;
    }

    div.other-suggestions-switcher {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .other-suggestions-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }

    .other-suggestions-table {
        width: 48%;
    }
</style>
<template>
    <p class="body-large black">
        {{ $t('profile.see_terms_and_conditions') }}
        <router-link to="/terms_and_conditions">
            {{ $t('profile.this_link') }}
        </router-link>
    </p>
</template>

<script>
    export default {
        name: 'Terms-And-Conditions-Component'
    }
</script>

<style scoped>
    a {
        color: var(--tertiary);
    }
</style>
import api from '@/services/api';

class PatchUsersManualAssetName {

    patch(id, data) {
        return api.patch('/v1/users/' + id + '/manual/asset/name', data);
    }
}

export default new PatchUsersManualAssetName();

<template>
    <div class="custom-paddings-form">
        <filtered-list-component :list="filteredCountries"
                                 :placeholder="$t('connect.openbanking.country_placeholder')"
                                 :title="$t('connect.openbanking.country_title')" />
    </div>
</template>

<script>

    import FilteredListComponent from '@/components/fields/FilteredList';

    export default {
        name: "Select-Openbanking-Country",
        components: {
            FilteredListComponent
        },
        methods: {
            /* THE ACTION TO TRIGGER WHEN THE CTA BUTTON OF THE FILTERED LIST IS PRESSED */
            cta_action(clicked_item_id) {
                this.$router.push('/connect/openbanking/institution?countryCode=' + clicked_item_id);
            }
        },
        computed: {
            countryFlagLogos() {
                const countryFlagLogos = this.$store.getters['platform/getCountries'];
                return (countryFlagLogos) ? countryFlagLogos : {};
            },
            financialInstitutions() {
                const institutions = this.$store.getters['platform/getFinancialInstitutions'];
                return (institutions) ? institutions : [];
            },

            /* FILTER COUNTRIES BY THE NORDIGEN SUPPORTED ISO COUNTRY CODES */

            filteredCountries() {
                let nordigenSupportedCountryCodes = [];
                const filteredCountryList = [];
                const localeLanguage = this.$i18n.locale;

                this.financialInstitutions.forEach(institution => {
                    institution.countries.forEach(countryCode => {
                        nordigenSupportedCountryCodes.push(countryCode);
                    })
                });
                nordigenSupportedCountryCodes = Array.from(new Set(nordigenSupportedCountryCodes));

                nordigenSupportedCountryCodes.forEach(countryCode => {
                    let countryName = this.$i18n.getLocaleMessage(localeLanguage).i18nCountriesISO[countryCode];

                    if (Object.prototype.toString.call(countryName) === '[object Array]') {
                        countryName = countryName[0];
                    }

                    filteredCountryList.push({
                        id: countryCode,
                        logo: this.countryFlagLogos[countryCode],
                        name: countryName
                    });
                });

                /* Put the nation of the current locale language to the front of the array */
                const indexOfLocaleCountry = filteredCountryList.map(x => x.id).indexOf(localeLanguage.toUpperCase());
                if (indexOfLocaleCountry > 0) {
                    const localeCountryItem = filteredCountryList.splice(indexOfLocaleCountry, 1);
                    filteredCountryList.unshift(localeCountryItem[0]);
                }

                return filteredCountryList;
            }
        }
    };
</script>

<style scoped>
    .custom-paddings-form {
        display: flex;
        justify-content: center;
        margin: 32px auto auto auto;
        width: calc(100%-32px);
        max-width: 808px;
        background-color: var(--white);
        border-radius: var(--high-rounded);
        padding: 16px;
    }
</style>
import api from '../../../api';

class PatchMarketsCryptoPrices {

  patch(tickers) {
    return api.patch('/v1/markets/crypto/' + tickers.join(',') );
  }
}

export default new PatchMarketsCryptoPrices();


export default {
    install: (app, options) => {

        /* expose breakpoints */

        app.config.globalProperties.$breakpoints = {

            /* variables */

            xs: options.xs,
            sm: options.sm,
            md: options.md,
            lg: options.lg,
            xl: options.xl,
            xxl: options.xxl,

            /* methods */

            isMobile: () => {
                return window.innerWidth < options.mobile;
            },
            isDesktop: () => {
                return window.innerWidth >= options.desktop;
            },
            isXS: () => {
                return window.innerWidth < options.sm;
            },
            isSM: () => {
                return window.innerWidth >= options.sm && window.innerWidth < options.md;
            },
            isMD: () => {
                return window.innerWidth >= options.md && window.innerWidth < options.lg;
            },
            isLG: () => {
                return window.innerWidth >= options.lg && window.innerWidth < options.xl;
            },
            isXL: () => {
                return window.innerWidth >= options.xl && window.innerWidth < options.xxl;
            },
            isXXL: () => {
                return window.innerWidth >= options.xxl;
            },

            /* comparative methods */
            isMDOrLarger: () => {
                return window.innerWidth >= options.md;
            },
            isLGOrLarger: () => {
                return window.innerWidth >= options.md;
            }
        };
    }
}

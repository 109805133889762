<template>

    <div class="previous-season-frame-card">
        <stylish-element-component :width="'12px'"
                                   height="true"/>
        <div class="previous-season">
            <div class="review-frame">
                <p class="title-small">
                    {{ $t('rewards.recap_season_1') }}
                </p>
                <stylish-element-component :height="'12px'"
                                           :variant="'neutral'" />
                <p class="title-medium">
                    {{ $t('rewards.next_developments') }}
                </p>
                <stylish-element-component :height="'12px'"
                                           :variant="'neutral'" />
                <stylish-element-component :height="'12px'"
                                           :variant="'neutral'" />
            </div>
            <div class="description-frame">
                <div>
                    <span class="material-icons-outlined title-large">
                        looks_one
                    </span>
                    <p class="body-medium">
                        {{ $t('rewards.development_1') }}
                    </p>
                </div>
                <div>
                    <span class="material-icons-outlined title-large">
                        looks_two
                    </span>
                    <p class="body-medium">
                        {{ $t('rewards.development_2') }}
                    </p>
                </div>
                <div>
                    <span class="material-icons-outlined title-large">
                        looks_3
                    </span>
                    <p class="body-medium">
                        {{ $t('rewards.development_3') }}
                    </p>
                </div>
                <div>
                    <span class="material-icons-outlined title-large">
                        looks_4
                    </span>
                    <p class="body-medium">
                        {{ $t('rewards.development_4') }}
                    </p>
                </div>
            </div>
            <img :src="require('@/assets/img/illustrations/team_building_1.svg')" />
        </div>
    </div>

</template>

<script>

    import StylishElementComponent from '@/components/StylishElement';

    export default {
        name: 'Previous-Season-Frame',
        components: {
            StylishElementComponent
        }
    }
</script>

<style scoped>

    /* wrapper */

    div.previous-season-frame-card {
        background-color: var(--white);
        border-radius: var(--high-rounded);
        padding-top: 24px;
        padding-left: 16px;
        padding-bottom: 24px;
        display: flex;
    }

    /* internal element */

    div.previous-season {
        margin: 0px 24px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 48px);
        gap: 32px;
    }

    div.previous-season > img {
        width: 168px;
        margin: auto;
    }

    /* internal elemenets */

    div.review-frame,
    div.description-frame {
        display: flex;
        flex-direction: column;
        min-width: 144px;
    }

        div.review-frame {
            width: fit-content;
            gap: 4px;
        }

    div.description-frame > div {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }
</style>
import api from '../../../api';

class PostUsersProfileTimeframe {

  post(id, timeframe) {
    return api.post('/v1/users/'+id+'/profile/timeframe',{
      timeframe: timeframe
    });
  }
}

export default new PostUsersProfileTimeframe();




export default {

    it: {

        enter_your_vault: "Entra nel tuo vault",
        welcome_back: "È un piacere rivederti 👋",

        email_or_username: "Email o Username",

        password: "Password",
        password_required: "La password è obbligatoria",

        enter_button: "Login",

        forgot_email_username: "Non ricordi email o username?",
        contact_us: "Contattaci",

        forgot_password: "Password dimenticata?",
        reset_here: "Reimposta password",

        this_field_required: "Questo campo è obbligatorio",
        wrong_credentials: "Qualcosa non va. Controlla le tue credenziali e riprova ad accedere",

        pending_account: "Non hai ancora verificato il tuo account. Segui le istruzioni che trovi nell'email che ti abbiamo inviato",
        invalid_password: "Password non valida",
        generic_error: "Si è verificato un errore",

        sign_in: 'Login',
        sign_up: 'Crea Account',
    },

    es: {

        enter_your_vault: "Inicia sesión",
        welcome_back: "Feliz de verte otra vez 👋",

        email_or_username: "Email o nombre de usuario",

        password: "Contraseña",
        password_required: "La contraseña es obligatoria",

        enter_button: "Iniciar sesión",

        forgot_email_username: "¿Has olvidado tu email o nombre de usuario?",
        contact_us: "¡Contáctanos!",

        forgot_password: "¿Has olvidado tu contraseña?",
        reset_here: "Restablecer contraseña",

        this_field_required: "Este campo es obligatorio",
        wrong_credentials: "Los datos de acceso son incorrectos",

        pending_account: "Cuenta pendiente de verificación. Consulta tu correo",
        invalid_password: "Contraseña invalida",
        generic_error: "Se ha producido un error",

        sign_in: 'Iniciar sesión',
        sign_up: 'Crear cuenta',
    },

    en: {

        enter_your_vault: "Sign in to your vault",
        welcome_back: "Welcome back 👋",

        email_or_username: "Email or Username",

        password: "Password",
        password_required: "Password is required",

        enter_button: "Enter your vault",

        forgot_email_username: "Forgot your email or username?",
        contact_us: "Contact us",

        forgot_password: "Forgot your password?",
        reset_here: "Reset it here",

        this_field_required: "This field is required",
        wrong_credentials: "Something is wrong. Check your credentials and try again",

        pending_account: "Your account is pending verification. Please follow the instructions in the email we sent you",
        invalid_password: "Invalid password",
        an_error_occurred: "An error occurred",


        sign_in: 'Sign in',
        sign_up: 'Sign Up',
    },

    fr: {

        enter_your_vault: "Entrez dans votre caveau",
        welcome_back: "Ravi de vous revoir 👋",

        email_or_username: "Email ou Nom d'utilisateur",

        password: "Mot de passe",
        password_required: "Le mot de passe est obligatoire",

        enter_button: "Connectez-vous",

        forgot_email_username: "Email ou nom d'utilisateur oublié ?",
        contact_us: "Contactez-nous !",

        forgot_password: "Mot de passe oublié ?",
        reset_here: "Réinitialiser mot de passe",

        this_field_required: "Ce champ est obligatoire",
        wrong_credentials: "Nom d'utilisateur, email, ou mot de passe incorrect",

        pending_account: "Compte en attente de vérification jusqu'à ce que les instructions indiquées dans l'e-mail soient satisfaites",
        invalid_password: "Mot de passe incorrect",
        generic_error: "Une erreur s'est produite",

        sign_in: 'Connectez-vous',
        sign_up: 'Créer mon compte',
    },

};
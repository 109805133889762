<template>
    <div class="task-frame">

        <!-- progress bar -->
        <div class="progress-bar-rewards">
            <div :class="{
                 'bar': true,
                 'completed': rewardStep>=0
                 }" />
            <div :class="{
                 'dot': true,
                 'completed': rewardStep>1
                 }">
                <span v-if="rewardStep>1" class="material-icons white label-large">
                    check
                </span>
            </div>
            <div :class="{
                 'bar': true,
                 'completed': rewardStep>=2
                 }" />
            <div :class="{
                 'dot': true,
                 'completed': rewardStep>2
                 }">
                <span v-if="rewardStep>2" class="material-icons white label-large">
                    check
                </span>
            </div>
        </div>

        <!-- add bank account -->
        <div v-if="rewardStep === 0 || rewardStep === 1"
             class="task">
            <p class="body-medium black">
                {{ $t('rewards.step_1') }}
            </p>
            <button class="tertiary full-rounded" @click="$router.push('/connect/connection')">
                <p class="label-large black">
                    {{ $t('rewards.lets_do_it') }}
                </p>
            </button>
        </div>

        <!-- fill the survey -->
        <div v-if="rewardStep === 2"
             class="task"
             id="task-survey-superhuman">
            <p class="body-medium black">
                {{ $t('rewards.step_2') }}
            </p>
            <button data-tf-opacity="100"
                    data-tf-size="100"
                    data-tf-transitive-search-params
                    data-tf-medium="snippet"
                    data-tf-on-submit="typeform_task_frame_survey_superhuman"
                    class="tertiary full-rounded"
                    style="border: none;">
                <p class="label-large">
                    {{ $t('rewards.lets_do_it') }}
                </p>
            </button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Progress-Bar-Component',
        props: ['rewardStep'],
        mounted() {
            this.setupSuperhumanSurveyEmbed();
        },
        methods: {
            closed() {
                console.log('closed');
            },

            setupSuperhumanSurveyEmbed() {

                if (this.rewardStep !== 2) {
                    return; // break if it is not the correct step of the reward
                }

                const superhumanHTML = document.getElementById('task-survey-superhuman');

                /* import the typeform script programmatically */
                let recaptchaScript = document.createElement('script');
                recaptchaScript.setAttribute('src', '//embed.typeform.com/next/embed.js');
                superhumanHTML.appendChild(recaptchaScript);

                /* set typeform handler at window level */
                window['typeform_task_frame_survey_superhuman'] = function () {
                    setTimeout(() => {
                        location.reload();
                    }, 750);
                }

                const superhumanButton = superhumanHTML.getElementsByTagName('button')[0];

                /* set the hidden field user_id to the typeform */
                const userId = this.$store.getters['auth/getUserId'];
                superhumanButton.setAttribute('data-tf-hidden', 'user_id=' + userId);

                /* set the id and title */
                const userLanguage = this.$store.getters['auth/getUserLanguage'];
                if (userLanguage === 'it') {
                    superhumanButton.setAttribute('data-tf-popup', 'AsVwgBuU');
                    superhumanButton.setAttribute('data-tf-iframe-props', 'title=Superhuman');
                } else {
                    superhumanButton.setAttribute('data-tf-popup', 'cgj0VXqi');
                    superhumanButton.setAttribute('data-tf-iframe-props', 'title=360Track - 30 seconds survey');
                }
            }
        }
        
    }
</script>

<style scoped>
    div.task-frame {
        width: calc(100%-32px);
        max-width: 905px;
        display: flex;
        margin: 8px auto 8px auto;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
    div.task-frame div.task {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        text-align: center;
        width: 100%;
    }

    div.task-frame div.progress-bar-rewards {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    div.task-frame div.progress-bar-rewards div {
        background-color: var(--neutral);
        border-radius: 100px;
        padding-left: 8px;
        padding-right: 8px;
    }

    div.task-frame div.progress-bar-rewards div.bar {
        height: 8px;
        width: 100%;
    }
    div.task-frame div.progress-bar-rewards div.dot {
        height: 16px;
        position: relative;
    }

    div.task-frame div.progress-bar-rewards div.dot span.material-icons {
        position: absolute;
        top: 1px;
        left: 1px;
    }

    div.task-frame div.progress-bar-rewards div.completed {
        background-color: var(--secondary);
    }

    button.tertiary {
        width: 100%;
        justify-content: center;
    }
</style>
<template>
    <div id="connect-cash-manual">

        <h1 class="headline-large black">
            {{ $t('connect.add_cash') }}
        </h1>

        <manual-form-component />
    </div>
</template>

<script>
import ManualFormComponent from '@/components/connect/cash/manual/Form.vue';

export default {
        name: "Connect-Cash-Page",
        components: {
            ManualFormComponent
        }
}
</script>

<style scoped>
    div#connect-cash-manual {
        width: 100%;
        max-width: 808px;
        margin: 32px auto auto auto;
    }
    @media (max-width: 599px) {
        div#connect-cash-manual {
            padding: 0px 16px;
            width: calc(100% - 32px);
        }
    }
</style>

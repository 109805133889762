import api from '@/services/api';

class UsersProfileSurname {

    post(id, surname) {
        return api.post('/v1/users/' + id + '/profile/surname', {
            surname: surname
        });
    }
}

export default new UsersProfileSurname();

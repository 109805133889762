<template>
    <div :class="{
            'service-label': true,
            'active': status === 'active',
            'expired': status === 'expired',
            'inactive': status === 'inactive'
         }">
        {{ connectionStatus }}
    </div>
</template>

<script>
    export default {
        name: 'Asset-Type-Component',
        props: ['status'],
        computed: {
            connectionStatus() {

                switch (this.status) {
                    case 'expired': return 'Expired';
                    case 'active': return 'Active';
                    case 'inactive': return 'Inactive';
                    default: return '-';
                }
            },
        }
    }
</script>

<style scoped>
    div.service-label {
        padding: 2px 8px;
        border-radius: var(--middle-rounded);
        width: fit-content;
    }

        div.service-label.active {
            background-color: var(--green);
            color: var(--white);
        }
        div.service-label.inactive {
            background-color: var(--red);
            color: var(--white);
        }
        div.service-label.expired {
            background-color: var(--tertiary);
            color: var(--white);
        }
</style>
<template>

    <div class="connection-assets">
        <div>
            <h2 class="title-medium black bold">
                {{ $t('dashboard.connection_details.your_assets_in') }}
                {{ connection.name }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>

        <table-component :table="{
                            properties: {
                                menuPreference: 'asset',
                                allConnections: false,
                                showNavigation: false
                            },
                            connections: [connection.id]
                        }"
                         :page="page"
                         :path="path" />
        

    </div>
</template>

<script>

    import TableComponent from '@/components/dashboard/section/elements/Table';

    export default {
        name: 'Connection-Assets-Component',
        props: ['connection', 'page', 'path'],
        components: {
            TableComponent
        },
        methods: {
            
            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },
        }
    }
</script>

<style scoped>

    div.connection-assets {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

        div.connection-assets > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            div.connection-assets > div > span {
                cursor: pointer;
            }

</style>
import api from '../../../api';

class GetUsersCryptoExchanges {

  get(id) {
    return api.get('/v1/users/'+id+'/crypto/exchange');
  }
}

export default new GetUsersCryptoExchanges();

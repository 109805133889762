import api from '../../../api';

class DeleteUsersManualAsset {

  delete(id, item_id) {
    return api.delete('/v1/users/'+id+'/manual/asset/'+item_id);
  }
}

export default new DeleteUsersManualAsset();

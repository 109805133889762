<template>
    <div class="tier-frame">
        <div class="user-tier-badge">
            <div>
                <p class="title-small black">
                    {{ $t('rewards.current_tier') }}
                </p>
                <stylish-element-component :height="'12px'"
                                           :width="'65%'"
                                           :variant="'neutral'" />
                <h3 class="title-large black">
                    {{ $t('rewards.prince_elector') }}
                </h3>
                <stylish-element-component :height="'12px'"
                                           :variant="'neutral'" />
            </div>
            <img src="@/assets/img/illustrations/characters/PrinceElector.svg" />
        </div>
        <div class="user-tier-description">
            <stylish-element-component :width="'12px'"
                                       height="true" />
            <div class="instructions">
                <h3 class="title-large black">
                    {{ $t('rewards.season_2') }}
                </h3>
                <p class="title-small black bold">
                    {{ $t('rewards.how_seasons_work') }}
                </p>
                <p class="body-medium black">
                    {{ $t('rewards.vote_and_suggest') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>

    import StylishElementComponent from '@/components/StylishElement';

    export default {
        name: 'Prince-Elector-Tier-Component',
        components: {
            StylishElementComponent
        },
    }
</script>

<style scoped>

    div.tier-frame {
        display: flex;
        width: calc(100%-32px);
        max-width: 1240px;
        align-self: center;
        align-items: center;
    }
    @media(max-width: 904px) {
        div.tier-frame {
            flex-direction: column;
        }
    }

    /* tier badge - left */
    div.user-tier-badge {
        width: calc(100%-32px);
        padding: 24px;
        display: flex;
        gap: 24px;
        align-items: center;
    }
    @media (min-width: 905px) {
        div.user-tier-badge {
            width: 72%;
        }
    }

    div.user-tier-badge > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    div.user-tier-badge img {
        border-radius: 100%;
        width: 22vw;
        max-width: 96px;
    }

    /* tier description - right */

    div.user-tier-description {
        padding: 24px;
        display: flex;
        gap: 16px;
    }

        div.user-tier-description > div.instructions {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }
</style>
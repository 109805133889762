

export default {

	it: {
		congrats: 'Congratulazioni 🥳',
		account_verified: 'Account verificato con successo',
		superpowers: "I tuoi superpoteri finanziari ti aspettano!",
		connect: 'Connetti i tuoi asset, monitorane le performance, pianifica.',
		connect_superpowers: 'Connetti i tuoi asset, monitorane le performance, pianifica. I tuoi superpoteri finanziari ti aspettano!',

		go_to_login: 'Login'
	},

	es: {
		congrats: '¡Perfecto!🥳',
		account_verified: 'Cuenta verificada',
		superpowers: "¡Reclama tu superpoderes financieros!",
		connect: 'Conecta tus activos, supervisa el rendimiento, planifica tus objectivos',
		connect_superpowers: 'Conecta tus activos, supervisa el rendimiento, planifica tus objectivos. ¡Reclama tu superpoderes financieros!',

		go_to_login: 'Iniciar sesión'
	},

	en: {
		congrats: 'Congrats 🥳',
		account_verified: 'Your account has been verified',
		superpowers: "Are you ready to get your financial superpowers?",
		connect: 'Connect your assets, monitor their performance, strategize!',
		connect_superpowers: 'Connect your assets, monitor their performance, strategize! Are you ready to get your financial superpowers?',

		go_to_login: 'Login'
	},

	fr: {
		congrats: 'Bravo 🥳 !',
		account_verified: 'Compte vérifié',
		superpowers: "Obtenez vos superpouvoirs financiers !",
		connect: 'Connectez vos actifs, surveiller leur rendement, élaborez vos stratégies .',
		connect_superpowers: 'Connectez vos actifs, surveiller leur rendement, élaborez vos stratégies . Obtenez vos superpouvoirs financiers !',

		go_to_login: 'Connectez-vous'
	},

};
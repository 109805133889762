<template>
    <router-link :to="to">
        <div>
            <span class="material-icons tertiary">
                {{ icon }}
            </span>
            <p class="label-small">
                {{ name }}
            </p>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: 'Collapsed-Sidebar-Navigation-Item-Component',
        props: ['name', 'icon', 'to']
    }
</script>

<style scoped>

    a {
        text-decoration: none;
    }

        a.router-link-active > div {
            color: var(--tertiary);
            background-color: var(--hover-item);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            padding: 8px;
            margin: auto 4px auto 4px;
            border-radius: var(--high-rounded);
        }

        a:not(.router-link-active) > div {
            color: var(--white);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            padding: 8px;
            margin: auto 4px auto 4px;
            border-radius: var(--high-rounded);
        }

            a:not(.router-link-active) > div:hover {
                color: var(--tertiary);
                background-color: var(--hover-item);
                transition: 0.4s;
            }
</style>
import api from '@/services/api';

class UsersProfileAction {

    post(id, type, action) {
        return api.post('/v1/users/' + id + '/profile/action', {
            type: type,
            action: action
        });
    }
}

export default new UsersProfileAction();

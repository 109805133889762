<template>

    <form class="branded-input-form" @submit.prevent="handleRegister">

        <!-- Heading and text -->

        <div class="form-heading-frame">

            <h1 class="headline-medium black">
                {{ $t('forms.signup.create_your_360_account') }}
            </h1>
            <p class="title-medium black">
                {{ $t('forms.signup.your_journey_starts_here') }}
            </p>

        </div>

        <!-- Sign up with google here -->
        <signin-with-google-button-component :context="'signup_with'"
                                             @onCallback="setLoadingState"/>

        <div class="fields-frame">

            <input-field-component type="email"
                                   v-model="input_value.email"
                                   id="email"
                                   :label="$t('forms.signup.email')"
                                   :isValid="!error_email"
                                   :errorMessage="errorMessage" />
        </div>


        <div class="fields-frame">

            <input-field-component type="text"
                                   v-model="input_value.username"
                                   id="username"
                                   :label="$t('forms.signup.username')"
                                   :isValid="!error_username"
                                   :errorMessage="errorMessage" />
        </div>


        <div class="fields-frame">

            <input-field-component type="password"
                                   v-model="input_value.password"
                                   id="password"
                                   :label="$t('forms.signup.password')"
                                   :isValid="!error_password"
                                   :errorMessage="errorMessage" />
        </div>
        <password-strength-bar v-if="input_value.password"
                               :passwordScore="passwordScore" />



        <div class="checkbox-frame">

            <div class="checkbox-frame-item">
                <input type="checkbox" 
                       v-model="input_value.terms" />

                <p :class="{
                   'body-medium': true,
                   'black': !error_terms,
                   'error': error_terms
                   }">
                    {{ $t('forms.signup.acceptance') }}
                    <a href="https://360track.me/en/legal" target="_blank" class="tertiary">
                        {{ $t('forms.signup.terms_and_conditions') }}
                        {{ $t('forms.signup.and') }}
                        {{ $t('forms.signup.privacy_policy') }}
                    </a>
                </p>
            </div>

            <div class="checkbox-frame-item">

                <input type="checkbox" v-model="input_value.updates" />
                <p class="body-medium black">
                    {{ $t('forms.signup.keep_me_updated') }}
                </p>
            </div>
        </div>

        <div class="buttons-frame">

            <button :disabled="loading" class="secondary full-rounded">
                <span v-if="loading"
                      class="material-icons loading-icon">
                    sync
                </span>
                <p class="label-large">
                    {{ $t('forms.signup.create_account') }}
                </p>
            </button>
        </div>

    </form>

</template>

<script>
    import InputFieldComponent from '@/components/fields/InputField';
    import { calculatePasswordStrength } from "@/assets/js/auth/calculatePasswordStrength";
    import PasswordStrengthBar from "@/components/auth/PasswordStrengthBar";
    import SigninWithGoogleButtonComponent from "@/components/auth/google/SigninWithGoogleButton.vue";

    export default {
        components: {
            InputFieldComponent,
            PasswordStrengthBar,
            SigninWithGoogleButtonComponent
        },
        data() {

            return {
                input_value: {},
                error_username: false,
                error_password: false,
                error_email: false,
                error_terms: false,
                errorMessage: '',
                loading: false,
            };
        },
        computed: {
            passwordScore() {
                return calculatePasswordStrength(this.input_value.password);
            },
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        },
        mounted() {
            if (this.loggedIn) {
                this.$router.push("/dashboard");
            }
        },
        methods: {
            setLoadingState(newState) {
                this.loading = newState;
            },

            handleRegister() {

                // validation

                this.error_email = false;
                this.error_username = false;
                this.error_password = false;
                this.error_terms = false;

                if (this.input_value.email == null ||
                    this.input_value.email.trim() == '') {
                    this.error_email = true;
                    return
                }
                if (this.input_value.username == null ||
                    this.input_value.username.trim() == '') {
                    this.error_username = true;
                    return
                }
                if (this.input_value.password == null ||
                    calculatePasswordStrength(this.input_value.password) < 3) {
                    this.error_password = true;
                    return
                }
                if(this.input_value.terms == null ||
                   this.input_value.terms === false) {
                   this.error_terms = true;
                   return
                }

                // execution

                this.errorMessage = '';
                this.loading = true;
                const language = this.$i18n.locale;

                const user = {
                    email: this.input_value.email,
                    password: this.input_value.password,
                    passwordConfirmation: this.input_value.password,
                    username: this.input_value.username,
                    terms: this.input_value.terms,
                    updates: this.input_value.updates
                };

                // add acquisition parameters
                user['utmSource'] = this.$route.query.utm_source || 'unknown';
                user['utmMedium'] = this.$route.query.utm_medium || 'unknown';
                user['utmCampaign'] = this.$route.query.utm_campaign || 'unknown';

                this.$store.dispatch("auth/register", { user, language }).then(
                    () => {
                        this.loading = false;
                        this.$router.push({ path: '/register/success' });
                    },
                    (error) => {
                        const message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        switch (message) {
                            case 'The password does not satisfy the constraints':
                                this.error_password = true;
                                this.errorMessage = 'The password does not satisfy the constraints';
                                break;
                            case 'Failed! Email is already in use!':
                                this.error_email = true;
                                this.errorMessage = 'Failed! Email is already in use!';
                                break;
                            case 'Failed! Username is already in use!':
                                this.error_username = true;
                                this.errorMessage = 'Failed! Username is already in use!';
                                break;
                            default: 
                                this.error_password = true;
                                this.error_email = true;
                                this.error_username = true;
                                this.errorMessage = '';
                        }

                        this.loading = false;
                    }
                );
            },
        },
    };
</script>

<style scoped>
    div.form-heading-frame {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-self: flex-start;
    }

    div.checkbox-frame {
        align-items: flex-start;
        border: 1px none;
        display: flex;
        flex-direction: column;
        gap: 2vh;
        height: fit-content;
        width: 100%;
    }

    div.checkbox-frame-item {
        align-items: flex-start;
        border: 1px none;
        display: flex;
        gap: 2vh;
        padding: 0px 0px 0px 1vw;
        width: 100%;
    }
</style>



exports.getDateBeforeISO = (todayISO) => {

    const todayDate = new Date(todayISO);
    const yesterdayUTC = todayDate.setDate(todayDate.getDate() - 1);
    const yesterdayDate = new Date(yesterdayUTC);
    const yesterdayISO = yesterdayDate.toISOString();

    return yesterdayISO;
};
<template>

    <template v-if="percentageChange && type !== 'forex'">
        <current-price-component :price="price"
                                 :percentageChange="percentageChange" />
    </template>
    <template v-else>
        <p class="body-medium black">
            -
        </p>
    </template>
</template>

<script>

    import CurrentPriceComponent from '@/components/dashboard/table/row/asset/CurrentPrice';
    const { getDatesISO } = require('@/assets/js/markets/dates/getDatesISO.js');
    const { getDateBeforeISO } = require('@/assets/js/markets/dates/getDateBeforeISO.js');


    export default {
        name: 'Timeframe-Price-Component',
        props: ['ticker', 'type'],
        components: {
            CurrentPriceComponent
        },
        computed: {
            timeframe() {
                return this.$store.getters['auth/getUserTimeframe'];
            },
            completeTicker() {
                if (this.type === 'forex') {
                    return this.ticker + '/USD';
                }
                return this.ticker;
            },
            currentAsset() {
                return this.$store.getters['markets/getMarkets'][this.completeTicker];
            },
            price() {

                return (this.currentAsset) ? this.currentAsset.price : undefined;

                /*
                const dates = getDatesISO();

                if (this.currentAsset == null || this.currentAsset.price == null) {
                    return undefined;
                }

                let index = 0;

                switch (this.timeframe) {
                    case '1D':
                        return this.currentAsset.price;
                    case '1W':
                        index = this.currentAsset.historical.map(x => x.timestamp).indexOf(dates.week);
                        if (index >= 0) {
                            return this.currentAsset.historical[index].close;
                        }
                        break;
                    case '3M':
                        index = this.currentAsset.historical.map(x => x.timestamp).indexOf(dates.quarter);
                        if (index >= 0) {
                            return this.currentAsset.historical[index].close;
                        }
                        break;
                    case 'YTD':
                        index = this.currentAsset.historical.map(x => x.timestamp).indexOf(dates.year);
                        if (index >= 0) {
                            return this.currentAsset.historical[index].close;
                        }
                        break;
                }

                return undefined;
                */
            },
            percentageChange() {

                const dates = getDatesISO();

                if (this.currentAsset == null ||
                    this.currentAsset.percentageChange == null ||
                    this.currentAsset.price == null ||
                    this.currentAsset.historical == null) {
                    return undefined;
                }

                const currentPrice = this.currentAsset.price;
                // declare variables outside swith case
                let index = 0;
                let currentDate = undefined;
                let breakFlag = 0;

                switch (this.timeframe) {
                    case '1D':
                        return this.currentAsset.percentageChange;
                    case '1W':
                        currentDate = dates.week;
                        do {

                            breakFlag += 1;
                            index = this.currentAsset.historical.map(x => x.timestamp).indexOf(currentDate);
                            currentDate = getDateBeforeISO(currentDate);

                        } while (index === -1 && breakFlag < 10);

                        if (index >= 0) {
                            return (currentPrice / this.currentAsset.historical[index].close - 1) * 100;
                        }
                        break;
                    case '3M':
                        currentDate = dates.quarter;
                        do {

                            breakFlag += 1;
                            index = this.currentAsset.historical.map(x => x.timestamp).indexOf(currentDate);
                            currentDate = getDateBeforeISO(currentDate);

                        } while (index === -1 && breakFlag < 10);

                        if (index >= 0) {
                            return (currentPrice / this.currentAsset.historical[index].close - 1) * 100;
                        }
                        break;
                    case 'YTD':
                        currentDate = dates.year;
                        do {

                            breakFlag += 1;
                            index = this.currentAsset.historical.map(x => x.timestamp).indexOf(currentDate);
                            currentDate = getDateBeforeISO(currentDate);

                        } while (index === -1 && breakFlag < 10);

                        if (index >= 0) {
                            return (currentPrice / this.currentAsset.historical[index].close - 1) * 100;
                        }
                        break;
                }

                return undefined;
            }
        }
    }
</script>

<style scoped>
</style>
<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <h2 class="headline-medium black">
                        {{ $t('forms.add_portfolio') }}
                    </h2>

                    <form class="branded-input-form" >

                        <div class="fields-frame">

                            <input-field-component v-model="input_value"
                                                   type="text"
                                                   :label="$t('forms.write_portfolio_name')"
                                                   :isValid="isValid.input_value" />

                            <add-owner-multiselect-field v-model="ownerIds"
                                                    @onAddItem="onAddItem"
                                                    @onRemoveItem="onRemoveItemIndex"
                                                    :isValid="isValid.ownerIds" />

                        </div>

                        <!-- Input Box -->

                        <div class="buttons-frame">
                            <button :disabled="loading"
                                    class="tertiary full-rounded"
                                    @click.prevent="addEmptyManualSource">
                                <span v-if="loading"
                                      class="material-icons loading-icon">
                                    sync
                                </span>
                                <p class="label-large white">
                                    {{ $t('forms.submit')}}
                                </p>
                            </button>

                                <button class="secondary outlined full-rounded"
                                        @click.prevent="closeModal(undefined)">
                                    <p class="label-large secondary">
                                        {{ $t('forms.skip')}}
                                    </p>
                                </button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </Transition>
</template>

<script>

    import InputFieldComponent from '@/components/fields/InputField';
    import AddOwnerMultiselectField from '@/components/fields/owner/AddOwnerMultiselectField';

    import PostUsersManualAssetSource from "@/services/users/manual/asset/source/post.service";
    import EventBus from "@/common/EventBus";

    export default {
        name: 'AmendModal-Component',
        props: ['show', 'suggestion'],
        components: {
            InputFieldComponent,
            AddOwnerMultiselectField
        },
        data() {
            return {
                ownerIds: [],
                loading: false,
                input_value: (this.suggestion) ? this.suggestion : '',
                isValid: {
                    ownerIds : true,
                    input_value: true
                }
            }
        },
        methods: {
            /* multiselect field */
            onAddItem(value) {
            if (!this.ownerIds.includes(value)) {
                    this.ownerIds.push(value);
                }
            },
            onRemoveItemIndex(index) {
                this.ownerIds.splice(index, 1);
            },
            /* close modal while notifying the parent of the new added source, if given in input */
            /* newlyAddedSource is the source just added, it is not mandatory */
            closeModal(newlyAddedSource) {
                if (newlyAddedSource) {
                    this.$parent.hidePortfolioModal(this.input_value);
                } else {
                    this.$parent.hidePortfolioModal();
                }
            },
            addEmptyManualSource() {

                //validation

                if (this.input_value == null || 
                    this.input_value === '' ||
                    !this.input_value.trim()) 
                {
                    this.isValid.input_value = false;
                    return;
                } else {
                    this.isValid.input_value= true;
                }

                if (this.ownerIds == null ||
                    this.ownerIds.length === 0) {
                    this.isValid.ownerIds = false;
                    return;
                } else {
                    this.isValid.ownerIds = true;
                }

                this.loading = true;

                const data = {
                    source: this.input_value,
                    owners: this.ownerIds
                }
                const user_id = this.$store.getters['auth/getUserId'];
                PostUsersManualAssetSource.post(user_id, data).then(
                    () => {

                        // Add connection to vuex store
                        this.$store.dispatch('connections/refresh').then(() => {
                            this.loading = false;
                            this.closeModal(this.input_value);
                        })

                    },
                    (error) => {
                        this.loading = true;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    });
            }
        }
    }
</script>
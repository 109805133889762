<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <span class="material-icons modal-close-button" @click="closeModal">
                        close
                    </span>

                    <div class="display-direction-row"
                         v-if="symbolData[page_index]">
                        <h3 class="headline-small black">
                            {{ $t('forms.amend_modal.edit_manual_title') }}
                            {{ symbolData[page_index].name }}
                            {{ $t('forms.amend_modal.in') }}
                            {{ symbolData[page_index].source_name }}
                        </h3>
                        <p v-if="maxNumberOfPages > 1"
                           class="title-small black">
                            {{ page_index + 1 }} {{ $t('forms.amend_modal.of')}} {{ maxNumberOfPages }}
                        </p>
                    </div>


                    <div class="info-box"
                         v-if="symbolData[page_index]">
                        <span class="material-icons-outlined black">
                            error_outline
                        </span>

                        <p class="body-medium black" v-if="symbolData[page_index] && symbolData[page_index].service === 'manual'">
                            {{ $t('forms.amend_modal.edit_amount_of') }}
                            <span class="bold">
                                {{ symbolData[page_index].name }}
                            </span>
                            {{ $t('forms.amend_modal.in') }}
                            <span class="bold">
                                {{ symbolData[page_index].source_name }}
                            </span>
                        </p>

                        <p class="body-medium black" v-else>
                            {{ $t('forms.amend_modal.cannot_edit') }}
                            <span class="bold">
                                {{ symbolData[page_index].name }}
                            </span>
                            {{ $t('forms.amend_modal.in') }}
                            <span class="bold">
                                {{ symbolData[page_index].source_name }}
                            </span>
                            {{ $t('actions.because_is_connection') }}
                        </p>
                    </div>

                    <form class="branded-input-form">


                        <template v-if="symbolData[page_index] && symbolData[page_index].service !== 'manual'">

                            <div class="align-center frame-with-exchange-logo">
                                <security-lottie-component />
                                <div v-if="currentInstitutionLogo">
                                    <img :src="currentInstitutionLogo" />
                                </div>
                            </div>

                        </template>
                        <template v-else-if="input_value[page_index]">

                            <div class="fields-frame">

                                <input-field-component v-model="input_value[page_index].source_name"
                                                       :label="$t('tables.portfolio_name')"
                                                       :readonly="true"
                                                       id="source_name"
                                                       :isValid="true" />

                            </div>


                            <div class="fields-frame">

                                <input-field-component v-model="input_value[page_index].name"
                                                       :label="$t('tables.name')"
                                                       :readonly="true"
                                                       id="name"
                                                       :isValid="true" />

                                <input-field-component v-model="input_value[page_index].ticker"
                                                       :label="$t('tables.ticker')"
                                                       :readonly="true"
                                                       id="ticker"
                                                       :isValid="true" />

                            </div>



                            <div class="fields-frame">

                                <input-field-component v-model="input_value[page_index].amount"
                                                       :label="$t('forms.amount')"
                                                       id="amount"
                                                       :isValid="isValid.amount" />

                            </div>


                        </template>
                        <template v-else>
                            <loading-spinner-component :active="true"
                                                       class="align-center" />
                        </template>




                        <div class="buttons-frame">

                            <!-- confirm -->
                            <button v-if="symbolData[page_index] && symbolData[page_index].service === 'manual'"
                                    class="secondary full-rounded"
                                    :disabled="loading"
                                    @click.prevent="patchUsersManualBalance">
                                <span v-if="loading"
                                      class="material-icons loading-icon">
                                    sync
                                </span>
                                <p class="label-large">
                                    {{ $t('forms.amend_modal.submit_button')}}
                                </p>
                            </button>


                            <!-- skip -->
                            <button class="secondary full-rounded outlined"
                                    @click.prevent="skipForNow"
                                    v-if="thereAreMorePages">
                                <p class="label-large">
                                    {{ $t('forms.amend_modal.skip_for_now')}}
                                </p>
                            </button>
                            <!-- close -->
                            <button class="secondary full-rounded outlined"
                                    @click.prevent="skipForNow"
                                    v-else>
                                <p class="label-large">
                                    {{ $t('forms.cancel')}}
                                </p>
                            </button>

                        </div>

                    </form>





                </div>
            </div>
        </div>
    </Transition>
</template>

<script>


    import InputFieldComponent from '@/components/fields/InputField';
    import LoadingSpinnerComponent from '@/components/LoadingSpinner';
    import SecurityLottieComponent from '@/components/lottieImages/Security';

    import PatchUsersManualAssetBalance from "@/services/users/manual/asset/balance/patch.service";
    import EventBus from "@/common/EventBus";


    export default {
        name: 'AmendModal-Component',
        props: ['show', 'asset'],
        emits: ['close'],
        components: {
            InputFieldComponent,
            LoadingSpinnerComponent,
            SecurityLottieComponent
        },
        data() {

            return {
                loading: false,
                input_value: [],
                isValid: {
                    amount: true
                },
                page_index: 0
            }
        },
        computed: {

            connections() {
                return this.$store.getters['connections/getConnections'];
            },

            symbolData() {
                const symbols = [];

                this.connections.forEach(connection => {

                    const index = connection.balance.map(x => x.ticker).indexOf(this.asset.ticker);
                    if (index === -1) {
                        return;
                    }

                    symbols.push({
                        source: connection.source,
                        source_name: connection.name,
                        service: connection.service,
                        name: connection.balance[index].name,
                        ticker: connection.balance[index].ticker,
                        type: connection.balance[index].type,
                        amount: connection.balance[index].amount,
                        id: connection.balance[index]._id,
                        institutionId: connection.institutionId
                    });
                });


                return symbols;
            },

            cryptoExchanges() {
                return this.$store.getters['platform/getCryptoExchanges'];
            },

            openbankingInstitutions() {
                return this.$store.getters['platform/getFinancialInstitutions'];
            },

            maxNumberOfPages() {
                return this.symbolData.length;
            },

            thereAreMorePages() {
                return this.page_index + 1 < this.maxNumberOfPages;
            },


            currentInstitutionLogo() {

                if (this.symbolData[this.page_index].service === 'exchange') {
                    const currentExchange = this.cryptoExchanges
                        .find(x => x.ccxt_id === this.symbolData[this.page_index].source);
                    return (currentExchange) ? currentExchange.url_logo : undefined;
                }

                if (this.symbolData[this.page_index].service === 'openbanking') {
                    const currentInstitution = this.openbankingInstitutions
                        .find(x => x.institutionId === this.symbolData[this.page_index].institutionId);
                    return (currentInstitution) ? currentInstitution.logo : undefined;
                }

                return undefined;
            }

        },
        watch: {
            symbolData: function (newValue) {

                if (newValue == null) {
                    return;
                }

                this.input_value = [];
                newValue.forEach(symbol => {

                    this.input_value.push({
                        source_name: symbol.source_name,
                        name: symbol.name,
                        ticker: symbol.ticker,
                        amount: symbol.amount
                    });
                });
            }
        },
        mounted() {
            if (this.symbolData == null) {
                return;
            }

            this.input_value = [];
            this.symbolData.forEach(symbol => {

                this.input_value.push({
                    source_name: symbol.source_name,
                    name: symbol.name,
                    ticker: symbol.ticker,
                    amount: symbol.amount
                });
            });
        },
        methods: {

            skipForNow() {
                this.loading = false;
                this.isValid.amount = true;
                this.page_index += 1;
                if (this.page_index >= this.maxNumberOfPages) {
                    
                    this.$store.dispatch('connections/refresh');
                    this.$emit('close');
                }
            },

            closeModal() {
                this.$store.dispatch('connections/refresh');
                this.$emit('close');
            },

            patchUsersManualBalance() {

                if (this.input_value[this.page_index].amount == null ||
                    this.input_value[this.page_index].amount === '' ||
                    isNaN(this.input_value[this.page_index].amount)) {
                    this.isValid.amount = false;
                    return;
                } else {
                    this.isValid.amount = true;
                }

                this.loading = true;

                const user_id = this.$store.getters['auth/getUserId'];
                const data = {
                    source: this.symbolData[this.page_index].source,
                    balance_id: this.symbolData[this.page_index].id,
                    amount: this.input_value[this.page_index].amount
                };

                PatchUsersManualAssetBalance.patch(user_id, data).then(
                    () => {

                        this.skipForNow();
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            }
        },
    }
</script>

<style scoped>

    div.display-direction-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        div.display-direction-row > h3 {
            max-width: 80%;
        }

        .align-center {
            margin: auto;
            margin-top: -48px;
        }

    div.frame-with-exchange-logo {
        min-height: 240px;
        display: flex;
        align-items: center;
    }

        div.frame-with-exchange-logo > div > img {
            width: 100%;
            max-width: 120px;
        }

        div.info-box {
            display: flex;
            align-items: center;
            border-radius: var(--high-rounded);
            background-color: var(--tertiary);
            padding: 8px 8px;
            margin-top: 8px;
            gap: 8px;
        }
</style>
<template>
    <span v-if="value !== '-'">
        {{ currencySymbol }}
    </span> {{ value.toLocaleString(userLanguage, {maximumFractionDigits: 2}) }}
</template>

<script>
    export default {
        name: 'Value-Component',
        props: ['amount', 'currentPriceDollars'],
        computed: {

            userCurrency() {
                return this.$store.getters['auth/getUserCurrency'];
            },

            currencySymbol() {

                // most of escape characters here
                // https://www.toptal.com/designers/htmlarrows/currency/
                switch (this.userCurrency) {
                    case 'USD': return '\x24';
                    case 'EUR': return '\u20AC';
                    case 'GBP': return '\u00A3';
                    case 'CHF': return '\u20A3';
                    default: return '$';
                }
            },

            conversionRate() {

                let currencyPair = null;

                switch (this.userCurrency) {
                    case 'USD': currencyPair = 'USD/USD'; break;
                    case 'EUR': currencyPair = 'EUR/USD'; break;
                    case 'GBP': currencyPair = 'GBP/USD'; break;
                    case 'CHF': currencyPair = 'CHF/USD'; break;
                    default: currencyPair = 'USD/USD';
                }

                const conversionRate = this.$store.getters['markets/getMarkets'][currencyPair];
                return (conversionRate) ? conversionRate.price : 1;
            },


            value() {

                if ((this.amount || this.amount === 0) && this.currentPriceDollars) {
                    // round to max 2 decimal places
                    return Math.round(this.currentPriceDollars / this.conversionRate * this.amount * 100) / 100;
                }
                return '-';
            },

            userLanguage() {
                return this.$store.getters['auth/getUserLanguage'];
            }
        }
    }
</script>
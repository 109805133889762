<template>
    <div class="go-back-arrow"
         @click="goPageBack">
        <span class="material-icons body-large">
            arrow_back
        </span>
        <p class="label-large">
            {{ $t('actions.go_back') }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'Go-Back-Arrow',
        methods: {
            goPageBack() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
    div.go-back-arrow {
        display: flex;
        align-items: center;
        gap: 2px;
        margin: 0px 52px;
        width: fit-content;
        cursor: pointer;
    }
    @media(max-width: 904px) {
        div.go-back-arrow {
            margin: 0px 24px;
        }
    }
</style>
import api from '../../../../api';

class MarketsETFsPricesHistorical {

  patch(tickers) {
    return api.patch('/v1/markets/etfs/historical/' + tickers.join(",") );
  }
}

export default new MarketsETFsPricesHistorical();

import api from '../../../../api';

class MarketsStocksPricesHistorical {

    get(tickers, query) {

        if (query) {
            const dates = query.dates;
            return api.get('/v1/markets/stocks/historical/' + tickers.join(',') + '?dates=' + dates.join(','));
        }
        else {
            return api.get('/v1/markets/stocks/historical/' + tickers.join(','));
        }
    }
}

export default new MarketsStocksPricesHistorical();

<template>
    <div class="asset-name-data-table">
        <img :src="assetImage" alt="logo" />
        <div>
            <p class="body-medium black">
                {{ name }}
            </p>
            <p class="body-small black">
                {{ ticker }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Amount-Component',
        props: ['name', 'ticker', 'type'],
        computed: {

            cryptoLogos() {
                return this.$store.getters['platform/getCryptoLogos'];
            },
            forexLogos() {
                return this.$store.getters['platform/getForexLogoUrls'];
            },
            stocksEtfsLogos() {
                return this.$store.getters['platform/getStocksETFsLogos'];
            },

            assetImage() {

                switch (this.type) {
                    case 'cryptocurrency':
                        if (this.cryptoLogos && this.cryptoLogos[this.ticker]) {
                            return this.cryptoLogos[this.ticker];
                        }
                        return require('@/assets/img/logos/crypto/unknown.png');
                    case 'forex':
                        if (this.forexLogos && this.forexLogos[this.ticker]) {
                            return this.forexLogos[this.ticker];
                        }
                        return require('@/assets/img/logos/fiat/unknown.png');
                    case 'stocks':
                    case 'etfs':
                        if (this.stocksEtfsLogos && this.stocksEtfsLogos[this.ticker]) {
                            return this.stocksEtfsLogos[this.ticker];
                        }
                        return require('@/assets/img/logos/stocks/unknown.png');
                    default:
                        return require('@/assets/img/logos/unknown.png');
                }

            }
        }
    }
</script>

<style scoped>
    div.asset-name-data-table {
        display: flex;
        align-items: center;
        width: fit-content;
        gap: 8px;
    }

        div.asset-name-data-table > img {
            width: 24px;
        }
</style>
<template>

    <div class="amend-crypto-staking"
         @click="showAmendCryptoModal = true">
        <p class="label-large black">
            {{ $t('tables.add') }}
        </p>
        <p class="label-large black">
            {{ $t('tables.data') }}
        </p>
    </div>


    <Teleport v-if="showAmendCryptoModal" to="body">
        <amend-crypto-staking-modal-component :show="showAmendCryptoModal"
                                              @close="showAmendCryptoModal = false"
                                              :data="amendCryptoData" />
    </Teleport>

</template>

<script>

    import AmendCryptoStakingModalComponent from '@/components/dashboard/table/amend/AmendCryptoStakingModal';

    export default {
        name: 'Amend-Crypto-Staking-Component',
        props: ['id', 'ticker', 'amount', 'table'],
        components: {
            AmendCryptoStakingModalComponent
        },
        data() {
            return {
                showAmendCryptoModal: false,
                amendCryptoData: '{}'
            }
        },
        computed: {

            connections() {
                return this.$store.getters['connections/getConnections'];
            }
        },
        created() {

            const allConnections = this.table.properties.allConnections;
            const tableConnections = this.table.connections;

            // filter the connections based on the ones present in the table
            let filteredConnections = []
            if (allConnections) {

                filteredConnections = this.connections;

            } else {

                filteredConnections = this.connections.filter(x => {
                    return tableConnections.includes(x.id);
                });
            }

            // filter the connections based on the ones that contain the asset
            filteredConnections = filteredConnections.filter(connection => {
                return connection.balance &&
                       connection.balance.length > 0 &&
                       connection.balance.map(x => x.ticker).indexOf(this.ticker) !== -1
            });


            // arrays of the source, source_name and individual amounts
            // of the asset in the balance of the connections
            const sources = [];
            const sourceNames = [];
            const individualAmounts = [];
            const balance_ids = [];

            filteredConnections.forEach(connection => {

                if (connection.balance.map(x => x.ticker).includes(this.ticker)) {
                    sources.push(connection.source);
                    sourceNames.push(connection.name);
                    individualAmounts.push(
                        connection.balance
                            .find(x => x.ticker === this.ticker)
                            .amount
                    );
                    balance_ids.push(
                        connection.balance
                            .find(x => x.ticker === this.ticker)
                            ._id
                    );
                }
            });

            // fill the data
            this.amendCryptoData = JSON.stringify({
                ticker: this.ticker,
                amount: this.amount,

                source: sources,
                source_name: sourceNames,
                individual_amounts: individualAmounts,
                id: balance_ids,
            });
        }
    }
</script>

<style scoped>
    div.amend-crypto-staking {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
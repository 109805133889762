import api from '../../../api';

class GetUsersManualAsset {

  get(id) {
    return api.get('/v1/users/'+id+'/manual/asset');
  }
}

export default new GetUsersManualAsset();

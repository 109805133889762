

export default {


    it: {
        connect_your_wallet: "Connettere un wallet",
        add_wallet: "Aggiungi un Wallet",
        type_your_wallet: "Nome del wallet (e.g. Metamask)",
        select_wallet: "Seleziona wallet",
        blockchain: "Blockchain (e.g. Bitcoin o Ethereum)",
        successfully_connected: "Indirizzo connesso con successo",
        wallet_address: "Aggiungi indirizzo del wallet",
        open_your_wallet: 'Apri il tuo wallet (e.g. Metamask o Crypto.com)',
        copy_the_address: "Copia l'indirizzo del wallet che vuoi connettere (e.g. 0xac20CbAFf5a7A52C92Fb ...)",
        type_the_blockchain: "Inserisci la blockchain dell'indirizzo (e.g. Bitcoin o Ethereum)",
        missing_required_field: 'Se non riesci a trovare il campo richiesto, per favore',

        add_the_new_wallet: 'Crea il nuovo wallet',
        or_create_wallet: 'Aggiungi un nuovo wallet',
        write_wallet_name: 'Scrivi il nome del wallet',
    },

    es: {
        connect_your_wallet: "Conexi�n de un Wallet",
        add_wallet: "Inserta un wallet",
        type_your_wallet: "Nombre de lo wallet (e.g. Metamask)",
        select_wallet: "Seleccionar wallet",
        blockchain: "Blockchain (e.g. Bitcoin o Ethereum)",
        successfully_connected: "Direcci�n a�adida correctamente",
        wallet_address: "Añadir dirección de lo Wallet",
        open_your_wallet: 'Abre tu wallet (e.g. Metamask o Crypto.com)',
        copy_the_address: 'Copie la direcci�n que desea conectar (e.g. 0xac20CbAFf5a7A52C92Fb ...)',
        type_the_blockchain: 'Escriba la blockchain de la direcci�n (e.g. Bitcoin o Ethereum)',
        missing_required_field: 'Si tienes alg�n problema, puedes',

        add_the_new_wallet: 'A�adir lo nuevo wallet',
        or_create_wallet: 'A�adir un nuevo wallet',
        write_wallet_name: 'Escribe el nombre de lo wallet',
    },

    en: {
        connect_your_wallet: "Connect your Wallet",
        add_wallet: "Add your wallet",
        type_your_wallet: "Wallet name (e.g. Metamask)",
        select_wallet: "Select Wallet",
        blockchain: "Blockchain (e.g. Bitcoin)",
        successfully_connected: "Address connected successfully",
        wallet_address: "Add address of the Wallet",
        open_your_wallet: 'Open your wallet (e.g. Metamask or Crypto.com)',
        copy_the_address: 'Copy the address you want to connect (e.g. 0xac20CbAFf5a7A52C92Fb ...)',
        type_the_blockchain: 'Type the blockchain of the address (e.g. Bitcoin or Ethereum)',
        missing_required_field: 'If you have any problem, please',

        add_the_new_wallet: 'Add the new wallet',
        or_create_wallet: 'Add a new wallet',
        write_wallet_name: 'Type the portfolio name',
    },

    fr: {
        connect_your_wallet: "Connectez vos Wallet",
        add_wallet: "Ins�rez votre Wallet",
        type_your_wallet: "Nom du wallet (e.g. Metamask)",
        select_wallet: "Choisissez Wallet",
        blockchain: "Blockchain (e.g. Bitcoin ou Ethereum)",
        successfully_connected: "Adresse ajout� avec succ�s",
        wallet_address: "Ajoutez l'adresse du wallet",
        open_your_wallet: 'Ouvrez votre wallet (e.g. Metamask ou Crypto.com)',
        copy_the_address: "Copiez l'adresse que vous souhaitez connecter (e.g. 0xac20CbAFf5a7A52C92Fb ...)",
        type_the_blockchain: "Ins�rez la blockchain de l'adresse (e.g. Bitcoin ou Ethereum)",
        missing_required_field: 'En cas de probl�me, veuillez',

        add_the_new_wallet: 'Ajoutez le nouveau wallet',
        or_create_wallet: 'Ajoutez un nouveau wallet',
        write_wallet_name: '�crivez le nom du wallet',
    },

};
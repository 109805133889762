import api from '../../../api';

class UsersProfileLanguage {

  post(id, language) {
    return api.post('/v1/users/'+id+'/profile/language',{
      language: language
    });
  }
}

export default new UsersProfileLanguage();

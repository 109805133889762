import api from '@/services/api';

class AuthGoogleSignin {

    post(bodyJSON) {
        return api.post('/v1/auth/signin/google', bodyJSON);
    }
}

export default new AuthGoogleSignin();

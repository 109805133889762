<template>
    <div id="topbar-menu">
        <div id="logo"
             @click="goToDashboard">
            <img :src="require('@/assets/logo.png')" />
        </div>
        <div class="menu">
            <permagreen-topbar-navigation-item-component v-if="showInstallButton"
                                                         :to="'/download'"
                                                         :icon="'get_app'"
                                                         :name="$t('navigation.download')"
                                                         @click.prevent="fireDefferedPrompt" />
            <topbar-navigation-item-component :to="'/profile'"
                                              :icon="'account_circle'"
                                              :name="$t('navigation.profile')" />
            <topbar-navigation-item-component :to="'/logout'"
                                              :icon="'logout'"
                                              :name="$t('navigation.logout')" />
        </div>
    </div>
</template>

<script>

    import TopbarNavigationItemComponent from '@/components/navigation/topbar/NavigationItem';
    import PermagreenTopbarNavigationItemComponent from '@/components/navigation/topbar/NavigationItemPermagreen';

    export default {
        name: 'Topbar-Component',
        components: {
            TopbarNavigationItemComponent,
            PermagreenTopbarNavigationItemComponent
        },
        data() {
            return {
                showInstallButton: false,
                deferredPrompt: undefined
            };
        },
        methods: {
            goToDashboard() {
                this.$router.push('/dashboard');
            },
            // is the user using an iOS device?
            iOSCanInstall() {
                return 'standalone' in window.navigator;
            },
            iOSIsInstalled() {
                return window.navigator.standalone === true;
            },
            isIOS() {
                if (typeof navigator === `undefined`) return false;
                return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor);
            },
            isSafari() {
                return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            },
            showDownloadButtonOnIos() {
                return this.isIOS() && this.isSafari() && this.iOSCanInstall() && !this.iOSIsInstalled();
            },
            async fireDefferedPrompt() {
                if (this.deferredPrompt) {
                    this.deferredPrompt.prompt();
                }
            }
        },
        created() {

            if (this.showDownloadButtonOnIos()) {
                // if it is iOS and safari and it is not installed
                // display the download button, but it will only prompt the nudge
                this.showInstallButton = true;
            } else {
                window.addEventListener("beforeinstallprompt", (e) => {
                    // prevent mobile browsers to automatically show the prompt
                    e.preventDefault();
                    // save the deferred prompt
                    this.deferredPrompt = e;
                    // display the 'install app' item in the navbar
                    this.showInstallButton = true;
                });
            }
        }
    }
</script>

<style scoped>
    div#topbar-menu {
        height: 48px;
        background-color: var(--primary);
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        div#topbar-menu > div#logo {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-left: 24px;
            cursor: pointer;
        }

            div#topbar-menu > div#logo > img {
                height: 32px;
            }

        div#topbar-menu > div.menu {
            display: flex;
            align-items: center;
            gap: 2px;
            margin-right: 64px;
        }
</style>
<template>

    <div class="amend-manual-asset-dropdown"
         @click="this.showDropdown = !this.showDropdown">

        <span class="material-icons black title-large">
            more_horiz
        </span>

        <dropdown-menu-component :show="showDropdown"
                                 :list="[{
                                            name: $t('tables.edit'),
                                            value: 'amendAsset'
                                        },
                                        {
                                            name: $t('tables.delete'),
                                            value: 'deleteAsset'
                                        }]"
                                 @onItemClick="dropdownItemAction"
                                 class="dropdown" />
    </div>



    <Teleport v-if="showModalEditManual" to="body">
        <amend-manual-asset-modal-component @close="showModalEditManual = false"
                                            :show="showModalEditManual"
                                            :asset="asset" />
    </Teleport>

    <Teleport v-if="showModalDeleteManualAsset" to="body">
        <delete-manual-asset-modal-component @close="showModalDeleteManualAsset = false"
                                            :show="showModalDeleteManualAsset"
                                            :asset="asset" />
    </Teleport>

</template>

<script>

    import DropdownMenuComponent from '@/components/fields/DropdownMenu';
    import AmendManualAssetModalComponent from '@/components/dashboard/page/asset/AmendManualAssetModal';
    import DeleteManualAssetModalComponent from '@/components/dashboard/page/asset/DeleteManualAssetModal';


    export default {
        name: 'Amend-Manual-Asset-Dropdown',
        props: ['asset'],
        data() {
            return {
                showDropdown: false,
                showModalEditManual: false,
                showModalDeleteManualAsset: false
            };
        },
        components: {
            DropdownMenuComponent,
            AmendManualAssetModalComponent,
            DeleteManualAssetModalComponent
        },
        computed: {
            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
        },
        methods: {


            dropdownItemAction(action) {

                this.showDropdown = false;

                switch (action) {
                    case 'deleteAsset':

                        this.showModalDeleteManualAsset = true;
                        break;


                    case 'amendAsset':

                        this.showModalEditManual = true;
                        break;
                }
            },
        }
    }
</script>

<style scoped>
    div.amend-manual-asset-dropdown {
        position: relative;
    }

        div.amend-manual-asset-dropdown > span {
            cursor: pointer;
        }

        .dropdown {
            margin-left: -136px;
        }
</style>
<template>

    <div v-if="connectionLogo && service === 'wallet'"
         class="connection-name">
        <img :src="connectionLogo" />
        {{ name }}
    </div>
    <div v-else-if="connectionLogo"
         class="connection-name">
        <img :src="connectionLogo" />
    </div>
    <div v-else class="connection-name">
        {{ name }}
    </div>

</template>

<script>
    export default {
        name: 'Amount-Component',
        props: ['name', 'service', 'institutionId', 'connection'],

        computed: {

            currentFinancialInstitution() {

                if (this.service !== 'openbanking') {
                    return undefined;
                }
                const financialInstitutions = this.$store.getters['platform/getFinancialInstitutions'] || [];

                return financialInstitutions.filter(x => x.id === this.institutionId)[0];
            },

            currentCryptoExchange() {
                if (this.service !== 'exchange') {
                    return undefined;
                } 
                const cryptoExchanges = this.$store.getters['platform/getCryptoExchanges'];

                return cryptoExchanges.filter(x => x.ccxt_id === this.institutionId)[0];
            },

            currentWalletLogo() {
                const cryptoLogos = this.$store.getters['platform/getCryptoLogos'];
                if(this.connection == null || this.connection.balance[0] == null) {
                    return undefined;
                }
                const walletBalanceTicker = this.connection.balance[0].ticker;
                if (cryptoLogos == null) {
                    return undefined;
                }
                return cryptoLogos[walletBalanceTicker] || require('@/assets/img/logos/crypto/unknown.png');
            },

            connectionLogo() {


                switch (this.service) {

                    case 'openbanking':
                        if (this.currentFinancialInstitution) {
                            return this.currentFinancialInstitution.logo;
                        }
                        
                        break;
                    case 'exchange':
                        if (this.currentCryptoExchange) {
                            return this.currentCryptoExchange.url_logo;
                        }


                        break;
                    case 'wallet':
                        if (this.connection &&
                            this.connection.balance[0] &&
                            this.connection.balance[0].ticker) {
                            return this.currentWalletLogo;
                        }


                        break;
                }

                return undefined;
            }
        }
    }
</script>

<style scoped>
    div.connection-name {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    div.connection-name > img {
        height: 24px;
        border-radius: var(--squared-rounded);
    }
</style>
import api from '../../../api';

class PlatformCryptoExchanges {

  get() {
    return api.get('/v1/platform/crypto/exchanges');
  }
}

export default new PlatformCryptoExchanges();

<template>
    <div :class="{
         'asset-connection-switch-item': true,
         'left-oriented': isOrientedLeft,
         'right-oriented': isOrientedRight
         }">
        <p :class="{
       'label-large black': true,
       'inactive': !active,
       'active': active }">
            {{ label }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'Asset-Connection-Toggle-Component',
        props: ['label', 'active', 'orientation'],
        /**
         * 
         * 3 possible values of the orientation props
         * 
         * left - middle - right
         * 
         * */
        computed: {
            isOrientedLeft() {
                return this.orientation === 'left';
            },
            isOrientedRight() {
                return this.orientation === 'right';
            },
            isOrientedMiddle() {
                return this.orientation === 'middle';
            }
        }
    }
</script>

<style scoped>
    div.asset-connection-switch-item {
        padding: 6px 10px;
        min-width: 72px;
        text-align: center;
        border: 1px solid var(--primary);
    }
        div.asset-connection-switch-item.left-oriented {
            border-bottom-left-radius: var(--full-rounded);
            border-top-left-radius: var(--full-rounded);
            padding-left: 12px;
        }
        div.asset-connection-switch-item.right-oriented {
            border-bottom-right-radius: var(--full-rounded);
            border-top-right-radius: var(--full-rounded);
            padding-right: 12px;
        }
        div.asset-connection-switch-item:has(> p.active) {
            background-color: var(--secondary);
        }
    div.asset-connection-switch-item > p.inactive {
        cursor: pointer;
    }
</style>
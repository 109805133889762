<template>

    <div class="input-field high-rounded">
        <input :id="inputFieldId"
               class="body-medium black"
               :value="modelValue"
               placeholder=" ">
        <label v-if="label"
               :for="inputFieldId"
               class="label-large black">
            {{ label }}
        </label>
        <button type="button">
            <span class="material-icons black">
                arrow_drop_down
            </span>
        </button>
    </div>

</template>

<script>
    export default {
        name: 'Stock-Exchange-Select-Field-Component',
        props: ['label', 'modelValue', 'id'],

        /*
         *  label - the label - optional
         *  type - the type of the input - optional
         *  id - the id of the input - optional, but it is safer to pass it
         * 
         * */

        data() {
            return {
                inputFieldId: (this.id) ? this.id : this.randomString()
            };
        },
        methods: {
            randomString() {
                // generate a random 7 characters string
                return (Math.random() + 1).toString(36).substring(2);
            }
        }
    }
</script>

<style scoped>
    div.input-field > label,
    div.input-field > button,
    div.input-field > input {
        pointer-events: none;
    }
</style>
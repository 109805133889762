
const infoCard = require('./connect/InfoCard.js').default;
const WalletForm = require('./connect/WalletForm.js').default;
const Openbanking = require('./connect/Openbanking.js').default;
const Result = require('./connect/Result.js').default;

export default {

    it: {

        connect_your_assets: 'Connetti i tuoi asset',

        // connection navigation
        banks_and_payments: 'Banche & Titoli',
        cryptocurrencies: 'Cripto',

        crypto_exchange_title: 'Exchange',
        crypto_exchange_text: 'Connettiti in modo sicuro con 100+ exchange di criptovalute',
        crypto_wallet_address_title: 'Indirizzo Wallet',
        crypto_wallet_address_text: 'Collega gli indirizzi dei wallet di più di 15 blockchain',
        crypto_manual_title: 'Aggiunta Manuale',
        crypto_manual_text: 'Aggiungi manualmente 1000+ criptovalute',

        bank_api_title: 'Conti Correnti',
        bank_api_text: 'Connettiti in modo sicuro con 2000+ banche europee e app di pagamento',
        stock_title: 'Titoli',
        stock_text: 'Collega le tue azioni, ETF, REIT, trust e altro',
        cash_title: 'Denaro Contante',
        cash_text: 'Aggiungi manualmente il tuo denaro contante e le tue valute straniere',

        // connection pages
        add_crypto: "Aggiungi criptovaluta",
        add_stock: "Aggiungi Ammontare Titolo",
        add_cash: "Aggiungi Denaro Contante",
        add_exchange: "Connetti un exchange",
        add_wallet: "Connetti un wallet",
        info_card: infoCard.it,
        wallet_form: WalletForm.it,
        openbanking: Openbanking.it,
        result: Result.it,

        exchange_title: "Seleziona Mercato Azionario",
        exchange_search: "Cerca Mercato Azionario",

    },

    es: {

        connect_your_assets: 'Conecta tus activos',

        // connection navigation
        banks_and_payments: 'Bancos & Titulos',
        cryptocurrencies: 'Cripto',

        crypto_exchange_title: 'Exchange',
        crypto_exchange_text: 'Conéctate de forma segura con 100+ exchange de criptomonedas',
        crypto_wallet_address_title: 'Dirección de wallet',
        crypto_wallet_address_text: 'Conecte direcciones de wallet de más de 15 blockchain',
        crypto_manual_title: 'Adición Manual',
        crypto_manual_text: 'Añadas manualmente 1000+ criptomonedas',

        bank_api_title: 'Cuentas Bancarias',
        bank_api_text: 'Conéctate de forma segura con 2000+ bancos europeos y aplicaciones',
        stock_title: 'Titulos',
        stock_text: 'Añadas tus acciones, ETF, REIT, trust y más',
        cash_title: 'Dinero en efectivo',
        cash_text: 'Añadas tu dinero en efectivo y divisas extranjeras',

        // connection pages
        add_crypto: "Añadir criptomoneda",
        add_stock: "Añadir Cantidad Titulo",
        add_cash: "Añadir dinero en efectivo",
        add_exchange: "Conectar un Exchange",
        add_wallet: "Conectar un Wallet",
        info_card: infoCard.es,
        wallet_form: WalletForm.es,
        openbanking: Openbanking.es,
        result: Result.es,

        exchange_title: "Select Stock Exchange",
        exchange_search: "Search Stock Exchange",

    },

    en: {

        connect_your_assets: 'Connect your assets',

        // connection navigation
        banks_and_payments: 'Banks & Securities',
        cryptocurrencies: 'Crypto',

        crypto_exchange_title: 'Exchange',
        crypto_exchange_text: 'Securely connect with 100+ crypto exchanges',
        crypto_wallet_address_title: 'Wallet Address',
        crypto_wallet_address_text: 'Connect wallet addresses from more than 15 blockchains',
        crypto_manual_title: 'Manual Add',
        crypto_manual_text: 'Manually add 1000+ cryptocurrencies',

        bank_api_title: 'Bank Accounts',
        bank_api_text: 'Securely connect with 2000+ European banks and payment apps',
        stock_title: 'Securities',
        stock_text: 'Manually add your ETFs, REITs, stocks, trusts and more',
        cash_title: 'Cash & Currencies',
        cash_text: 'Manually add your cash and fiat currencies',

        // connection pages
        add_crypto: "Add a Cryptocurrency",
        add_stock: "Add Stock Amount",
        add_cash: "Add Cash and Currencies",
        add_exchange: "Connect an Exchange",
        add_wallet: "Connect a Wallet",
        info_card: infoCard.en,
        wallet_form: WalletForm.en,
        openbanking: Openbanking.en,
        result: Result.en,

        exchange_title: "Seleccione Mercado",
        exchange_search: "Busca Mercado",

    },

    fr: {

        connect_your_assets: 'Connectez vos actifs',

        // connection navigation
        banks_and_payments: 'Banques & Titres',
        cryptocurrencies: 'Crypto',

        crypto_exchange_title: 'Échange',
        crypto_exchange_text: 'Connectez-vous en toute sécurité avec 100+ échanges de cryptomonnaies',
        crypto_wallet_address_title: 'Adresse de Wallet',
        crypto_wallet_address_text: 'Connectez les adresses de wallet de plus de 15 blockchains',
        crypto_manual_title: 'Ajout Manuel',
        crypto_manual_text: 'Ajoutez manuellement 1000+ cryptomonnaies',

        bank_api_title: 'Comptes',
        bank_api_text: 'Connectez-vous en toute sécurité avec 2000+ banques européennes et applications',
        stock_title: 'Titres',
        stock_text: 'Ajoutez vos actiones, ETF, REIT, trust et beaucoup plus',
        cash_title: 'Argent Liquide',
        cash_text: 'Ajoutez votre argent liquide et vos devises étrangères',

        // connection pages
        add_crypto: "Ajouter une crypto-monnaie",
        add_stock: "Ajouter Quantité",
        add_cash: "Ajouter Argent Liquide",
        add_exchange: "Connecter un Échange",
        add_wallet: "Connecter un Wallet",
        info_card: infoCard.fr,
        wallet_form: WalletForm.fr,
        openbanking: Openbanking.fr,
        result: Result.fr,

        exchange_title: "Sélectionner le marché",
        exchange_search: "Recherche Marché",

    }

};
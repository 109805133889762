



export default {

    it: {
        your_connection_expired: 'Sembra che la tua connessione con l\'istituzione sia scaduta.',
        click_here: 'Fai clic qui',
        to_refresh_it: 'per aggiornarla',

        your_assets_in: 'I tuoi asset in',
        add_holder: 'Aggiungi intestatario',
    },

    es: {
        your_connection_expired: 'Parece que su conexi�n con la instituci�n ha caducado.',
        click_here: 'Haga clic aqu�',
        to_refresh_it: 'para actualizarlo',

        your_assets_in: 'Sus activos en',
        add_holder: 'A�adir propietario',
    },

    en: {
        your_connection_expired: 'Seems like your connection to the institution is expired.',
        click_here: 'Click here',
        to_refresh_it: 'to refresh it',

        your_assets_in: 'Your assets in',
        add_holder: 'Add holder',
    },

    fr: {
        your_connection_expired: 'Il semble que votre connexion � l\'institution soit expir�e.',
        click_here: 'Cliquez ici pour',
        to_refresh_it: 'le mettre � jour',

        your_assets_in: 'Vos actifs dans',
        add_holder: 'Ajouter propri�taire',
    },
};
<template>
    <div class="lottie-container">
        <Vue3Lottie :animationData="animationJSON"/>
    </div>
</template>

<script>
    import { Vue3Lottie } from 'vue3-lottie'
    import animationJSON from '@/assets/lotties/click-here.json'

    export default {
        components: {
            Vue3Lottie,
        },
        data() {
            return {
                animationJSON,
            }
        },
    }
    
</script>

<style scoped>
    div.lottie-container {
        width: 100%;
        max-width: 240px;
    }
</style>
<template>
    <div v-if="showDownloadButtonOnIos"
         id="download-app">
        <h1 class="headline-large">
            Installa 360Track
        </h1>
        <h1 class="title-large">
            Segui le istruzioni sotto
        </h1>
        <img :src="require('@/assets/img/illustrations/download/instructions.jpg')" />
    </div>
    <div v-else />
</template>

<script>
    export default {
        name: 'Download-View',
        computed: {
            // is the user using an iOS device?
            iOSCanInstall() {
                return 'standalone' in window.navigator;
            },
            iOSIsInstalled() {
                return window.navigator.standalone === true;
            },
            isIOS() {
                if (typeof navigator === `undefined`) return false;
                return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor);
            },
            isSafari() {
                return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            },
            showDownloadButtonOnIos() {
                return this.isIOS && this.isSafari && this.iOSCanInstall && !this.iOSIsInstalled;
            }
        },

        created() {

            if (!this.showDownloadButtonOnIos) {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>
    div#download-app {
        width: 100%;
        max-width: 808px;
        margin: 32px auto auto auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    div#download-app > img {
        width: 80%;
        margin: auto;
    }
</style>
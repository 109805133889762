

exports.getDatesISO = () => {

    const today = new Date();

    const weekUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const weekIso = new Date(weekUTC).toISOString();

    const quarterUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - 90);
    const quarterIso = new Date(quarterUTC).toISOString();

    const currentYear = new Date().getFullYear();
    const yearFirstDay = new Date(currentYear, 0, 1);
    const yearFirstDayUTC = Date.UTC(yearFirstDay.getFullYear(), yearFirstDay.getMonth(), yearFirstDay.getDate());
    const yearFirstDayIso = new Date(yearFirstDayUTC).toISOString();


    return {
        week: weekIso,
        quarter: quarterIso,
        year: yearFirstDayIso
    };
};
<template>
    <div class="animated-placeholder"
         :style="cssProps" />
</template>

<script>
    export default {
        name: 'Animated-Placeholder-Component',
        props: ['height', 'width', 'borderRadius'],
        computed: {
            cssProps() {
                return {
                    '--height': this.height,
                    '--width': this.width || '100%',
                    '--border-radius': this.borderRadius,
                    '--animation-delay': '-' + this.randomInteger() + 's'
                };
            }
        },
        methods: {
            randomInteger() {
                // between 0 and 10
                return Math.floor(Math.random() * 11);
            }
        }
    }
</script>

<style scoped>
    div.animated-placeholder {
        height: var(--height);
        width: var(--width);
        border-radius: var(--border-radius);
        background-image: linear-gradient( to right, #d6d7d8 0%, #e2e3e4 10%, #d6d7d8 20%, #d6d7d8 100% );
        background-size: 200% 100%;
        animation: skeleton 1.2s linear infinite;
        animation-delay: var(--animation-delay);
    }
</style>
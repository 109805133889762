<template>

    <div class="skeleton-wrapper">
        <animated-placeholder-component height="48px"
                                        width="100%" />
        <animated-placeholder-component height="146px"
                                        width="100%" />
    </div>

</template>

<script>

    import AnimatedPlaceholderComponent from '@/components/AnimatedPlaceholder';

    export default {
        name: 'Skeleton-Full-Table-Component',
        components: {
            AnimatedPlaceholderComponent
        }
    }
</script>

<style scoped>

    div.skeleton-wrapper {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

</style>

const navigation = require('./table/navigation.js').default;


export default {

    it: {
        name: 'Nome',
        amount: 'Quantità',
        value: 'Valore',
        profit_and_loss: 'P&L',
        type: 'Tipo',
        current_price: 'Prezzo corrente',
        iban: 'IBAN',
        bban: 'BBAN',
        ticker: 'Ticker',
        edit: 'Modifica',
        delete: 'Elimina',
        portfolio_name: 'Nome del portfolio',
        add: 'Aggiungi',
        data: 'Dati',
        status: 'Status',
        empty_table: 'Questa tabella è vuota',
        address: 'Indirizzo',
        owners: 'Intestatari',
        email: 'E-mail',
        email_address: 'Indirizzo e-mail',
        surname: 'Cognome',
        legal_names: 'Nome intestatario',
        mobile: 'Cellulare',
        mobile_number: 'Numero di cellulare',
        navigation: navigation.it,
    },

    es: {
        name: 'Nombre',
        amount: 'Cantidad',
        value: 'Valor',
        profit_and_loss: 'P&L',
        type: 'Tipo',
        current_price: 'Precio actual',
        iban: 'IBAN',
        bban: 'BBAN',
        ticker: 'Ticker',
        edit: 'Modifica',
        delete: 'Elimina',
        portfolio_name: 'Nombre del portfolio',
        add: 'Añadir',
        data: 'Datos',
        status: 'Status',
        empty_table: 'Esta mesa está vacia',
        address: 'Dirección',
        owners: 'Titulares',
        email: 'Correo electrónico',
        email_address: 'Correo electrónico',
        surname: 'Apellido',
        legal_names: 'Nombre titular',
        mobile: 'Celular',
        mobile_number: 'Número Celular',
        navigation: navigation.es,
    },

    en: {
        name: 'Name',
        amount: 'Amount',
        value: 'Value',
        profit_and_loss: 'P&L',
        type: 'Type',
        current_price: 'Current price',
        iban: 'IBAN',
        bban: 'BBAN',
        ticker: 'Ticker',
        edit: 'Modifica',
        delete: 'Elimina',
        portfolio_name: 'Portoflio name',
        add: 'Add',
        data: 'Data',
        status: 'Status',
        empty_table: 'This table is empty',
        address: 'Address',
        owners: 'Holders',
        email: 'E-mail',
        email_address: 'E-mail address',
        surname: 'Surname',
        legal_names: 'Holder name',
        mobile: 'Mobile',
        mobile_number: 'Mobile number',
        navigation: navigation.en,
    },

    fr: {
        name: 'Nom',
        amount: 'Quantité',
        value: 'Valeur',
        profit_and_loss: 'P&L',
        type: 'Taper',
        current_price: 'Prix actuel',
        iban: 'IBAN',
        bban: 'BBAN',
        ticker: 'Ticker',
        edit: 'Modifica',
        delete: 'Elimina',
        portfolio_name: 'Nom du portefeuille',
        add: 'Ajouter',
        data: 'Données',
        status: 'Status',
        empty_table: 'Ce tableau est vide',
        address: 'Adresse',
        owners: 'Titulaires',
        email: 'E-mail',
        email_address: 'Adresse e-mail',
        surname: 'Prénom',
        legal_names: 'Nom titulaire',
        mobile: 'Portable',
        mobile_number: 'Numéro de portable',
        navigation: navigation.fr,
    }

};
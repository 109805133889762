<template>
    <div id="connect">
        <h1 class="headline-large black">
            Connetti i tuoi asset
        </h1>
        <div class="connection-frame">

            <connections-list-component />

        </div>
    </div>
</template>

<script>

    import ConnectionsListComponent from '@/components/connect/navigation/ConnectionsList';

    export default {
        name: 'Connect-Connections-View',
        components: {
            ConnectionsListComponent
        },
    }
</script>

<style scoped>
    div#connect {
        width: 100%;
        max-width: 808px;
        margin: 32px auto auto auto;
    }

        div#connect > div.connection-frame {
            margin-top: 16px;
        }
</style>
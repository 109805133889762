<template>
    <div class="table-navigation-menu">

        <div class="table-switcher-row">
            <asset-connection-switch-item-component :label="$t('tables.navigation.assets')"
                                                    :active="activeItem === 'asset'"
                                                    orientation="left"
                                                    @click="clickSwitcherMenu('asset')" />
            <asset-connection-switch-item-component :label="$t('tables.navigation.connections')"
                                                    :active="activeItem === 'connection'"
                                                    orientation="right"
                                                    @click="clickSwitcherMenu('connection')" />
        </div>

        <div class="table-navigation-row"
             v-if="activeItem === 'asset'">

            <asset-connection-toggle-item-component :label="$t('tables.navigation.all')"
                                                    :active="activeFilter === 'all'"
                                                    @click="clickToggleMenu('all')" />
            <asset-connection-toggle-item-component :label="$t('tables.navigation.crypto')"
                                                    :active="activeFilter === 'crypto'"
                                                    @click="clickToggleMenu('crypto')" />
            <asset-connection-toggle-item-component :label="$t('tables.navigation.stocks')"
                                                    :active="activeFilter === 'stocks'"
                                                    @click="clickToggleMenu('stocks')" />
            <asset-connection-toggle-item-component :label="$t('tables.navigation.liquidity')"
                                                    :active="activeFilter === 'cash'"
                                                    @click="clickToggleMenu('cash')" />
        </div>

        <div class="table-navigation-row"
             v-if="activeItem === 'connection'">

            <asset-connection-toggle-item-component :label="$t('tables.navigation.all')"
                                                    :active="activeFilter === 'all'"
                                                    @click="clickToggleMenu('all')" />
            <asset-connection-toggle-item-component :label="$t('tables.navigation.banks')"
                                                    :active="activeFilter === 'banks'"
                                                    @click="clickToggleMenu('banks')" />
            <asset-connection-toggle-item-component :label="$t('tables.navigation.exchange')"
                                                    :active="activeFilter === 'exchanges'"
                                                    @click="clickToggleMenu('exchanges')" />
            <asset-connection-toggle-item-component :label="$t('tables.navigation.wallet')"
                                                    :active="activeFilter === 'wallets'"
                                                    @click="clickToggleMenu('wallets')" />
            <asset-connection-toggle-item-component :label="$t('tables.navigation.manual')"
                                                    :active="activeFilter === 'manual'"
                                                    @click="clickToggleMenu('manual')" />
        </div>

    </div>
</template>

<script>

    import AssetConnectionSwitchItemComponent from '@/components/dashboard/table/navigation/AssetConnectionSwitchItem';
    import AssetConnectionToggleItemComponent from '@/components/dashboard/table/navigation/AssetConnectionToggleItem';

    export default {
        name: 'Navigation-Menu-Component',
        props: ['table', 'page', 'path'],
        components: {
            AssetConnectionSwitchItemComponent,
            AssetConnectionToggleItemComponent
        },
        data() {
            return {
                activeItem: undefined,
                activeFilter: undefined
            }
        },
        created() {
            this.activeItem = (this.table.properties && this.table.properties.menuPreference) ? this.table.properties.menuPreference : 'asset';
            this.activeFilter = (this.table.properties && this.table.properties.menuPreferenceFilter) ? this.table.properties.menuPreferenceFilter : 'all';
        },
        methods: {
            clickSwitcherMenu(value) {
                if (value !== this.activeItem) {
                    this.activeItem = value;
                    this.activeFilter = 'all'
                }
            },
            clickToggleMenu(value) {
                if (value !== this.activeFilter) {
                    this.activeFilter = value;
                }
            }
        },
        watch: {
            // save and notify the parent on the change of the activeItem data
            activeItem: function (newValue, oldValue) {
                
                this.$parent.setActiveItem(newValue);

                if (this.page == null || this.path == null || oldValue == null) {
                    return;
                }

                this.$store.dispatch('pages/saveTableMenuDisplayPreference', {
                    page: this.page,
                    path: this.path,
                    menuPreference: newValue
                });
            },

            activeFilter: function (newValue, oldValue) {
                
                this.$parent.setActiveFilter(newValue);

                if (this.page == null || this.path == null || oldValue == null) {
                    return;
                }

                this.$store.dispatch('pages/saveTableMenuDisplayPreferenceFilter', {
                    page: this.page,
                    path: this.path,
                    menuPreferenceFilter: newValue
                });
            }
        }
    }
</script>

<style scoped>
    div.table-navigation-menu {
        display: flex;
        flex-direction: column;
    }

        div.table-navigation-menu > div.table-navigation-row {
            display: flex;
            gap: 4px;
            margin-bottom: 6px;
            justify-content: center;
        }

        div.table-navigation-menu > div.table-switcher-row {
            display: flex;
            margin-bottom: 6px;
            justify-content: center;
        }
</style>
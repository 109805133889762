import api from '../../../api';

class MarketsStocksPrices {

  get(tickers) {
    return api.get('/v1/markets/stocks/' + tickers.join(',')  );
  }
}

export default new MarketsStocksPrices();

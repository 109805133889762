import api from '@/services/api';

class PostUsersPages {

    post(id, data) {
        return api.post('/v1/users/' + id + '/pages', data);
    }
}

export default new PostUsersPages();

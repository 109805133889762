import api from '@/services/api';

class GetUsersOpenbankingRequisition {

    get(userId, query) {

        /*
    
        query is a json like this:
    
    
        {
          account_id: abcdefghifle
          requisition_id: abcdefghifle
        }
    
        */

        let url = '/v1/users/' + userId + '/openbanking/requisition/'

        if (query && query.requisition_id) {
            url += '?requisition_id=' + query.requisition_id;
        }

        return api.get(url);
    }
}

export default new GetUsersOpenbankingRequisition();

import api from '@/services/api';

class PlatformPing {

    ping() {
        return api.get('/');
    }
}

export default new PlatformPing();

<template>

    <div :class="{
                'section mobile-drop-area': true,
                'align-middle': section.metatype === 'text',
                'dragHover': dragHover
            }"
         draggable="true"
         @dragstart="startDrag"
         @drop="onDrop"
         @dragleave.prevent="onLeave"
         @dragover.prevent="onEnter"
         :id="'section-' + index"
         :style="customPadding">

        <span v-if="!$breakpoints.isXS()"
              class="material-icons"
              @mouseover="mouseHoverAdd = true"
              @mouseleave="mouseHoverAdd = false"
              @click="addRowBelow">
            add
        </span>
        <span v-if="!$breakpoints.isXS()
              || (section.metatype !== 'table'
              && section.metatype !== 'portofliosDistribution'
              && section.metatype !== 'connectionAssets')"
              class="material-icons"
              @click="displayContextMenu"
              @mouseover="mouseHoverGrab = true"
              @mouseleave="mouseHoverGrab = false"
              @touchstart="startTouch"
              @touchmove="moveTouch"
              @touchend="endTouch">
            drag_indicator
        </span>


        <!-- choose which section to show based on the metatype of the section -->

        <template v-if="section.metatype === 'text'">

            <text-component :text="section.text"
                            :page="page"
                            :path="path" />


        </template>
        <template v-else-if="section.metatype === 'table'">

            <table-component :table="section.table"
                             :page="page"
                             :path="path" />

        </template>
        <template v-else-if="section.metatype === 'connectionAssets' &&
                             currentPage.properties.purpose === 'connection'">

            <connection-assets-component :connection="currentPage.properties.connection"
                                         :page="page"
                                         :path="path" />

        </template>
        <template v-else-if="section.metatype === 'assetGraph' &&
                             currentPage.properties.purpose === 'asset'">

            <asset-historical-graph-component :asset="currentPage.properties.asset"
                                              :page="page"
                                              :path="path" />

        </template>
        <template v-else-if="section.metatype === 'portofliosDistribution' &&
                             currentPage.properties.purpose === 'asset'">

            <asset-location-component :asset="currentPage.properties.asset"
                                      :page="page"
                                      :path="path" />

        </template>
        <template v-else-if="section.metatype === 'portofliosDistributionChart' &&
                             currentPage.properties.purpose === 'asset'">

            <asset-distribution-component :asset="currentPage.properties.asset"
                                          :page="page"
                                          :path="path" />

        </template>
        <template v-else-if="section.metatype === 'companyProfile' &&
                             currentPage.properties.purpose === 'asset' &&
                             currentPage.properties.asset.type === 'stocks'">

            <company-profile-component :asset="currentPage.properties.asset"
                                       :page="page"
                                       :path="path" />

        </template>
        <template v-else-if="section.metatype === 'companyValuationMetrics' &&
                             currentPage.properties.purpose === 'asset' &&
                             currentPage.properties.asset.type === 'stocks'">

            <company-valuation-metrics-component :asset="currentPage.properties.asset"
                                                 :page="page"
                                                 :path="path" />

        </template>
        <template v-else-if="section.metatype === 'companyDividends' &&
                             currentPage.properties.purpose === 'asset' &&
                             currentPage.properties.asset.type === 'stocks'">

            <company-dividends-component :asset="currentPage.properties.asset"
                                         :page="page"
                                         :path="path" />

        </template>
        <template v-else-if="section.metatype === 'insiderTransactions' &&
                             currentPage.properties.purpose === 'asset' &&
                             currentPage.properties.asset.type === 'stocks'">

            <company-insider-transactions-component :asset="currentPage.properties.asset"
                                                    :page="page"
                                                    :path="path" />

        </template>
        <template v-else-if="section.metatype === 'ownerConnections' &&
                             currentPage.properties.purpose === 'owner'">

            <owner-connections-component :owner="currentPage.properties.owner"
                                         :page="page"
                                         :path="path" />

        </template>


        <context-menu-component :show="showContextMenu"
                                :page="page"
                                :path="path"
                                :metatype="section.metatype"
                                :pagePurpose="currentPage.properties.purpose || 'general'" />

        <hover-information-grab-component :show="mouseHoverGrab && !showContextMenu" />
        <hover-information-add-component :show="mouseHoverAdd" />

    </div>

    <div class="dummy-section"
         :style="placeholderPositionCSS"/>

</template>

<script>

    import TextComponent from '@/components/dashboard/section/elements/Text';
    import TableComponent from '@/components/dashboard/section/elements/Table';


    import AssetHistoricalGraphComponent from '@/components/dashboard/section/elements/asset/HistoricalGraph';
    import AssetLocationComponent from '@/components/dashboard/section/elements/asset/AssetLocation';
    import AssetDistributionComponent from '@/components/dashboard/section/elements/asset/AssetDistribution';
    
    import CompanyProfileComponent from '@/components/dashboard/section/elements/asset/stocks/CompanyProfile';
    import CompanyValuationMetricsComponent from '@/components/dashboard/section/elements/asset/stocks/CompanyValuationMetrics';
    import CompanyInsiderTransactionsComponent from '@/components/dashboard/section/elements/asset/stocks/CompanyInsiderTransactions';
    import CompanyDividendsComponent from '@/components/dashboard/section/elements/asset/stocks/CompanyDividends';

    import ConnectionAssetsComponent from '@/components/dashboard/section/elements/connections/ConnectionAssets';

    import OwnerConnectionsComponent from '@/components/dashboard/section/elements/owner/OwnerConnections';

    import ContextMenuComponent from '@/components/dashboard/section/contextMenu/ContextMenu';
    import HoverInformationGrabComponent from '@/components/dashboard/section/contextMenu/HoverInformationGrab';
    import HoverInformationAddComponent from '@/components/dashboard/section/contextMenu/HoverInformationAdd';


    export default {
        name: 'Section-Component',
        props: ['section', 'page', 'index'],
        data() {
            return {

                hasMoved: false,
                touchingPlaceholder: {
                    top: 0,
                    left: 0
                },

                dragHover: false,
                showContextMenu: false,
                mouseHoverGrab: false,
                mouseHoverAdd: false
            };
        },
        components: {
            TextComponent,
            TableComponent,

            AssetHistoricalGraphComponent,
            AssetLocationComponent,
            AssetDistributionComponent,

            CompanyProfileComponent,
            CompanyValuationMetricsComponent,
            CompanyInsiderTransactionsComponent,
            CompanyDividendsComponent,

            ConnectionAssetsComponent,

            OwnerConnectionsComponent,

            ContextMenuComponent,
            HoverInformationGrabComponent,
            HoverInformationAddComponent
        },
        computed: {
            path() {
                return [this.index];
            },
            currentPage() {
                return this.$store.getters['pages/getPages'][this.page]
            },
            customPadding() {
                let padding = '2px 52px 2px 4px';
                if (this.$breakpoints.isXS()) {
                    padding = '2px 24px 2px 4px';
                    if (this.section.metatype === 'table' ||
                        this.section.metatype === 'portofliosDistribution' ||
                        this.section.metatype === 'connectionAssets') {
                        padding = '2px 4px 2px 4px';
                    }
                }

                return {
                    '--padding': padding
                };
            },
            placeholderPositionCSS() {
                return {
                    '--top-position-placeholder': this.touchingPlaceholder.top + 4 + 'px',
                    '--left-position-placeholder': this.touchingPlaceholder.left + 4 + 'px',
                    '--display-placeholder': (this.hasMoved) ? 'block' : 'none',
                }
            }
        },
        methods: {

            /***************************** */
            /****      CLICK EVENT      ****/
            /***************************** */


            addRowBelow(pointerEvent) {
                pointerEvent;

                this.$store.dispatch('pages/createRowBelow', {
                    page: this.page,
                    path: [this.index],
                    type: 'p',
                    text: ''
                });
            },

            displayContextMenu(pointerEvent) {
                pointerEvent;

                // do not trigger if it is a drag and drop action
                if(this.hasMoved) {
                    return;
                }
                this.showContextMenu = true;
            },

            hideContextMenu() {

                this.showContextMenu = false;
            },


            /***************************** */
            /****    DRAG MANAGEMENT    ****/
            /***************************** */


            // touch screen

            startTouch(touchEvent) {

                // set the dummy section to the proper location
                this.touchingPlaceholder.left = touchEvent.changedTouches[0].clientX;
                this.touchingPlaceholder.top = touchEvent.changedTouches[0].clientY;
            },
            moveTouch(touchEvent) {
                touchEvent.preventDefault();
                this.hideContextMenu();
                this.hasMoved = true;

                // get x and y coordinates
                const clientX = touchEvent.changedTouches[0].clientX;
                const clientY = touchEvent.changedTouches[0].clientY;

                // assuming a single touchpoint
                this.touchingPlaceholder.left = clientX;
                this.touchingPlaceholder.top = clientY;

                // highlight the section it is hovered
                const hoverTarget = document.elementFromPoint(clientX, clientY);
                const targetSection = (hoverTarget.classList.contains('mobile-drop-area'))
                    ? hoverTarget
                    : hoverTarget.parentElement;
                if (targetSection.classList.contains('mobile-drop-area')) {
                    targetSection.classList.add("dragHover");
                }

                // removing the highlight from all the classes
                const allTargets = document.querySelectorAll('.section.dragHover');
                allTargets.forEach(target => {
                    if (target.id !== targetSection.id) {
                        target.classList.remove('dragHover');
                    }
                });

            },
            endTouch(touchEvent) {
                this.hasMoved = false;

                const endTarget = document.elementFromPoint(
                    touchEvent.changedTouches[0].clientX,
                    touchEvent.changedTouches[0].clientY);

                // the section is marked as mobile-drop-are
                // the end target is the child element of the section it is dropped to
                // here it is checked if it is the section or the child
                const targetSection = (endTarget.classList.contains('mobile-drop-area'))
                    ? endTarget
                    : endTarget.parentElement;
                targetSection.classList.remove('dragHover'); // remove hover highlight
                if (targetSection.classList.contains('mobile-drop-area')) {
                    
                    const firstElementIndex = this.index;
                    const secondElementIndex = targetSection.id.split('-')[1];
                    const page = this.page;

                    this.$store.dispatch('pages/switchRows', {
                        page: page,
                        firstElementIndex: firstElementIndex,
                        secondElementIndex: secondElementIndex
                    });

                }
            },




            // regular screen

            startDrag(dragEvent) {

                dragEvent.dataTransfer.dropEffect = 'move';
                dragEvent.dataTransfer.effectAllowed = 'move';
                dragEvent.dataTransfer.setData('index', this.index);
                dragEvent.dataTransfer.setData('page', this.page);
            },

            onDrop(dragEvent) {

                this.dragHover = false;

                const firstElementIndex = dragEvent.dataTransfer.getData('index');
                const secondElementIndex = this.index;
                const page = this.page;

                this.$store.dispatch('pages/switchRows', {
                    page: page,
                    firstElementIndex: firstElementIndex,
                    secondElementIndex: secondElementIndex
                });
            },

            onEnter() {
                this.dragHover = true;
            },

            onLeave() {
                this.dragHover = false;
            }
        }
    }
</script>

<style scoped>

    div.section {
        padding: var(--padding);
        display: flex;
        margin-top: 4px;
        position: relative;
    }

        div.section.align-middle {
            align-items: center;
        }

        div.section.dragHover {
            border-top: 2px solid var(--secondary);
            padding-top: 0px;
        }

        div.section span {
            cursor: grab;
            height: fit-content;
            color: transparent;
            border-radius: 2px;
        }

            div.section span:hover {
                background: var(--neutral);
            }

        div.section:hover span {
            color: var(--placeholder);
        }

    div.dummy-section {
        position: absolute;
        z-index: 1000;
        background-color: var(--black);
        opacity: 0.6;
        height: 24px;
        width: 256px;
        display: var(--display-placeholder);
        top: var(--top-position-placeholder);
        left: var(--left-position-placeholder);
        overflow: clip;
    }
</style>
import api from '@/services/api';

class UsersProfileName {

    post(id, name) {
        return api.post('/v1/users/' + id + '/profile/name', {
            name: name
        });
    }
}

export default new UsersProfileName();

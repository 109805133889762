import api from '../../../api';

class PlatformForexSymbols {

  get() {
    return api.get('/v1/platform/forex/symbols');
  }
}

export default new PlatformForexSymbols();

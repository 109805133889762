import api from '@/services/api';

class GetUsersProfileView {

    get(id) {
        return api.get('/v1/users/' + id + '/profile/view');
    }
}

export default new GetUsersProfileView();

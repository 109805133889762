import api from '@/services/api';

class DeleteUsersCryptoWalletAddress {

    delete(id, source, blockchain) {
        return api.delete('/v1/users/' + id + '/crypto/wallet/address/' + source + '/' + blockchain);
    }
}

export default new DeleteUsersCryptoWalletAddress();

import api from '@/services/api';

class PostUsersProfileView {

    post(id, data) {
        return api.post('/v1/users/' + id + '/profile/view', data);
    }
}

export default new PostUsersProfileView();

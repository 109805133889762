<template>
    <div>
        <div @click="voteThisAnswer"
             :style="cssProps"
             :class="{
            'poll-answer-item': true,
            'active': isActive
         }">
            <span class="progress" />
            <p class="progress-value title-small secondary bold">
                {{ percentageVotes }}%
            </p>

            <span v-if="isLoading" class="body-large secondary">
                <span class="spinner-border spinner-border-sm" />
            </span>
            <span v-else-if="isActive"
                  class="material-icons secondary">
                check_box
            </span>
            <span v-else
                  class="material-icons primary">
                check_box_outline_blank
            </span>
            <div>
                <p class="body-large black">
                    {{ answerTitle }}
                </p>
                <p v-if="proposedBy" class="body-small black">
                    {{ $t('rewards.added_by') }}
                    <span class="bold">
                        {{ proposedBy }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pool-Answer',
        props: ['answerTitle', 'answerId', 'proposedBy', 'votes', 'percentageUsersChosen', 'loading'],
        computed: {
            cssProps() {
                if (this.percentageUsersChosen) {
                    return {
                        '--width-percentage-users': '' + this.percentageUsersChosen + '%'
                    };
                }
                return {
                    '--width-percentage-users': '0%'
                };
            },
            isLoading() {
                return this.loading;
            },
            percentageVotes() {
                if (this.percentageUsersChosen) {
                    return Math.round(this.percentageUsersChosen * 100) / 100;
                }
                return 0;
            },
            isActive() {
                const userId = this.$store.state.auth.user.id;
                if (this.votes && this.votes.length > 0) {
                    return this.votes.includes(userId);
                }
                return false;
            }
        },
        methods: {
            voteThisAnswer() {
                if (this.loading) {
                    return;
                }
                this.$parent.voteAnswer(this.answerId);
            }
        }
    }
</script>

<style scoped>

    div.poll-answer-item {
        padding: 12px 32px 12px 32px;
        border: 1px solid var(--primary);
        border-radius: 12px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
    }

    /** progress */
    
    div.poll-answer-item span.progress {
        position: absolute;
        background-color: var(--secondary);
        height: 100%;
        width: var(--width-percentage-users);
        margin: -12px -32px -12px -32px;
        border-radius: 12px 0px 0px 12px;
        opacity: 0.2;
    }
    div.poll-answer-item p.progress-value {
        position: absolute;
        margin: -8px 12px;
        top: 50%;
        right: 0px;
    }

    /** active */

    div.poll-answer-item.active {
        border: 2px solid var(--secondary);
    }
    div.poll-answer-item.active p {
        color: var(--secondary);
    }
</style>
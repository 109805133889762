import api from '@/services/api';

class PostUsersCryptoWalletAddress {

    post(id, data) {
        return api.post('/v1/users/' + id + '/crypto/wallet/address', data);
    }
}

export default new PostUsersCryptoWalletAddress();

<template>
    <div id="connection-success-page">
        <h1 class="headline-small black">
            {{ $t('connect.result.asset_failing_connecting') }}
        </h1>
        <connect-error-animation-component />
        <div class="horizontal-frame">
            <button class="secondary full-rounded"
                    @click="goToDashboard">
                <p class="label-large">
                    {{ $t('connect.result.go_to_dashboard') }}
                </p>
            </button>
            <button class="tertiary full-rounded"
                    @click="goToConnect">
                <p class="label-large">
                    {{ $t('connect.result.add_more') }}
                </p>
            </button>
        </div>
        <div class="horizontal-frame">
            <button class="secondary outlined full-rounded"
                    @click="goToSupport">
                <p class="label-large">
                    {{ $t('connect.result.contact_the_support') }}
                </p>
            </button>
        </div>
    </div>
</template>

<script>

    import ConnectErrorAnimationComponent from '@/components/connect/result/ErrorAnimation';
    import PostUsersProfileAction from '@/services/users/profile/action/post.service';
    import EventBus from "@/common/EventBus";

    export default {
        name: 'Connect-Cash-Manual-Success',
        components: {
            ConnectErrorAnimationComponent
        },
        created() {

            // notify the backend of the error
            const user_id = this.$store.getters['auth/getUserId'];
            const type = 'error';
            const action = {
                type: 'connection',
                service: 'exchange',
                source: this.exchangeSource
            };

            PostUsersProfileAction.post(user_id, type, action).then(
                () => {
                    // do nothing on success
                }, (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
            
        },
        computed: {
            exchangeSource() {
                return this.$route.query.exchange;
            }
        },
        methods: {
            goToDashboard() {
                this.$router.push('/dashboard');
            },
            goToConnect() {
                this.$router.go(-1);
            },
            goToSupport() {
                this.$router.push('/support');
            }
        }
    }
</script>

<style scoped>
    div#connection-success-page {
        width: 100%;
        max-width: 808px;
        margin: 32px auto auto auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    div#connection-success-page > div.horizontal-frame {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
</style>
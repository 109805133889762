<template>
    <div class="view-owners-page">
        <div v-for="(owner, index) in currentOwners"
             v-bind:key="index"
             @click="goToOwnerAtIndex(index)"
             :style="`background-color: ${colors[index%3]}`">

            <p class="label-large black">
                {{ owner.nickname }}
            </p>
        </div>
    </div>


</template>

<script>

    import { ownerColors } from '@/assets/js/fields/ownerColors';

    export default {
        name: 'View-Onwers-Page',
        computed: {
            colors() {
                return ownerColors();
            },
            views() {
                return this.$store.getters['views/getViews'];
            },
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
            currentViewId() {
                return this.$store.getters['views/getCurrentView'];
            },
            allViewId() {
                return this.$store.getters['views/getAllViewId'];
            },
            currentView() {
                return this.views.find(x => x._id === this.currentViewId);
            },
            currentOwnersIds() {
                if (this.currentView == null || this.currentView.owners == null) {
                    return [];
                }
                return this.currentView.owners;
            },
            currentOwners() {
                if (this.owners == null || this.owners.length === 0) {
                    return [];
                }
                if (this.currentViewId === this.allViewId) {
                    return this.owners;
                }
                return this.owners.filter(x => this.currentOwnersIds.includes(x._id));
            },
        },
        methods: {

            goToOwnerAtIndex(index) {

                const currentOwner = this.currentOwners[index];
                const currentOwnerPageId = 'o' + currentOwner._id;

                // go to the page
                if (currentOwnerPageId in this.pages) {

                    this.pushOwnerPageRoute(currentOwnerPageId);

                } else {

                    // create the page - the go to it
                    const params = {
                        pageId: currentOwnerPageId,
                        name: currentOwner.nickname,
                        id: currentOwner._id,
                        icon: undefined,
                    };
                    this.$store.dispatch('pages/createOwnerPage', params).then(() => {

                        this.pushOwnerPageRoute(currentOwnerPageId);
                    });
                }
            },

            pushOwnerPageRoute(currentOwnerPageId) {

                // push the page
                if (this.$breakpoints.isLGOrLarger()) {

                    // if the screen is large enough, open it as a side panel
                    this.$router.push('/dashboard?p=' + currentOwnerPageId);


                } else {

                    // else, just go to the page
                    this.$router.push('/dashboard/' + currentOwnerPageId);
                }
            }
        }
    }
</script>

<style scoped>



    div.view-owners-page {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2px;
        margin: 0px 52px;
    }

    @media(max-width: 904px) {
        div.view-owners-page {
            margin: 0px 24px;
        }
    }

    div.view-owners-page > div {
        padding: 1px 8px;
        margin: 2px 0px;
        height: fit-content;
        width: fit-content;
        border-radius: var(--middle-rounded);
        cursor: pointer;
    }
</style>
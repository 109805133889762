<template>

    <form class="branded-input-form" @submit.prevent="saveCryptoWalletAddress">

        <div class="fields-frame">
            <input-field-component v-model="cryptoWalletAddress.source_name"
                                   type="text"
                                   :label="$t('connect.wallet_form.type_your_wallet')"
                                   id="source_name"
                                   :isValid="isValid.source_name" />
        </div>
        <div class="fields-frame">
            <add-owner-multiselect-field-component v-model="ownerIds"
                                                   @onAddItem="onAddItem"
                                                   @onRemoveItem="onRemoveItemIndex"
                                                   :isValid="isValid.ownerIds" />
        </div>
        <div class="fields-frame">
            <input-field-component v-model="cryptoWalletAddress.address"
                                   type="text"
                                   :label="$t('connect.wallet_form.wallet_address')"
                                   id="address"
                                   :isValid="isValid.address"
                                   @change="autofillBlockchain" />
        </div>
        <div class="fields-frame">
            <input-field-autocomplete-component v-model="cryptoWalletAddress.blockchain_name"
                                                type="text"
                                                :label="$t('connect.wallet_form.blockchain')"
                                                id="blockchain_name"
                                                :list="supported_crypto_wallet_address_blockchain_names"
                                                :isValid="isValid.blockchain_name" />
        </div>

        <div class="buttons-frame">

            <!-- confirm button start -->
            <button class="tertiary full-rounded"
                    :disabled="isFormDisabled">
                <span v-if="isFormDisabled"
                      class="material-icons loading-icon">
                    sync
                </span>
                <p class="label-large">
                    {{ $t('forms.connect') }}
                </p>
            </button>
            <!-- confirm button end -->

        </div>
    </form>

</template>

<script>
    import EventBus from "@/common/EventBus";

    import DeleteUsersCryptoWalletAddress from "@/services/users/crypto/wallet/address/delete.service";
    import PostUsersCryptoWalletAddress from "@/services/users/crypto/wallet/address/post.service";
    import PatchUsersCryptoWalletAddress from "@/services/users/crypto/wallet/address/patch.service";
    import PlaftormCryptoWalletAddressBlockchain from "@/services/platform/crypto/wallet/address/blockchain/get.service";

    import AddOwnerMultiselectFieldComponent from '@/components/fields/owner/AddOwnerMultiselectField';
    import InputFieldAutocompleteComponent from '@/components/fields/autocomplete/InputFieldAutocomplete';
    import InputFieldComponent from '@/components/fields/InputField';

    export default {
        name: "Connect-Crypto-Wallet-Form",
        components: {
            InputFieldComponent,
            AddOwnerMultiselectFieldComponent,
            InputFieldAutocompleteComponent,
        },
        data() {

            return {
                cryptoWalletAddress: {},
                supported_crypto_wallet_address_blockchains: [],
                supported_crypto_wallet_address_blockchain_names: [],
                manual_sources: [],
                isFormDisabled: false,
                ownerIds: [],
                isValid: {
                    source_name: true,
                    address: true,
                    blockchain_name: true,
                    ownerIds: true,
                },
            };
        },
        methods: {



            /* owner ids multiselect field */
            onAddItem(value) {
                if (!this.ownerIds.includes(value)) {
                    this.ownerIds.push(value);
                }
            },
            onRemoveItemIndex(index) {
                this.ownerIds.splice(index, 1);
            },




            fillToggleBoxManual(sources) {
                this.manual_sources = sources;
            },
            autofillBlockchain() {
                const walletAddress = this.cryptoWalletAddress.address;

                if (!walletAddress) {
                    this.cryptoWalletAddress.blockchain_name = '';
                    this.isBlockchainFieldActive = true;
                    return;
                }

                // check if it is possible to autofill the blockchain

                const bitcoinRegex = "^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$";
                const ethereumRegex = "^0x[a-fA-F0-9]{40}$";
                const dogecoinRegex = "D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}";
                const litecoinRegex = "[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}";
                const moneroRegex = "[48][0-9AB][1-9A-HJ-NP-Za-km-z]{93}";
                const dashRegex = "X[1-9A-HJ-NP-Za-km-z]{33}";
                const rippleRegex = "r[0-9a-zA-Z]{24,34}";

                if (walletAddress.match(bitcoinRegex)) {
                    this.cryptoWalletAddress.blockchain_name = 'Bitcoin';
                    this.isBlockchainFieldActive = false;

                } else if (walletAddress.match(ethereumRegex)) {
                    this.cryptoWalletAddress.blockchain_name = 'Ethereum';
                    this.isBlockchainFieldActive = false;

                } else if (walletAddress.match(litecoinRegex)) {
                    this.cryptoWalletAddress.blockchain_name = 'Litecoin';
                    this.isBlockchainFieldActive = false;

                } else if (walletAddress.match(dogecoinRegex)) {
                    this.cryptoWalletAddress.blockchain_name = 'Dogecoin';
                    this.isBlockchainFieldActive = false;

                } else if (walletAddress.match(moneroRegex)) {
                    this.cryptoWalletAddress.blockchain_name = 'Monero';
                    this.isBlockchainFieldActive = false;

                } else if (walletAddress.match(dashRegex)) {
                    this.cryptoWalletAddress.blockchain_name = 'Dash';
                    this.isBlockchainFieldActive = false;

                } else if (walletAddress.match(rippleRegex)) {
                    this.cryptoWalletAddress.blockchain_name = 'Ripple';
                    this.isBlockchainFieldActive = false;

                }
            },
            saveCryptoWalletAddress() {

                // validate

                if (this.cryptoWalletAddress.source_name == null ||
                    this.cryptoWalletAddress.source_name === '') {
                    this.isValid.source_name = false;
                    return;
                } else {
                    this.isValid.source_name = true;
                }
                if (this.ownerIds == null ||
                    this.ownerIds.length === 0) {
                    this.isValid.ownerIds = false;
                    return;
                } else {
                    this.isValid.ownerIds = true;
                }
                if (this.cryptoWalletAddress.address == null ||
                    this.cryptoWalletAddress.address === '') {
                    this.isValid.address = false;
                    return;
                } else {
                    this.isValid.address = true;
                }
                if (this.cryptoWalletAddress.blockchain_name == null ||
                    this.cryptoWalletAddress.blockchain_name === '' ||
                    !this.supported_crypto_wallet_address_blockchain_names.includes(this.cryptoWalletAddress.blockchain_name)) {
                    this.isValid.blockchain_name = false;
                    return;
                } else {
                    this.isValid.blockchain_name = true;
                }

                // execute

                this.isFormDisabled = true;

                this.supported_crypto_wallet_address_blockchains.forEach((item) => {
                    if (item.name === this.cryptoWalletAddress.blockchain_name) {
                        this.cryptoWalletAddress.blockchain = item.id;
                    }
                });

                const data = {
                    blockchain_name: this.cryptoWalletAddress.blockchain_name,
                    blockchain: this.cryptoWalletAddress.blockchain,
                    source: this.cryptoWalletAddress.source_name,
                    source_name: this.cryptoWalletAddress.source_name,
                    address: this.cryptoWalletAddress.address,
                    owners: this.ownerIds,
                };


                const user_id = this.$store.getters['auth/getUserId'];
                PostUsersCryptoWalletAddress.post(user_id, data).then(
                    () => {

                        PatchUsersCryptoWalletAddress.patch(user_id, data.source, data.blockchain).then(
                            () => {


                                // Refresh Vuex store with new data
                                this.$store.dispatch('connections/refresh').then(() => {
                                    this.isFormDisabled = false;
                                }).then(() => {
                                    this.$router.push('/connect/crypto/wallet/success');
                                });

                            },
                            () => {
                                this.isFormDisabled = false;

                                /** if the connection fails, destroy the record on the database */
                                DeleteUsersCryptoWalletAddress.delete(user_id, data.source, data.blockchain).then(
                                    () => {
                                        // do nothing on success
                                    },
                                    () => {
                                        // do nothing on error
                                    }
                                ).then(() => {

                                    this.isFormDisabled = false;
                                    this.$router.push({
                                        path: '/connect/crypto/wallet/error',
                                        query: {
                                            source: data.source,
                                            blockchain: data.blockchain
                                        }
                                    });
                                });

                            }
                        );
                    },
                    (error) => {
                        this.isFormDisabled = false;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            }
        },
        created() {


            PlaftormCryptoWalletAddressBlockchain.get().then(
                (response) => {
                    this.supported_crypto_wallet_address_blockchains = response['data'].blockchains;
                    this.supported_crypto_wallet_address_blockchain_names = this.supported_crypto_wallet_address_blockchains.map(a => a.name.toString());
                },
                (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
    };
</script>
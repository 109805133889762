<template>


    <table class="branded-table">
        <thead>
            <tr>
                <th class="text-align-left">
                    <p class="title-medium black bold">
                        {{ $t('tables.name') }}
                    </p>
                </th>
                <th>
                    <p class="title-medium black bold">
                        {{ $t('tables.value') }}
                    </p>
                </th>
                <th>
                    <timeframe-switcher-component />
                </th>
            </tr>
        </thead>
        <tbody v-if="assets.length > 0">

            <template v-for="(asset, index) in assets"
                      v-bind:key="index">

                <tr :class="{
                        'white-background': index%2,
                        'neutral-background': !(index%2)
                    }">

                    <td class="text-align-left"
                        @click="goToDetails(asset)">
                        <asset-name-component :name="asset.name"
                                              :ticker="asset.ticker"
                                              :type="asset.type" />
                    </td>
                    <td @click="goToDetails(asset)">
                        <p class="body-medium black">
                            <value-component :amount="asset.amount"
                                             :currentPriceDollars="asset.price" />
                        </p>
                        <amount-component :amount="asset.amount"
                                          :precisionDigits="6" />
                    </td>
                    <td v-if="asset.unknownCryptocurrency">

                        <amend-crypto-staking-component :id="asset._id"
                                                        :ticker="asset.ticker"
                                                        :amount="asset.amount"
                                                        :table="table" />

                    </td>
                    <td v-else
                        class="text-align-left"
                        @click="goToDetails(asset)">

                        <timeframe-price-component :ticker="asset.ticker"
                                                   :type="asset.type" />
                        <percentage-change-component :ticker="asset.ticker"
                                                     :type="asset.type" />

                    </td>

                </tr>

                <tr :class="{
                        'white-background': index%2,
                        'neutral-background': !(index%2)
                    }">

                    <td class="text-align-left"
                        @click="goToDetails(asset)"
                        colspan="3">

                        <asset-owners-component :ownerIds="asset.owners" />

                    </td>
                </tr>

            </template>

        </tbody>
        <tbody v-else>

            <no-item-in-table-component :activeItem="'asset'"
                                        :activeFilter="filter" />

        </tbody>
    </table>


</template>

<script>

    import TimeframeSwitcherComponent from '@/components/dashboard/table/heading/TimeframeSwitcher';

    import ValueComponent from '@/components/dashboard/table/row/asset/Value';
    import AmountComponent from '@/components/dashboard/table/row/asset/Amount';
    import AssetNameComponent from '@/components/dashboard/table/row/asset/AssetName';
    import TimeframePriceComponent from '@/components/dashboard/table/row/asset/TimeframePrice';
    import PercentageChangeComponent from '@/components/dashboard/table/row/asset/PercentageChange';
    import AssetOwnersComponent from '@/components/dashboard/table/row/asset/AssetOwners';

    import NoItemInTableComponent from '@/components/dashboard/table/NoItemInTable';


    import AmendCryptoStakingComponent from '@/components/dashboard/table/amend/AmendCryptoStaking';

    export default {
        name: 'Asset-View-Component',
        props: ['table', 'page', 'path', 'filter'],
        components: {
            TimeframeSwitcherComponent,
            ValueComponent,
            AmountComponent,
            AssetNameComponent,
            NoItemInTableComponent,
            TimeframePriceComponent,
            PercentageChangeComponent,
            AssetOwnersComponent,
            AmendCryptoStakingComponent
        },
        data() {
            return {
                showModalEditManual: false,
                modalEditManualData: '{}'
            };
        },
        methods: {
            goToDetails(asset) {

                const targetPageId = asset.ticker + '-' + this.currentView;

                if (targetPageId in this.pages) {

                    this.routeToPage(asset);

                } else {

                    // create the page - the go to it
                    const params = {
                        name: asset.name,
                        pageId: targetPageId,
                        ticker: asset.ticker,
                        icon: undefined,
                        type: asset.type,
                    };
                    this.$store.dispatch('pages/createAssetPage', params).then(() => {
                        this.routeToPage(asset);
                    });
                }
            },
            routeToPage(asset) {

                if (this.$breakpoints.isLGOrLarger()) {

                    // if the screen is large enough, open it as a side panel
                    this.$router.push('/dashboard?p=' + asset.ticker);

                } else {

                    // else, just go to the page
                    this.$router.push('/dashboard/' + asset.ticker);
                }
            }
        },
        computed: {

            connections() {
                return this.$store.getters['connections/getConnections'];
            },
            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            pages() {
                return this.$store.getters['pages/getPages'];
            },
            currentView() {
                return this.$store.getters['views/getCurrentView'];
            },
            filterTypes() {
                let filter = [];

                switch (this.filter) {
                    case 'stocks': filter = ['stocks', 'etfs']; break;
                    case 'crypto': filter = ['cryptocurrency']; break;
                    case 'cash': filter = ['forex']; break;
                }
                return filter;
            },

            assets() {
                let assets = [];

                // get all assets from connections
                this.connections.forEach(connection => {
                    if (this.table.properties.allConnections || this.table.connections.includes(connection.id)) {
                        const connectionAssets = [...connection.balance]
                        connectionAssets.forEach(asset => {
                            asset.owners = [...connection.owners];
                        });
                        assets.push(...connectionAssets);
                    }
                });


                // filter assets by asset type
                if (this.filter != null && this.filter !== 'all') {

                    assets = assets.filter(x => this.filterTypes.includes(x.type));
                }


                // aggregate by asset ticker
                const aggregatedAssets = [];

                for (const asset of assets) {


                    let ticker = asset.ticker;
                    // handle staking of crypto
                    if (asset.type === 'cryptocurrency') {
                        ticker = (asset.staking_ticker) ? asset.staking_ticker : ticker;
                        asset.ticker = ticker;
                    }

                    const index = aggregatedAssets.map(x => x.ticker).indexOf(ticker);

                    if (index === -1) {
                        const aggregatedAsset = { ...asset } // copy the value

                        ticker = (asset.type === 'forex') ? ticker + '/USD' : ticker; // handle currency

                        // the current price of the asset
                        aggregatedAsset.price = (this.markets[ticker]) ? this.markets[ticker].price : undefined;
                        // set this flag to true if it is an unknow cryptocurrency - display the add information modal
                        aggregatedAsset.unknownCryptocurrency = (asset.type === 'cryptocurrency' && aggregatedAsset.price == null) ? true : false;
                        aggregatedAssets.push(aggregatedAsset);
                    } else {
                        aggregatedAssets[index].purchasePrice = (aggregatedAssets[index].purchasePrice * aggregatedAssets[index].amount + asset.purchasePrice * asset.amount) / (aggregatedAssets[index].amount + asset.amount);
                        aggregatedAssets[index].amount = aggregatedAssets[index].amount + asset.amount;
                        aggregatedAssets[index].owners.push(...asset.owners);
                    }
                }

                // amend names of cryptocurrencies if they are not present - they are in staking

                for (const aggregatedAsset of aggregatedAssets) {
                    aggregatedAsset.name = aggregatedAsset.name || aggregatedAsset.ticker;
                    aggregatedAsset.owners = [...new Set(aggregatedAsset.owners)];
                }

                return aggregatedAssets;
            }
        }
    }
</script>

<style scoped>

    table.branded-table tbody tr {
        cursor: pointer;
    }

        table.branded-table tbody tr.white-background {
            background-color: var(--white);
        }
        table.branded-table tbody tr.neutral-background {
            background-color: var(--neutral);
        }
</style>
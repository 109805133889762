<template>
    <form class="branded-input-form" @submit.prevent="handleLogin">

        <!-- Heading and text -->

        <div class="form-heading-frame">

            <h1 class="headline-medium black">
                {{ $t('forms.auth.enter_your_vault') }}
            </h1>
            <p class="title-medium black">
                {{ $t('forms.auth.welcome_back') }}
            </p>

        </div>

        <!-- Sign up with google here -->
        <signin-with-google-button-component :context="'signin_with'"
                                             @onCallback="setLoadingState" />

        <!-- Form starts here -->

        <div class="fields-frame">

            <input-field-component type="text"
                                   v-model="input_value.username"
                                   id="username"
                                   :label="$t('forms.auth.email_or_username')"
                                   :isValid="isValid.username" />
        </div>

        <div class="fields-frame">

            <input-field-component type="password"
                                   v-model="input_value.password"
                                   id="password"
                                   :label="$t('forms.auth.password')"
                                   :isValid="isValid.password" />
        </div>

        <div class="info-frame">
            <p class="body-medium black">
                {{ $t('forms.auth.forgot_email_username') }}
                <a href="https://linktr.ee/360track" class="tertiary">
                    {{ $t('forms.auth.contact_us') }}
                </a>
            </p>

            <p class="body-medium black">
                {{ $t('forms.auth.forgot_password') }}
                <router-link to="/forgot-password" class="tertiary">
                    {{ $t('forms.auth.reset_here') }}
                </router-link>
            </p>
        </div>

        <div v-if="error_message.length>0" class="body-medium error">
            {{ error_message }}
        </div>

        <button :disabled="loading" class="secondary full-rounded">
            <span v-if="loading"
                  class="material-icons loading-icon">
                sync
            </span>
            <p class="label-large">
                {{ $t('forms.auth.enter_button') }}
            </p>
        </button>

    </form>

</template>

<script>  


    import InputFieldComponent from '@/components/fields/InputField';
    import SigninWithGoogleButtonComponent from "@/components/auth/google/SigninWithGoogleButton";

    export default {
        name: "Login-Component",
        components: {
            InputFieldComponent,
            SigninWithGoogleButtonComponent
        },
        data() {
            return {
                fieldsValid: true,
                isValid: {
                    username: true,
                    password: true
                },
                input_value: {},
                loading: false,
                error_message: ""
            };
        },
        methods: {
            setLoadingState(newState) {
                this.loading = newState;
            },

            handleLogin() {

                /** validation */

                if (this.input_value.username == null || this.input_value.username === '') {
                    this.isValid.username = false;
                    return;
                } else {
                    this.isValid.username = true;
                }

                if (this.input_value.password == null || this.input_value.password === '') {
                    this.isValid.password = false;
                    return;
                } else {
                    this.isValid.password = true;
                }

                /** execution */

                this.loading = true;
                this.error_message = "";
                const user = {
                    username: this.input_value.username,
                    password: this.input_value.password
                };

                this.$store.dispatch("auth/login", user).then(
                    () => {

                        // you have logged in - refresh the authentication
                        this.$store.dispatch("auth/refresh");
                        // add the user id to the datalayer for the google tag manager
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'user_id': this.$store.getters['auth/getUserId']
                        });
                        this.$router.push("/dashboard");
                    },
                    (error) => {
                        const message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        this.isValid.username = false;
                        this.isValid.password = false;

                        switch (message) {
                            case 'Pending Account. Please Verify Your Email!':
                                this.error_message = this.$i18n.getLocaleMessage(this.$i18n.locale).forms.auth.pending_account;
                                break;
                            case 'User Not found.':
                                this.error_message = this.$i18n.getLocaleMessage(this.$i18n.locale).forms.auth.wrong_credentials;
                                break;
                            case 'Invalid Password!':
                                this.error_message = this.$i18n.getLocaleMessage(this.$i18n.locale).forms.auth.wrong_credentials;
                                break;
                            default:
                                this.error_message = this.$i18n.getLocaleMessage(this.$i18n.locale).forms.auth.generic_error;
                                break;
                        }

                        this.loading = false;
                    }
                );
            },
        },
    }

</script>

<style scoped>

    div.form-heading-frame,
    div.info-frame {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 0.5rem;
    }
</style>

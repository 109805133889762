<template>
    <div class="top-menu">

        <div class="align-beginning">
            <div class="breadcrumb">
                <span class="material-icons black">
                    dashboard
                </span>
                <p class="label-large black">
                    <template v-if="!$breakpoints.isXS()">
                        {{ $t('navigation.dashboard') }}
                    </template>
                    <span v-if="currentViewName">
                        <template v-if="!$breakpoints.isXS()">
                            /
                        </template>
                            {{ currentViewName }}
                    </span>
                    <span v-if="pageTitle">
                        / {{ pageTitle }}
                    </span>
                </p>
            </div>
            <div class="saving-status">

                <template v-if="!isLoaded">

                    <span class="material-icons tertiary rotate-right">
                        rotate_right
                    </span>
                    <p class="label-large tertiary">
                        {{ $t('navigation.loading_data') }}
                    </p>

                </template>
                <template v-else-if="isSaved">

                    <span class="material-icons secondary">
                        check_circle_outline
                    </span>
                    <p class="label-large secondary">
                        {{ $t('navigation.saved') }}
                    </p>

                </template>
                <template v-else>

                    <span class="material-icons tertiary rotate-left">
                        sync
                    </span>
                    <p class="label-large tertiary">
                        {{ $t('navigation.saving') }}
                    </p>

                </template>

            </div>
        </div>

        <div class="align-end"
             v-if="isMainDashboard">
            <switch-view-dropdown-component />
        </div>

    </div>
</template>

<script>

    import SwitchViewDropdownComponent from '@/components/fields/view/SwitchViewDropdown';

    export default {
        name: 'Top-Menu-Components',
        props: ['pageId'],
        components: {
            SwitchViewDropdownComponent
        },
        computed: {
            isSaved() {
                return this.$store.getters['pages/isSaved'];
            },
            pageTitle() {
                const currentPage = this.$store.getters['pages/getPages'][this.pageId];
                return currentPage ? currentPage.properties.title : undefined;
            },
            isLoaded() {
                return this.$store.getters['pages/isLoaded'] &&
                    this.$store.getters['connections/isLoaded'];
            },
            isMainDashboard() {
                return this.pageId && this.pageId.split('-')[0] === 'dashboard';
            },
            views() {
                return this.$store.getters['views/getViews'];
            },
            currentView() {
                return this.$store.getters['views/getCurrentView'];
            },
            currentViewName() {

                if (this.views == null || this.currentView == null) {
                    return undefined;
                }

                const currentView = this.views.find(x => x._id === this.currentView);

                return currentView ? currentView.name : undefined;
            }
        }
    }
</script>

<style scoped>
    div.top-menu {
        width: calc(100% - 94px); /* 94px because takes into account the collapsed sidebar*/
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 8px;
        background-color: var(--neutral);
        position: fixed;
        top: 48px;
        z-index: 2;
    }
    /* on mobile the top distance is 68px because the topbar is thicker*/
    @media (max-width: 599px) {
        div.top-menu {
            width: calc(100% - 16px); /* 16px because takes does not take into account any sidebar */
            top: 64px;
        }
    }
    @media (min-width: 1440px) {
        div.top-menu {
            width: calc(100% - 272px); /* 272px because takes does not take into account the extended sidebar */
        }
    }

    div.top-menu > div.align-beginning {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    @media (min-width: 600px) {
        div.top-menu > div.align-end {
            margin-right: 64px;
        }
    }

    div.top-menu > div.align-beginning > div.breadcrumb {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 2px;
        overflow-x: hidden;
        white-space: nowrap;
    }

    div.top-menu > div.align-beginning > div.saving-status {
        display: flex;
        align-items: center;
        gap: 2px;
        padding: 2px;
    }

    /* icon animation management */

    span.rotate-right {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    span.rotate-left {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        animation-direction: reverse;
    }
</style>
import api from '../../../api';

class MarketsCryptoPrices {

  get(tickers) {
    return api.get('/v1/markets/crypto/' + tickers.join(',') );
  }
}

export default new MarketsCryptoPrices();


import GetUsersPages from '@/services/users/pages/get.service';
import PostUsersPages from '@/services/users/pages/post.service';
import DeleteUsersPages from '@/services/users/pages/delete.service';

import EventBus from "@/common/EventBus";


import { i18n } from '@/plugins/i18n';

const CHAIN_DELAY_MILLISEC = 620;

export const pages = {
    namespaced: true,
    state: {
        pages: {},
        isLoaded: false,
        saved: true,
        callChains: {}
    },
    actions: {


        /***************************************** */
        /***********       REFRESH       ***********/
        /***************************************** */


        refresh({ commit, rootGetters, dispatch }) {
            commit('notSetPageLoaded');

            const user_id = rootGetters['auth/getUserId'];
            GetUsersPages.get(user_id).then(
                (response) => {

                    const pages = response.data;
                    const allViewId = rootGetters['views/getAllViewId'];

                    if (pages == null ||
                        pages.length === 0 ||
                        !pages.find(x => x.page === 'dashboard' && x.view === allViewId)) {

                        // if there are no pages - create the dashboard page
                        commit('startOperation');
                        commit('initializePages');

                        // save the page to the database
                        dispatch('savePageToBackend', 'dashboard-' + allViewId);

                    } else {

                        pages.forEach(page => {
                            commit('storePage', page);
                        });
                    }

                    commit('setPageLoaded');


                }, (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },


        /***************************************** */
        /******     BACKEND COMMUNICATION     ******/
        /***************************************** */


        savePageToBackend({ commit, rootGetters }, page) {

            const user_id = rootGetters['auth/getUserId'];

            const allPages = rootGetters['pages/getPages'];
            const sections = allPages[page].sections;
            const properties = allPages[page].properties;

            const data = {
                page: page,
                sections: JSON.stringify(sections),
                properties: JSON.stringify(properties)
            };

            // take into consideration the presence of the view
            const splitPage = page.split('-');
            if (splitPage.length === 2) {
                data.page = splitPage[0];
                data.view = splitPage[1];
            }

            PostUsersPages.post(user_id, data).then(
                () => {

                    // end the operation by notifying it to the system
                    commit('endOperation');

                }, (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        },

        chainSavePageToBackend({ dispatch, rootGetters }, page) {

            setTimeout(() => {

                // check the chain of operations
                const lastSync = rootGetters['pages/getCallChains'][page];
                const now = new Date().getTime();
                if (lastSync && now < lastSync) {
                    return; // break, this operation is chained into the next one
                }

                dispatch('savePageToBackend', page);

            }, CHAIN_DELAY_MILLISEC + 25);

        },


        /***************************************** */
        /*********     TEXT MANAGEMENT     *********/
        /***************************************** */


        updateTitle({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('updateTitle', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        updateText({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('updateText', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        updateType({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('updateType', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        clearText({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('clearText', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        clearType({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('clearType', params);
            dispatch('chainSavePageToBackend', params.page);
        },



        /**************************************** */
        /*********     ROW MANAGEMENT     *********/
        /**************************************** */


        createRowAbove({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('createRowAbove', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        createRowBelow({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('createRowBelow', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        mergeRowWithUpperOne({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('mergeRowWithUpperOne', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        clearUpperRow({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('clearUpperRow', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        deleteCurrentRow({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('deleteCurrentRow', params);
            dispatch('chainSavePageToBackend', params.page);
        },


        /**************************************** */
        /********     TABLE MANAGEMENT     ********/
        /**************************************** */


        createTableBelow({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('createTableBelow', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        addConnectionsToTable({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('addConnectionsToTable', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        addConnectionsToTableByTableId({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('addConnectionsToTableByTableId', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        saveTableMenuDisplayPreference({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('saveTableMenuDisplayPreference', params);
            dispatch('chainSavePageToBackend', params.page);
        },
        saveTableMenuDisplayPreferenceFilter({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('saveTableMenuDisplayPreferenceFilter', params);
            dispatch('chainSavePageToBackend', params.page);
        },


        /***************************************** */
        /*********     DRAG MANAGEMENT     *********/
        /***************************************** */


        switchRows({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('switchRows', params);
            dispatch('chainSavePageToBackend', params.page);
        },


        /****************************************** */
        /*********    SECTION MANAGEMENT    *********/
        /****************************************** */


        appendSection({ commit, dispatch }, params) {
            commit('startOperation', params.page);
            commit('appendSection', params);
            dispatch('chainSavePageToBackend', params.page);
        },


        /***************************************** */
        /*********     PAGE MANAGEMENT     *********/
        /***************************************** */


        createAssetPage({ commit, dispatch }, params) {
            commit('startOperation');
            commit('createAssetPage', params);
            dispatch('savePageToBackend', params.pageId);
        },
        createConnectionPage({ commit, dispatch }, params) {
            commit('startOperation');
            commit('createConnectionPage', params);
            dispatch('savePageToBackend', params.id);
        },
        createOwnerPage({ commit, dispatch }, params) {
            commit('startOperation');
            commit('createOwnerPage', params);
            dispatch('savePageToBackend', params.pageId);
        },
        createDashboardViewPage({ commit, dispatch }, params) {
            commit('startOperation');
            // compose pageId
            params.pageId = 'dashboard-' + params.viewId;
            commit('createDashboardViewPage', params);
            dispatch('savePageToBackend', params.pageId);
        },
        deletePage({ commit, rootGetters }, params) {
            commit('startOperation');
            commit('deletePage', params);

            // communicate to the backend
            const page = params.pageId;
            const user_id = rootGetters['auth/getUserId'];

            DeleteUsersPages.delete(user_id, page).then(
                () => {

                    // end the operation by notifying it to the system
                    commit('endOperation');

                }, (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );


        }


    },
    mutations: {


        /***************************************** */
        /*********     TEXT MANAGEMENT     *********/
        /***************************************** */

        updateTitle(state, params) {

            const page = params.page;
            const title = params.title;

            state.pages[page].properties.title = title;
        },

        updateText(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays of the text
            const text = params.text; // the new text

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });
            currentItem.text.text = text;
        },

        updateType(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays of the text
            const type = params.type; // the new type

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });
            currentItem.text.type = type;
        },

        clearText(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays of the text

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });
            currentItem.text.text = '';
            currentItem.text.type = 'p';
        },

        clearType(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays of the text

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });
            currentItem.text.type = 'p';
        },


        /**************************************** */
        /*********     ROW MANAGEMENT     *********/
        /**************************************** */

        createRowAbove(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays
            const type = params.type;
            const text = params.text;
            const position = path.pop(1);

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            // insert the row before the "position" of the currentItem
            currentItem.splice(position, 0, {
                metatype: 'text',
                text: {
                    type: type,
                    text: text
                }
            });
        },

        createRowBelow(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays
            const type = params.type;
            const text = params.text;
            const position = path.pop(1);

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            // insert the row before the "position" of the currentItem
            currentItem.splice(position + 1, 0, {
                metatype: 'text',
                text: {
                    type: type,
                    text: text
                }
            });
        },

        mergeRowWithUpperOne(state, params) {

            /** the upper row is merged with the current one and its content
             * is put at the beginning of the target row
             
             this mechanism is counter-intuitive because we are used to think
             that the current one is the one that merge. The operation:

             row 4 -> Ciao, sono        // upper
             row 5 -> Marco             // target

             the operation is called on the row 5

             the result will be

             row 4 -> Ciao, sono
             row 5 -> Ciao, sono Marco
             
             */

            const page = params.page; // the id of the page
            const targetPath = params.path.slice(); // the path of the row to be merged

            if (targetPath[targetPath.length - 1] === 0) {
                return; // do nothing if the row is the first one
            }

            const upperPath = [...targetPath]; // the path of the upper row to merge
            upperPath[upperPath.length - 1] -= 1;

            let targetItem = state.pages[page].sections;
            targetPath.forEach(step => {
                targetItem = targetItem[step];
            });

            let upperItem = state.pages[page].sections;
            upperPath.forEach(step => {
                upperItem = upperItem[step];
            });

            targetItem.text.type = upperItem.text.type;
            targetItem.text.text = upperItem.text.text + targetItem.text.text;
        },

        clearUpperRow(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays
            const position = path.pop(1) - 1;

            if (position < 0) {
                return; // do nothing
            }

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            currentItem.splice(position, 1);
        },

        deleteCurrentRow(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays
            const position = path.pop(1);

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            // remove object at "position" in the current item
            currentItem.splice(position, 1);
        },


        /**************************************** */
        /********     TABLE MANAGEMENT     ********/
        /**************************************** */


        createTableBelow(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays

            const position = path.pop(1);

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            // insert the row before the "position" of the currentItem
            currentItem.splice(position + 1, 0, {
                metatype: 'table',
                table: {
                    properties: {
                        menuPreference: 'asset',
                        allConnections: true,
                        showNavigation: true
                    },
                    connections: []
                }
            });
        },

        addConnectionsToTable(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays
            const connectionIds = params.connectionIds;

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            currentItem.table.connections.push(...connectionIds);
        },

        addConnectionsToTableByTableId(state, params) {

            const page = params.page; // the id of the page
            const tableId = params.tableId; // the id of the table of the subarrays
            const connectionIds = params.connectionIds;

            const sections = state.pages[page].sections;

            // find the table by id, tables are always in the section level
            sections.forEach(section => {
                if (section.metatype === 'table' && 
                    section.table.id === tableId) {
                    section.table.connections.push(...connectionIds);
                }
            });
        },

        saveTableMenuDisplayPreference(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays
            const menuPreference = params.menuPreference;

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            currentItem.table.properties.menuPreference = menuPreference;
        },

        saveTableMenuDisplayPreferenceFilter(state, params) {

            const page = params.page; // the id of the page
            const path = params.path.slice(); // the indexes of the subarrays
            const menuPreferenceFilter = params.menuPreferenceFilter;

            let currentItem = state.pages[page].sections;
            path.forEach(step => {
                currentItem = currentItem[step];
            });

            currentItem.table.properties.menuPreferenceFilter = menuPreferenceFilter;
        },


        /***************************************** */
        /*********     DRAG MANAGEMENT     *********/
        /***************************************** */


        switchRows(state, params) {

            const firstElementPage = params.page;
            const firstElementIndex = params.firstElementIndex;

            const secondElementPage = params.page;
            const secondElementIndex = params.secondElementIndex;

            const temp = state.pages[firstElementPage].sections[firstElementIndex];
            state.pages[firstElementPage].sections[firstElementIndex] = state.pages[secondElementPage].sections[secondElementIndex];
            state.pages[secondElementPage].sections[secondElementIndex] = temp;
        },


        /****************************************** */
        /*********    SECTION MANAGEMENT    *********/
        /****************************************** */


        appendSection(state, params) {

            const page = params.page;
            const sectionId = params.sectionId;
            const tableObject = params.tableObject;

            let newSection = {};

            // switch among all the sections
            switch (sectionId) {
                case 'p':
                    newSection = {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    };
                    break;
                case 'h1':
                    newSection = {
                        metatype: 'text',
                        text: {
                            type: 'h1',
                            text: ''
                        }
                    };
                    break;
                case 'h2':
                    newSection = {
                        metatype: 'text',
                        text: {
                            type: 'h2',
                            text: ''
                        }
                    };
                    break;
                case 'h3':
                    newSection = {
                        metatype: 'text',
                        text: {
                            type: 'h3',
                            text: ''
                        }
                    };
                    break;
                case 'portofliosDistribution':
                    newSection = {
                        metatype: 'portofliosDistribution'
                    };
                    break;
                case 'portofliosDistributionChart':
                    newSection = {
                        metatype: 'portofliosDistributionChart'
                    };
                    break;
                case 'assetGraph':
                    newSection = {
                        metatype: 'assetGraph'
                    };
                    break;
                case 'companyProfile':
                    newSection = {
                        metatype: 'companyProfile'
                    };
                    break;
                case 'companyValuationMetrics':
                    newSection = {
                        metatype: 'companyValuationMetrics'
                    };
                    break;
                case 'companyDividends':
                    newSection = {
                        metatype: 'companyDividends'
                    };
                    break;
                case 'insiderTransactions':
                    newSection = {
                        metatype: 'insiderTransactions'
                    };
                    break;
                case 'connectionAssets':
                    newSection = {
                        metatype: 'connectionAssets'
                    };
                    break;
                case 'ownerConnections':
                    newSection = {
                        metatype: 'ownerConnections'
                    };
                    break;
                case 'table':
                    newSection = {
                        metatype: 'table',
                        table: {
                            properties: {
                                menuPreference: tableObject.properties.menuPreference,
                                allConnections: tableObject.properties.allConnections,
                                showNavigation: tableObject.properties.showNavigation
                            },
                            connections: tableObject.connections
                        }
                    };
                    break;
                default: return; // do not insert anything
            }

            // append the section
            state.pages[page].sections.push(newSection);
        },


        /***************************************** */
        /*********     PAGE MANAGEMENT     *********/
        /***************************************** */


        createAssetPage(state, params) {

            state.pages[params.pageId] = {
                properties: {
                    icon: params.icon,
                    title: params.name,
                    purpose: 'asset',
                    asset: {
                        name: params.name,
                        ticker: params.ticker,
                        type: params.type
                    }
                },
                sections: [
                    {
                        metatype: 'text',
                        text: {
                            type: 'h2',
                            text: i18n.global.t('dashboard.initialization.your_notes')
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: i18n.global.t('dashboard.initialization.write_here_notes')
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'portofliosDistribution'
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'portofliosDistributionChart'
                    },
                ],
            };

            // if the type is not forex, then add the historical graph
            if (params.type !== 'forex') {
                state.pages[params.pageId].sections.push(...[
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'assetGraph'
                    }
                ]);
            }

            // stocks specific blocks
            if (params.type === 'stocks') {
                state.pages[params.pageId].sections.push(...[
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'companyProfile'
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'companyValuationMetrics'
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'companyDividends'
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'insiderTransactions'
                    }
                ]);
            }
        },

        createConnectionPage(state, params) {

            state.pages[params.id] = {
                properties: {
                    icon: params.icon,
                    title: params.name,
                    purpose: 'connection',
                    connection: {
                        name: params.name,
                        id: params.id,
                        service: params.service
                    }
                },
                sections: [
                    {
                        metatype: 'text',
                        text: {
                            type: 'h2',
                            text: i18n.global.t('dashboard.initialization.your_notes')
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: i18n.global.t('dashboard.initialization.write_here_notes')
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'connectionAssets'
                    },
                ],
            };
        },

        createOwnerPage(state, params) {
            state.pages[params.pageId] = {
                properties: {
                    icon: params.icon,
                    title: params.name,
                    purpose: 'owner',
                    owner: {
                        id: params.id
                    }
                },
                sections: [
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'ownerConnections'
                    },
                ],
            };
        },

        createDashboardViewPage(state, params) {
            state.pages[params.pageId] = {
                properties: {
                    title: ''
                },
                sections: [
                    {
                        metatype: 'text',
                        text: {
                            type: 'h3',
                            text: i18n.global.t('dashboard.initialization.your_dashboard_of') + ' ' + params.viewName
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'table',
                        table: {
                            properties: {
                                menuPreference: 'asset',
                                allConnections: true,
                                showNavigation: true
                            },
                            connections: []
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    }
                ],
            };
        },

        deletePage(state, params) {
            const pageId = params.pageId;
            if (pageId in state.pages) {
                delete state.pages[pageId];
            }
        },


        /****************************************** */
        /*********     STATUS MUTATIONS     *********/
        /****************************************** */


        // the pages are loaded
        notSetPageLoaded(state) {
            state.isLoaded = false;
        },
        setPageLoaded(state) {
            state.isLoaded = true;
        },

        // the current operation has completed
        startOperation(state, page) {
            if (page) {
                // chain operation 650 milliseconds in the future
                state.callChains[page] = new Date().getTime() + CHAIN_DELAY_MILLISEC;
            }
            state.saved = false;
        },
        endOperation(state) {
            state.saved = true;
        },


        /******************************************* */
        /********     TECHNICAL MUTATIONS     ********/
        /******************************************* */


        storePage(state, page) {
            let pageId = page.page;
            if (page.view) {
                pageId += '-' + page.view;
            }
            state.pages[pageId] = {
                properties: page.properties,
                sections: page.sections
            }
        },
        initializePages(state) {
            state.pages['dashboard-64a6c39748a115cc1611781e'] = {
                properties: {
                    title: ''
                },
                sections: [
                    {
                        metatype: 'text',
                        text: {
                            type: 'h3',
                            text: i18n.global.t('dashboard.initialization.your_dashboard')
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: i18n.global.t('dashboard.initialization.dashboard_introduction_first')
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: i18n.global.t('dashboard.initialization.dashboard_introduction_second')
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'table',
                        table: {
                            properties: {
                                menuPreference: 'asset',
                                allConnections: true,
                                showNavigation: true
                            },
                            connections: []
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    },
                    {
                        metatype: 'text',
                        text: {
                            type: 'p',
                            text: ''
                        }
                    }
                ],
            };
        },

    },
    getters: {
        isLoaded(state) {
            /**
             *  
             *  true when the page structure is completely loaded from the backend
             *  false otherwise
             * 
             * */
            return state.isLoaded;
        },
        getPages(state) {
            return state.pages;
        },
        isSaved(state) {
            /** 
             *  
             *  turns to false while the software is updating
             *  the information on the pages, true otherwise
             * 
             * */
            return state.saved;
        },
        getCallChains(state) {
            return state.callChains;
        }
    }
};

<template>

    <div class="dropdown-wrapper">
        <div class="switch-view-dropdown"
             @click="showDropdown = !showDropdown">
            <p class="label-large">
                Cambia vista
            </p>
            <span class="material-icons">
                arrow_drop_down
            </span>


        </div>
        <dropdown-menu-component :show="showDropdown"
                                    :list="viewList"
                                    @onItemClick="changeCurrentView"
                                    class="dropdown" />
    </div>


    <Teleport v-if="showAddViewModal" to="body">
        <add-view-modal-component :show="showAddViewModal"
                                  @close="closeAddViewModal" />
    </Teleport>

</template>


<script>

    import DropdownMenuComponent from '@/components/fields/DropdownMenu';
    import AddViewModalComponent from '@/components/fields/view/AddViewModal';

    import PostUsersProfileCurrentView from '@/services/users/profile/currentView/post.service';
    import EventBus from '@/common/EventBus';


    import { i18n } from '@/plugins/i18n';

    export default {
        name: 'Switch-View-Dropdown',
        components: {
            DropdownMenuComponent,
            AddViewModalComponent
        },
        data() {
            return {
                showDropdown: true,
                showAddViewModal: false,
            };
        },
        computed: {
            pages() {
                return this.$store.getters['pages/getPages'];
            },
            views() {
                return this.$store.getters['views/getViews'];
            },
            viewList() {
                const viewList = this.views.map(x => {
                    return {
                        name: x.name,
                        value: x._id
                    };
                });

                if (viewList.length < 2) {
                    return [];
                }

                viewList[0]['options'] = {
                    materialIcon: 'groups'
                };
                viewList[1]['options'] = {
                    materialIcon: 'account_circle'
                };

                viewList.push({
                    name: i18n.global.t('forms.new_view.manage_views'),
                    value: 'manage_views',
                    options: {
                        materialIcon: 'edit',
                        color: 'var(--black)',
                        backgroundColor: 'var(--neutral)',
                    }
                });
                viewList.push({
                    name: i18n.global.t('forms.new_view.add_view'),
                    value: 'add_view',
                    options: {
                        materialIcon: 'add',
                        backgroundColor: 'var(--tertiary)',
                        hoverBackgroundColor: 'var(--neutral)',
                    }
                });

                return viewList;
            },
        },
        methods: {

            saveCurrentView(viewId, viewName) {

                this.$store.dispatch('views/setCurrentView', viewId);
                this.$store.dispatch('auth/setUsersCurrentView', viewId);
                
                const userId = this.$store.getters['auth/getUserId'];
                PostUsersProfileCurrentView.post(userId, viewId).then(
                    () => {

                        // if the view does not exists in the pages
                        // create a new view

                        const pageId = 'dashboard-' + viewId;
                        if (!this.pages[pageId]) {
                            this.$store.dispatch('pages/createDashboardViewPage', {
                                viewId: viewId,
                                viewName: viewName,
                            });
                        }
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    });
            },

            changeCurrentView(viewId) {
                this.showDropdown = false;

                if (viewId === 'manage_views') {
                    this.$router.push({
                        path: '/profile',
                        hash: '#views'
                    });
                }



                else if (viewId === 'add_view') {
                    this.showAddViewModal = true;



                } else {
                    this.saveCurrentView(viewId);
                }
            },

            closeAddViewModal(newlyCreatedView) {

                this.showAddViewModal = false;

                if (newlyCreatedView == null) {
                    return;
                }

                const viewId = newlyCreatedView._id;
                const viewName = newlyCreatedView.name;

                if (viewId) {
                    this.saveCurrentView(viewId, viewName);
                }
            }
        }
    }

</script>

<style scoped>
    div.dropdown-wrapper {
        position: relative;
    }

        div.dropdown-wrapper > div.switch-view-dropdown {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: 1px solid var(--primary);
            border-radius: var(--middle-rounded);
            padding: 1px 8px;
        }

            div.dropdown-wrapper > div.dropdown {
                margin-left: -40px;
                margin-top: 8px;
            }
</style>
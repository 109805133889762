

const navigation = require('./translations/navigation').default;
const connect = require('./translations/connect').default;
const dashboard = require('./translations/dashboard').default;
const tables = require('./translations/tables').default;
const rewards = require('./translations/rewards').default;
const support = require('./translations/support').default;
const forms = require('./translations/forms').default;
const profile = require('./translations/profile').default;
const actions = require('./translations/actions').default;



const es = {
    current: "ES",

    navigation: navigation.es,
    connect: connect.es,
    dashboard: dashboard.es,
    tables: tables.es,
    rewards: rewards.es,
    support: support.es,
    forms: forms.es,
    profile: profile.es,
    actions: actions.es,

    /* mapping ISO 2366 Alpha 2 codes to name of the country in the language */
    /*
     * AD: "Andorra"
     * AE: "United Arab Emirates"
     * ...
     * 
     * */
    i18nCountriesISO: require("i18n-iso-countries/langs/es.json").countries
};

export { es };

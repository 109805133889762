
import GetUsersProfileOwners from '@/services/users/profile/owner/get.service';
import GetUsersProfileViews from '@/services/users/profile/view/get.service';

import EventBus from "@/common/EventBus";

import { i18n } from '@/plugins/i18n';

export const views = {
    namespaced: true,
    state: {
        views: [],
        owners: [],
        currentView: '64a6c39748a115cc1611781e',
        isLoaded: false,
        _concurrentCallsCounter: 1
    },
    actions: {


        /***************************************** */
        /***********       REFRESH       ***********/
        /***************************************** */
        
        refresh({ commit, rootGetters }) {

            /** preliminary operations */

            commit('startOperation');
            const user_id = rootGetters['auth/getUserId'];
            const user = rootGetters['auth/getUser'];
            const currentView = rootGetters['auth/getUserCurrentView'];
            commit('setCurrentView', currentView);
            

            /** add personal owner */
            const userPersonalOwner = {
                _id: '649d8623da0ad8fce77a2013',
                user: user_id,
                nickname: i18n.global.t('profile.me') + ' ' + ' (' + user.username + ')',
                name: user.name,
                surname: user.surname,
                email: user.email,
                mobile: user.mobile,
                legalNames: user.legalNames ? user.legalNames : [],
            };
            commit('addOwner', userPersonalOwner);

            /** add personal view */

            const userAllView = {
                _id: '64a6c39748a115cc1611781e',
                name: 'Tutti',
                user: user_id,
            };
            commit('addView', userAllView);

            const userPersonalView = {
                _id: '64a6c3a348a115cc1611781f',
                name: i18n.global.t('profile.me') + ' (' + user.username + ')',
                user: user_id,
                owners: ['649d8623da0ad8fce77a2013']
            };
            commit('addView', userPersonalView);



            /** make backend calls */

            commit('increaseCallCounter');
            GetUsersProfileOwners.get(user_id).then(
                (response) => {

                    commit('saveOwners', response.data);
                    commit('decreaseCallCounter');


                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });


            commit('increaseCallCounter');
            GetUsersProfileViews.get(user_id).then(
                (response) => {

                    commit('saveViews', response.data);
                    commit('decreaseCallCounter');


                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });


            commit('decreaseCallCounter');
        },

        addOwner({ commit }, newOwner) {
            commit('addOwner', newOwner);
        },
        addView({ commit }, newView) {
            commit('addView', newView);
        },
        updateOwnerFields({ commit }, newOwner) {
            commit('updateOwnerFields', newOwner);
        },
        updateView({ commit }, newView) {
            commit('updateView', newView);
        },
        deleteView({ commit }, view) {
            commit('deleteView', view);
        },
        deleteOwner({ commit }, owner) {
            commit('deleteOwner', owner);
        },
        setCurrentView({ commit }, viewId) {
            commit('setCurrentView', viewId);
        },
        removeOwnerFromAllViews({ commit }, ownerId) {
            commit('removeOwnerFromAllViews', ownerId);
        },

        setPersonalOwnerName({ commit }, name) {
            commit('setPersonalOwnerName', name);
        },
        setPersonalOwnerSurname({ commit }, surname) {
            commit('setPersonalOwnerSurname', surname);
        },
        setPersonalOwnerLegalNames({ commit }, legalNames) {
            commit('setPersonalOwnerLegalNames', legalNames);
        }

    },
    mutations: {

        // add views and owners in batch
        saveOwners(state, owners) {
            state.owners.push(...owners);
        },
        saveViews(state, views) {
            state.views.push(...views);
        },
        // add views and owners on1 by one
        addOwner(state, newOwner) {
            state.owners.push(newOwner);
        },
        addView(state, newView) {
            state.views.push(newView);
        },
        updateOwnerFields(state, newOwner) {
            const index = state.owners.map(x => x._id).indexOf(newOwner._id);

            if (index > -1) {
                for (const [key, value] of Object.entries(newOwner)) {
                    state.owners[index][key] = value
                }
            }
        },
        updateView(state, newView) {
            const index = state.views.map(x => x._id).indexOf(newView._id);
            if (index > -1) {
                state.views[index] = newView;
            }
        },
        deleteView(state, view) {
            const index = state.views.map(x => x._id).indexOf(view._id);
            if (index > -1) {
                state.views.splice(index, 1);
            }
        },
        deleteOwner(state, owner) {
            const index = state.owners.map(x => x._id).indexOf(owner._id);
            if (index > -1) {
                state.owners.splice(index, 1);
            }
        },
        setCurrentView(state, viewId) {
            state.currentView = viewId;
        },
        removeOwnerFromAllViews(state, ownerId) {
            state.views.forEach(view => {
                if (view.owners == null) {
                    return; // skip
                }
                const index = view.owners.map(x => x._id).indexOf(ownerId);
                if (index > -1) {
                    view.owners.splice(index, 1);
                }
            });
        },


        setPersonalOwnerName(state, name) {
            const index = state.owners.map(x => x._id).indexOf('649d8623da0ad8fce77a2013');
            if (index > -1) {
                state.owners[index].name = name;
            }
        },
        setPersonalOwnerSurname(state, surname) {
            const index = state.owners.map(x => x._id).indexOf('649d8623da0ad8fce77a2013');
            if (index > -1) {
                state.owners[index].surname = surname;
            }
        },
        setPersonalOwnerLegalNames(state, legalNames) {
            const index = state.owners.map(x => x._id).indexOf('649d8623da0ad8fce77a2013');
            if (index > -1) {
                state.owners[index].legalNames = legalNames;
            }
        },


        // technical mutations

        startOperation(state) {
            state.views = [];
            state.owners = [];
            state.currentView = '64a6c39748a115cc1611781e';
            state.isLoaded = false;
        },
        increaseCallCounter(state) {
            state._concurrentCallsCounter += 1;
        },
        decreaseCallCounter(state) {
            state._concurrentCallsCounter -= 1;
            if (state._concurrentCallsCounter === 0) {
                state.isLoaded = true;
            }
        },


    },
    getters: {
        isLoaded(state) {
            /**
             *  
             *  true when the page structure is completely loaded from the backend
             *  false otherwise
             * 
             * */
            return state.isLoaded;
        },
        getViews(state) {
            return state.views;
        },
        getOwners(state) {
            return state.owners;
        },
        getUserPersonalOwnerId() {
            return '649d8623da0ad8fce77a2013';
        },
        getUserPersonalViewIds() {
            return ['64a6c39748a115cc1611781e', '64a6c3a348a115cc1611781f'];
        },
        getAllViewId() {
            return '64a6c39748a115cc1611781e';
        },
        getUserViewId() {
            return '64a6c3a348a115cc1611781f';
        },
        getCurrentView(state) {
            return state.currentView;
        }
    }
};

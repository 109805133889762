<template>

    <div :class="{
            'input-field high-rounded': true,
            'selected': displaySuggestionList,
            'idle': !displaySuggestionList,
            'invalid': !isValid
         }"
         @click.prevent="showSuggestionList"
         :ref="inputFieldId_"
         :style="cssProps"
         v-click-outside="hideSuggestionList">
        <template v-if="displayList && displayList.length > 0">
            <div class="display-labels"
                 v-for="(item, index) in displayList"
                 v-bind:key="index"
                 :style="`background-color: ${colors[index%3]}`"
                 @click.prevent="onRemoveItem(index)">

                <p class="body-medium black">
                    {{ item }}
                </p>
                <span v-if="displaySuggestionList"
                      class="material-icons black body-large">
                    clear
                </span>

            </div>
        </template>
        <div v-else
             class="display-labels">
            <p :class="{
                'body-medium black': isValid,
                'body-medium error': !isValid,
            }">
                Seleziona intestatari
            </p>
        </div>

        <span v-if="displaySuggestionList"
              class="material-icons">
            arrow_drop_up
        </span>
        <span v-else
              class="material-icons">
            arrow_drop_down
        </span>

        <!-- autocomplete list -->

        <div class="suggestions-list"
             v-if="displaySuggestionList">
            <div class="suggestion-item"
                 v-for="(suggestion, index) in suggestionList"
                 v-bind:key="index"
                 @click.prevent="onAddItem(suggestion.value)">

                <p class="body-medium black">
                    {{ suggestion.name }}
                </p>
                <span class="material-icons green body-large">
                    add
                </span>

            </div>

            <div class="add-new-owner-option"
                 @click.prevent="showAddNewOwnerModal">
                <p class="title-small bold">
                    Aggiungi nuovo intestatario
                </p>
                <span class="material-icons body-large">
                    add
                </span>
            </div>

        </div>



    </div>


    <Teleport v-if="showModal" to="body">
        <add-owner-modal-component :show="showModal"
                                   @close="closeAddNewOwnerModal" />
    </Teleport>


</template>

<script>

    import AddOwnerModalComponent from '@/components/fields/owner/AddOwnerModal.vue';
    import { ownerColors } from '@/assets/js/fields/ownerColors';

    export default {
        name: 'Multiselect-Field-Component',
        components: {
            AddOwnerModalComponent
        },
        emits: ['onAddItem', 'onRemoveItem'],
        props: ['modelValue', 'id', 'isValid'],

        /**
         * 
         * modelValue must be a list of id of the owners
         * 
         * 
         *  id - the id of the input - optional, but it is safer to pass it
         * */


        /**
         *
         *
                    <toggle-field-component v-model="profileLanguage"
                                             @onAddItem="addItemByValue"
                                             @onRemoveItem="onRemoveItemindex"/>
         *
         * */
        data() {
            return {
                displaySuggestionList: false,
                showModal: false,


                // technical data fields
                inputFieldId_: (this.id) ? this.id : this.randomString(),
                preventClosingSuggestionList_: false, // to prevent suggestion list to close via v-click-outside
                refreshCssProps_: true, // to update the margin top to the height of the field
            };
        },
        computed: {


            owners() {
                return this.$store.getters['views/getOwners'];
            },

            suggestionList() {

                const suggestionList = [];

                // map all the owners
                const list = this.owners.map(x => {
                    return {
                        name: x.nickname,
                        value: x._id
                    };
                });

                // remove the ones in the model
                list.forEach(item => {
                    if (!this.modelValue.includes(item.value)) {
                        suggestionList.push(item);
                    }
                });

                // return the filtered list
                return suggestionList;
            },

            displayList() {

                const displayList = [];

                this.owners.forEach(owner => {
                    if (this.modelValue.includes(owner._id)) {
                        displayList.push(owner.nickname);
                    }
                })

                return displayList;
                
            },

            /* technical computed properties */

            colors() {
                return ownerColors();
            },
            cssProps() {
                const refreshCssProps_ = this.refreshCssProps_;
                refreshCssProps_;

                if (this.$refs[this.inputFieldId_] == null) {
                    return {
                        '--input-box-height': 40 + 'px'
                    };
                }
                return {
                    '--input-box-height': this.$refs[this.inputFieldId_].clientHeight + 'px'
                };
            }
        },
        mounted() {
            this.refreshCssProps_ = !this.refreshCssProps_;
        },
        methods: {
            randomString() {
                // generate a random 7 characters string
                return (Math.random() + 1).toString(36).substring(2);
            },

            /** suggestion list management */

            hideSuggestionList() {
                if (this.preventClosingSuggestionList_) {
                    this.preventClosingSuggestionList_ = false;
                    return;
                }
                setTimeout(() => {
                    this.refreshCssProps_ = !this.refreshCssProps_;
                    this.displaySuggestionList = false;
                }, 128);
            },
            showSuggestionList() {
                setTimeout(() => {
                    this.refreshCssProps_ = !this.refreshCssProps_;
                    this.displaySuggestionList = true;
                }, 128);
            },

            /** item in the field management */

            onAddItem(value) {
                this.refreshCssProps_ = !this.refreshCssProps_;
                if (this.displaySuggestionList) {
                    this.$emit('onAddItem', value);
                    this.preventClosingSuggestionList_ = true;
                }
            },
            onRemoveItem(index) {
                this.refreshCssProps_ = !this.refreshCssProps_;
                if (this.displaySuggestionList) {
                    this.$emit('onRemoveItem', index);
                    this.preventClosingSuggestionList_ = true;
                }
            },

            /** modal management */

            showAddNewOwnerModal() {
                // hard close the suggestion list
                this.preventClosingSuggestionList_ = false;
                this.displaySuggestionList = false;

                // open the modal to add an owner
                this.showModal = true;
            },
            closeAddNewOwnerModal(newlyCreatedOwner) {

                this.showModal = false;

                // add new owner to the selected list
                this.$emit('onAddItem', newlyCreatedOwner._id);

                // hard open the suggestion list
                this.preventClosingSuggestionList_ = true;
                this.displaySuggestionList = true;
            },
        }
    }
</script>

<style scoped>

    div.input-field {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 4px;
    }
        div.input-field.selected {
            flex-wrap: wrap;
        }
        div.input-field.idle {
            overflow-x: hidden;
        }
        div.input-field.invalid {
            border-color: var(--error);
        }

        div.input-field > div.display-labels {
            padding: 1px 8px;
            border-radius: var(--middle-rounded);
            width: fit-content;
            display: flex;
            gap: 4px;
            align-items: center;
        }

        div.input-field > span.material-icons {
            position: absolute;
            right: 12px;
            top: calc(50% - 0.75rem);
        }

    div.suggestions-list {
        position: absolute;
        z-index: 50;
        margin: var(--input-box-height) auto auto -8px;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 100%;
        border: 1px solid var(--primary);
        background-color: var(--white);
    }

        div.suggestions-list > div.add-new-owner-option,
        div.suggestions-list > div.suggestion-item {
            padding: 2px 8px 2px 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            div.suggestions-list > div.suggestion-item:hover {
                background-color: var(--secondary);
                cursor: pointer;
            }


        div.suggestions-list > div.add-new-owner-option {
            color: var(--black);
            background-color: var(--tertiary);
        }


            div.suggestions-list > div.add-new-owner-option:hover {
                color: var(--black);
                background-color: var(--secondary);
                cursor: pointer;
            }
</style>
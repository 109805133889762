import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";

// pwa support
import serviceWorker from './plugins/serviceWorker.js';

/* login with google */
import vue3GoogleLogin from 'vue3-google-login';

// i18n
import { i18n } from './plugins/i18n.js'

// custom plugins
import breakpoints from "./plugins/breakpoints";

// custom directives
import clickOutside from './directives/clickOutside';

// setup interceptors
import setupInterceptors from './services/auth/setupInterceptors';
setupInterceptors(store);


/****************************** */
/***    Google Tag Manager    ***/
/****************************** */


import { createGtm } from '@gtm-support/vue-gtm';
const gtm = createGtm({
    id: process.env.VUE_APP_GTM_TAG, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});


/****************************** */
/*******    Custom CSS    *******/
/****************************** */


// core
import './assets/css/reset-stylesheet.min.css';
import './assets/css/styleguide.css';

import './assets/css/scrollbar.css';
import './assets/css/typography.css';

// icons
import './assets/css/icons/materialUI.css';

// animations
import './assets/css/animations/spin.css';
import './assets/css/animations/skeleton.css';
import './assets/css/animations/dash.css';
import './assets/css/animations/dash-check.css';


// modals
import './assets/css/modals/modals.css';

// fields
import './assets/css/fields/buttons.css';
import './assets/css/fields/inputs.css';

// forms
import './assets/css/forms/branded-input-form.css';

// tables
import './assets/css/tables/branded-table.css';


/****************************** */
/********     LEGACY     ********/
/****************************** */


// sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
// sweetalert css
import 'sweetalert2/dist/sweetalert2.min.css';

// body backgrounds
import './assets/css/body-background.css';


/****************************** */
/*******    Create App    *******/
/****************************** */


createApp(App)
    .use(router)
    .use(store)
    .use(breakpoints, {
        xs: 0,
        sm: 600,
        md: 905,
        lg: 1240,
        xl: 1440,
        xxl: 1900,
        mobile: 0,
        desktop: 905
    })
    .use(i18n)
    .use(gtm) // google tag manager
    .use(serviceWorker) // pwa support
    .use(vue3GoogleLogin, {
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
    })
    .use(VueSweetalert2) // legacy
    .directive('click-outside', clickOutside)
    .mount('#app')

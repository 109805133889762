<template>
    <div class="card-list">

        <connection-card-component :title="$t('connect.bank_api_title')"
                                   :description="$t('connect.bank_api_text')"
                                   :to="'/connect/openbanking/country'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/london_day.svg')"
                                   :logos="[require('@/assets/img/logos/banks/paypal.png'),
                                      require('@/assets/img/logos/banks/revolut.png'),
                                      require('@/assets/img/logos/banks/postepay.png'),
                                      require('@/assets/img/logos/banks/unicredit.png'),
                                      require('@/assets/img/logos/banks/hsbc.png')]"
                                   cta="connect" />


        <connection-card-component :title="$t('connect.crypto_exchange_title')"
                                   :description="$t('connect.crypto_exchange_text')"
                                   :to="'/connect/crypto/exchange'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/egypt_day.svg')"
                                   :logos="[require('@/assets/img/logos/crypto/exchanges/binance.png'),
                                      require('@/assets/img/logos/crypto/exchanges/coinbase.jpg'),
                                      require('@/assets/img/logos/crypto/exchanges/kraken.png'),
                                      require('@/assets/img/logos/crypto/exchanges/kucoin.png'),
                                      require('@/assets/img/logos/crypto/exchanges/bitstamp.png')]"
                                   cta="connect" />


        <connection-card-component :title="$t('connect.crypto_wallet_address_title')"
                                   :description="$t('connect.crypto_wallet_address_text')"
                                   :to="'/connect/crypto/wallet'"
                                   :page="page"
                                   :tableId="tableId"
                                   :backgroundImage="require('@/assets/img/illustrations/landscapes/desert_day.svg')"
                                   :logos="[require('@/assets/img/logos/crypto/btc.png'),
                                      require('@/assets/img/logos/crypto/eth.png'),
                                      require('@/assets/img/logos/crypto/ltc.png'),
                                      require('@/assets/img/logos/crypto/xrp.png'),
                                      require('@/assets/img/logos/crypto/doge.png')]"
                                   cta="add" />
    </div>
</template>

<script>

    import ConnectionCardComponent from '@/components/connect/navigation/Card';

    export default {
        name: 'Connections-List-Component',
        props: ['page', 'tableId'],
        components: {
            ConnectionCardComponent
        }
    }
</script>

<style scoped>
    div.card-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
</style>
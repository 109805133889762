import api from '@/services/api';

class UsersProfileLegalNames {

    post(id, legalNames) {
        return api.post('/v1/users/' + id + '/profile/legalNames', {
            legalNames: legalNames
        });
    }
}

export default new UsersProfileLegalNames();

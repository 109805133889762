import api from '../../../api';

class MarketsStocksPrices {

  patch(tickers) {
    return api.patch('/v1/markets/stocks/' + tickers.join(',') );
  }
}

export default new MarketsStocksPrices();

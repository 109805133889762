<template>
    <GoogleLogin :callback="callback"
                 :buttonConfig="buttonConfig" />
</template>

<script>

    import PostAuthGoogleSignin from "@/services/auth/google/signin/post.service";
    import TokenService from "@/services/auth/token.service";

    export default {
        name: "Signin-With-Google-Button",
        props: ['context'],
        emits: ['onCallback'],
        data() {
            return {
                // https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration
                buttonConfig: {
                    type: 'standard',
                    theme: 'outline',
                    text: (this.context && this.context === 'signup_with') ? 'signup_with' : 'signin_with',
                    size: 'medium',
                    shape: 'pill'
                }
            };
        },
        methods: {
            callback(response) {

                this.$emit('onCallback', true);

                // This callback will be triggered when the user selects or login to
                // his Google account from the popup

                const credential = response.credential;
                const client_id = response.client_id;
                const uat = {};

                // add acquisition parameters
                uat.utmSource = this.$route.query.utm_source || 'unknown';
                uat.utmMedium = this.$route.query.utm_medium || 'unknown';
                uat.utmCampaign = this.$route.query.utm_campaign || 'unknown';


                const bodyJSON = {
                    client_id: client_id,
                    credential: credential,
                    uat: uat,

                    terms: true,
                    updates: true,
                    language: this.$i18n.locale,
                    timeframe: '1D',
                    currency: 'EUR',
                    rewardStep: 0
                }

                PostAuthGoogleSignin.post(bodyJSON).then(
                    (response) => {
                        if (response.status === 202) {
                            // check your email to verify your email address
                            this.$router.go('/register/success');
                        }
                        else if (response.status === 200 &&
                            response.data.user &&
                            response.data.user.accessToken) {
                            // you correctly logged in
                            TokenService.setUser(response.data.user);
                            this.$store.dispatch('auth/loginWithGoogle', response.data.user).then(
                                () => {
                                    this.$store.dispatch("auth/refresh"); // refresh the authorization
                                    // add the user id to the datalayer for the google tag manager
                                    window.dataLayer = window.dataLayer || [];
                                    window.dataLayer.push({
                                        'user_id': this.$store.getters['auth/getUserId']
                                    });
                                    if (response.data.context === 'login') {
                                        this.$router.push("/dashboard");
                                    }
                                    else if (response.data.context === 'register') {
                                        this.$router.push({ path: '/register/success' });
                                    }
                                }, () => {
                                    this.$emit('onCallback', false);
                                }
                            );
                        }
                    }
                )
            }
        }
    }
</script>
import api from '../../../api';

class PlatformCryptoSymbols {

  get() {
    return api.get('/v1/platform/crypto/symbols');
  }
}

export default new PlatformCryptoSymbols();

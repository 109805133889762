import api from '@/services/api';

class PutUsersOpenbankingAccount {

  put(userId, body) {
    return api.put('/v1/users/'+ userId +'/openbanking/account/', body);
  }
}

export default new PutUsersOpenbankingAccount();

import api from '@/services/api';

class PlatformCryptoWalletAddressBlockchain {

    get() {
        return api.get('/v1/platform/crypto/wallet/address/blockchain');
    }
}

export default new PlatformCryptoWalletAddressBlockchain();

<template>
    <tr>
        <td colspan="4"
            @click="goToConnectMenuItem">
            <div class="empty-table-frame">
                <p class="body-medium black italic" >
                    {{ $t('tables.empty_table') }}
                </p>

                <!-- asset -->

                <p v-if="activeItem === 'asset' && activeFilter === 'crypto'"
                   class="body-medium black italic underlined">
                    {{ $t('tables.navigation.add_cryptocurrency') }}
                </p>
                <p v-if="activeItem === 'asset' && activeFilter === 'stocks'"
                   class="body-medium black italic underlined">
                    {{ $t('tables.navigation.add_stocks') }}
                </p>
                <p v-if="activeItem === 'asset' && activeFilter === 'cash'"
                   class="body-medium black italic underlined">
                    {{ $t('tables.navigation.add_cash') }}
                </p>

                <!-- connection -->

                <p v-if="activeItem === 'connection' && activeFilter === 'banks'"
                   class="body-medium black italic underlined">
                    {{ $t('tables.navigation.add_bank') }}
                </p>
                <p v-if="activeItem === 'connection' && activeFilter === 'exchanges'"
                   class="body-medium black italic underlined">
                    {{ $t('tables.navigation.add_exchange') }}
                </p>
                <p v-if="activeItem === 'connection' && activeFilter === 'wallets'"
                   class="body-medium black italic underlined">
                    {{ $t('tables.navigation.add_wallet') }}
                </p>
                <p v-if="activeItem === 'connection' && activeFilter === 'manual'"
                   class="body-medium black italic underlined">
                    {{ $t('tables.navigation.add_manual') }}
                </p>
            </div>
        </td>
    </tr>

</template>

<script>

    export default {
        name: 'No-Item-In-Table-Component',
        props: ['activeItem', 'activeFilter'],
        methods: {
            goToConnectMenuItem() {

                // fancy switch

                if (this.activeItem === 'asset') {
                    if (this.activeFilter === 'crypto') {
                        this.$router.push('/connect?activeItem=crypto');
                    } else if (this.activeFilter === 'stocks') {
                        this.$router.push('/connect/stocks/manual');
                    } else if (this.activeFilter === 'cash') {
                        this.$router.push('/connect/cash/manual');
                    }
                }
                if (this.activeItem === 'connection') {
                    if (this.activeFilter === 'banks') {
                        this.$router.push('/connect/openbanking/country');
                    } else if (this.activeFilter === 'exchanges') {
                        this.$router.push('/connect/crypto/exchange');
                    } else if (this.activeFilter === 'wallets') {
                        this.$router.push('/connect/crypto/wallet');
                    } else if (this.activeFilter === 'manual') {
                        this.$router.push('/connect');
                    }
                }
            }
        }
    }
</script>

<style scoped>
    div.empty-table-frame {
        display: flex;
        flex-direction: column;
    }

        div.empty-table-frame p {
            margin: auto;
            max-width: 100%;
        }

        div.empty-table-frame:hover p.underlined {
            color: var(--tertiary);
        }
</style>
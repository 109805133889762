<template>

    <div class="container">
    
        <loading-spinner :active="isLoading"
                         :text="'Extablishing secure connection with your Financial Institution'" />
       
    </div>

</template>

<script>

    import PatchUsersOpenbankingRequisition from '@/services/users/openbanking/requisition/patch.service';
    import PutUsersOpenbankingAccount from '@/services/users/openbanking/account/put.service';
    import GetUsersOpenbankingAccount from '@/services/users/openbanking/account/get.service';
    import PatchUsersOpenbankingAccountDetails from '@/services/users/openbanking/account/details/patch.service';
    import PatchUsersOpenbankingAccountMetadata from '@/services/users/openbanking/account/metadata/patch.service';

    import EventBus from "@/common/EventBus";
    import LoadingSpinner from "@/components/LoadingSpinner";

    export default {
        name: "Bank-Connected",
        components: {
            LoadingSpinner
        },
        data() {
            return {
                isLoading: true,
                connectedAccounts: []
            }
        },
        created() {
            // Route back to the connection form if the user cancelled the session
            if (this.$route.query.error) {
                const query = '?error=' + this.$route.query.error + '&requisitionId=' + this.$route.query.ref;
                this.$router.push('/connect/openbanking/error' + query);
            } else {
                this.confirmCorrectConnection();
            }
        },
        methods: {
            async confirmCorrectConnection() {
                this.isLoading = true;
                const requisition_id = this.$route.query.ref;
                const user_id = this.$store.getters['auth/getUserId'];
                // patch the requisition, so that the backend has the updated data

                await PatchUsersOpenbankingRequisition.patch(user_id, requisition_id).then(
                    (response) => { response; },
                    (error) => { this.isLoading = false; if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                // create the empty accounts from the requisition

                const body = {
                    requisition_id: requisition_id
                };
                await PutUsersOpenbankingAccount.put(user_id, body).then(
                    (response) => { response; },
                    (error) => { this.isLoading = false; if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                // get the accounts just created

                const query = {
                    requisition_id: requisition_id
                }
                await GetUsersOpenbankingAccount.get(user_id, query).then(
                    (response) => {
                        this.connectedAccounts = response.data; // save it in the data variable of the page
                    },
                    (error) => { this.isLoading = false; if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                // for each account, patch the details and metadata

                for (let i = 0; i < this.connectedAccounts.length; i++) {

                    const body = {
                        account_id: this.connectedAccounts[i].accountId
                    };

                    // get the metadata
                    PatchUsersOpenbankingAccountMetadata.patch(user_id, body).then(
                        (response) => { response; },
                        (error) => { this.isLoading = false; if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    // get the details
                    await PatchUsersOpenbankingAccountDetails.patch(user_id, body).then(
                        (response) => { response; },
                        (error) => { this.isLoading = false; if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }

                // get the filled accounts again for the display to the user

                this.isLoading = false;
                this.$store.dispatch('connections/refresh');
                this.$router.push('/connect/openbanking/success/success?ref=' + requisition_id);
            }
        },
    }
</script>

<style scoped>

    div.container {
        display: flex;
        justify-content: center;
        padding-top: 5%;
        width: 100%;
    }
    @media(max-width:904px) {
        div.container {
            padding-left: 16px;
            padding-right: 16px;
            flex-direction: column;
            width: calc(100%-32px);
        }
    }

    div.content {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    div.cards-frame {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    div.buttons-frame {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    img.support-image-width {
        max-width: 96vw;
    }
</style>
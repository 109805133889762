<template>
    <div class="owner-properties">
        <table>
            <tbody>
                <owner-name-component :owner="owner" :name="name" />
            </tbody>
            <tbody>
                <owner-surname-component :owner="owner" :surname="surname" />
            </tbody>
            <tbody v-if="legalNames && legalNames.length > 0">
                <owner-legal-names-component :owner="owner" :legalNames="legalNames" />
            </tbody>
            <tbody>
                <owner-email-component :owner="owner" :email="email" />
            </tbody>
            <tbody v-if="!isPersonalOwner">
                <owner-mobile-component :owner="owner" :mobile="mobile" />
            </tbody>
        </table>

    </div>
</template>

<script>
    import OwnerNameComponent from "@/components/dashboard/table/row/owner/OwnerName"
    import OwnerSurnameComponent from "@/components/dashboard/table/row/owner/OwnerSurname"
    import OwnerLegalNamesComponent from "@/components/dashboard/table/row/owner/OwnerLegalNames"
    import OwnerEmailComponent from "@/components/dashboard/table/row/owner/OwnerEmail"
    import OwnerMobileComponent from "@/components/dashboard/table/row/owner/OwnerMobile"


    export default {
        name: 'Owner-Properties-Component',
        props: ['owner'],
        components: {
            OwnerNameComponent,
            OwnerSurnameComponent,
            OwnerLegalNamesComponent,
            OwnerEmailComponent,
            OwnerMobileComponent,
        },
        computed: { 
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            currentOwner() {
                const index = this.owners.map(x => x._id).indexOf(this.owner.id);

                if (index === -1) {
                    return undefined;
                }

                return this.owners[index];
            },
            name() {
                return (this.currentOwner) ? this.currentOwner.name : undefined;
            },
            surname() {
                return (this.currentOwner) ? this.currentOwner.surname : undefined;
            },
            legalNames() {
                return (this.currentOwner) ? this.currentOwner.legalNames : [];
            },
            email() {
                return (this.currentOwner) ? this.currentOwner.email : undefined;
            },
            mobile() {
                return (this.currentOwner) ? this.currentOwner.mobile : undefined;
            },


            personalOwnerId() {
                return this.$store.getters['views/getUserPersonalOwnerId'];
            },
            isPersonalOwner() {
                return this.owner.id === this.personalOwnerId;
            },
        }
    }
</script>

<style scoped>
    div.owner-properties {
        width: calc(100% - 104px);
        padding: 8px 52px;
        margin: 8px 0px;
        border-bottom: 1px solid var(--placeholder);
    }

    @media (max-width: 904px) {
        div.owner-properties {
            width: calc(100% - 48px);
            padding: 8px 24px;
        }
    }

    table {
        width: 100%;
    }

        table > tbody > tr > td {
            vertical-align: middle;
        }

        table > tbody > tr > td.minimum-width {
            width: 172px;
        }

            table > tbody > tr > td > div.icon-cell {
                display: flex;
                align-items: center;
                gap: 4px;
                width: fit-content;
                margin-top: 4px;
                margin-bottom: 4px;
            }
</style>
import api from '@/services/api';

class PlatformOpenBankingInstitutions {

  get() {
    return api.get('/v1/platform/openbanking/institutions');
  }
}

export default new PlatformOpenBankingInstitutions();

<template>
    <div id="sidebar-menu">
        <div>

            <sidebar-navigation-item-special-component :to="'/connect'"
                                                       :icon="'add_circle'"
                                                       :name="$t('navigation.connect')" />
            <sidebar-navigation-item-component :to="'/dashboard'"
                                               :icon="'dashboard'"
                                               :name="$t('navigation.dashboard')" />
            <sidebar-navigation-item-component :to="'/rewards'"
                                               :icon="'how_to_vote'"
                                               :name="$t('navigation.vote')" />
        </div>
        <div>

            <sidebar-navigation-item-component :to="'/support'"
                                               :icon="'help_outline'"
                                               :name="$t('navigation.support')" />
            <sidebar-navigation-item-component :to="'/terms_and_conditions'"
                                               :icon="'policy'"
                                               :name="$t('navigation.terms_and_conditions')" />
        </div>
    </div>
</template>

<script>

    import SidebarNavigationItemComponent from '@/components/navigation/sidebar/NavigationItem';
    import SidebarNavigationItemSpecialComponent from '@/components/navigation/sidebar/NavigationItemSpecial';

    export default {
        name: 'Sidebar-Component',
        components: {
            SidebarNavigationItemComponent,
            SidebarNavigationItemSpecialComponent
        },
    }
</script>

<style scoped>
    div#sidebar-menu {
        width: 256px;
        height: calc(100% - 48px); /* remove top sidebar height from the height*/
        position: fixed;
        top: 48px;
        left: 0;
        overflow-x: hidden;
        z-index: 2;
        background-color: var(--primary);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    div#sidebar-menu div {
        margin: 16px auto 16px auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1px;
    }

</style>
<template>

    <div id="connect-wallet-view">
        <info-card v-if="!$breakpoints.isMDOrLarger()" />

        <div>
            <h1 v-if="$breakpoints.isMDOrLarger()"
                class="headline-large black">
                {{ $t('connect.add_wallet') }}
            </h1>
            <form-component />
        </div>

        <info-card v-if="$breakpoints.isMDOrLarger()" />
    </div>

</template>

<script>
    import FormComponent from '@/components/connect/crypto/wallet/Form';
    import InfoCard from '@/components/connect/crypto/wallet/InfoCard';

    export default {
        name: 'Connect-Wallets-Page',
        components: {
            FormComponent,
            InfoCard
        }
    }
</script>

<style scoped>
    div#connect-wallet-view {
        margin: 32px auto auto auto;
        display: flex;
        justify-content: space-around;
        padding: 0px 16px;
    }

    @media (max-width: 904px) {
        div #connect-wallet-view {
            flex-direction: column;
        }
    }

    @media (min-width: 905px) {
        div#connect-wallet-view > div {
            width: 45%;
        }
    }
</style>
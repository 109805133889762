<template>
    <!--
    <template v-if="!isLongLoading || !currentUser">-->
    <loading-bar-component v-if="currentUser && isPageInternal" />
    <mobile-topbar-component v-if="isPageInternal && displayNoSidebar" />
    <topbar-component v-else-if="isPageInternal" />
    <sidebar-component v-if="isPageInternal && displayExtendedSidebar" />
    <collapsed-sidebar-component v-else-if="isPageInternal && displayCollapsedSidebar" />
    <bottombar-component v-if="isPageInternal && displayNoSidebar" />
    <div id="content" :style="computedMarginsCSS">
        <router-view />
    </div>
    <!--
    </template>
    <loading-screen-component v-else />-->
</template>

    <script>

        //import LoadingScreenComponent from '@/components/navigation/LoadingScreen';
        import LoadingBarComponent from '@/components/navigation/LoadingBar';
        import TopbarComponent from '@/components/navigation/Topbar';
        import MobileTopbarComponent from '@/components/navigation/MobileTopbar';
        import SidebarComponent from '@/components/navigation/Sidebar';
        import CollapsedSidebarComponent from '@/components/navigation/CollapsedSidebar';
        import BottombarComponent from '@/components/navigation/Bottombar';


        import EventBus from "./common/EventBus";

        export default {
            name: 'App',
            data() {
                return {
                    windowWidth: window.innerWidth,
                    isLongLoading: true, // flag that is set to true when the app is builded, then set to false after the first full load
                }
            },
            mounted() {
                window.addEventListener('resize', () => {
                    this.windowWidth = window.innerWidth
                })
                /*
                window.addEventListener('beforeunload', (beforeUnloadEvent) => {
                    beforeUnloadEvent.preventDefault();
                    console.log('beforeunload event fired');
                });
                */

                // notify firefox users of known bugs

                const isFirefox = navigator.userAgent.search("Firefox") > -1;

                if (isFirefox) {

                    this.$swal({
                        icon: 'warning',
                        title:
                            '<h2 class="headline-small white">'
                            + this.$i18n.getLocaleMessage(this.$i18n.locale).support.firefox_problem_title
                            + '</h2>',
                        html:
                            '<p class="body-large white">'
                            + this.$i18n.getLocaleMessage(this.$i18n.locale).support.firefox_problem_text
                            + '</p>',
                        background: 'var(--primary)',
                        confirmButtonColor: 'var(--secondary)',
                    });
                }

            },
            components: {
                //LoadingScreenComponent,
                LoadingBarComponent,
                TopbarComponent,
                MobileTopbarComponent,
                SidebarComponent,
                CollapsedSidebarComponent,
                BottombarComponent
            },


            /**
             *
             * These functions are called in sequence
             *
             * platform/ping        ping the platform to wake it up
             * auth/refresh         an authenticated ping that makes sure the user has all the rights to access the platform
             * pages/refresh        get the pages structure from the backend
             * connections/refresh  get the connections and patch them with the new data
             * markets/refresh      get the market data for the assets in the connection
             *
             *
             * */


            created() {

                EventBus.on("logout", () => {
                    this.logOut();
                });

                this.$store.dispatch('platform/ping').then(() => { });
            },
            watch: {
                isPlatformLive: function (newValue, oldValue) {
                    if (newValue === true && (oldValue == null || oldValue === false)) {
                        this.$store.dispatch('auth/refresh');
                    }
                },
                isLogged: function (newValue, oldValue) {
                    if (newValue === true && (oldValue == null || oldValue === false)) {

                        // set language
                        const language = this.$store.getters['auth/getUserLanguage'];
                        if (language) {
                            this.$i18n.locale = language;
                        }
                        // keep loading the platform
                        this.$store.dispatch('pages/refresh');
                        this.$store.dispatch('platform/loadPlatformData');
                        this.$store.dispatch('views/refresh');
                    }
                },
                arePagesLoaded: function (newValue, oldValue) {
                    if (newValue === true && (oldValue == null || oldValue === false)) {
                        if (this.isConnectBankPage) {
                            return; // do not automatically refresh on the connect bank success page
                        }
                        this.$store.dispatch('connections/refresh');
                    }
                },
                areConnectionsLoaded: function (newValue, oldValue) {
                    if (newValue === true && (oldValue == null || oldValue === false)) {
                        this.$store.dispatch('platform/loadAssetLogos');
                        this.$store.dispatch('markets/refresh');
                    }
                },
                areMarketsLoaded: function (newValue, oldValue) {
                    if (newValue === true && (oldValue == null || oldValue === false)) {
                        this.isLongLoading = false;
                    }
                },

                isPageInternal: function (newValue, oldValue) {
                    if (oldValue === false && newValue === true) {
                        document.body.classList.remove('register');
                        document.body.classList.remove('login');
                        document.body.classList.remove('register-success');
                        document.body.classList.remove('register-confirm');
                    }
                }
            },
            computed: {

                // start loading application properties

                isPlatformLive() {
                    return this.$store.getters['platform/isAlive'];
                },
                isLogged() {
                    return this.$store.getters['auth/isLogged'];
                },
                arePagesLoaded() {
                    return this.$store.getters['pages/isLoaded'];
                },
                areConnectionsLoaded() {
                    return this.$store.getters['connections/isLoaded'];
                },
                areMarketsLoaded() {
                    return this.$store.getters['markets/isLoaded'];
                },
                currentUser() {
                    return this.$store.getters['auth/getUser'];
                },

                // end loading application properties


                isPageInternal() {
                    // save an array of the external pages
                    const externalPages = ['Login', 'Register', 'Successful Registration', 'Confirm', 'Not Found', 'Forgot Password', 'Reset Password'];
                    return !externalPages.includes(this.$route.name);
                },
                isConnectBankPage() {
                    return this.$route.name === 'Bank Connected';
                },

                displayExtendedSidebar() {
                    return this.windowWidth >= this.$breakpoints.xl;
                },
                displayCollapsedSidebar() {
                    return this.windowWidth < this.$breakpoints.xl && this.windowWidth >= this.$breakpoints.sm;
                },
                displayNoSidebar() {
                    return this.windowWidth < this.$breakpoints.sm;
                },

                computedMarginsCSS() {

                    // external pages, no navigation
                    if (!this.isPageInternal) {
                        return {
                            '--content-margin-left': '0px',
                            '--content-margin-top': '0px',
                            '--content-margin-bottom': '0px'
                        };
                    }

                    // smartphone, no sidebar, bottombar
                    if (this.displayNoSidebar) {
                        return {
                            '--content-margin-left': '0px',
                            '--content-margin-top': '64px',
                            '--content-margin-bottom': '64px'
                        };
                    }

                    // LG devices, collapsed sidebar, no bottombar
                    if (this.displayCollapsedSidebar) {
                        return {
                            '--content-margin-left': '78px',
                            '--content-margin-top': '48px',
                            '--content-margin-bottom': '0px'
                        };
                    }

                    // XL and XXL devices, extended sidebar, no bottombar
                    return {
                        '--content-margin-left': '256px',
                        '--content-margin-top': '48px',
                        '--content-margin-bottom': '0px'
                    };
                }
            },
            methods: {
                logOut() {
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/login');
                }
            },
        }
    </script>

    <style>
        /* useful for external pages */
        html,
        body {
            background-color: var(--neutral);
            height: 100vh;
        }

        div#app {
            height: 100vh;
            display: flex;
        }

            div#app > div#content {
                width: 100%;
                height: fit-content;
                margin-left: var(--content-margin-left);
                margin-top: var(--content-margin-top);
                padding-bottom: var(--content-margin-bottom);
            }
    </style>

import api from '@/services/api';

class DeleteUsersManualAssetBalance {

  patch(id, data) {
    return api.patch('/v1/users/'+id+'/manual/asset/balance', data);
  }
}

export default new DeleteUsersManualAssetBalance();

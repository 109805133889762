

export default {

    it: {
        username: 'Username',
        email: 'Email',
        language: 'Lingua',
        preferred_currency: 'Valuta preferita',
        me: 'Me',

        profile: 'Profilo',
        personal_information: 'Informazioni personali',
        system_preference: 'Preferenze di sistema',
        manage_api_connections: 'Gestisci le tue connessioni API',
        manage_manual_portfolios: 'Gestisci i tuoi portfolio manuali',
        account_and_privacy: 'Account e privacy',
        banks_institutions: 'Banche e app di pagamento',
        cripto_wallets: 'Wallet di criptovalute',
        cripto_exchanges: 'Exchange di criptovalute',
        manual_portfolios: 'Portoflio manuali',
        last_updated: 'Ultimo aggiornamento',
        connection_updated: 'Connessione aggiornata',
        days_ago: 'giorni fa',
        today_at: 'oggi alle',
        connection_expired: 'Connessione scaduta',
        your_views: 'Le tue viste',
        your_owners: 'I tuoi intestatari',
        connections: 'Connessioni',

        no_manual_portfolios: 'Nessun portfolio manuale',
        no_api_connections: 'Nessuna connessione API',
        delete_account: 'Elimina account',
        see_terms_and_conditions: 'Puoi vedere i termini e condizioni a',
        this_link: 'questo link',
    },

    es: {
        username: 'Username',
        email: 'Correo electrónico',
        language: 'Idioma',
        preferred_currency: 'Moneda preferida',
        me: 'Mí',

        profile: 'Perfil',
        personal_information: 'Informacion personal',
        system_preference: 'Preferencias del Sistema',
        manage_api_connections: 'Administra tus conexiones API',
        manage_manual_portfolios: 'Gestiona tus portfolios manuales',
        account_and_privacy: 'Account and privacy',
        banks_institutions: 'Bancos & Titulos',
        cripto_wallets: 'Wallet de criptomonedas',
        cripto_exchanges: 'Exchange de criptomonedas',
        manual_portfolios: 'Portfolios Manuales',
        last_updated: 'Última actualización',
        connection_updated: 'Conexión actualizada',
        days_ago: 'hace días', // however, this specific case in handled in a custom way because "3 days ago"" is said "hace 3 dias"" and not "3 hace dias"
        today_at: 'hoy a las',
        connection_expired: 'Conexión caducada',
        your_views: 'Tus vistas',
        your_owners: 'Tus dueños',
        connections: 'Conexiones',

        no_manual_portfolios: 'Sin portfolio manual',
        no_api_connections: 'Sin conexión API',
        delete_account: 'Borrar cuenta',
        see_terms_and_conditions: 'Puedes ver los términos & condiciones en',
        this_link: 'esto link',
    },

    en: {
        username: 'Username',
        email: 'Email',
        language: 'Language',
        preferred_currency: 'Preferred currency',
        me: 'Me',

        profile: 'Profile',
        personal_information: 'Personal Information',
        system_preference: 'System preferences',
        manage_api_connections: 'Manage your API connections',
        manage_manual_portfolios: 'Manage your manual portfolios',
        account_and_privacy: 'Account and privacy',
        banks_institutions: 'Banks and Payment Apps',
        cripto_wallets: 'Crypto Wallets',
        cripto_exchanges: 'Crypto Exchanges',
        manual_portfolios: 'Manual Portfolios',
        last_updated: 'Last updated',
        connection_updated: 'Connection updated',
        days_ago: 'days ago',
        today_at: 'today at',
        connection_expired: 'Connection expired',
        your_views: 'Your views',
        your_owners: 'Your holders',
        connections: 'Connections',

        no_manual_portfolios: 'No manual portfolio',
        no_api_connections: 'No API connection',
        delete_account: 'Delete account',
        see_terms_and_conditions: 'You can see the terms and conditions at',
        this_link: 'this link',
    },

    fr: {
        username: 'Nom d\'utilisateur',
        email: 'Email',
        language: 'Langue',
        preferred_currency: 'Devise préférée',
        me: 'Moi',

        profile: 'Profil',
        personal_information: 'Informations personnelles',
        system_preference: 'Préférences de système',
        manage_api_connections: 'Gérez vos connexions API',
        manage_manual_portfolios: 'Gérez vos portefeuilles manuels',
        account_and_privacy: 'Compte et privacy',
        banks_institutions: 'Comptes bancaires',
        cripto_wallets: 'Wallet de cryptomonnaies',
        cripto_exchanges: 'Échange de cryptomonnaies',
        manual_portfolios: 'Portefeuilles manuels',
        last_updated: 'Dernière mise à jour',
        connection_updated: 'Connexion actualisé',
        days_ago: 'il ya jours', // this one is handled custom because in french it is said "il ya 3 hours" with the 3 in the middle
        today_at: 'aujourd\'hui à',
        connection_expired: 'Connexion expirée',
        your_views: 'Votre vues',
        your_owners: 'Votre propriétaires',
        connections: 'Connections',

        no_manual_portfolios: 'Pas de portefeuille manuel',
        no_api_connections: 'Pas de connexion API',
        delete_account: 'supprimer le compte',
        see_terms_and_conditions: 'Vous pouvez voir les conditions générales sur',
        this_link: 'ce link',
    }

};
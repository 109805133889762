import api from '@/services/api';

class PostRequisition{

  post(userId, body) {
    return api.post('/v1/users/'+ userId +'/openbanking/requisition', body);
  }
}

export default new PostRequisition();

<template>

    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <span class="material-icons modal-close-button" @click="closeModal">
                        close
                    </span>


                    <template v-if="viewIsNotDeleatable">

                        <div id="connection-delete-modal">

                            <h1 class="headline-small black">
                                {{ $t('actions.you_cant_delete_view') }}
                            </h1>

                            <cannot-delete-this-view-component />

                            <div class="horizontal-frame">
                                <button class="tertiary full-rounded"
                                        @click="closeModal">
                                    <p class="label-large">
                                        {{ $t('actions.abort_button') }}
                                    </p>
                                </button>
                            </div>
                        </div>

                    </template>
                    <template v-else>


                        <div id="connection-delete-modal">
                            <h1 class="headline-small black">
                                {{ $t('actions.delete_view_warning') }}
                                "{{ view.name }}"
                            </h1>
                            <button-eliminate-animation-component />
                            <p class="body-large black">
                                {{ $t('actions.to_delete') }}
                                "{{ view.name }}"
                                {{ $t('actions.type_name') }}
                                "{{ view.name }}"
                                {{ $t('actions.then_click_delete') }}
                            </p>
                            <div class="input-fields">
                                <input-field-component v-model="inputValue.doubleCheck"
                                                       type="text"
                                                       :label="$t('actions.type_here')"
                                                       :isValid="isValid.doubleCheck" />
                            </div>
                            <div class="horizontal-frame">
                                <button class="primary full-rounded"
                                        @click="deleteView">
                                    <p class="label-large">
                                        {{ $t('actions.delete_button') }}
                                    </p>
                                </button>
                                <button class="tertiary full-rounded"
                                        @click="closeModal">
                                    <p class="label-large">
                                        {{ $t('actions.abort_button') }}
                                    </p>
                                </button>
                            </div>
                        </div>


                    </template>

                </div>
            </div>
        </div>
    </Transition>

</template>

<script>
    
    import ButtonEliminateAnimationComponent from '@/components/lottieImages/ClickHere';
    import CannotDeleteThisViewComponent from '@/components/lottieImages/Security';
    import InputFieldComponent from '@/components/fields/InputField';

    import PostUsersProfileCurrentView from '@/services/users/profile/currentView/post.service';
    import DeleteUsersPages from '@/services/users/pages/delete.service';
    import DeleteUsersProfileView from '@/services/users/profile/view/delete.service';
    import EventBus from "@/common/EventBus";
    
    export default {
        name: 'Create-New-View',
        emits: ['close'],
        props: ['show', 'view'],
        components: {
            InputFieldComponent,
            ButtonEliminateAnimationComponent,
            CannotDeleteThisViewComponent
        },
        data() {
            return {
                inputValue: {
                    doubleCheck: ''
                },
                isValid: {
                    doubleCheck: true
                }
            };
        },
        computed: {
            undeleatableViewIds() {
                return this.$store.getters['views/getUserPersonalViewIds'];
            },
            viewIsNotDeleatable() {
                return this.undeleatableViewIds.includes(this.view._id);
            }
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },

            deleteView() {

                if (this.viewIsNotDeleatable) {
                    return;
                }

                if (this.inputValue.doubleCheck !== this.view.name) {
                    this.isValid.doubleCheck = false;
                    return;
                } else {
                    this.isValid.doubleCheck = true;
                }

                const userId = this.$store.getters['auth/getUserId'];
                DeleteUsersProfileView.delete(userId, this.view._id).then(
                    () => {
                        // move the user away from current view
                        this.$store.dispatch('auth/setUsersCurrentView', this.$store.getters['views/getAllViewId']);
                        PostUsersProfileCurrentView.post(userId, this.view._id).then(
                            () => {
                                // do nothing
                            }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                        // set the current view to another one from current view
                        this.$store.dispatch('views/setCurrentView', this.$store.getters['views/getAllViewId']);
                        this.$store.dispatch('views/deleteView', this.view);
                        
                        // force the elimination of all the pages with the current view id
                        DeleteUsersPages.delete(userId, '-' + this.view._id).then(
                            () => {
                                this.$emit('close');
                            },
                            (error) => {
                                if (error.response && error.response.status === 403) {
                                    EventBus.dispatch("logout");
                                }
                            }
                        );
                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            
            }
        }
    }
</script>


<style scoped>

    div#connection-delete-modal {
        width: 100%;
        max-width: 808px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

        div#connection-delete-modal > div.input-fields {
            width: 100%;
            max-width: 408px;
            display: flex;
        }

        div#connection-delete-modal > div.horizontal-frame {
            display: flex;
            justify-content: center;
            gap: 16px;
        }
</style>
import api from '@/services/api';

class PatchUsersCryptoWalletName {

    patch(id, data) {
        return api.patch('/v1/users/' + id + '/crypto/wallet/name', data);
    }
}

export default new PatchUsersCryptoWalletName();

import api from '../../../api';

class PlatformCryptoLogos {

  get() {
    return api.get('/v1/platform/crypto/logos');
  }
}

export default new PlatformCryptoLogos();

<template>
    <div id="connect">
        <h1 class="headline-small black">
            {{ $t('connect.connect_your_assets')}}
        </h1>
        <div class="connection-frame">

            <div class="menu-frame">
                <menu-item-component :label="$t('connect.banks_and_payments')"
                                     :isActive="activeItem === 'banks'"
                                     @click="activeItem = 'banks'" />
                <menu-item-component :label="$t('connect.cryptocurrencies')"
                                     :isActive="activeItem === 'crypto'"
                                     @click="activeItem = 'crypto'" />
            </div>


            <banks-and-titles-list-component v-if="activeItem === 'banks'"
                                             :page="page"
                                             :tableId="tableId" />
            <cryptocurrencies-list-component v-if="activeItem === 'crypto'"
                                             :page="page"
                                             :tableId="tableId" />

        </div>
    </div>
</template>

<script>

    import MenuItemComponent from '@/components/connect/navigation/MenuItem';

    import BanksAndTitlesListComponent from '@/components/connect/navigation/BanksAndTitlesList';
    import CryptocurrenciesListComponent from '@/components/connect/navigation/CryptocurrenciesList';

    export default {
        name: 'Connect-View',
        data() {
            return {
                activeItem: 'banks'
            }
        },
        components: {
            MenuItemComponent,
            BanksAndTitlesListComponent,
            CryptocurrenciesListComponent
        },
        created() {
            const queryActiveItem = this.$route.query.activeItem;
            if (queryActiveItem) {
                this.activeItem = queryActiveItem;
            }
        },
        computed: {
            page() {
                return this.$route.query.page;
            },
            tableId() {
                return this.$route.query.table;
            }
        }
    }
</script>

<style scoped>
    div#connect {
        width: 100%;
        max-width: 808px;
        margin: 32px auto auto auto;
    }
    @media (max-width: 599px) {
        div#connect {
            padding: 0px 8px;
            width: calc(100% - 16px);
        }
    }

        div#connect > div.connection-frame {
            margin-top: 16px;
        }

            div#connect > div.connection-frame > div.menu-frame {
                display: flex;
                margin: 8px auto 8px auto;
                justify-content: center;
            }
</style>
import { createStore } from "vuex";
import { auth } from "./auth.module";
import { pages } from "./pages.module";
import { connections } from "./connections.module";
import { markets } from "./markets.module";
import { platform } from "./platform.module";
import { views } from "./views.module";

const store = createStore({
    modules: {
        auth: auth,
        pages: pages,
        connections: connections,
        markets: markets,
        platform: platform,
        views: views
    },
});

export default store;

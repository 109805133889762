<template>

    <top-bar />


    <div id="confirm-view">
        <div v-if="$breakpoints.isMDOrLarger()">
            <img class="img-responsive" :src="require('@/assets/img/illustrations/Onboarding.svg')" />
        </div>
        <div class="align-vertically">
            <h1 class="display-small white">
                {{ $t('forms.confirm_page.congrats') }}
            </h1>
            <h2 class="title-large white">
                {{ $t('forms.confirm_page.account_verified') }}
            </h2>

            <template v-if="!$breakpoints.isMDOrLarger()">
                <p class="body-large white">
                    {{ $t('forms.confirm_page.connect_superpowers') }}
                </p>
            </template>
            <template v-else>
                <p class="d-none d-md-flex title-medium white">
                    {{ $t('forms.confirm_page.connect') }}
                </p>
                <p class="d-none d-md-flex title-medium white">
                    {{ $t('forms.confirm_page.superpowers') }}
                </p>
            </template>

            <button class="secondary full-rounded"
                    @click="goToLogin">
                <p class="label-large">
                    {{ $t('forms.confirm_page.go_to_login') }}
                </p>
            </button>
            <img v-if="!$breakpoints.isMDOrLarger()"
                    class="img-responsive-mobile"
                    :src="require('@/assets/img/illustrations/Onboarding.svg')" />
        </div>
    </div>

</template>

<script>
    import TopBar from "@/components/auth/TopBar";

    import AuthService from "@/services/auth/auth.service";

    export default {
        name: "Confirm-Page",
        components: {
            TopBar
        },
        beforeCreate() {
            document.body.className = 'register-confirm';
            AuthService.verifyUser(this.$route.params.confirmationCode);
            console.log('Thank you for registering.');
        },
        methods: {
            goToLogin() {
                this.$router.push('/login');
            }
        }
    }
</script>

<style scoped>

    div#confirm-view {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin: 80px auto auto auto;
    }

    @media (min-width: 600px) {
        div#confirm-view {
            gap: 128px;
        }

            div#confirm-view > div.form-wrapper {
                width: 40%;
            }
    }


    .img-responsive {
        object-fit: contain;
        width: 80%;
        max-width: 70vh;
        margin: 0 0 0 8vw;
    }
    .img-responsive-mobile {
        object-fit: contain;
        width: 80%;
    }
    .align-vertically {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 16px;
        text-align: center;
    }
</style>
<template>
    <p class="body-medium black">
        {{ assetAmount.toLocaleString(userLanguage, {maximumFractionDigits: precisionDigits}) }}
    </p>
</template>

<script>
    export default {
        name: 'Amount-Component',
        props: ['amount', 'precisionDigits'],
        computed: {

            assetAmount() {

                if (this.amount) {
                    let digits = 100;
                    if (this.precisionDigits && this.precisionDigits >= 0) {
                        digits = 10 ** this.precisionDigits;
                    }
                    return Math.round(this.amount * digits) / digits;
                }
                return '-';
            },

            userLanguage() {
                return this.$store.getters['auth/getUserLanguage'];
            }
        }
    }
</script>
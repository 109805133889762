<template>

    <top-bar />

    <div id="register-view">
        <div class="form-wrapper">
            <sign-up-form />
        </div>
        <img v-if="$breakpoints.isMDOrLarger()"
             class="img-responsive"
             :src="require('@/assets/img/illustrations/high_quality_products.svg')" />
    </div>

</template>

<script>

    import SignUpForm from "@/components/auth/register/SignUpForm";
    import TopBar from "@/components/auth/TopBar";

    export default {
        name: "Register-Page",
        components: {
            TopBar,
            SignUpForm
        },
        beforeCreate: function () {
            document.body.className = 'register';
        }
    };
</script>

<style scoped>

    div#register-view {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 80px auto auto auto;
    }

        div#register-view > div.form-wrapper {
            padding-left: 16px;
            padding-right: 16px;
        }

    @media (min-width: 600px) {
        div#register-view {
            gap: 60px;
            background-color: transparent;
        }

            div#register-view > div.form-wrapper {
                width: 40%;
            }
    }

    div .img-responsive {
        object-fit: contain;
        width: 80%;
        max-width: 70vh;
        margin: 0 8vw 0 0;
    }
</style>

<template>

    <div :class="{
         'menu-item': true,
         'active': isActive
         }">
        <p class="label-large">
            {{ label }}
        </p>
    </div>
</template>


<script>
    export default {
        name: 'Menu-Item-Component',
        props: ['isActive', 'label']
    }
</script>


<style scoped>
    div.menu-item {
        padding: 16px;
        border-bottom: 1px solid var(--secondary);
        cursor: pointer;
        min-width: 96px;
        text-align: center;
    }

        div.menu-item.active {
            color: var(--secondary);
            border-bottom: 2px solid var(--secondary);
            cursor: auto;
        }
</style>
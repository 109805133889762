<template>

    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <span class="material-icons modal-close-button" @click="closeModal">
                        close
                    </span>

                    <h2 class="headline-small black">
                        {{ $t('forms.new_owner.create_new_owner') }}
                    </h2>

                    <form class="branded-input-form" @submit.prevent="submitForm">

                        <div class="fields-frame">
                            <input-field-component v-model="inputFields.nickname"
                                                                type="text"
                                                                :label="$t('forms.new_owner.nickname')"
                                                                :isValid="isValid.nickname" />

                        </div>

                        <div class="fields-frame">
                            <input-field-component v-model="inputFields.name"
                                                                    type="text"
                                                                    :label="$t('forms.new_owner.name')"
                                                                    :isValid="isValid.name" />
                            <input-field-component v-model="inputFields.surname"
                                                                    type="text"
                                                                    :label="$t('forms.new_owner.surname')"
                                                                    :isValid="isValid.surname" />
                        </div>

                        <div class="fields-frame">
                            <input-field-component v-model="inputFields.email"
                                                type="text"
                                                :label="$t('forms.new_owner.email')"
                                                :isValid="isValid.email" />
                            <input-field-component v-model="inputFields.mobile"
                                                type="text"
                                                :label="$t('forms.new_owner.mobile')"
                                                :isValid="isValid.mobile" />

                        </div>


                        <div class="buttons-frame">
                            <!-- confirm button start -->
                            <button class="tertiary full-rounded"
                                    :disabled="isFormDisabled">
                                <span v-if="isFormDisabled"
                                    class="material-icons loading-icon">
                                    sync
                                </span>
                                <p class="label-large">
                                    {{ $t('forms.add') }}
                                </p>
                            </button>
                            <!-- confirm button end -->
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </Transition>

</template>

<script>

    import InputFieldComponent from '@/components/fields/InputField';

    import PostUsersProfileOwner from "@/services/users/profile/owner/post.service";
    import EventBus from "@/common/EventBus";

    export default {
        name: 'Create-New-Owner',
        emits: ['close'],
        props: ['show'],
        components: {
            InputFieldComponent,
        },
        data() {
            return {
                isFormDisabled: false,
                inputFields: {
                    nickname: undefined,
                    name: undefined,
                    surname: undefined,
                    email: undefined,
                    mobile: undefined
                },
                isValid: {
                    nickname: true,
                    name: true,
                    surname: true,
                    email: true,
                    mobile: true
                }
            };
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },
            submitForm() {

                //validation (we can transform this into a javascripts function)

                if (this.inputFields.nickname == null ||
                    this.inputFields.nickname === '' ||
                    !this.inputFields.nickname.trim()
                    //fastest and easiest way to check whether a string is made of only whitespaces
                    ) {
                    this.isValid.nickname = false;
                    return;
                } else {
                    this.isValid.nickname = true;
                }

                if (this.inputFields.name == null ||
                    this.inputFields.name === '' ||
                    !this.inputFields.name.trim()
                    ) {
                    this.isValid.name = false;
                    return;
                } else {
                    this.isValid.name = true;
                }


                if (this.inputFields.surname == null ||
                    this.inputFields.surname === '' ||
                    !this.inputFields.surname.trim()
                    ) {
                    this.isValid.surname = false;
                    return;
                } else {
                    this.isValid.surname = true;
                }

                // execute

                this.isFormDisabled = true;

                const data = {
                    nickname: this.inputFields.nickname,
                    name: this.inputFields.name,
                    surname: this.inputFields.surname,
                    email: this.inputFields.email,
                    mobile: this.inputFields.mobile
                };

                const user_id = this.$store.getters['auth/getUserId'];

                PostUsersProfileOwner.post(user_id, data).then(
                    response => {

                        const newlyCreatedOwner = response.data;

                        this.$store.dispatch('views/addOwner', newlyCreatedOwner);
                        this.$emit('close', newlyCreatedOwner);

                    },
                    (error) => {
                        this.isFormDisabled = false;
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            }
        }
    }
</script>
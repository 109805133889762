<template>

    <div class="company-profile-card">
        <div class="title-card">
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.company_profile_title') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>
        <div>
            <p v-if="companyProfile.sector"
               class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.sector') }}
                </span>
                {{ companyProfile.sector }}
            </p>
            <p v-if="companyProfile.industry"
               class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.industry') }}
                </span>
                {{ companyProfile.industry }}
            </p>
            <p v-if="companyProfile.ceo"
               class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.ceo') }}
                </span>
                {{ companyProfile.ceo }}
            </p>
            <p v-if="companyProfile.description"
               class="body-large black">
                <span class="bold">
                    {{ $t('dashboard.asset_details.description') }}
                </span>
                {{ getCompanyDescription }}
                <span v-if="!showMoreCompanyDescription"
                      class="show-more-button-text"
                      @click="showMoreCompanyDescription = !showMoreCompanyDescription">
                    {{ $t('dashboard.asset_details.show_more') }}
                </span>
                <span v-if="showMoreCompanyDescription"
                      class="show-more-button-text"
                      @click="showMoreCompanyDescription = !showMoreCompanyDescription">
                    {{ $t('dashboard.asset_details.show_less') }}
                </span>
            </p>
            <p v-if="!companyProfile.sector &&
                 !companyProfile.industry &&
                 !companyProfile.ceo &&
                 !companyProfile.description"
               class="body-large black">
                {{ $t('dashboard.asset_details.could_not_find_profile') }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Company-Profile-Component',
        props: ['asset', 'page', 'path'],
        data() {
            return {
                showMoreCompanyDescription: false
            };
        },
        methods: {

            removeCard() {

                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },
        },
        computed: {
            markets() {
                return this.$store.getters['markets/getMarkets'];
            },
            companyFundamentals() {
                return (this.markets[this.asset.ticker]) ? this.markets[this.asset.ticker].fundamentals || undefined : undefined;
            },
            companyProfile() {
                return (this.companyFundamentals) ? this.companyFundamentals.profile : {};
            },
            getCompanyDescription() {

                if (this.companyProfile) {
                    if (this.showMoreCompanyDescription) {

                        return this.companyProfile.description;

                    } else {

                        const descriptionSimpleSentences = this.companyProfile.description.split('.');
                        let shortDescription = descriptionSimpleSentences[0] + '.';

                        let i = 1;
                        while (shortDescription.length < 50) {
                            shortDescription += descriptionSimpleSentences[i] + '.';
                            i++;
                        }
                        return shortDescription;

                    }
                }
                return '';
            }
        }
    }
</script>

<style scoped>

    div.company-profile-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 4px;
        border-radius: var(--high-rounded);
        background-color: var(--white);
        padding: 16px 32px;
        width: calc(100% - 64px);
    }

        div.company-profile-card > div.title-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 8px;
        }

            div.company-profile-card > div.title-card > span {
                cursor: pointer;
            }

    .show-more-button-text {
        color: var(--secondary);
        cursor: pointer;
    }
</style>
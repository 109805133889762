


export default {

    it: {
        assets: 'Asset',
        connections: 'Connessioni',

        all: 'Tutto',
        crypto: 'Cripto',
        stocks: 'Titoli',
        liquidity: 'Liquidità',
        manual: 'Manuale',
        banks: 'Banche',
        exchange: 'Exchange',
        wallet: 'Wallet',

        // placeholders

        add_cryptocurrency: 'Aggiungi una criptovaluta',
        add_stocks: 'Aggiungi un titolo azionario',
        add_cash: 'Aggiungi denaro contante',
        add_bank: 'Connetti un\'istituzione o app di pagamento',
        add_exchange: 'Connetti un exchange di criptovalute',
        add_wallet: 'Connetti un wallet di criptovalute',
        add_manual: 'Connetti un portfolio manuale',
    },

    es: {
        assets: 'Activos',
        connections: 'Conexiones',

        all: 'Todos',
        crypto: 'Cripto',
        stocks: 'Titulos',
        liquidity: 'Liquidez',
        manual: 'Manual',
        banks: 'Bancos',
        exchange: 'Exchange',
        wallet: 'Wallet',

        // placeholders

        add_cryptocurrency: 'Añadir una criptomoneda',
        add_stocks: 'Añadir un titulo',
        add_cash: 'Añadir dinero en efectivo',
        add_bank: 'Conectar una cuenta bancaria',
        add_exchange: 'Conectar un exchange de criptomoneda',
        add_wallet: 'Conectar un wallet de criptomoneda',
        add_manual: 'Conectar un portoflio manual',
    },

    en: {
        assets: 'Assets',
        connections: 'Connections',

        all: 'All',
        crypto: 'Crypto',
        stocks: 'Securities',
        liquidity: 'Liquidity',
        manual: 'Manual',
        banks: 'Banks',
        exchange: 'Exchange',
        wallet: 'Wallet',

        // placeholders

        add_cryptocurrency: 'Add a cryptocurrency',
        add_stocks: 'Add a security',
        add_cash: 'Add cash and currencies',
        add_bank: 'Connect a bank account',
        add_exchange: 'Connect a crypto exchange',
        add_wallet: 'Connect a crypto wallet',
        add_manual: 'Connect a manual portfolio',
    },

    fr: {
        assets: 'Actifs',
        connections: 'Connexions',

        all: 'Tout',
        crypto: 'Crypto',
        stocks: 'Titres',
        liquidity: 'Liquidité',
        manual: 'Manuel',
        banks: 'Banques',
        exchange: 'Échange',
        wallet: 'Wallet',

        // placeholders

        add_cryptocurrency: 'Ajoutez une cryptomonnaie',
        add_stocks: 'Ajoutez un titre',
        add_cash: 'Ajoutez argent liquide',
        add_bank: 'Connectez un compte bancaire',
        add_exchange: 'Connectez un échange de cryptomonnaies',
        add_wallet: 'Connectez un wallet de cryptomonnaies',
        add_manual: 'Connectez un portefeuille manuel',
    },

};
<template>
    <div class="asset-owners">
        <div v-for="(owner, index) in currentOwners"
             v-bind:key="index"
             :style="`background-color: ${colors[index%3]}`">

            <p class="label-large black">
                {{ owner.nickname }}
            </p>
        </div>
    </div>

</template>

<script>

    import { ownerColors } from '@/assets/js/fields/ownerColors';

    export default {
        name: 'Asset-Owners-Component',
        props: ['ownerIds'],
        computed: {
            colors() {
                return ownerColors();
            },
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            views() {
                return this.$store.getters['views/getViews'];
            },
            currentViewId() {
                return this.$store.getters['views/getCurrentView'];
            },
            allViewId() {
                return this.$store.getters['views/getAllViewId'];
            },
            currentView() {
                return this.views.find(x => x._id === this.currentViewId);
            },
            currentOwners() {

                if (this.owners == null ||
                    this.currentView == null ||
                    (this.currentView.owners == null &&
                    this.allViewId !== this.currentViewId)) {
                    return [];
                }

                return this.owners.filter(x => this.ownerIds.includes(x._id) &&
                    (this.allViewId === this.currentViewId ||
                    this.currentView.owners.includes(x._id)));
            },
        },
    }
</script>

<style scoped>

    div.asset-owners {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2px;
    }

    div.asset-owners > div {
        padding: 1px 8px;
        margin: 2px 0px;
        height: fit-content;
        width: fit-content;
        border-radius: var(--middle-rounded);
    }

</style>
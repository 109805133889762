import api from '@/services/api';

class PlatformCountries {

  get() {
    return api.get('/v1/platform/countries/');
  }
}

export default new PlatformCountries();

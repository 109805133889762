<template>
    <div class="center-screen">
        <div class="help-frame custom-device-width">
            <h5 v-if="form_step === 1 && exchangeName" class="headline-large tertiary">
                {{ $t('connect.info_card.connecting_to') }} {{exchange_name}}
            </h5>
            <h5 v-else class="headline-large tertiary">
                {{ $t('connect.info_card.connecting_heading') }}
            </h5>

            <!-- DISPLAY WHEN THE EXCHANGE IS NOT PROVIDED  -->

            <div class="tutorial-frame">
                <div v-if="form_step === 0" class="tutorial-step">
                    <p class="body-large black">
                        {{ $t('connect.info_card.list_insert_name') }}
                    </p>
                </div>

                <div v-if="form_step === 0" class="tutorial-step">
                    <p class="body-large black">
                        {{ $t('connect.info_card.list_follow_instruction') }}
                    </p>
                </div>

                <!-- DISPLAY WHEN THE EXCHANGE IS PROVIDED  -->

                <div v-if="form_step === 1" class="tutorial-step">
                    <p class="body-large black">
                        <span class="bold"> 1. </span>
                        {{ $t('connect.info_card.login_to') }}
                        <a :href="forwardUrl" target="_blank" class="tertiary bold">
                            {{exchange_name}}
                        </a>.
                    </p>
                </div>
                <div v-if="form_step === 1" class="tutorial-step">
                    <p class="body-large black">
                        <span class="bold"> 2. </span>
                        {{ $t('connect.info_card.list_create_api') }}
                    </p>
                </div>
                <div v-if="form_step === 1" class="tutorial-step">
                    <p class="body-large black">
                        <span class="bold"> 3. </span>
                        {{ $t('connect.info_card.list_insert_info') }}
                    </p>
                </div>
                <div v-if="form_step === 1" class="tutorial-step d-none d-md-block">
                    <p class="body-large black">
                        <span class="bold"> 4. </span>
                        {{ $t('connect.info_card.missing_required_field') }}
                        <router-link to="/support">
                            <span class="tertiary bold">
                                {{ $t('connect.info_card.missing_contact_us') }}
                            </span>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'ExchangeInfo-Card',
        props: ['www_url', 'api_url', 'exchange_name', 'form_step'],
        computed: {
            forwardUrl() {
                if (typeof this.api_url !== 'undefined' && this.api_url.length > 0) {
                    return this.api_url;
                }
                if (typeof this.www_url !== 'undefined' && this.www_url.length > 0) {
                    return this.www_url;
                }
                return '';
            },
            exchangeName() {
                if (typeof this.exchange_name !== 'undefined' && this.exchange_name.length > 0) {
                    return this.exchange_name;
                }
                return undefined;
            }
        }
    }
</script>

<style scoped>

    .center-screen {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .help-frame {
        background-color: var(--white);
        border-radius: var(--high-rounded);
        padding: 32px 64px;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    /* the info card should display on 100% width if it is displayed vertically with the input form*/
    @media (max-width: 904px) {
        .help-frame.custom-device-width {
            width: calc(100%-64px);
            padding: 16px 32px;
        }
    }

    @media (min-width: 905px) {
        .help-frame.custom-device-width {
            margin: 64px auto auto auto;
            width: calc(90%-128px);
        }
    }

    .tutorial-frame {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        gap: 2px;
    }

    .tutorial-step {
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: left;
        align-items: center;
        gap: 1rem;
    }

    .connection-step {
        color: var(--white);
        background-color: var(--black);
        border-radius: 4px;
    }

    a.tertiary:hover {
        color: var(--secondary);
    }
</style>

import api from '@/services/api';

class PutPlatformSurveysPoll {

    put(id, data) {
        return api.put('/v1/platform/surveys/poll/' + id, data);
    }
}

export default new PutPlatformSurveysPoll();

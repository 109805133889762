<template>
	<div />
</template>

<script>
	export default {
		name: 'Redirect-To-Dashboard',
		beforeCreate() {
			this.$router.push('/dashboard');
		}
	}
</script>
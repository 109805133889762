<template>


    <div id="support-view">

        <img class="img" :src="require('@/assets/img/illustrations/support.svg')" />


        <div>

            <h1 class="headline-large tertiary">
                {{ $t('support.need_help') }}
            </h1>
            <p class="body-large black">
                {{ $t('support.text_help_frame') }}
            </p>
            <button class="primary high-rounded" href="tel:00393331980774">
                <span class="material-icons">call</span>
                <p class="label-large">
                    {{ $t('support.call_us') }}
                </p>
            </button>
            <button class="primary high-rounded" href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/giuseppe-360track/find-out-more'});return false;">
                <span class="material-icons">video_call</span>
                <p class="label-large">
                    {{ $t('support.meet_us') }}
                </p>
            </button>
            <button class="primary high-rounded" href="mailto:giuseppe@360track.me" @click="emailClicked=true">
                <span class="material-icons">alternate_email</span>
                <p v-if="emailClicked===false" class="label-large">
                    {{ $t('support.write_us') }}
                </p>
                <p v-else class="label-large">giuseppe@360track.me</p>
            </button>
        </div>


    </div>
    
</template>


<script>
    export default {
        name: "Support-View",
        data() {
            return {
                emailClicked: false
            };
        },
        mounted() {
            const calendlyScript = document.createElement('script');
            calendlyScript.setAttribute('type', 'text/javascript');
            calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
            document.head.appendChild(calendlyScript);
        }
    }
</script>


<style scoped>
    @import 'https://assets.calendly.com/assets/external/widget.css';


    div#support-view {
        display: flex;
        align-items: center;
        gap: 32px;
        width: calc(100%-32px);
        max-width: 808px;
        margin: 32px auto auto auto;
        padding: 16px;
    }
        div#support-view > img {
            width: 42%;
            margin-top: 24px;
        }

        div#support-view > div {
            background-color: var(--white);
            border-radius: var(--high-rounded);
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 12px;
        }

            div#support-view > div button {
                padding: 16px;
                justify-content: center;
                width: 80%;
            }
                div#support-view > div button:hover {
                    background-color: var(--secondary);
                }

    @media (max-width: 1239px) {
        div#support-view {
            flex-direction: column;
        }
            div#support-view > img {
                width: 60%;
                margin-top: 24px;
            }
    }
</style>

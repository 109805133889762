import api from '@/services/api';

class PatchUsersCryptoWalletAddress {

    patch(id, source, blockchain) {
        return api.patch('/v1/users/' + id + '/crypto/wallet/address', { source: source, blockchain: blockchain });
    }
}

export default new PatchUsersCryptoWalletAddress();

import api from '@/services/api';

class MarketsStocksFundamentals {

    get(ticker) {
        return api.get('/v1/markets/stocks/fundamentals/' + ticker);
    }
}

export default new MarketsStocksFundamentals();

import api from '../../../api';

class MarketsETFsPrices {

  patch(tickers) {
    return api.patch('/v1/markets/etfs/' + tickers.join(',')  );
  }
}

export default new MarketsETFsPrices();

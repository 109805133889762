<template>

    <!-- small devices layout -->

    <form class="branded-input-form small-layouts" @submit.prevent="searchStocksAndETFs">
        <div class="fields-frame">
            <input-field-component type="text"
                                   v-model="inputQuery.symbol_or_name"
                                   :label="$t('forms.security_or_ticker')"
                                   :isValid="true" />
        </div>
        <div class="fields-frame">
            <stock-exchange-select-field-component v-model="inputQuery.exchange_name"
                                                   style="cursor:pointer;"
                                                   :label="$t('forms.exchange')"
                                                   @click="showSelectExchangeModalFunction"/>
        </div>


        <div class="buttons-frame">
            <button class="secondary full-rounded">
                <p class="label-large">
                    {{ $t('forms.search') }}
                </p>
            </button>
        </div>
    </form>

    <!-- large devices layout -->

    <form class="branded-input-form large-layouts" @submit.prevent="searchStocksAndETFs">
        <div class="fields-frame">
            <input-field-component type="text"
                                   v-model="inputQuery.symbol_or_name"
                                   :label="$t('forms.security_or_ticker')"
                                   :isValid="true" />
            <stock-exchange-select-field-component v-model="inputQuery.exchange_name"
                                                   style="cursor:pointer;"
                                                   :label="$t('forms.exchange')"
                                                   @click="showSelectExchangeModalFunction" />
            <button class="secondary full-rounded">
                <p class="label-large">
                    {{ $t('forms.search') }}
                </p>
            </button>
        </div>
    </form>

    <div class="table-wrapper">
        <table v-if="etfsAndStocksAndMarket.length > 0"
               class="branded-table">
            <thead>
                <tr>
                    <th class="text-align-left">
                        <p class="title-medium black bold">
                            Security
                        </p>
                    </th>
                    <th class="text-align-left">
                        <p class="title-medium black bold">
                            Exchange
                        </p>
                    </th>
                    <th class="text-align-left">
                        <p class="title-medium black bold">
                            Country
                        </p>
                    </th>
                    <th />
                </tr>
            </thead>
            <tbody>

                <search-stocks-etfs-table-row v-for="(item, index) in etfsAndStocksAndMarket"
                                              :key="index"
                                              :ticker="item.symbol"
                                              :name="item.name"
                                              :mic_code="item.mic_code"
                                              :exchange_name="item.exchange_name"
                                              :country_code="item.country_code"
                                              :country_name="item.country"
                                              :type="item.type" />
            </tbody>
        </table>
    </div>



    <!-- show the modal component to complete the insertion of the stock or etf if necessary -->
    <Teleport v-if="showModal" to="body">
        <AddStocksETFsModalComponent @close="showModal = false" :show="showModal" :type="modalData.type"
                                     :mic_code="modalData.mic_code" :name="modalData.name" :exchange_name="modalData.exchange_name"
                                     :symbol="modalData.symbol" />
    </Teleport>

    <!-- show the modal component to select a stock exchange if necessary -->
    <Teleport v-if="showSelectExchangeModal" to="body">
        <SelectExchangeModal @close="showSelectExchangeModal = false" :show="showSelectExchangeModal"
                             :currentSelection="inputQuery.exchange_name"
                             :allStockExchangesPlaceholderName="allStockExchangesPlaceholderName" />
    </Teleport>

</template>

<script>

    import AddStocksETFsModalComponent from './AddStocksETFsModal';
    import SelectExchangeModal from './SelectExchangeModal';
    import SearchStocksEtfsTableRow from './SearchStocksEtfsTableRow';

    import InputFieldComponent from '@/components/fields/InputField';
    import StockExchangeSelectFieldComponent from '@/components/fields/stocks/StockExchangeSelectField';

    export default {
        name: 'Stocks-ETFs-Search-Component',
        components: {
            AddStocksETFsModalComponent,
            SelectExchangeModal,
            SearchStocksEtfsTableRow,
            InputFieldComponent,
            StockExchangeSelectFieldComponent
        },
        data() {
            return {
                showModal: false,
                showModalAddSource: false,
                showSelectExchangeModal: false,
                modalData: {},
                inputQuery: {},
                supported_stock_exchanges: [],
                allStockExchangesPlaceholderName: 'All Stocks' //this.$i18n.getLocaleMessage(this.$i18n.locale).i18n.ManualForms.all_exchanges

            };
        },
        computed: {
            etfsAndStocksAndMarket() {
                let etfs = this.$store.getters['platform/getEtfs'];
                etfs = (etfs) ? etfs : [];

                let stocks = this.$store.getters['platform/getStocks'];
                stocks = (stocks) ? stocks : [];

                let exchanges = this.$store.getters['platform/getMarketsExchanges'];
                exchanges = (exchanges) ? exchanges : [];

                const mergedItems = [];

                /* merge stocks and etfs */
                etfs.forEach(etf => {
                    mergedItems.push({
                        symbol: etf.symbol,
                        name: etf.name,
                        currency: etf.currency,
                        mic_code: etf.mic_code,
                        type: 'etfs'
                    });
                });

                stocks.forEach(stock => {
                    mergedItems.push({
                        symbol: stock.symbol,
                        name: stock.name,
                        currency: stock.currency,
                        mic_code: stock.mic_code,
                        type: 'stocks'
                    });
                });

                /* add information on the exchange */
                mergedItems.forEach(item => {
                    const exchange = exchanges.find(x => x.code === item.mic_code);
                    if (exchange) {
                        item.exchange_name = exchange.market_name;
                        item.country = exchange.country;
                        item.country_code = exchange.country_code;
                    }
                });

                // sort by name
                mergedItems.sort(function (a, b) {
                    return a.symbol.localeCompare(b.symbol)
                });

                return mergedItems;
            }
        },
        methods: {

            searchStocksAndETFs() {

                const name = (this.inputQuery.symbol_or_name) ? this.inputQuery.symbol_or_name : undefined;
                const symbol = (this.inputQuery.symbol_or_name) ? this.inputQuery.symbol_or_name : undefined;
                let mic_code = undefined;
                if (this.inputQuery.exchange_name) {
                    // get the content of the item in the last round brackets pair
                    mic_code = this.inputQuery.exchange_name.split('(');
                    mic_code = mic_code[mic_code.length - 1].slice(0, -1);
                }

                const query = {
                    name: name,
                    symbol: symbol,
                    mic_code: mic_code
                };

                this.$store.dispatch('platform/refreshStocks', query);
                this.$store.dispatch('platform/refreshETFs', query);
            },


            // go to next step
            saveStocksETFsSymbol(inputItem) {
                this.modalData = {
                    type: inputItem.type,
                    mic_code: inputItem.mic_code,
                    exchange_name: inputItem.exchange_name,
                    name: inputItem.name,
                    symbol: inputItem.symbol
                };
                this.showModal = true;
            },


            // modals logic
            closeModal() {
                this.modalData = false;
                this.showModal = false;
            },
            flipModals() {
                this.showModal = false;
                this.showModalAddSource = true;
            },
            hideModalFunction() {
                this.showModal = true;
                this.showModalAddSource = false;
            },
            showSelectExchangeModalFunction() {
                this.showSelectExchangeModal = true;
            },
            closeModalSelectExchange(selectedExchange) {
                this.showSelectExchangeModal = false;
                // set the selection
                this.inputQuery.exchange_name = (selectedExchange === this.allStockExchangesPlaceholderName) ? undefined : selectedExchange;
                // update the search
                this.searchStocksAndETFs();
            }
        },
        created() {
            const language = this.$store.getters['auth/getUserLanguage'];

            // set in automatic the italian stock exchange if the user speaks italian
            if (language === 'it') {
                this.inputQuery.exchange_name = "Borsa Italiana (XMIL)";
                this.searchStocksAndETFs();
            } else if (language === 'en') {
                this.inputQuery.exchange_name = "London Stock Exchange (XLON)";
                this.searchStocksAndETFs();
            } else if (language === 'es') {
                this.inputQuery.exchange_name = "Bolsa De Madrid (XMAD)";
                this.searchStocksAndETFs();
            } else if (language === 'fr') {
                this.inputQuery.exchange_name = "Euronext Paris (XPAR)";
                this.searchStocksAndETFs();
            }
        }
    };

</script>

<style scoped>
    @media (max-width: 904px) {
        form.branded-input-form.small-layouts {
            display: flex;
        }
        form.branded-input-form.large-layouts {
            display: none;
        }
    }
    @media (min-width: 905px) {
        form.branded-input-form.small-layouts {
            display: none;
        }

        form.branded-input-form.large-layouts {
            display: flex;
        }
    }
</style>
<template>
    <tr @click="saveStocksETFsSymbol(ticker, name, exchange_name, mic_code, type)">

        <td class="text-align-left">
            <p class="body-medium black bold">
                {{ticker}}
            </p>
            <p class="body-medium black">
                {{name}}
            </p>
        </td>

        <td class="text-align-left">
            <p class="body-medium black bold">
                {{mic_code}}
            </p>
            <p class="body-medium black">
                {{exchange_name}}
            </p>
        </td>

        <td class="text-align-left">
            <p class="body-medium black bold">
                {{country_code}}
            </p>
            <p class="body-medium black">
                {{country_name}}
            </p>
        </td>

        <td>
            <span class="material-icons tertiary">
                add_circle
            </span>
        </td>

    </tr>
</template>

<script>

    export default {
        name: 'SearchStocksEtfsTableRow-Component',
        props: ['ticker', 'name', 'mic_code', 'exchange_name', 'country_code', 'country_name', 'type'],
        methods: {
            saveStocksETFsSymbol(symbol, name, exchange_name, mic_code, type) {

                this.$parent.saveStocksETFsSymbol({
                    type: type,
                    mic_code: mic_code,
                    exchange_name: exchange_name,
                    name: name,
                    symbol: symbol
                });
            }
        }
    }
</script>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>

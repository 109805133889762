import api from '@/services/api';

class UsersProfileCurrentView {

    post(id, currentView) {
        return api.post('/v1/users/' + id + '/profile/current-view', {
            currentView: currentView
        });
    }
}

export default new UsersProfileCurrentView();




/* FOREX */

import PatchMarketsForexPrices from "@/services/markets/forex/prices/patch.service";
import GetMarketsForexPrices from "@/services/markets/forex/prices/get.service";

import PatchMarketsForexHistorical from "@/services/markets/forex/prices/historical/patch.service";
import GetMarketsForexHistorical from "@/services/markets/forex/prices/historical/get.service";


/* STOCKS */

import PatchMarketsStocksPrices from "@/services/markets/stocks/prices/patch.service";
import GetMarketsStocksPrices from "@/services/markets/stocks/prices/get.service";

import PatchMarketsStocksHistorical from "@/services/markets/stocks/prices/historical/patch.service";
import GetMarketsStocksHistorical from "@/services/markets/stocks/prices/historical/get.service";

import GetMarketsStocksFundamentals from '@/services/markets/stocks/fundamentals/get.service';

/* ETFs */

import PatchMarketsETFsPrices from "@/services/markets/etfs/prices/patch.service";
import GetMarketsETFsPrices from "@/services/markets/etfs/prices/get.service";

import PatchMarketsETFsHistorical from "@/services/markets/etfs/prices/historical/patch.service";
import GetMarketsETFsHistorical from "@/services/markets/etfs/prices/historical/get.service";

/* CRYPTO */

import PatchMarketsCryptoPrices from "@/services/markets/crypto/prices/patch.service";
import GetMarketsCryptoPrices from "@/services/markets/crypto/prices/get.service";

import PatchMarketsCryptoHistorical from "@/services/markets/crypto/prices/historical/patch.service";
import GetMarketsCryptoHistorical from "@/services/markets/crypto/prices/historical/get.service";


const { getDatesISO } = require('@/assets/js/markets/dates/getDatesISO.js');
import EventBus from "@/common/EventBus";




export const markets = {
    namespaced: true,
    state: {
        isLoaded: false,
        markets: {},
        // used to assess that all the async calls have finished properly
        // increases by 1 before each external call
        // decreases by 1 after each external call
        _concurrentCallsCounter: 1, // starts at 1 for technical reasons - to avoid an initial glitch
        maxConcurrentCallsCounter: 1, // the maximum number of concurrent calls that happened
    },
    actions: {

        refresh({ commit, rootGetters }) {


            /****************************************/
            /******** Preliminary operations ********/
            /****************************************/


            commit('clearMarkets');

            const connections = rootGetters['connections/getConnections'];

            // get all the assets grouped by market type - cryptocurrency forex stocks ...
            const cryptoTickers = [];
            const stockTickers = [];
            const forexTickers = ['USD', 'EUR', 'GBP', 'CHF']; // add currencies conversions to the market data to ask
            const etfsTickers = [];

            for (const connection of connections) {
                for (const asset of connection.balance) {
                    switch (asset.type) {
                        case 'cryptocurrency':
                            if (!cryptoTickers.includes(asset.ticker)) {
                                cryptoTickers.push(asset.ticker);
                            } break;
                        case 'stocks':
                            if (!stockTickers.includes(asset.ticker)) {
                                stockTickers.push(asset.ticker);
                            } break;
                        case 'forex':
                            if (!forexTickers.includes(asset.ticker)) {
                                forexTickers.push(asset.ticker);
                            } break;
                        case 'etfs':
                            if (!etfsTickers.includes(asset.ticker)) {
                                etfsTickers.push(asset.ticker);
                            } break;
                    }
                }
            }

            // get iso dates for historical timeframes - week, quarter, year
            const isoDates = getDatesISO();
            const query = {
                dates: [
                    isoDates.week,
                    isoDates.quarter,
                    isoDates.year
                ]
            }

            /****************************************/
            /**************** CRYPTO ****************/
            /****************************************/

            if (cryptoTickers && cryptoTickers.length > 0) {
                commit('increaseCallCounter');
                PatchMarketsCryptoPrices.patch(cryptoTickers).then(() => {
                    GetMarketsCryptoPrices.get(cryptoTickers).then((response) => {

                        commit('saveCurrentMarketData', response.data);
                        commit('decreaseCallCounter');

                    }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                commit('increaseCallCounter');
                PatchMarketsCryptoHistorical.patch(cryptoTickers).then(() => {
                    GetMarketsCryptoHistorical.get(cryptoTickers, query).then((response) => {

                        commit('saveHistoricalMarketData', response.data);
                        commit('decreaseCallCounter');

                    }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

            }

            /*****************************************/
            /***************** FOREX *****************/
            /*****************************************/

            if (forexTickers && forexTickers.length > 0) {
                // prepare the data with a standard quote currency
                const quoteCurrency = 'USD';
                const sameBaseQuoteCurrencyIndexes = [];
                for (let i = 0; i < forexTickers.length; i++) {
                    if (forexTickers[i] === quoteCurrency) {
                        sameBaseQuoteCurrencyIndexes.push(i);
                    }
                    forexTickers[i] += '-' + quoteCurrency;
                }

                // handle the cases with same quote and base currencies
                sameBaseQuoteCurrencyIndexes.forEach(index => {

                    const currentTicker = forexTickers[index].replace('-', '/');

                    const mockCurrentMarketData = {};
                    mockCurrentMarketData[currentTicker] = {};
                    mockCurrentMarketData[currentTicker].price = 1;
                    mockCurrentMarketData[currentTicker].percentage_change = 0;
                    commit('saveCurrentMarketData', mockCurrentMarketData);

                    const mockHistoricalMarketData = {};
                    mockHistoricalMarketData[currentTicker] = [];
                    query.dates.forEach(date => {
                        mockHistoricalMarketData[currentTicker].push({
                            timestamp: date,
                            open: 1,
                            high: 1,
                            low: 1,
                            close: 1
                        });
                    });
                    commit('saveHistoricalMarketData', mockHistoricalMarketData);

                    forexTickers.splice(index, 1); // remove them from the query
                });

                // handle calls one by one
                forexTickers.forEach((forexTicker) => {
                    commit('increaseCallCounter');
                    PatchMarketsForexPrices.patch(forexTicker).then(() => {
                        GetMarketsForexPrices.get(forexTicker).then((response) => {

                            // prepare the data to be stored
                            const marketData = {};
                            const ticker = forexTicker.replace('-', '/');
                            marketData[ticker] = {};
                            marketData[ticker].price = response.data.rate;
                            marketData[ticker].percentage_change = 0;

                            commit('saveCurrentMarketData', marketData);
                            commit('decreaseCallCounter');

                        }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                    commit('increaseCallCounter');
                    PatchMarketsForexHistorical.patch(forexTicker).then(() => {
                        GetMarketsForexHistorical.get(forexTicker, query).then((response) => {

                            commit('saveHistoricalMarketData', response.data);
                            commit('decreaseCallCounter');

                        }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                });
            }

            /******************************************/
            /***************** STOCKS *****************/
            /******************************************/

            if (stockTickers && stockTickers.length > 0) {
                commit('increaseCallCounter');
                PatchMarketsStocksPrices.patch(stockTickers).then(() => {
                    GetMarketsStocksPrices.get(stockTickers).then((response) => {

                        commit('saveCurrentMarketData', response.data);
                        commit('decreaseCallCounter');

                    }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                commit('increaseCallCounter');
                PatchMarketsStocksHistorical.patch(stockTickers).then(() => {
                    for (const ticker of stockTickers) {
                        commit('increaseCallCounter');
                        GetMarketsStocksHistorical.get([ticker], query).then((response) => {

                            commit('saveHistoricalMarketData', response.data);
                            commit('decreaseCallCounter');

                        }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }
                    commit('decreaseCallCounter');
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

            }

            /****************************************/
            /***************** ETFs *****************/
            /****************************************/

            if (etfsTickers && etfsTickers.length > 0) {
                commit('increaseCallCounter');
                PatchMarketsETFsPrices.patch(etfsTickers).then(() => {
                    GetMarketsETFsPrices.get(etfsTickers).then((response) => {

                        commit('saveCurrentMarketData', response.data);
                        commit('decreaseCallCounter');

                    }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

                commit('increaseCallCounter');
                PatchMarketsETFsHistorical.patch(etfsTickers).then(() => {
                    for (const ticker of etfsTickers) {
                        commit('increaseCallCounter');
                        GetMarketsETFsHistorical.get([ticker], query).then((response) => {

                            commit('saveHistoricalMarketData', response.data);
                            commit('decreaseCallCounter');

                        }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
                    }
                    commit('decreaseCallCounter');
                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });

            }

            /******************************************/
            /********* Complete the procedure *********/
            /******************************************/


            // decrease by 1 for technical reasons - because it started from 1 at the beginning
            commit('decreaseCallCounter'); 
        },


        /************************************* */
        /*******     HISTORICAL DATA     *******/
        /************************************* */


        askHistoricalData({ commit }, params) {

            const type = params.type;
            const ticker = params.ticker;
            let queryTickers = [];
            const MarketProxy = {};

            switch (type) {
                case 'stocks':
                    MarketProxy.patch = PatchMarketsStocksHistorical.patch;
                    MarketProxy.get = GetMarketsStocksHistorical.get;
                    queryTickers = [ticker];
                    break;
                case 'etfs':
                    MarketProxy.patch = PatchMarketsETFsHistorical.patch;
                    MarketProxy.get = GetMarketsETFsHistorical.get;
                    queryTickers = [ticker];
                    break;
                case 'forex':
                    MarketProxy.patch = PatchMarketsForexHistorical.patch;
                    MarketProxy.get = GetMarketsForexHistorical.get;
                    queryTickers = ticker + '-USD';
                    break;
                case 'cryptocurrency':
                    MarketProxy.patch = PatchMarketsCryptoHistorical.patch;
                    MarketProxy.get = GetMarketsCryptoHistorical.get;
                    queryTickers = [ticker];
                    break;
                default:
                    return;
            }

            MarketProxy.patch(queryTickers).then(() => {
                MarketProxy.get(queryTickers).then((response) => {

                    commit('saveHistoricalMarketData', response.data);

                }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
            }, (error) => { if (error.response && error.response.status === 403) { EventBus.dispatch("logout"); } });
        },


        /************************************* */
        /****    Stocks fundamental data    ****/
        /************************************* */


        askFundamentalData({ commit }, ticker) {

            GetMarketsStocksFundamentals.get(ticker).then(
                (response) => {

                    const fundamentalData = {};
                    fundamentalData[ticker] = response.data;
                    commit('saveFundamentalData', fundamentalData);

                },
                (error) => {
                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                });
        }

    },
    mutations: {

        clearMarkets(state) {
            state._concurrentCallsCounter = 1;
            state.maxConcurrentCallsCounter = 1;
            state.isLoaded = false;
        },

        saveCurrentMarketData(state, marketData) {

            const tickers = Object.keys(marketData);

            for (const ticker of tickers) {
                if (!(ticker in state.markets)) {
                    state.markets[ticker] = {};
                }
                state.markets[ticker].price = marketData[ticker].price;
                state.markets[ticker].percentageChange = marketData[ticker].percentage_change;
            }
        },

        saveHistoricalMarketData(state, marketData) {

            const tickers = Object.keys(marketData);

            for (const ticker of tickers) {
                if (!(ticker in state.markets)) {
                    state.markets[ticker] = {};
                }
                state.markets[ticker].historical = marketData[ticker];
            }
        },

        saveFundamentalData(state, fundamentalData) {

            const tickers = Object.keys(fundamentalData);

            for (const ticker of tickers) {
                if (!(ticker in state.markets)) {
                    state.markets[ticker] = {};
                }
                state.markets[ticker].fundamentals = fundamentalData[ticker];
            }
        },


        /***************************************** */
        /*****    MANAGEMENT OF ASYNC CALLS    *****/
        /***************************************** */


        increaseCallCounter(state) {
            state._concurrentCallsCounter += 1;
            state.maxConcurrentCallsCounter += 1;
        },

        decreaseCallCounter(state) {
            state._concurrentCallsCounter -= 1;
            if (state._concurrentCallsCounter === 0) {
                state.isLoaded = true;
            }
        }

    },
    getters: {

        isLoaded(state) {
            return state.isLoaded;
        },

        getMarkets(state) {
            return state.markets;
        },

        getProgress(state) {
            return (state.maxConcurrentCallsCounter - state._concurrentCallsCounter) / state.maxConcurrentCallsCounter * 100;
        },

    }
};

export default {

    it: {
        // common
        delete_button: 'Elimina',
        abort_button: 'Annulla',
        type_here: "Digita qui",
        go_back: "Indietro",

        // delete account
        delete_account_warning: "Attenzione. Stai per eliminare l'account",
        delete_account_message: "Per eliminare l'account digita ELIMINA ACCOUNT e poi clicca su ELIMINA",
        delete_account_boolean_check: 'ELIMINA ACCOUNT', // used to do the final check

        // delete connection
        delete_connection_warning: 'Attenzione. Stai per eliminare',
        to_eliminate: 'Per disconnettere o eliminare',
        type_name: 'digita',
        then_click_delete: 'e poi clicca su ELIMINA.',
        you_will_lose_data: 'Perderai tutti i dati connessi a',

        // delete assets
        delete_manual_title: 'Vuoi eliminare i tuoi',
        delete_manual_infobox: 'Cliccando su "Elimina" eliminerai tutti le unità di',
        click_delete_to_go_on: '. Clicca su "Elimina" per proseguire ad eliminare i tuoi asset. Altrimenti clicca su "Salta" o "Annulla".',
        cannot_delete: "Non puoi eliminare",
        because_is_connection: "perché è un asset aggiornato in tempo reale e sincronizzato tramite connessione API.",

        // delete view
        you_cant_delete_view: "Non puoi eliminare questa vista",
        delete_view_warning: "Attenzione! Stai per eliminare la vista",
        to_delete: "Per eliminare",

        // delete owner
        delete_owner_warning: "Attenzione! Stai per eliminare la vista",
    },

    es: {
        // common
        delete_button: 'Eliminar',
        abort_button: 'Cancelar',
        type_here: "Escriba aqu�",
        go_back: "Volver",

        // delete account
        delete_account_warning: 'Advertencia. Está a punto de eliminar su cuenta',
        delete_account_message: "Para eliminar la cuenta, escriba ELIMINAR CUENTA y haga clic en ELIMINAR.",
        delete_account_boolean_check: 'ELIMINAR CUENTA', // used to do the final check

        // delete connection 
        delete_connection_warning: 'Advertencia. Está a punto de desconectar',
        to_eliminate: 'Para desconectar',
        type_name: 'escriba',
        then_click_delete: 'y luego haga clic en BORRAR.',
        you_will_lose_data: 'YPerderás todos los datos asociados a',

        // delete assets
        delete_manual_title: '¿Quieres eliminar tus',
        delete_manual_infobox: 'Al hacer clic en "Eliminar" se borrarán todas las unidades de',
        click_delete_to_go_on: '. Haga clic en "Eliminar" para proceder a la eliminación de sus activos. De lo contrario, haga clic en "Omitir" o "Cancelar".',
        cannot_delete: "No puede eliminar",
        because_is_connection: "porque es un activo actualizado en tiempo real y sincronizado a través de una conexión API.",

        // delete view
        you_cant_delete_view: "No puedes eliminar esta vista",
        delete_view_warning: "¡Atención! Está a punto de eliminar la vista",
        to_delete: "Para eliminar",

        // delete owner
        delete_owner_warning: "¡Atención! Está a punto de eliminar el propietario",
    },

    en: {
        // common
        delete_button: 'Delete',
        abort_button: 'Go Back',
        type_here: "Type in here",
        go_back: "Go back",

        // delete account
        delete_account_warning: 'Warning. You are about to eliminate your account',
        delete_account_message: "To delete the account type DELETE ACCOUNT and then click DELETE",
        delete_account_boolean_check: 'DELETE ACCOUNT', // used to do the final check

        // delete connection & portfolio
        delete_connection_warning: 'Warning. You are about to delete',
        to_eliminate: 'To disconnect or delete',
        type_name: 'type in',
        then_click_delete: 'and then click on DELETE.',
        you_will_lose_data: 'You will lose all data associated with',

        // delete assets
        delete_manual_title: 'Do you want to eliminate your',
        delete_manual_infobox: 'Clicking on "Delete" will delete all the units of',
        click_delete_to_go_on: 'Click on "Delete" to proceed with the deletion of your assets. Otherwise, click "Skip" or "Cancel".',
        cannot_delete: "You cannot delete",
        because_is_connection: "because it is an asset updated in real time and synchronized via API connection.",

        // delete view
        you_cant_delete_view: "You cannot delete this view",
        delete_view_warning: "Attention! You are about to delete the view",
        to_delete: "To delete",

        // delete owner
        delete_owner_warning: "Attention! You are about to delete the holder",
    },

    fr: {
        // common
        delete_button: 'Supprimer',
        abort_button: 'Annuler',
        type_here: "�crivez ici",
        go_back: "Retourner",

        // delete account
        delete_account_warning: 'Avertissement. Vous êtes sur le point de supprimer votre compte',
        delete_account_message: "Pour supprimer le compte, tapez SUPPRIMER LE COMPTE et cliquez sur SUPPRIMER.",
        delete_account_boolean_check: 'SUPPRIMER LE COMPTE', // used to do the final check

        // delete connection & portfolio
        delete_connection_warning: 'Avertissement. Vous êtes sur le point de supprimer',
        to_eliminate: ' Pour déconnecter ou supprimer',
        type_name: 'entrez',
        then_click_delete: 'et cliquez sur SUPPRIMER.',
        you_will_lose_data: 'Vous perdrez toutes les données associées à',

        // delete assets
        delete_manual_title: 'Voulez-vous éliminer vos',
        delete_manual_infobox: 'En cliquant sur "Supprimer", vous supprimez toutes les unités de',
        click_delete_to_go_on: 'Cliquez sur "Supprimer" pour procéder à la suppression de vos avoirs. Sinon, cliquez sur "Passer" ou "Annuler".',
        cannot_delete: "Vous ne pouvez pas supprimer",
        because_is_connection: "car il s'agit d'un actif mis à jour en temps réel et synchronisé via une connexion API.",

        // delete view
        you_cant_delete_view: "Vous ne pouvez pas supprimer cette vue",
        delete_view_warning: "Attention! Vous êtes sur le point de supprimer la vue",
        to_delete: "Pour supprimer",

        // delete owner
        delete_owner_warning: "Attention! Vous êtes sur le point de supprimer le propriétaire",
    },

};
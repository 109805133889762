<template>
    <div class="hover-information-section"
         :style="cssProps">
        <p class="body-small white">
            {{ $t('dashboard.do') }}
            <span class="bold">
                {{ $t('dashboard.click') }}
            </span>
            {{ $t('dashboard.to_add_row') }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'Hover-Information-Section',
        props: ['show'],
        data() {
            return {
                isFullyShown: false
            }
        },
        watch: {

            show: function (newValue, oldValue) {
                // apply 0.25s delay to the show of the menu

                if (newValue === true && oldValue === false) {
                    setTimeout(() => {
                        this.isFullyShown = true;
                    }, 250);
                } else {
                    this.isFullyShown = false;
                }
            }

        },
        computed: {
            cssProps() {
                return {
                    display: (this.show && this.isFullyShown) ? 'block' : 'none'
                };
            }
        },
    }
</script>

<style scoped>
    div.hover-information-section {
        background-color: var(--primary);
        position: absolute;
        top: 32px;
        left: 0px;
        z-index: 100;
        padding: 8px;
        border-radius: var(--middle-rounded);
        text-align: center;
    }
</style>
<template>
    <template v-if="!isLoaded">

        <animated-placeholder-component height="176px"
                                        width="100%" />

    </template>
    <template v-else>

        <div>
            <div v-if="views && views.length > 0">
                <div class="views-frame">
                    <view-card-component v-for="(view, index) in views"
                                         v-bind:key="index"
                                         :view="view" />
                </div>
            </div>
        </div>


    </template>
</template>

<script>

    import AnimatedPlaceholderComponent from '@/components/AnimatedPlaceholder';

    import ViewCardComponent from '@/components/profile/views/ViewCard';

    export default {
        name: 'Profile-Views-Component',
        components: {
            AnimatedPlaceholderComponent,
            ViewCardComponent
        },
        computed: {
            isLoaded() {
                return this.$store.getters['views/isLoaded'];
            },
            views() {
                return this.$store.getters['views/getViews'];
            }
        },
    }
</script>

<style scoped>
    div.views-frame {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
</style>
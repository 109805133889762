


export default {

    it: {
        edit_manual_title: "Modifica",
        in: "in",
        edit_amount_of: "Modifica la quantità di",
        cannot_edit: "Non puoi modificare la quantità di",

        handles_staking: "gestisce lo staking con ticker speciali.",
        may_stand_for: "potrebbe corrispondere a",
        please_insert: "Per favore inserisci il ticker standard della tua cripto in staking",
        other_suggestions: "Altri suggerimenti",

        submit_button: "Invia",
        skip_for_now: "Salta",

        exchange_title: "Seleziona Mercato Azionario",
        exchange_search: "Cerca Mercato Azionario",

        of: "di"
    },

    es: {
        edit_manual_title: "Editar",
        in: "en",
        edit_amount_of: "Editar la cantidad de",
        cannot_edit: "No se puede modificar la cantidad de",

        handles_staking: "maneja el staking de cripto con ticker especiales.",
        may_stand_for: "podría coincidir con ",
        please_insert: "Por favor ingresa el ticker standard de tu cripto en staking",
        other_suggestions: "Otras sugerencias",

        submit_button: "Enviar",
        skip_for_now: "Saltar",

        exchange_title: "Seleccione Mercado",
        exchange_search: "Busca Mercado",
        security_or_ticker: "Título o Ticker",

        of: "de"
    },

    en: {
        edit_manual_title: "Edit",
        in: "in",
        edit_amount_of: "Edit the amount of",
        cannot_edit: "You cannot edit the amount of",

        handles_staking: "handles staking with custom tickers.",
        may_stand_for: "may stand for",
        please_insert: "Please insert the standard ticker of the crypto you staked",
        other_suggestions: "Other suggestions",

        submit_button: "Submit",
        skip_for_now: "Skip",

        exchange_title: "Select Stock Exchange",
        exchange_search: "Search Stock Exchange",
        of: "of"
    },

    fr: {
        edit_manual_title: "Modifier",
        in: "dans",
        edit_amount_of: "Modifier la quantité de",
        cannot_edit: "Vous ne pouvez pas modifier le montant de",

        handles_staking: "gère le staking des crypto en utilisant tickers spéciaux.",
        may_stand_for: "pourrait correspondre à ",
        please_insert: "Veuillez insérer le ticker standard de votre crypto en staking",
        other_suggestions: "Autres possibilités",

        submit_button: "Envoyer",
        skip_for_now: "Ignorer",

        exchange_title: "Sélectionner le marché",
        exchange_search: "Recherche Marché",

        of: "de"
    },

};
<template>

    <Transition name="modal">
        <div v-if="show" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <span class="material-icons modal-close-button" @click="closeModal">
                        close
                    </span>


                    <div id="connection-delete-modal">
                        <h1 class="headline-small black">
                            {{ $t('actions.delete_owner_warning') }}
                            "{{ currentOwner.nickname }}"
                        </h1>
                        <button-eliminate-animation-component />
                        <p class="body-large black">
                            {{ $t('actions.to_delete') }}
                            "{{ currentOwner.nickname }}"
                            {{ $t('actions.type_name') }}
                            "{{ currentOwner.nickname }}"
                            {{ $t('actions.then_click_delete') }}
                        </p>
                        <div class="input-fields">
                            <input-field-component v-model="inputValue.doubleCheck"
                                                   type="text"
                                                   :label="$t('actions.type_here')"
                                                   :isValid="isValid.doubleCheck" />
                        </div>
                        <div class="horizontal-frame">
                            <button class="primary full-rounded"
                                    @click="deleteView">
                                <p class="label-large">
                                    {{ $t('actions.delete_button') }}
                                </p>
                            </button>
                            <button class="tertiary full-rounded"
                                    @click="closeModal">
                                <p class="label-large">
                                    {{ $t('actions.abort_button') }}
                                </p>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Transition>

</template>

<script>

import ButtonEliminateAnimationComponent from '@/components/lottieImages/ClickHere';
import InputFieldComponent from '@/components/fields/InputField';

import DeleteUsersPages from '@/services/users/pages/delete.service';
import DeleteUsersProfileOwner from '@/services/users/profile/owner/delete.service';
import EventBus from "@/common/EventBus";

export default {
        name: 'Create-New-View',
        emits: ['close'],
        props: ['show', 'ownerId'],
        components: {
            InputFieldComponent,
            ButtonEliminateAnimationComponent
        },
        data() {
            return {
                inputValue: {
                    doubleCheck: ''
                },
                isValid: {
                    doubleCheck: true
                }
            };
        },
        computed: {
            owners() {
                return this.$store.getters['views/getOwners'];
            },
            currentOwner() {
                if (this.owners == null || this.ownerId == null) {
                    return {};
                }
                return this.owners.find(x => x._id === this.ownerId) || {};
            }
        },
        methods: {
            closeModal() {
                this.$emit('close');
            },

            deleteView() {

                if (this.viewIsNotDeleatable) {
                    return;
                }

                if (this.inputValue.doubleCheck !== this.currentOwner.nickname) {
                    this.isValid.doubleCheck = false;
                    return;
                } else {
                    this.isValid.doubleCheck = true;
                }

                const userId = this.$store.getters['auth/getUserId'];
                DeleteUsersProfileOwner.delete(userId, this.currentOwner._id).then(
                    () => {

                        const currentOwnerId = this.currentOwner._id;

                        // delete owner from storage
                        this.$store.dispatch('views/deleteOwner', this.currentOwner);
                        // delete the owner from the view in the storage
                        this.$store.dispatch('views/removeOwnerFromAllViews', currentOwnerId);


                        // force the elimination of all the pages with the current owner id
                        DeleteUsersPages.delete(userId, 'o' + currentOwnerId).then(
                            () => {
                                this.$router.go(-1);
                                this.$emit('close');
                            },
                            (error) => {
                                if (error.response && error.response.status === 403) {
                                    EventBus.dispatch("logout");
                                }
                            }
                        );

                    },
                    (error) => {
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );

            }
        }
}
</script>


<style scoped>

    div#connection-delete-modal {
        width: 100%;
        max-width: 808px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

        div#connection-delete-modal > div.input-fields {
            width: 100%;
            max-width: 408px;
            display: flex;
        }

        div#connection-delete-modal > div.horizontal-frame {
            display: flex;
            justify-content: center;
            gap: 16px;
        }
</style>
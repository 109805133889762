<template>
    <div class="asset-distribution-card">
        <div>
            <h2 class="title-medium black bold">
                {{ $t('dashboard.asset_details.distribution_over_portfolios') }}
            </h2>
            <span class="material-icons error title-medium"
                  @click="removeCard">
                cancel
            </span>
        </div>
        <canvas :id="chartId" />
    </div>
</template>

<script>

    import Chart from 'chart.js/auto';

	export default {
		name: "Asset-Location-Chart",
        props: ['asset', 'page', 'path'],
        data() {
            return {
                assetLocationChart: undefined,
                chartId: 'asset-location-chart-' + (Math.random() + 1).toString(36).substring(2)
            };
        },
        methods: {

            removeCard() {
                this.$store.dispatch('pages/deleteCurrentRow', {
                    page: this.page,
                    path: this.path

                });
            },



            writeChart(amounts, sourceNames) {
                const ctx = document.getElementById(this.chartId);

                if (amounts == null ||
                    sourceNames == null ||
                    amounts.length === 0 ||
                    sourceNames.length === 0 ||
                    amounts.length !== sourceNames.length) {
                    return;
                }

                if (this.assetLocationChart) {
                    this.assetLocationChart.destroy();
                }

                // random colors
                const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
                const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

                const colors = ['#5ED5A8', '#D7B058', '#18232A', '#EE7671', '#E5ECF0'];

                for (let _ = 0; _ < sourceNames.length - 5; _ ++) {
                    colors.push(randomRGB());
                }

                const data = {
                    labels: sourceNames,
                    datasets: [{
                        label: 'Asset Location',
                        data: amounts,
                        backgroundColor: colors,
                        hoverOffset: 4
                    }]
                };

                const options = {
                    plugins: {
                        legend: {
                            display: true,
                            position: 'right'
                        },
                        title: {
                            display: false
                        }
                    }
                };

                this.assetLocationChart = new Chart(ctx, {
                    type: 'doughnut',
                    data: data,
                    options: options
                });
			}
        },
        computed: {
            areAssetsLoading() {
                return this.$store.getters['assets/isLoading'];
            },

            connections() {
                return this.$store.getters['connections/getConnections'];
            },

            tickerInformation() {
                const information = {
                    source_name: [],
                    source: [],
                    id: [],
                    service: [],
                    individual_amounts: [],
                    amount: 0
                };

                if (this.connections == null) {
                    return undefined;
                }

                this.connections.forEach(connection => {

                    const balances = connection.balance.filter(x => (x.staking_ticker || x.ticker) === this.asset.ticker);
                    if (balances.length === 0) {
                        return; // skip iteration
                    }

                    information.source_name.push(connection.name);
                    information.source.push(connection.source);
                    information.id.push(connection.id);
                    information.service.push(connection.service);
                    information.individual_amounts.push(balances.map(x => x.amount).reduce((a, b) => a + b, 0));
                    information.amount += balances.map(x => x.amount).reduce((a, b) => a + b, 0);
                });

                return information;
            },
        },
        watch: {
            areAssetsLoading: async function () {

                // TODO: remove this abomination
                await new Promise(resolve => setTimeout(resolve, 2000));

                if (this.tickerInformation) {
                    const amounts = this.tickerInformation.individual_amounts;
                    const sourceNames = this.tickerInformation.source_name;
                    this.writeChart(amounts, sourceNames);
                }
            }
        },
        mounted() {
            if (this.tickerInformation) {
                const amounts = this.tickerInformation.individual_amounts;
                const sourceNames = this.tickerInformation.source_name;
                this.writeChart(amounts, sourceNames);
            }
		}
	}
</script>

<style scoped>
    div.asset-distribution-card {
        width: calc(100% - 64px);
        max-height: 256px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px 32px;
        background-color: var(--white);
        border-radius: var(--high-rounded);
    }
        div.asset-distribution-card > canvas {
            align-self: center;
        }

        div.asset-distribution-card > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

            div.asset-distribution-card > div > span {
                cursor: pointer;
            }
</style>
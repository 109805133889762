import api from '../../../../api';

class PostUsersManualAssetSource {

  post(id, data) {
    return api.post('/v1/users/'+id+'/manual/asset/source', data);
  }
}

export default new PostUsersManualAssetSource();

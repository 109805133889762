<template>

    <div class="input-field high-rounded">
        <input :id="inputFieldId"
               :type="type"
               :class="{
                    'body-medium black': true,
                    'invalid': !isValid,
               }"
               :value="modelValue"
               placeholder=" "
               autocomplete="off"
               @focus="showSuggestionList"
               @blur="hideSuggestionList"
               @input="$emit('update:modelValue', $event.target.value);
                       showSuggestionList">
        <label v-if="label"
               :for="inputFieldId"
               class="label-large black">
            {{ label }}
        </label>


        <button class="toggle-icon"
                type="button"
                v-if="showDropdownArrowDown">
            <span class="material-icons black">
                arrow_drop_down
            </span>
        </button>
        <button class="toggle-icon"
                type="button"
                v-else-if="showDropdownArrowUp">
            <span class="material-icons black">
                arrow_drop_up
            </span>
        </button>
        <button type="button"
                v-else
                @click="$emit('update:modelValue', '')">
            <span class="material-icons black">
                clear
            </span>
        </button>


        <p class="body-small error"
           v-if="!isValid && errorMessage">
            {{ errorMessage }}
        </p>

        <!-- autocomplete list -->

        <div class="suggestions-list"
             v-if="inputSuggestions.length > 0 && displaySuggestionList">
            <div class="suggestion-item"
                 v-for="(suggestion, index) in inputSuggestions"
                 v-bind:key="index"
                 @click="hideSuggestionList; $emit('update:modelValue', suggestion)">

                <p class="body-medium black">
                    {{ suggestion }}
                </p>

            </div>
        </div>



    </div>


</template>

<script>
    export default {
        name: 'Input-Field-Component',
        props: ['label', 'modelValue', 'type', 'id', 'isValid', 'list', 'errorMessage'],

        /*
         *  label - the label - optional
         *  modelValue (not passed) - bind it using v-model from the parent
         *  type - the type of the input - optional
         *  id - the id of the input - optional, but it is safer to pass it
         *  isValid - true or false. Must control it in the parent component - mandatory
         * 
         * */

        data() {
            return {
                inputFieldId: (this.id) ? this.id : this.randomString(),
                displaySuggestionList: false
            };
        },
        computed: {
            inputSuggestions() {
                const suggestions = [];
                let count = 0;

                if (this.modelValue == null || this.modelValue === '') {
                    return this.list.slice(0, 9);
                }

                for (const suggestion of this.list) {
                    if (suggestion.toLowerCase().startsWith(this.modelValue.toLowerCase())) {
                        suggestions.push(suggestion);
                        count++;
                    }
                    
                    if (count > 9) {
                        break;
                    }
                }

                return suggestions;
            },

            showDropdownArrowDown() {
                return this.displaySuggestionList === false && (this.modelValue == null || this.modelValue.length === 0);
            },
            showDropdownArrowUp() {
                return this.displaySuggestionList && (this.modelValue == null || this.modelValue.length === 0);
            },
            
        },
        methods: {
            randomString() {
                // generate a random 7 characters string
                return (Math.random() + 1).toString(36).substring(2);
            },
            hideSuggestionList() {
                setTimeout(() => {
                    this.displaySuggestionList = false;
                }, 128);
            },
            showSuggestionList() {
                this.displaySuggestionList = true;
            }
        }
    }
</script>

<style scoped>

    div.suggestions-list {
        position: absolute;
        z-index: 50;
        margin: 16px auto auto -8px;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 100%;
        border: 1px solid var(--primary);
        background-color: var(--white);
    }
        div.suggestions-list > div.suggestion-item {
            padding: 2px 8px 2px 8px;
        }

            div.suggestions-list > div.suggestion-item:hover {
                background-color: var(--secondary);
                cursor: pointer;
            }

    /* override button display preference for toggle buttons */

    div.input-field > input:valid:placeholder-shown ~ button.toggle-icon {
        display: inline;
    }

    div.input-field > input:focus:placeholder-shown ~ button.toggle-icon {
        display: inline;
    }
</style>
import api from '../../../../api';

class MarketsStocksPricesHistorical {

  patch(tickers) {
    return api.patch('/v1/markets/stocks/historical/' + tickers.join(","));
  }
}

export default new MarketsStocksPricesHistorical();

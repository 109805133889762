

const navigation = require('./translations/navigation').default;
const connect = require('./translations/connect').default;
const dashboard = require('./translations/dashboard').default;
const tables = require('./translations/tables').default;
const rewards = require('./translations/rewards').default;
const support = require('./translations/support').default;
const forms = require('./translations/forms').default;
const profile = require('./translations/profile').default;
const actions = require('./translations/actions').default;



const it = {
    current: "IT",

    navigation: navigation.it,
    connect: connect.it,
    dashboard: dashboard.it,
    tables: tables.it,
    rewards: rewards.it,
    support: support.it,
    forms: forms.it,
    profile: profile.it,
    actions: actions.it,


    /* mapping ISO 2366 Alpha 2 codes to name of the country in the language */
    /*
     * AD: "Andorra"
     * AE: "United Arab Emirates"
     * ...
     * 
     * */
    i18nCountriesISO: require("i18n-iso-countries/langs/it.json").countries
};

export { it };


const auth = require('./forms/auth.js').default;
const signup = require('./forms/signup.js').default;
const register_success = require('./forms/registerSuccess.js').default;
const recovery_form = require('./forms/recoveryForm.js').default;
const reset_form = require('./forms/resetForm.js').default;
const confirm_page = require('./forms/confirmPage.js').default;
const amend_modal = require('./forms/amendModal.js').default;
const new_owner = require('./forms/newOwner.js').default;
const new_view = require('./forms/newView.js').default;


export default {

    it: {
        add_the_new_portfolio: 'Crea il nuovo portfolio',
        or_create_portfolio: 'Aggiungi un nuovo portfolio',
        write_portfolio_name: 'Scrivi il nome del portfolio',
        add_portfolio: 'Aggiungi portfolio',
        submit: 'Invia',
        skip: 'Salta',
        add: 'Aggiungi',
        connect: 'Connetti',
        continue: 'Continua',
        search: 'Cerca',
        security_or_ticker: 'Nome o Ticker (e.g Tesla, TSLA)',
        delete: 'Elimina',
        back: 'Indietro',
        cancel: 'Annulla',

        select_portfolio: 'Seleziona portfolio',
        currency: 'Valuta (e.g. Euro)',
        cryptocurrency: 'Criptovaluta (e.g. Bitcoin)',
        amount: 'Digita Quantità',
        type_your_exchange: 'Scrivi il nome del tuo exchange',
        select_wallet: '',
        wallet_address: '',
        blockchain_name: '',
        exchange: 'Exchange',
        stock: 'Titolo',

        auth: auth.it,
        signup: signup.it,
        register_success: register_success.it,
        recovery_form: recovery_form.it,
        reset_form: reset_form.it,
        confirm_page: confirm_page.it,
        amend_modal: amend_modal.it,
        new_owner: new_owner.it,
        new_view: new_view.it,
    },

    es: {
        add_the_new_portfolio: 'Añadir lo nuevo portfolio',
        or_create_portfolio: 'Añadir un nuevo portfolio',
        write_portfolio_name: 'Escribe el nombre de lo portfolio',
        add_portfolio: 'Añadir Portfolio',
        submit: 'Enviar',
        skip: 'Saltar',
        add: 'Añadir',
        connect: 'Conectar',
        continue: 'Continuar',
        search: 'Busca',
        security_or_ticker: 'Nombre o Ticker (e.g Tesla, TSLA)',
        delete: 'Eliminar',
        back: 'Regresa',
        cancel: 'Cancelar',

        select_portfolio: 'Seleccionar Portfolio',
        currency: "Moneda (e.g. Euro)",
        cryptocurrency: 'Criptomoneda (e.g. Bitcoin)',
        amount: "Escriber Cantidad",
        type_your_exchange: 'Inserta tu Exchange',
        select_wallet: '',
        wallet_address: '',
        blockchain_name: '',
        exchange: 'Exchange',
        stock: 'Titulo',

        auth: auth.es,
        signup: signup.es,
        register_success: register_success.es,
        recovery_form: recovery_form.es,
        reset_form: reset_form.es,
        confirm_page: confirm_page.es,
        amend_modal: amend_modal.es,
        new_owner: new_owner.es,
        new_view: new_view.es,
    },

    en: {
        add_the_new_portfolio: 'Add the new portfolio',
        or_create_portfolio: 'Add a new portfolio',
        write_portfolio_name: 'Type the portfolio name',
        add_portfolio: 'Add portfolio',
        submit: 'Submit',
        skip: 'Skip',
        add: 'Add',
        connect: 'Connect',
        continue: 'Continue',
        search: 'Search',
        security_or_ticker: 'Name or Ticker (e.g Tesla, TSLA)',
        delete: 'Delete',
        back: 'Back',
        cancel: 'Cancel',

        select_portfolio: 'Select portfolio',
        currency: "Currency (e.g. British Pound)",
        cryptocurrency: 'Cryptocurrency (e.g. Bitcoin)',
        amount: "Type Quantity ",
        type_your_exchange: 'Type your Exchange',
        select_wallet: '',
        wallet_address: '',
        blockchain_name: '',
        exchange: 'Exchange',
        stock: 'Stock',

        auth: auth.en,
        signup: signup.en,
        register_success: register_success.en,
        recovery_form: recovery_form.en,
        reset_form: reset_form.en,
        confirm_page: confirm_page.en,
        amend_modal: amend_modal.en,
        new_owner: new_owner.en,
        new_view: new_view.en,
    },

    fr: {
        add_the_new_portfolio: 'Ajoutez le nouveau portefeuille',
        or_create_portfolio: 'Ajoutez un nouveau portefeuille',
        write_portfolio_name: 'Écrivez le nom du portefeuille',
        add_portfolio: 'Ajouter Portefeuille',
        submit: 'Envoyer',
        skip: 'Ignorer',
        add: 'Ajouter',
        connect: 'Connecter',
        continue: 'Continuer',
        search: 'Recherche',
        security_or_ticker: 'Nom ou Ticker (e.g Tesla, TSLA)',
        delete: 'Supprimer',
        back: 'Retourner',
        cancel: 'Annuler',

        select_portfolio: 'Sélectionner Portefeuille',
        currency: "Devise (e.g. Euro)",
        cryptocurrency: 'Crypto-monnaie (e.g. Bitcoin)',
        amount: "Insérer Quantité",
        type_your_exchange: 'Insérez votre Échange',
        select_wallet: '',
        wallet_address: '',
        blockchain_name: '',
        exchange: 'Exchange',
        stock: 'Titre',

        auth: auth.fr,
        signup: signup.fr,
        register_success: register_success.fr,
        recovery_form: recovery_form.fr,
        reset_form: reset_form.fr,
        confirm_page: confirm_page.fr,
        amend_modal: amend_modal.fr,
        new_owner: new_owner.fr,
        new_view: new_view.fr,
    }
};
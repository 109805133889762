



export default {

    it: {

        add_block: 'Aggiungi blocco',
        search_block: 'Cerca blocco',

        // asset distribution
        units_in_your_portfolios: 'Unità nei tuoi portfolio',
        portfolio_distribution: 'Distribuzione sui portfolio',
        distribution_over_portfolios: "Distribuzione dell'asset nei tuoi portfolio e account",
        portfolio: 'Portfolio',
        Units: 'Unità',
        percentage: 'Percentuale',
        you_own: 'Possiedi',
        value: 'Valore',

        // company profile
        company_profile_title: 'Profilo dell\'azienda',
        sector: 'Settore:',
        industry: 'Industry:',
        ceo: 'CEO:',
        description: 'Descrizione (ENG):',
        show_more: 'Mostra di più...',
        show_less: 'Mostra di meno',
        could_not_find_profile: 'Non abbiamo potuto ottenere i dati sul profilo di questa azienda',

        // valuation metrics
        company_valuation_metrics_title: 'Indicatori economici',
        could_not_find_valuations: 'Non abbiamo potuto ottenere gli indicatori economici per questo asset',
        pe_ratio: 'P/E Ratio',
        ps_ratio: 'P/S Ratio',
        pb_ratio: 'P/B Ratio',

        // historical graph
        price_history_title: 'Prezzo storico',
        one_week_label: '1 settimana',
        one_month_label: '1 mese',
        three_months_label: '3 mesi',
        one_year_label: '1 anno',
        three_years_label: '3 anni',
        five_years_label: '5 anni',
        one_week_ago: '1 settimana fa',
        three_month_ago: '3 mesi fa',
        year_to_date: 'Da inizio anno',
        ytd: 'YTD',
        max_label: 'MAX',
        could_not_fetch_historicals: 'Non abbiamo potuto ottenere i prezzi storici per questo asset',

        // dividends
        company_dividends_title: 'Dividendi',
        dividend_rate: 'Tasso del dividendo:',
        dividend_yield: 'Rendimento del dividendo:',
        payout_ratio: 'Payout ratio:',
        dividend_date: 'Data di pagamento:',
        could_not_fetch_dividends: 'Non abbiamo potuto ottenere i dividendi per questo asset',

        // insider transactions
        insider_transactinos_title: 'Transazioni degli insider',
        insider: 'Insider',
        transaction: 'Transazione',
        shares: 'Quote',
        date: 'Data',
        buy: 'COMPRA',
        sell: 'VENDE',
        units: 'unità',
        see_all_transactions: 'Mostra tutte le transazioni',
        see_less_transactions: 'Mostra meno transazioni',
        no_insider_transactions: 'Nessuna transazione insider da mostrare per questa azienda',
    },

    es: {

        add_block: 'Añadir bloque',
        search_block: 'Busca bloque',

        // asset distribution
        units_in_your_portfolios: 'Units in your portfolios',
        portfolio_distribution: 'Distribution over portfolios',
        distribution_over_portfolios: 'Distribution over portfolios',
        portfolio: 'Portfolio',
        Units: 'Units',
        percentage: 'Percentage',
        you_own: 'You own',
        value: 'Value',

        // company profile
        company_profile_title: 'Company Profile',
        sector: 'Sector:',
        industry: 'Industry:',
        ceo: 'CEO:',
        description: 'Description:',
        show_more: 'Show more...',
        show_less: 'Show less',
        could_not_find_profile: 'We could not find the profile of this company',

        // valuation metrics
        company_valuation_metrics_title: 'Company Valuation Metrics',
        could_not_find_valuations: 'We could not find company valuation metrics for this stock',
        pe_ratio: 'P/E Ratio',
        ps_ratio: 'P/S Ratio',
        pb_ratio: 'P/B Ratio',

        // historical graph
        price_history_title: 'Price History',
        one_week_label: '1 Week',
        one_month_label: '1 Month',
        three_months_label: '3 Months',
        one_year_label: '1 Year',
        three_years_label: '3 Years',
        five_years_label: '5 Years',
        one_week_ago: '1 Week ago',
        three_month_ago: '3 Months ago',
        year_to_date: 'Year to Date',
        ytd: 'YTD',
        max_label: 'MAX',
        could_not_fetch_historicals: 'We could not fetch historical prices for this asset',

        // dividends
        company_dividends_title: 'Company Dividends',
        dividend_rate: 'Dividend Rate:',
        dividend_yield: 'Dividend Yield:',
        payout_ratio: 'Payout ratio:',
        dividend_date: 'Dividend date:',
        could_not_fetch_dividends: 'We could not find dividends for this stock',

        // insider transactions
        insider_transactinos_title: 'Insider Transactions',
        insider: 'Insider',
        transaction: 'Transaction',
        shares: 'Shares',
        date: 'Date',
        buy: 'BUY',
        sell: 'SELL',
        units: 'units',
        see_all_transactions: 'See al transactions',
        see_less_transactions: 'See less transaction',
        no_insider_transactions: 'No insider transactions to show for this company',
    },

    en: {

        add_block: 'Add block',
        search_block: 'Search block',

        // asset distribution
        units_in_your_portfolios: 'Units in your portfolios',
        portfolio_distribution: 'Distribution over portfolios',
        distribution_over_portfolios: 'Distribution over portfolios',
        portfolio: 'Portfolio',
        Units: 'Units',
        percentage: 'Percentage',
        you_own: 'You own',
        value: 'Value',

        // company profile
        company_profile_title: 'Company Profile',
        sector: 'Sector:',
        industry: 'Industry:',
        ceo: 'CEO:',
        description: 'Description:',
        show_more: 'Show more...',
        show_less: 'Show less',
        could_not_find_profile: 'We could not find the profile of this company',

        // valuation metrics
        company_valuation_metrics_title: 'Company Valuation Metrics',
        could_not_find_valuations: 'We could not find company valuation metrics for this stock',
        pe_ratio: 'P/E Ratio',
        ps_ratio: 'P/S Ratio',
        pb_ratio: 'P/B Ratio',

        // historical graph
        price_history_title: 'Price History',
        one_week_label: '1 Week',
        one_month_label: '1 Month',
        three_months_label: '3 Months',
        one_year_label: '1 Year',
        three_years_label: '3 Years',
        five_years_label: '5 Years',
        one_week_ago: '1 Week ago',
        three_month_ago: '3 Months ago',
        year_to_date: 'Year to Date',
        ytd: 'YTD',
        max_label: 'MAX',
        could_not_fetch_historicals: 'We could not fetch historical prices for this asset',

        // dividends
        company_dividends_title: 'Company Dividends',
        dividend_rate: 'Dividend Rate:',
        dividend_yield: 'Dividend Yield:',
        payout_ratio: 'Payout ratio:',
        dividend_date: 'Dividend date:',
        could_not_fetch_dividends: 'We could not find dividends for this stock',

        // insider transactions
        insider_transactinos_title: 'Insider Transactions',
        insider: 'Insider',
        transaction: 'Transaction',
        shares: 'Shares',
        date: 'Date',
        buy: 'BUY',
        sell: 'SELL',
        units: 'units',
        see_all_transactions: 'See al transactions',
        see_less_transactions: 'See less transaction',
        no_insider_transactions: 'No insider transactions to show for this company',
    },

    fr: {

        add_block: 'Ajouter bloc',
        search_block: 'Chercher bloc',

        // asset distribution
        units_in_your_portfolios: 'Units in your portfolios',
        portfolio_distribution: 'Distribution over portfolios',
        distribution_over_portfolios: 'Distribution over portfolios',
        portfolio: 'Portfolio',
        Units: 'Units',
        percentage: 'Percentage',
        you_own: 'You own',
        value: 'Value',

        // company profile
        company_profile_title: 'Company Profile',
        sector: 'Sector:',
        industry: 'Industry:',
        ceo: 'CEO:',
        description: 'Description:',
        show_more: 'Show more...',
        show_less: 'Show less',
        could_not_find_profile: 'We could not find the profile of this company',

        // valuation metrics
        company_valuation_metrics_title: 'Company Valuation Metrics',
        could_not_find_valuations: 'We could not find company valuation metrics for this stock',
        pe_ratio: 'P/E Ratio',
        ps_ratio: 'P/S Ratio',
        pb_ratio: 'P/B Ratio',

        // historical graph
        price_history_title: 'Price History',
        one_week_label: '1 Week',
        one_month_label: '1 Month',
        three_months_label: '3 Months',
        one_year_label: '1 Year',
        three_years_label: '3 Years',
        five_years_label: '5 Years',
        one_week_ago: '1 Week ago',
        three_month_ago: '3 Months ago',
        year_to_date: 'Year to Date',
        ytd: 'YTD',
        max_label: 'MAX',
        could_not_fetch_historicals: 'We could not fetch historical prices for this asset',

        // dividends
        company_dividends_title: 'Company Dividends',
        dividend_rate: 'Dividend Rate:',
        dividend_yield: 'Dividend Yield:',
        payout_ratio: 'Payout ratio:',
        dividend_date: 'Dividend date:',
        could_not_fetch_dividends: 'We could not find dividends for this stock',

        // insider transactions
        insider_transactinos_title: 'Insider Transactions',
        insider: 'Insider',
        transaction: 'Transaction',
        shares: 'Shares',
        date: 'Date',
        buy: 'BUY',
        sell: 'SELL',
        units: 'units',
        see_all_transactions: 'See al transactions',
        see_less_transactions: 'See less transaction',
        no_insider_transactions: 'No insider transactions to show for this company',
    },
};
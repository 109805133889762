<template>

    <div class="tier-frame">
        <img src="@/assets/img/illustrations/Mention.svg" />
        <div class="user-tier-description">
            <p class="title-medium black">
                {{ $t('rewards.complete_missions') }}
            </p>
            <ul>
                <li>
                    <p class="body-medium black">
                        {{ $t('rewards.get_special_badge') }}
                    </p>
                </li>
                <li>
                    <p class="body-medium black">
                        {{ $t('rewards.see_future_developments') }}
                    </p>
                </li>
                <li>
                    <p class="body-medium black">
                        {{ $t('rewards.join_voting_room') }}
                    </p>
                </li>
            </ul>
            <tasks-frame-component :rewardStep="rewardStep" />
        </div>
    </div>
</template>

<script>

    import TasksFrameComponent from '@/components/profile/rewards/TasksFrame.vue';

    export default {
        name: 'Adventurer-Tier-Component',
        props: ['points', 'rewardStep'],
        components: {
            TasksFrameComponent
        }
    }
</script>

<style scoped>

    div.tier-frame {
        display: flex;
        justify-content: space-between;
        padding: 24px;
    }

        div.tier-frame > img {
            max-width: 72%;
            margin: auto;
        }

        div.tier-frame > div.user-tier-description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
        }

            div.tier-frame > div.user-tier-description p {
                padding: 0px 16px;
            }

            div.tier-frame > div.user-tier-description > ul {
                list-style-type: circle;
                margin-left: 32px;
            }

        @media (max-width: 904px) {
            div .tier-frame {
                flex-direction: column;
            }

                div.tier-frame > div.user-tier-description {
                    align-items: center;
                }
        }
</style>
<template>

    <top-bar />

    <div class="image-frame">

        <img class="img-responsive" :src="require('@/assets/img/illustrations/success.svg')" />

        <!-- the user is not logged in -->

        <template v-if="userId">
            <h1 class="headline-medium white">
                {{ $t('forms.register_success.register_success') }}
            </h1>
            <button class="secondary full-rounded"
                    @click.prevent="goToDashboard">
                <p class="label-large ">
                    {{ $t('forms.register_success.enter_the_platform') }}
                </p>
            </button>
            <br>
        </template>

        <!-- the user is NOT logged in -->

        <template v-else>
            <h1 class="headline-medium white">
                {{ $t('forms.register_success.check_your_email') }}
            </h1>
            <p class="title-medium white">
                {{ $t('forms.register_success.verify_your_email_address') }}
            </p>
        </template>
    </div>

</template>

<script>

    import TopBar from "@/components/auth/TopBar";

    export default {
        name: "Register-Page",
        components: {
            TopBar
        },
        beforeCreate: function () {
            document.body.className = 'register-success';
        },
        computed: {
            userId() {
                return this.$store.getters['auth/getUserId'];
            }
        },
        methods: {
            goToDashboard() {
                this.$router.push({ path: '/dashboard' });
            }
        }
    };
</script>

<style scoped>

    .image-frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        margin-top: 80px;
    }

    @media (min-width: 905px) {
        .img-responsive {
            object-fit: contain;
            width: 24vw;
            margin: 0 auto;
        }
    }

    @media (max-width: 904px) {
        .img-responsive {
            object-fit: contain;
            width: 80%;
            max-width: 70vh;
            margin: 0 auto;
        }
    }
</style>
import api from '@/services/api';

class PatchUsersOpenbankingAccountMetadata {

  patch(userId, body) {
    return api.patch('/v1/users/'+ userId +'/openbanking/account/metadata', body);
  }
}

export default new PatchUsersOpenbankingAccountMetadata();

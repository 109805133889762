import api from '@/services/api';

class PutUsersOpenbankingAccountOwners {

    put(userId, body) {
        return api.put('/v1/users/' + userId + '/openbanking/account/owners', body);
    }
}

export default new PutUsersOpenbankingAccountOwners();
